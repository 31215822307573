import { useApolloClient, useQuery } from "@apollo/client";
import { useAsyncList } from "@react-stately/data";
import Alert from "components/atoms/Alert";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import NoResults from "components/atoms/NoResults";
import SectionCard from "components/atoms/SectionCard";
import GraphqlError from "components/GraphqlError";
import Layout from "components/layouts/OneColumn";
import AddBptTemplateModalWithButton from "components/molecules/admin/bptTemplates/Template/AddBptTemplateModal/AddBptTemplateButtonWithModal";
import BptAdminPageFilterForm, {
  defaultQueryFields,
  QueryFields,
} from "components/molecules/admin/bptTemplates/Template/BptAdminPageFilterForm";
import PageSizeSelect from "components/molecules/PageSizeSelect";
import Pagination from "components/molecules/Pagination";
import ResultsTable from "components/organisms/search/ResultsTable/ResultsTable";
import { RenderWhenAuthorized, ROLE_ACTIONS } from "features/auth/components";
import { GlobalAlert, useGlobalAlertContext } from "features/globalAlert";
import {
  AuthorizationCurrentUserDocument,
  BptTemplateListDocument,
  BptTemplateStage,
} from "generated/gql-types";
import useAdminAlertMsg from "hooks/admin/useAdminAlertMsg";
import { usePagination } from "hooks/util/usePagination";
import React from "react";
import { flushSync } from "react-dom";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { formatTimestamp } from "util/formatTimestamp";
import { AdminPathHelpers } from "./AdminRouter";

export const AdminBptTemplatesPage: React.FC = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const globalAlertContext = useGlobalAlertContext();
  const [filters, setFilters] = React.useState<QueryFields>(defaultQueryFields);
  const adminAlertMsg = useAdminAlertMsg(t("bptTemplate"));
  const client = useApolloClient();
  const [totalCount, setTotalCount] = React.useState<number>(0);
  const [totalPages, setTotalPages] = React.useState<number>(0);
  const pagination = usePagination();
  const {
    loading: loadingCurrentUser,
    error: errorCurrentUser,
    data: dataCurrentUser,
  } = useQuery(AuthorizationCurrentUserDocument, {
    errorPolicy: "all",
    fetchPolicy: "network-only",
  });
  const getSortByOrder = (column: string, direction: string) => {
    const sortBy = () => {
      switch (column) {
        case "name":
          return "name.text";
        case "program":
          return i18n.language === "fr"
            ? "program.name.french"
            : "program.name.english";
        default:
          return column;
      }
    };
    const sortOrder = () => {
      return direction === "ascending" ? "asc" : "desc";
    };
    return `${sortBy()}:${sortOrder()}`;
  };

  const getStage = (includeDeactivatedAndObsolete: boolean) => {
    let stage =
      BptTemplateStage.Draft.toString() +
      "," +
      BptTemplateStage.Active.toString();

    if (includeDeactivatedAndObsolete) {
      return (
        stage +
        "," +
        BptTemplateStage.Inactive.toString() +
        "," +
        BptTemplateStage.Obsolete.toString()
      );
    } else {
      return stage;
    }
  };

  React.useEffect(() => {
    rows.reload(); // refetchQueries
  }, [pagination.currentPage, pagination.pageSize]);

  const rows = useAsyncList<any>({
    initialSortDescriptor: {
      column: "name",
      direction: "ascending",
    },
    async load({ cursor, filterText, sortDescriptor }: any) {
      const results = await client.query({
        errorPolicy: "all",
        query: BptTemplateListDocument,
        variables: {
          params: {
            pageNumber: pagination.currentPage,
            pageSize: pagination.pageSize,
            stage: getStage(filterText.includeDeactivatedAndObsolete),
            sortBy: getSortByOrder(
              sortDescriptor.column,
              sortDescriptor.direction
            ),
            search:
              filterText.search.length > 0 ? filterText.search : undefined,
          },
        },
        fetchPolicy: "network-only",
      });

      const items =
        results.data.bptTemplateList?.templates?.map((template) => {
          let name: string | undefined = "";

          if (template?.name?.text != null) {
            name += `${template?.name?.text} `;
          }

          // Show "no data" if the name is empty
          if (name.length === 0) {
            name = undefined;
          }

          return {
            ...template,
            _href: `/admin/bptTemplates/${template?.id}`,
            createdAt: formatTimestamp(template?.createdAt),
            modifiedAt: formatTimestamp(template?.modifiedAt),
            name: name,
            program:
              i18n.language === "fr"
                ? t(template?.program?.name?.french ?? "")
                : t(template?.program?.name?.english ?? ""),
            stage: t(template?.stage ?? ""),
            visibility: t(template?.visibility ?? ""),
          };
        }) ?? [];

      setTotalCount(results.data.bptTemplateList?.pagination?.totalCount ?? 0);
      setTotalPages(results.data.bptTemplateList?.pagination?.totalPages ?? 0);

      return {
        items,
        sortDescriptor,
      };
    },
  });

  const columns = React.useMemo(
    () => [
      {
        name: t("name"),
        key: "name",
        sortable: true,
        hasLink: true,
        isHTML: true,
      },
      {
        name: t("program"),
        key: "program",
        sortable: true,
      },
      {
        name: t("version"),
        key: "templateVersion",
        sortable: true,
      },
      {
        name: t("updated_date"),
        key: "modifiedAt",
        sortable: true,
      },
      {
        name: t("updated_by"),
        key: "modifiedBy",
        sortable: true,
      },
      {
        name: t("stage"),
        key: "stage",
        sortable: true,
      },
      {
        name: t("project_visibility"),
        key: "visibility",
        sortable: true,
      },
    ],
    [t]
  );

  const onFilterSubmit = (arr: QueryFields) => {
    flushSync(() => {
      setFilters(arr);
      rows.setFilterText(arr as any);
      pagination.goToPage(1);
    });
  };

  return (
    <>
      <Layout.Root>
        <Layout.Content>
          <GlobalAlert />
          <h1>{t("business_process_tracking_templates")}</h1>
          <GraphqlError title={t("error_loading_bpt")} errors={rows?.error} />
          <GraphqlError title={t("fetch_fail")} errors={errorCurrentUser} />

          {adminAlertMsg.alertIsVisible ? (
            <Alert
              type={adminAlertMsg.pageAlertType!}
              content={adminAlertMsg.pageAlertMsg!}
              onClose={adminAlertMsg.close}
              timeOut={5000}
            />
          ) : null}

          <SectionCard
            header={
              <div className="flex justify-between align-start">
                <h2>{t("all_templates")}</h2>
                <div className="flex gap-md">
                  <RenderWhenAuthorized
                    authorizedRoles={
                      ROLE_ACTIONS.administration.bptTemplate.create
                    }
                  >
                    <AddBptTemplateModalWithButton
                      onCompleted={(data: any) => {
                        rows.reload(); // refetchQueries
                        //Todo: Need to fix this message to show
                        globalAlertContext.showSuccess({
                          message:
                            i18n.language === "fr"
                              ? data?.createBptTemplate?.name?.english
                              : data?.createBptTemplate?.name?.french,
                          timeOut: 5000,
                        });
                        history.push({
                          pathname: AdminPathHelpers.BptTemplates(),
                        });
                      }}
                      onError={adminAlertMsg.onCreateError}
                      program={
                        dataCurrentUser?.authorizationCurrentUser?.program
                      }
                      title={t("create_new_bpt_template")}
                    />
                  </RenderWhenAuthorized>
                </div>
              </div>
            }
          >
            {/*FILTERS:*/}
            <div className="flex justify-between flex-wrap mrgn-bttm-sm">
              <BptAdminPageFilterForm onSubmit={onFilterSubmit} />
              <div className="flex-auto flex-col gap-sm align-end">
                <div>
                  <PageSizeSelect
                    pageSize={pagination.pageSize}
                    onChangePageSize={pagination.setPageSize}
                  />
                </div>
                <div className="font-size-16 justify-end pb-3">
                  {pagination.makeShowingString(totalCount)}
                </div>
              </div>
            </div>

            {/*RESULTS*/}
            {rows.isLoading || loadingCurrentUser ? (
              <LoadingIndicator centered className="mrgn-bttm-md" />
            ) : rows && rows?.items?.length === 0 ? (
              <NoResults centered />
            ) : (
              <>
                {rows == null || rows?.items?.length === 0 ? null : (
                  <>
                    <ResultsTable
                      rows={rows.items}
                      columns={columns}
                      sortable
                      sortDescriptor={rows.sortDescriptor}
                      onSortChange={rows.sort}
                    />

                    <Pagination
                      {...pagination.paginationComponentProps}
                      totalPages={totalPages ?? 0}
                    />
                  </>
                )}
              </>
            )}
          </SectionCard>
        </Layout.Content>
      </Layout.Root>
    </>
  );
};

export default AdminBptTemplatesPage;
