import { formatTimestamp } from "util/formatTimestamp";
import { Timeline } from "../../../../generated/gql-types";
import NoAnswer from "./NoAnswer";

export const getProjectTimeline = (timeline: Timeline | undefined | null) => {
  const startDate = formatTimestamp(timeline?.startDate) ?? "";
  const endDate = formatTimestamp(timeline?.endDate) ?? "";

  return timeline != null ? (
    <>
      {startDate ? startDate : <NoAnswer />} {" - "}
      {endDate ? endDate : <NoAnswer />}
    </>
  ) : null;
};
