import cc from "classcat";
import { get } from "lodash";
import * as React from "react";
import { FieldErrors } from "react-hook-form";
import { useTranslation } from "react-i18next";
import FieldValidationError from "./FieldValidationError";
import { lTrimInputText, rTrimInputText } from "util/strings";

export interface TextInputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  label: string;
  info?: string;
  errors?: FieldErrors;
  divClassName?: string;
  labelClassName?: string;
  inputClassName?: string;
  data_testid?: string;
  pattern?: string | undefined;
  type?: "text" | "email" | "hidden" | "password" | "tel" | "url";
}

export const TextInput = React.forwardRef(
  (
    {
      label,
      errors,
      divClassName,
      labelClassName,
      inputClassName,
      type = "text",
      data_testid,
      pattern,
      children,
      ...props
    }: TextInputProps,
    ref
  ) => {
    const { t } = useTranslation();

    const name = props.name ?? "";
    const labelText = label ?? props.name ?? "";
    const id = props.id ?? name;
    const placeholder = props.placeholder ?? "";
    const maxLength = props.maxLength ?? 100;

    const err = get(errors, name);
    const shouldShowErrors = err != null;

    const _divClassName = React.useMemo(
      () => cc(["form-group", divClassName]),
      [divClassName]
    );
    const _labelClassName = React.useMemo(
      () => cc([props.required ? "required" : "", labelClassName]),
      [labelClassName, props.required]
    );

    const _inputClassName = React.useMemo(
      () => cc(["form-control", inputClassName]),
      [inputClassName]
    );

    const { required, ...propsWithoutRequired } = props;

    return (
      <div className={_divClassName}>
        <label htmlFor={id} className={_labelClassName}>
          {labelText}
          {props.required ? (
            <strong className="required"> ({t("required")})</strong>
          ) : null}
        </label>
        {props.info != null ? (
          <>
            <br />
            <div className="label label-info mrgn-tp-0 mrgn-bttm-lg">
              {props.info}
            </div>
          </>
        ) : null}

        <input
          type={type}
          id={id}
          className={_inputClassName}
          placeholder={placeholder}
          maxLength={maxLength}
          data-testid={data_testid}
          onInput={lTrimInputText}
          onBlurCapture={rTrimInputText}
          pattern={pattern}
          {...propsWithoutRequired}
          ref={ref as any}
        />
        {shouldShowErrors && (
          <FieldValidationError>{err.message}</FieldValidationError>
        )}
        {children}
      </div>
    );
  }
);
