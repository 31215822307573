import * as React from "react";
import { useTranslation } from "react-i18next";
import EditStepLinkModal, { useEditStepLinkModal } from "./EditStepLinkModal";

const EditStepLinkBtn: React.FC<any> = ({ ...props }) => {
  const { t } = useTranslation();
  const modalState = useEditStepLinkModal({});
  const validProjectId = props.projectId != null && props.projectId.length > 0;

  return (
    <>
      <button
        type="button"
        className="btn btn-link card-header-button-link font-size-14 hover-grey"
        onClick={modalState.open}
        disabled={!validProjectId || props.disabled}
        data-testid="button-edit-step-link"
        title={t("edit_link")}
      >
        <i className="fas fa-pen"></i>
      </button>
      {validProjectId ? (
        <EditStepLinkModal modalState={modalState} {...props} />
      ) : null}
    </>
  );
};

export default EditStepLinkBtn;
