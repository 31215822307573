import Layout from "components/layouts/TwoColumn";
import SideNav from "components/molecules/SideNav";
import { useOverviewContext } from "features/overview";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import ListingForm, {
  ListingFormOutput,
} from "../../components/organisms/listing/ListingForm";
import { ChangeTrackingProvider } from "../../features/changeTracking";
import { SubmitHandler } from "react-hook-form";
import { ListingPathHelpers } from "./ListingRouter";
import { mapListingToListingFormFields } from "../../components/organisms/listing/ListingForm/util";
import { useMutation } from "@apollo/client";
import { CreateListingWsDocument } from "../../generated/gql-types";
import GraphqlError from "../../components/GraphqlError";

const Create: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const overviewContext = useOverviewContext();

  React.useEffect(() => {
    overviewContext.clearOverview();
  }, []);

  const [createListingWs, { loading: saving, error: savingError }] =
    useMutation(CreateListingWsDocument, {
      refetchQueries: ["ListingProfile"],
      errorPolicy: "all",
    });

  const onSubmit: SubmitHandler<Partial<ListingFormOutput>> = async (
    values
  ) => {
    try {
      const res = await createListingWs({
        variables: {
          input: values,
        },
      });

      if (res.errors) throw res.errors;

      const listingWsId = res.data?.createListingWs?.id;

      history.push({
        pathname: ListingPathHelpers.ListingProfile(listingWsId ?? "error"),
      });

      // Refetch overviewContext to update ribbon and sideNav
      overviewContext.refetch();
    } catch (e) {
      console.error(e);
    }
  };

  const initialFormFields = mapListingToListingFormFields({} as any);

  return (
    <>
      <Layout.Root className="mrgn-tp-md">
        <Layout.Content>
          <GraphqlError
            title="Error creating new Listing WS"
            errors={savingError}
          />

          <h1>{t("add_listing_wildlife_species")}</h1>
          {saving ? <p>Saving...</p> : null}

          <ChangeTrackingProvider isEnabled={false}>
            <ListingForm
              onSubmit={onSubmit}
              initialValues={initialFormFields}
            />
          </ChangeTrackingProvider>
        </Layout.Content>
        <Layout.Sidebar>
          <SideNav />
        </Layout.Sidebar>
      </Layout.Root>
    </>
  );
};

export default Create;
