import { useApolloClient } from "@apollo/client";
import { Item } from "@react-stately/collections";
import { useAsyncList } from "@react-stately/data";
import { useMutation } from "@tanstack/react-query";
import { getApiContactServiceUrl } from "azure/environment";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import { MenuButton } from "components/atoms/MenuButton/MenuButton";
import MissingData from "components/atoms/MissingData";
import SectionCard from "components/atoms/SectionCard";
import PageSizeSelect from "components/molecules/PageSizeSelect";
import Pagination from "components/molecules/Pagination";
import InfoButtonAndModal from "components/organisms/InfoButtonAndModal/InfoButtonAndModal";
import AddContactButtonAndModal from "components/organisms/contacts/AddContactButtonAndModal/AddContactButtonAndModal";
import { ContactType } from "components/organisms/contacts/types";
import ResultsTable, {
  ColumnHeader,
} from "components/organisms/search/ResultsTable/ResultsTable";
import SearchFilterForm, {
  QueryFields,
  defaultQueryFields,
} from "components/organisms/search/SearchFilterForm/SearchFilterForm";
import { SEARCH_DEBOUNCE_TIME_MS } from "config/constants";
import { getUserAccessToken } from "features/auth/CustomMsalProvider";
import { ROLE_ACTIONS, RenderWhenAuthorized } from "features/auth/components";
import { useGlobalAlertContext } from "features/globalAlert";
import {
  Contact,
  DistributionListMemberDocument,
  Organization,
} from "generated/gql-types";
import { usePagination } from "hooks/util/usePagination";
import pDebounce from "p-debounce";
import * as React from "react";
import { flushSync } from "react-dom";
import { useTranslation } from "react-i18next";
import { formatTimestamp } from "util/formatTimestamp";
import isNullOrEmpty from "util/isNullOrEmpty";
import { ContactOrganizationInfo } from "../ContactOrganizationInfo/ContactOrganizationInfo";
import EmailsPopup, {
  useEmailsModal,
} from "../EmailsButtonAndModal/EmailsPopup";
import { RemoveContactButtonAndModal } from "../RemoveContactButtonAndModal/RemoveContactButtonAndModal";
import { VisibilitySwitch } from "../VisibilitySwitch/VisibilitySwitch";

interface ManageContactsOrganizationsCardProps {
  distributionListId: string;
  distributionListName: string;
  updatedBy?: string | null;
  updatedDate?: string | null;
  onAddContactOrOrg: (
    newValue: Contact | Organization | undefined | null,
    contactType: ContactType
  ) => void;
  onRemoveContactOrOrg: (memberId: string) => void;
}

export const ManageContactsOrganizationsCard: React.FC<
  ManageContactsOrganizationsCardProps
> = (props) => {
  const { distributionListId, distributionListName, updatedBy, updatedDate } =
    props;
  const { t, i18n } = useTranslation();
  const client = useApolloClient();
  const pagination = usePagination();
  const alertContext = useGlobalAlertContext();
  const modalState = useEmailsModal({});

  const [selectedRowId, setSelectedRowId] = React.useState<string>("");
  const [filters, setFilters] = React.useState<QueryFields>(defaultQueryFields);
  const [totalCount, setTotalCount] = React.useState<number>(0);
  const [totalPages, setTotalPages] = React.useState<number>(0);

  const getSortByOrder = (column: string, direction: string) => {
    const sortBy = () => {
      switch (column) {
        case "visibilityState":
        case "name":
          return i18n.language === "fr" ? "name.french" : "name.english";
        case "title":
          return i18n.language === "fr" ? "title.french" : "title.english";
        case "organizationName":
          return i18n.language === "fr"
            ? "organizationName.french"
            : "organizationName.english";
        default:
          return column;
      }
    };
    const sortOrder = () => {
      return direction === "ascending" ? "asc" : "desc";
    };
    return `${sortBy()}:${sortOrder()}`;
  };

  const debouncedSearch = React.useMemo(() => {
    return pDebounce(
      (_cursor: number, _filterText: any, _sortDescriptor: any) =>
        client.query({
          errorPolicy: "all",
          query: DistributionListMemberDocument,
          variables: {
            distributionListMemberId: distributionListId,
            params: {
              search:
                _filterText.search.length > 0 ? _filterText.search : undefined,
              sortBy: getSortByOrder(
                _sortDescriptor.column,
                _sortDescriptor.direction
              ),
              pageNumber: pagination.currentPage,
              pageSize: pagination.pageSize,
            },
          },
          fetchPolicy: "network-only",
        }),
      SEARCH_DEBOUNCE_TIME_MS
    );
  }, [pagination.currentPage, pagination.pageSize]);

  const onSelectionChange = (keys: "all" | Set<string>) => {
    if (keys === "all") return;
    const selectedId = Array.from(keys)[0] ?? "";
    setSelectedRowId(selectedId);
  };

  const contactInfoButtonAndModalCallback = (rowItem: any) => (
    <InfoButtonAndModal
      text={rowItem.name || <MissingData />}
      title={t("contact_organization_information")}
      className={"text-left mrgn-0"}
      classNameModal="modal-width-50"
    >
      <ContactOrganizationInfo
        contactRefId={rowItem.contactRefId}
        organizationRefId={rowItem.organizationRefId}
      />
    </InfoButtonAndModal>
  );

  const visibilitySwitchCallback = (rowItem: any) => (
    <VisibilitySwitch
      distributionListId={rowItem?.distributionListId}
      memberId={rowItem?.id}
      visibilityState={rowItem?.visibilityState}
    />
  );

  React.useEffect(() => {
    setSelectedRowId("");
  }, [filters, pagination.pageSize]);

  React.useEffect(() => {
    rows.reload(); // refetch DistributionListMember
    setSelectedRowId("");
  }, [pagination.currentPage, pagination.pageSize]);

  const columns: ColumnHeader[] = React.useMemo(
    () => [
      {
        name: t("email"),
        key: "email",
        sortable: true,
      },
      {
        name: t("member"),
        key: "name",
        sortable: true,
        isCallback: true,
        callbackFn: contactInfoButtonAndModalCallback,
      },
      {
        name: t("title"),
        key: "title",
        sortable: true,
      },
      {
        name: t("organization"),
        key: "organizationName",
        sortable: true,
      },
      { name: t("city"), key: "city", sortable: true },
      {
        name: t("province_territory"),
        key: "provinceOrStateName",
        sortable: true,
      },
      {
        name: t("include_exclude"),
        key: "visibilityState",
        sortable: true,
        isCallback: true,
        callbackFn: visibilitySwitchCallback,
      },
    ],
    [t]
  );

  const rows = useAsyncList<any>({
    initialSortDescriptor: { column: "email", direction: "ascending" },
    async load({ cursor, filterText, sortDescriptor }: any) {
      const results = await debouncedSearch(cursor, filterText, sortDescriptor);
      const memberList = (results?.data?.distributionListMember?.members ??
        []) as Array<any>;

      const items =
        memberList.map((x) => {
          return {
            ...x,
            id: x?.memberId,
            email: x?.email,
            name: i18n.language === "fr" ? x?.name?.french : x?.name?.english,
            title:
              i18n.language === "fr" ? x?.title?.french : x?.title?.english,
            organizationName:
              i18n.language === "fr"
                ? x?.organizationName?.french
                : x?.organizationName?.english,
            city: x?.city,
            provinceOrState: t(x?.provinceOrState),
            provinceOrStateName:
              i18n.language === "fr"
                ? x?.provinceOrStateName?.french
                : x?.provinceOrStateName?.english,
            visibilityState: x?.visibilityState,
            distributionListId: distributionListId,
          };
        }) ?? [];

      setTotalCount(
        results.data.distributionListMember?.pagination?.totalCount ?? 0
      );
      setTotalPages(
        results.data.distributionListMember?.pagination?.totalPages ?? 0
      );

      return {
        items,
        sortDescriptor,
      };
    },
  });

  const onFilterSubmit = (value: QueryFields) => {
    flushSync(() => {
      rows.setFilterText(value as any); // pDebounce <- Commented out since this was causing duplicate queries upon search submission
      setFilters(value); // useState
      pagination.goToPage(1);
    });
  };

  const onSave = (
    newValue: Contact | Organization | undefined | null,
    contactType: ContactType
  ) => {
    props.onAddContactOrOrg(newValue, contactType);
    rows.reload(); // refetch table
    setSelectedRowId("");
  };

  const onRemove = (selectedMemberId: string) => {
    props.onRemoveContactOrOrg(selectedMemberId);
    rows.reload(); // refetch table
    setSelectedRowId("");
  };

  //#region Distribution list export.
  const fetchExport = async (params: {
    distributionListId: string;
    languageCode: string;
    isGCNotify: boolean;
  }) => {
    const token = await getUserAccessToken();
    let baseUrl =
      getApiContactServiceUrl("7093") +
      `distributionlist/${
        params.distributionListId
      }/export?sortBy=${getSortByOrder(
        rows.sortDescriptor?.column?.toString() ?? "",
        rows.sortDescriptor?.direction ?? ""
      )}`;

    if (params.isGCNotify) {
      baseUrl += "&exporttype=gcnotify";
    }

    const languageHeader = params.languageCode === "fr" ? "fr-CA" : "en-CA";

    return fetch(baseUrl, {
      method: "GET",
      body: null, // Not required.
      headers: {
        Authorization: `Bearer ${token}`,
        "Accept-Language": languageHeader,
      },
    })
      .then((res) => {
        // 204 = No content
        if (res.status === 204 || res.status < 200 || res.status >= 300) {
          throw new Error(res.statusText);
        }
        return res;
      })
      .then((res) => res.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", distributionListName + ".csv");
        document.body.appendChild(link);
        link.click();
        link?.parentNode?.removeChild(link);
      })
      .catch((e) => {
        alertContext.showError({
          title: t("download_failure"),
          message: e.message,
          timeOut: 5000,
        });
      });
  };

  const exportMutation = useMutation(
    ["contact", "distributionlist", "export"],
    fetchExport,
    {
      onError: () => {
        alertContext.showError({
          title: t("download_failure"),
          message: (exportMutation.error as any)?.message ?? undefined,
          timeOut: 5000,
        });
        exportMutation.reset();
        window.scrollTo(0, 260); // Scroll to page header to show error message.
      },
    }
  );

  const downloadExport = (
    distributionListId: string | undefined,
    isGCNotify: boolean
  ) => {
    if (distributionListId == null) return;
    const languageCode = i18n.language as any;
    if (languageCode !== "en" && languageCode !== "fr") {
      console.warn(
        `Assertion failed: Expected a language code of 'en' or 'fr', but got '${languageCode}'`
      );
    }

    return exportMutation.mutate({
      distributionListId,
      languageCode,
      isGCNotify,
    });
  };

  const noMember = rows?.items == null || rows?.items.length <= 0;

  const onExportAction = (key: any) => {
    switch (key) {
      case "all": {
        downloadExport(distributionListId, false);
        break;
      }
      case "gcnotify": {
        downloadExport(distributionListId, true);
        break;
      }
      case "outlook": {
        modalState.open();
        break;
      }
    }
  };
  //#endregion

  return (
    <>
      <SectionCard
        header={
          <div className="flex justify-between align-start flex-wrap">
            <h2>{t("manage_contacts_organizations")}</h2>
            <div className="flex justify-right flex-auto gap-md">
              <MenuButton
                label={t("export")}
                onAction={onExportAction}
                className="btn btn-link mrgn-0 py-0 px-2 card-header-button-link"
                disabled={noMember}
                buttonIcon={<i className="fas fa-download mrgn-rght-sm" />}
                loading={exportMutation.isLoading}
              >
                <Item key="all">{t("export_all_data")}</Item>
                <Item key="gcnotify">{t("export_emails_for_GC_Notify")}</Item>
                <Item key="outlook">{t("export_emails_for_Outlook")}</Item>
              </MenuButton>

              <RenderWhenAuthorized
                authorizedRoles={ROLE_ACTIONS.distributionList.update}
              >
                <RemoveContactButtonAndModal
                  selectedMemberId={selectedRowId}
                  onRemove={onRemove}
                  modalTitle={t("remove_contact_organization")}
                  warningText={t("remove_contact_member_warning")}
                />
              </RenderWhenAuthorized>
              <RenderWhenAuthorized
                authorizedRoles={ROLE_ACTIONS.distributionList.update}
              >
                <AddContactButtonAndModal
                  id={"button_add_contact"}
                  onSave={onSave}
                  isDisabled={rows.isLoading || rows.error != null}
                  className={"card-header-button-link"}
                  buttonText={t("add")}
                  buttonTitle={t("add_contact_organization")}
                />
              </RenderWhenAuthorized>
            </div>
          </div>
        }
      >
        {rows.isLoading ? (
          <LoadingIndicator centered className="mrgn-bttm-md" />
        ) : rows?.items == null || rows?.items.length <= 0 ? (
          <div className="text-center mrgn-tp-md">
            <div className="lead mrgn-tp-md mrgn-bttm-sm">
              <RenderWhenAuthorized
                authorizedRoles={ROLE_ACTIONS.distributionList.update}
              >
                <AddContactButtonAndModal
                  id={"button_add_contact"}
                  onSave={onSave}
                  isDisabled={rows.isLoading || rows.error != null}
                  className={"card-header-button-link"}
                  buttonText={t("add_contact_organization")}
                  buttonTitle={t("add_contact_organization")}
                  showImage={true}
                />
              </RenderWhenAuthorized>
            </div>
          </div>
        ) : (
          <>
            {/*FILTERS:*/}
            <div className="flex justify-between flex-wrap mrgn-bttm-sm">
              <SearchFilterForm onSubmit={onFilterSubmit} />
              <div className="flex-auto flex-col gap-sm align-end">
                <div>
                  <PageSizeSelect
                    pageSize={pagination.pageSize}
                    onChangePageSize={pagination.setPageSize}
                  />
                </div>
                <div className="font-size-16 justify-end pb-3">
                  {pagination.makeShowingString(totalCount)}
                </div>
              </div>
            </div>
            {/*RESULTS*/}

            <ResultsTable
              aria-label="Distribution list table" /*TODO: i18n*/
              rows={rows.items}
              columns={columns}
              onSelectionChange={onSelectionChange as any}
              selectedKeys={[selectedRowId]}
              sortable
              selectionMode="single"
              showSelectionCheckboxes
              sortDescriptor={rows.sortDescriptor}
              onSortChange={rows.sort}
            />

            <Pagination
              {...pagination.paginationComponentProps}
              totalPages={totalPages ?? 0}
            />
          </>
        )}
        <div className="flex separator-line-top text-muted font-size-14">
          <div>
            {t("updated_by")}
            {": "}
            {!isNullOrEmpty(updatedBy) ? updatedBy : <MissingData />}
          </div>
          <span className="px-2_5">{"|"}</span>
          <div>
            {t("updated_date")}
            {": "}
            {formatTimestamp(updatedDate) ?? <MissingData />}
          </div>
        </div>
      </SectionCard>
      {!noMember ? <EmailsPopup modalState={modalState} {...props} /> : null}
    </>
  );
};

export default ManageContactsOrganizationsCard;
