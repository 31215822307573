import { useMutation, useQuery } from "@apollo/client";
import GraphqlError from "components/GraphqlError";
import Alert, { AlertTypes } from "components/atoms/Alert";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import Layout from "components/layouts/TwoColumn";
import SideNav from "components/molecules/SideNav";
import Ribbon from "components/organisms/cosewic/Ribbon";
import { AssessmentFormOutput } from "components/organisms/cosewic/assessment/AssessmentForm";

import { ASSESSMENT_CREATE_WAIT_BEFORE_REFRESH_TIME_MS } from "config/constants";
import { ChangeTrackingProvider } from "features/changeTracking";
import { useOverviewContext } from "features/overview";
import {
  AssessmentInput,
  AssessmentReportType,
  AssessmentStage,
  AssessmentStatusChange,
  AssessmentType,
  CosewicAssessmentListDocument,
  CosewicWsStatus,
  CreateAssessmentDocument,
  WsStatus,
} from "generated/gql-types";
import { CosewicPathHelpers } from "pages/cosewic/CosewicRouter";
import * as React from "react";
import { SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";
import { convertFormToCreateInput } from "util/cosewic/assessment/forms";
import timeoutPromise from "util/timeoutPromise";
import AssessmentCreateForm from "../../../components/organisms/cosewic/assessment/AssessmentCreateForm/AssessmentCreateForm";

export interface AssessmentCreateProps {}

const AssessmentCreate: React.FC<AssessmentCreateProps> = () => {
  const { t } = useTranslation();
  const { params } = useRouteMatch();
  const history = useHistory();
  const { cosewicWsId } = params as any;
  const overviewContext = useOverviewContext();

  const [waitingForServices, setWaitingForServices] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    overviewContext.updateOverview("cosewicws", cosewicWsId);
  }, [cosewicWsId]);

  const {
    loading,
    error: cosewicDataError,
    data: cosewicData,
  } = useQuery(CosewicAssessmentListDocument, {
    variables: {
      id: cosewicWsId,
    },
  });

  const [createAssessment, { loading: saving, error: savingError }] =
    useMutation(CreateAssessmentDocument, {
      refetchQueries: ["CosewicProfile", "CosewicProfileEdit"],
      errorPolicy: "all",
    });

  const onSubmit: SubmitHandler<Partial<AssessmentFormOutput>> = async (
    data
  ) => {
    const converted = convertFormToCreateInput(data);

    const visualDefaultValues: Partial<AssessmentInput> = {
      type: AssessmentType.Regular,
      reportType: AssessmentReportType.NotInitialized,
      statusAndCriteria: {
        status: WsStatus.NoStatus,
        statusChange: AssessmentStatusChange.NoChange,
      },
    };

    try {
      const res = await createAssessment({
        variables: {
          cosewicId: cosewicWsId,
          input: {
            ...visualDefaultValues,
            ...converted,
          },
        },
      });

      if (res.errors) throw res.errors;
      const assessmentId = res.data?.createAssessment?.id;

      // Wait for the backend services to update the "History of Status Designation"
      // before redirect to the Assessment profile page. (US 51981)
      setWaitingForServices(true);
      await timeoutPromise(ASSESSMENT_CREATE_WAIT_BEFORE_REFRESH_TIME_MS);

      history.push({
        pathname: CosewicPathHelpers.AssessmentProfile(
          cosewicWsId,
          assessmentId ?? "error"
        ),
      });
    } catch (e) {
      console.error(e);
    }
  };

  // Note: Business rules for adding new assessment:
  // - Cannot have more than one draft assessment per COSEWIC wildlife Species, and
  // - Only 1 Assessment is allowed when COSEWIC Species is in Draft. (Bug 8844, 30772)
  const assessments = cosewicData?.assessmentList?.assessment;
  const draftAssessmentFound =
    assessments?.find(
      (assessment) => assessment?.stage === AssessmentStage.Draft
    ) !== undefined;
  const assessmentFound = assessments !== undefined && assessments.length > 0;
  const cosewicWsStatus = cosewicData?.cosewicWs?.status;
  const assessmentFoundWhenCosewicIsDraft =
    cosewicWsStatus === CosewicWsStatus.Draft && assessmentFound;

  const formDisabled =
    saving ||
    waitingForServices ||
    draftAssessmentFound ||
    assessmentFoundWhenCosewicIsDraft ||
    cosewicDataError !== undefined;

  return (
    <>
      <Ribbon />

      <Layout.Root className="mrgn-tp-md">
        <Layout.Content>
          <h1>{t("add_new_assessment")}</h1>

          {/*TODO:i18n*/}
          <GraphqlError
            title="Error loading COSEWIC"
            errors={cosewicDataError}
          />

          <GraphqlError title="Error saving assessment" errors={savingError} />

          {draftAssessmentFound && (
            <Alert
              type={AlertTypes.WARNING}
              title={t("new_assessment_could_not_be_added_alert_title")}
              content={t("no_more_than_one_draft_assessment_alert_content")}
            />
          )}

          {assessmentFoundWhenCosewicIsDraft && (
            <Alert
              type={AlertTypes.WARNING}
              title={t("new_assessment_could_not_be_added_alert_title")}
              content={t("only_one_assessment_cosewic_draft_alert_content")}
            />
          )}

          {loading ? (
            <LoadingIndicator className="mrgn-bttm-md" />
          ) : (
            <div>
              <ChangeTrackingProvider isEnabled={false}>
                <AssessmentCreateForm
                  disabled={formDisabled}
                  onSubmit={onSubmit}
                />
              </ChangeTrackingProvider>
            </div>
          )}
        </Layout.Content>
        <Layout.Sidebar>
          <SideNav />
        </Layout.Sidebar>
      </Layout.Root>
    </>
  );
};

export default AssessmentCreate;
