import { useQuery } from "@apollo/client";
import GraphqlError from "components/GraphqlError";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import SectionCard from "components/atoms/SectionCard";
import Layout from "components/layouts/TwoColumn";
import SideNav from "components/molecules/SideNav";
import CosewicInformationSection from "components/molecules/overview/CosewicInformationSection/CosewicInformationSection";
import ListingInformationSection from "components/molecules/overview/ListingInformationSection/ListingInformationSection";
import PermitDescriptionsSection from "components/molecules/overview/PermitDescriptionsSection/PermitDescriptionsSection";
import RelatedDocumentsSection from "components/molecules/overview/RelatedDocumentsSection/RelatedDocumentsSection";
import Ribbon from "components/organisms/cosewic/Ribbon";
import RenderWhenFeatureIsEnabled from "features/featureFlags/RenderWhenFeatureIsEnabled";
import { FeatureFlagKeys } from "features/featureFlags/config";
import { GlobalAlert } from "features/globalAlert";
import { useOverviewContext } from "features/overview";
import { OverviewWsResponseStatementsDocument } from "generated/gql-types";
import useQueryParams from "hooks/util/useQueryParams";
import * as React from "react";
import { useTranslation } from "react-i18next";
import isNullOrEmpty from "util/isNullOrEmpty";
import { Link } from "react-router-dom";
import { ResponseStatementPathHelpers } from "../responseStatement/responseStatementRouter";
import MissingData from "../../components/atoms/MissingData";
import { formatTimestamp } from "../../util/formatTimestamp";
import { bilingualTextNameForLanguage } from "../../mappers";
import { getApiListingServiceUrl } from "../../azure/environment";
import { getUserAccessToken } from "../../features/auth/CustomMsalProvider";
import { SearchServiceExportParams } from "../../util/basicSearch/fetchExport";

const SpeciesInformation: React.FC = () => {
  const { t, i18n } = useTranslation();
  const overviewContext = useOverviewContext();
  const query = useQueryParams() as any;

  const overviewProgram = query.get("program");
  const overviewId = query.get("id");

  React.useEffect(() => {
    overviewContext.updateOverview(overviewProgram, overviewId);
  }, [overviewProgram, overviewId]);

  const cosewicWs = overviewContext.cosewic;
  const listingWs = overviewContext.listing;
  const latestListingProcess = overviewContext.latestListingProcess;
  const latestAssessment = overviewContext.latestAssessment;
  const permitDescriptions = overviewContext.permitDescriptions;
  const loading = overviewContext.loading;

  const lowercaseOverviewProgram = String(overviewProgram).toLowerCase();
  const listingWsRefIdForLegacy =
    lowercaseOverviewProgram === "listingws" ? overviewId : undefined;
  const cosewicWsRefIdForLegacy =
    lowercaseOverviewProgram === "cosewicws" ? overviewId : undefined;

  const overviewWsResponseStatementQuery = useQuery(
    OverviewWsResponseStatementsDocument,
    {
      variables: {
        referenceId: overviewId,
        referenceName: overviewProgram,
        listingWsRefIdForLegacy,
        cosewicWsRefIdForLegacy,
      },
      skip: isNullOrEmpty(overviewId) || isNullOrEmpty(overviewProgram),
      fetchPolicy: "network-only",
      errorPolicy: "all",
    }
  );

  return (
    <>
      <Ribbon />
      <Layout.Root>
        <Layout.Content>
          <GlobalAlert />
          <h1>{t("wildlife_species_information")}</h1>
          <GraphqlError
            title="Error fetching response statement data"
            errors={overviewWsResponseStatementQuery.error}
          />
          {/* <GraphqlError
            title="Error fetching overview data"
            errors={error}
          /> */}
          {/* TODO: Better error handling. ex. when data?... is null */}
          {/* ------------- COSEWIC Information ------------- */}
          {loading && <LoadingIndicator centered className="mrgn-bttm-md" />}
          {cosewicWs && (
            <SectionCard
              header={
                <div className="flex justify-between align-start">
                  <h2>{t("cosewic_information")}</h2>
                </div>
              }
            >
              {loading ? (
                <LoadingIndicator centered className="mrgn-bttm-md" />
              ) : (
                <CosewicInformationSection
                  cosewicWs={cosewicWs}
                  cosewicWsAssessmentLatest={latestAssessment}
                />
              )}
            </SectionCard>
          )}

          {/* ------------- Listing Information ------------- */}
          {listingWs && (
            <SectionCard
              header={
                <div className="flex justify-between">
                  <h2>{t("listing_information")}</h2>
                </div>
              }
            >
              {loading ? (
                <LoadingIndicator centered className="mrgn-bttm-md" />
              ) : (
                <ListingInformationSection
                  listingWs={listingWs}
                  listingWsProcessLatest={latestListingProcess}
                />
              )}
            </SectionCard>
          )}
        </Layout.Content>
        <Layout.Sidebar>
          <SideNav />
        </Layout.Sidebar>
      </Layout.Root>
    </>
  );
};

export default SpeciesInformation;

export const fetchDownload = async (
  downloadUrl: string,
  fileExtension: string,
  languageCode?: string
) => {
  let outputFileName =
    "sardmts-download-" + new Date().toDateString() + fileExtension; // default fallback filename
  const token = await getUserAccessToken();

  const languageHeader = languageCode === "fr" ? "fr-CA" : "en-CA";

  return await fetch(downloadUrl, {
    method: "get",
    headers: {
      "Accept-Language": languageHeader,
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      if (res.status < 200 || res.status >= 300) {
        throw new Error(res.statusText);
      }

      const contentDispositionHeader = res.headers.get("Content-Disposition");

      if (contentDispositionHeader != null) {
        const pattern = /filename=(?<filename>.+.\w+);/g;
        const groups = pattern.exec(contentDispositionHeader)?.groups;

        if (
          groups != null &&
          groups.filename != null &&
          groups.filename.length > 0
        ) {
          outputFileName = groups.filename;
        }
      }

      return res;
    })
    .then((res) => res.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", outputFileName);
      document.body.appendChild(link);
      link.click();
      link?.parentNode?.removeChild(link);
    });
};
