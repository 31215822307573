import { ApolloError, useMutation } from "@apollo/client";
import { OverlayContainer } from "@react-aria/overlays";
import type { OverlayTriggerState } from "@react-stately/overlays";
import { useOverlayTriggerState } from "@react-stately/overlays";
import ModalDialog from "components/molecules/Modal";
import * as React from "react";
import { useTranslation } from "react-i18next";
import {
  ResourceLink,
  UpdateBptProjectStepLinkDocument,
} from "generated/gql-types";
import { SubmitHandler } from "react-hook-form";
import StepLinkForm, { StepLinkFields } from "../StepLinkForm";
import {
  mapDomainModelToStepLinkForm,
  stepLinkFormIntoCreateInput,
} from "../../Utility/bptProjectFormUtil";
import GraphqlError from "components/GraphqlError";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import { useGlobalAlertContext } from "features/globalAlert";

export interface EditStepLinkModalProps {
  modalState: OverlayTriggerState;
  projectId: string;
  stepId: string;
  linkId: string;
  defaultValues?: ResourceLink;
  onCompleted: (data: any) => void;
  onError: (error: ApolloError | undefined) => void;
}

const EditStepLinkModal: React.FC<EditStepLinkModalProps> = (props) => {
  const { t } = useTranslation();
  const alertContext = useGlobalAlertContext();
  const { modalState, projectId, stepId, linkId, defaultValues } = props;

  const [updateBptProjectStepLink, { loading, error, reset }] = useMutation(
    UpdateBptProjectStepLinkDocument,
    {
      refetchQueries: ["BptProjectStepList"],
      onCompleted: props.onCompleted,
      onError: props.onError,
      errorPolicy: "all",
    }
  );

  const onSubmit: SubmitHandler<StepLinkFields> = async (formData) => {
    const input = stepLinkFormIntoCreateInput(formData);
    try {
      const res = await updateBptProjectStepLink({
        variables: {
          projectId: projectId,
          stepId: stepId,
          linkId: linkId,
          input: input,
        },
      });

      if (res.errors == null) {
        modalState.close();
        reset();
        alertContext.showSuccess({
          message: t("successfully_updated_step_link"),
          timeOut: 5000,
        });
      }
    } catch (e) {
      console.warn("Error editing step link");
      console.error(e);
    }
  };

  const onClose = () => {
    modalState.close();
    reset();
  };

  if (!modalState.isOpen) return null;

  const initialValues = mapDomainModelToStepLinkForm(defaultValues);

  return (
    <OverlayContainer>
      <ModalDialog
        title={t("edit_link")}
        className={"modal-width-70"}
        isOpen
        onClose={onClose}
        showModalFooter={false}
      >
        <GraphqlError
          title={t("error_update_project_step_link")}
          errors={error}
        />
        {loading && <LoadingIndicator centered />}
        <StepLinkForm
          defaultValues={initialValues}
          onClose={onClose}
          onSubmit={onSubmit}
        />
      </ModalDialog>
    </OverlayContainer>
  );
};

export default EditStepLinkModal;

export const useEditStepLinkModal = useOverlayTriggerState;
