import { TextInput } from "components/atoms/forms/TextInput";
import FormButtons from "components/molecules/FormButtons/FormButtons";
import { Maybe } from "graphql/jsutils/Maybe";
import * as React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  DocumentLanguage,
  UriReference,
} from "../../../../generated/gql-types";
import FieldValidationError from "../../../atoms/forms/FieldValidationError";
import { HTMLItalicsInputWithController } from "components/molecules/HTMLItalicsInput";
import { htmlToPlainText } from "util/richtext";
import { MAX_LENGTH_OF_500 } from "config/constants";

interface AddLinkFormProps {
  defaultValues?: Partial<StepLinkFields>;
  onClose: () => void;
  onSubmit: SubmitHandler<StepLinkFields>;
}

const StepLinkForm: React.FC<AddLinkFormProps> = (props) => {
  const form = useForm<StepLinkFields>({
    defaultValues: props.defaultValues,
  });

  const { register, handleSubmit, formState, control } = form;
  const { dirtyFields, isDirty, isSubmitting, errors } = formState;
  const { t } = useTranslation();
  const defaultValues = props.defaultValues;

  const validateHtmlTextMaxLengthLimit = (html: any) =>
    htmlToPlainText(html).length <= MAX_LENGTH_OF_500;

  const onSubmit: SubmitHandler<StepLinkFields> = async (formData) => {
    // Double clicking of the Save button causes form to update twice. (Bug 45183)
    // Cause: The isSubmitting status disables the Save button during submit (after the 1st click),
    //        but when API request is running too fast, isSubmitting status doesn't get updated.
    // Solution: Delay submit for half a second.
    // https://github.com/react-hook-form/react-hook-form/issues/1363

    // const cleanedValues = processStepLinkFormValues(
    //   formData,
    //   dirtyFields,
    //   defaultValues ?? {}
    // );

    return new Promise<void>((resolve) => {
      setTimeout(() => {
        props.onSubmit(formData);
        resolve();
      }, 500);
    });
  };

  return (
    <>
      <form
        onSubmit={(e) => {
          e.stopPropagation();
          handleSubmit(onSubmit)(e);
        }}
      >
        {/* -------------- Url to doccuemnt -------------- */}
        {/* 
          Update max length of link URL to 512
          https://dev.azure.com/ECCC-DevOps/SAR%20DMTS/_workitems/edit/65436
        */}
        <TextInput
          type="text"
          label={t("url_to_document")}
          id="uri"
          inputClassName="full-width"
          placeholder={t("url")}
          maxLength={512}
          required={true}
          {...register("reference.uri", {
            required: {
              value: true,
              message: t("field_is_required"),
            },
            pattern: {
              value: /^(https?:\/\/)[^\s\/$.?#].[^\s]*$/,
              message: t("invalid_url_message"),
            },
          })}
          errors={errors}
        />
        {/* -------------- link title -------------- */}
        <div className="form-group">
          <label htmlFor="linkTitleInput" className="required">
            {t("link_title")}
            <strong className="required"> ({"required"})</strong>
          </label>
          <HTMLItalicsInputWithController
            control={control}
            rules={{
              required: true,
              validate: {
                maxLengthLimit: validateHtmlTextMaxLengthLimit,
              },
            }}
            name="reference.name.text"
            id="linkTitleInput"
            defaultValue={props.defaultValues?.reference?.name?.text ?? ""}
            placeholder={t("link_title")}
          />
          {errors?.reference &&
            (errors.reference as any)?.name?.text?.type === "required" && (
              <FieldValidationError>
                {t("field_is_required")}
              </FieldValidationError>
            )}
          {errors.reference &&
            (errors.reference as any)?.name?.text?.type ===
              "maxLengthLimit" && (
              <FieldValidationError>
                {t("reach_the_max_length", {
                  count: MAX_LENGTH_OF_500,
                })}
              </FieldValidationError>
            )}
        </div>

        {/* -------------- language -------------- */}
        <div className="form-group">
          <fieldset className="chkbxrdio-grp">
            <legend className="required">
              <span className="field-name">{t("language")}</span>{" "}
              <strong className="required">({t("required")})</strong>
            </legend>
            <label htmlFor="languageEnglish" className="radio-inline">
              <input
                type="radio"
                value={DocumentLanguage.English}
                defaultChecked={
                  props?.defaultValues?.language === DocumentLanguage.English
                }
                id="languageEnglish"
                data-rule-required="true"
                {...register("language", {
                  required: true,
                })}
              />
              &#160;&#160;{t("english")}
            </label>
            <label htmlFor="languageFrench" className="radio-inline">
              <input
                type="radio"
                value={DocumentLanguage.French}
                defaultChecked={
                  props?.defaultValues?.language === DocumentLanguage.French
                }
                id="languageFrench"
                {...register("language", {
                  required: true,
                })}
              />
              &#160;&#160;{t("french")}
            </label>
          </fieldset>
          {errors.language && (
            <FieldValidationError>
              {t("field_is_required")}
            </FieldValidationError>
          )}
        </div>

        <FormButtons
          isDirty={isDirty}
          isSubmitting={isSubmitting}
          onCancel={props.onClose}
          errors={errors}
        />
      </form>
    </>
  );
};

export default StepLinkForm;

export interface StepLinkFields {
  // urlToDocument?: string;
  // name?: RichText;
  reference?: Maybe<UriReference>;
  language?: Maybe<DocumentLanguage>;
}
