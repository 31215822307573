import { useMutation, useQuery } from "@apollo/client";
import GraphqlError from "components/GraphqlError";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import SafeRenderHtml from "components/atoms/SafeRenderHtml";
import Layout from "components/layouts/OneColumn";
import ReturnToButton from "components/molecules/admin/bptTemplates/utility/ReturnToButton";
import ProjectPhaseInformationCard from "components/molecules/bptProjects/Phase/ProjectPhasesCard/ProjectPhaseInformationCard";
import ProjectStepsCard from "components/molecules/bptProjects/Step/ProjectStepsCard/ProjectStepsCard";
import { GlobalAlert, useGlobalAlertContext } from "features/globalAlert";
import {
  BptProjectDocument,
  BptProjectPhaseDocument,
  BptProjectStage,
  BptProjectStepListDocument,
  UpdateBptProjectActivityStageDocument,
  UpdateBptProjectPhaseStageDocument,
  UpdateBptProjectStepStageDocument,
} from "generated/gql-types";
import { BptPathHelpers } from "pages/bpt/bptRouter";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useRouteMatch } from "react-router-dom";
import htmlRemoveOuterPTag from "util/htmlRemoveOuterPTag";
import { RenderWhenAuthorized, ROLE_ACTIONS } from "features/auth/components";
import MarkAsCompletedBtn from "components/molecules/bptProjects/Utility/MarkAsCompletedBtn";
import StatusTag from "components/molecules/bptProjects/Utility/StatusTag";
const ProjectPhase: React.FC = (props) => {
  const { t } = useTranslation();
  const { params } = useRouteMatch();
  const { projectId, phaseId: projectPhaseId } = params as any;
  const [isOpen, setIsOpen] = React.useState(false);
  const alertContext = useGlobalAlertContext();

  // Query project information
  const {
    loading: loadingProject,
    error: errorProject,
    data: dataProject,
  } = useQuery(BptProjectDocument, {
    variables: {
      projectId: projectId,
    },
    skip: projectId == null,
    fetchPolicy: "network-only",
    errorPolicy: "all",
  });

  // Query project phase information
  const {
    loading: loadingProjectPhase,
    error: errorProjectPhase,
    data: dataProjectPhase,
  } = useQuery(BptProjectPhaseDocument, {
    variables: {
      projectId: projectId!,
      phaseId: projectPhaseId,
    },
    skip: projectId == null || projectPhaseId == null,
    fetchPolicy: "network-only",
    errorPolicy: "all",
  });

  // Query project step list information
  const {
    loading: loadingProjectStepList,
    error: errorProjectStepList,
    data: dataProjectStepList,
  } = useQuery(BptProjectStepListDocument, {
    variables: {
      params: {
        projectId: projectId,
        phaseId: projectPhaseId,
        pageNumber: 1,
        pageSize: 50,
      },
    },
    skip: projectId == null || projectPhaseId == null,
    fetchPolicy: "network-only",
    errorPolicy: "all",
  });

  // Mark phase as completed
  const [
    updateBptProjectPhaseStage,
    { loading: UpdatingBptProjectPhaseStage, error: errorBptProjectPhaseStage },
  ] = useMutation(UpdateBptProjectPhaseStageDocument, {
    refetchQueries: ["BptProjectPhase"],
    errorPolicy: "all",
  });

  const onMarkPhaseAsCompleted = async () => {
    try {
      const res = await updateBptProjectPhaseStage({
        variables: {
          projectId: projectId,
          phaseId: projectPhaseId,
          stage: BptProjectStage.Completed,
        },
      });

      if (res.errors) throw res.errors;

      alertContext.showSuccess({
        message: t("successfully_update_bpt_project_phase_stage"),
        timeOut: 5000,
      });
    } catch (e) {
      console.error(e);
    }
  };

  // Update step stage
  const [
    updateBptProjectStepStage,
    { loading: UpdatingBptProjectStepStage, error: errorBptProjectStepStage },
  ] = useMutation(UpdateBptProjectStepStageDocument, {
    refetchQueries: ["BptProjectPhase", "BptProjectStepList"],
    errorPolicy: "all",
  });

  const onUpdateBptProjectStepStage = async (
    projectId: string,
    stepId: string,
    stage: BptProjectStage
  ) => {
    try {
      const res = await updateBptProjectStepStage({
        variables: {
          projectId: projectId,
          stepId: stepId,
          stage: stage,
        },
      });

      if (res.errors) throw res.errors;

      alertContext.showSuccess({
        message: t("successfully_update_bpt_project_step_stage"),
        timeOut: 5000,
      });
    } catch (e) {
      console.error(e);
    }
  };

  // Update activity stage
  const [
    updateBptProjectActivityStage,
    {
      loading: UpdatingBptProjectActivityStage,
      error: errorBptProjectActivityStage,
    },
  ] = useMutation(UpdateBptProjectActivityStageDocument, {
    refetchQueries: [
      "BptProjectPhase",
      "BptProjectStepList",
      "BptProjectActivityList",
    ],
    errorPolicy: "all",
  });

  const onUpdateBptProjectActivityStage = async (
    projectId: string,
    activityId: string,
    stage: BptProjectStage
  ) => {
    try {
      const res = await updateBptProjectActivityStage({
        variables: {
          projectId: projectId,
          activityId: activityId,
          stage: stage,
        },
      });

      if (res.errors) throw res.errors;

      alertContext.showSuccess({
        message: t("successfully_update_bpt_project_activity_stage"),
        timeOut: 5000,
      });
    } catch (e) {
      console.error(e);
    }
  };

  const onSkipOrUndoskipAction = async (
    projectId: string,
    id: string,
    stage: BptProjectStage,
    __typename: string
  ) => {
    if (__typename === "BptProjectStep") {
      onUpdateBptProjectStepStage(projectId, id, stage);
    } else if (__typename === "BptProjectActivity") {
      onUpdateBptProjectActivityStage(projectId, id, stage);
    }
  };

  const onExpandAll = async () => {
    const details = document.querySelectorAll("details.detail-list");
    for (var i = 0; i < details.length; i++) {
      details[i].setAttribute("open", "true");
    }
    setIsOpen(true);
  };

  const onCollapseAll = async () => {
    $("details").removeAttr("open");
    setIsOpen(false);
  };

  const stepBundles = dataProjectStepList?.bptProjectStepList?.stepBundles;

  const disabledMarkAsCompletedBtn =
    stepBundles == null ||
    (stepBundles != null && stepBundles.length == 0) ||
    stepBundles?.find((x) =>
      x?.steps?.find(
        (y) =>
          y?.stage !== BptProjectStage.Completed &&
          y?.stage !== BptProjectStage.Skipped
      )
    ) != null;

  const isPhaseCompleted =
    dataProjectPhase?.bptProjectPhase?.stage === BptProjectStage.Completed;

  const isProjectCompleted =
    dataProject?.bptProject?.stage === BptProjectStage.Completed;

  return (
    <>
      <Layout.Root>
        <Layout.Content>
          {/* ----- Phase name title ----- */}
          <h1>
            <SafeRenderHtml
              htmlString={htmlRemoveOuterPTag(
                dataProjectPhase?.bptProjectPhase?.name?.text
              )}
            />
          </h1>
          {/* ----- Alert/Errors section ----- */}
          <GlobalAlert />
          <GraphqlError
            title={t("error_loading_bpt_project")}
            errors={errorProject}
          />
          <GraphqlError
            title={t("error_loading_bpt_project_phase")}
            errors={errorProjectPhase}
          />
          <GraphqlError
            title={t("error_loading_bpt_project_step_list")}
            errors={errorProjectStepList}
          />
          <GraphqlError
            title={t("error_update_bpt_project_phase_stage")}
            errors={errorBptProjectPhaseStage}
          />
          <GraphqlError
            title={t("error_update_bpt_project_step_stage")}
            errors={errorBptProjectStepStage}
          />
          <GraphqlError
            title={t("error_update_bpt_project_activity_stage")}
            errors={errorBptProjectActivityStage}
          />

          {loadingProject || loadingProjectPhase || loadingProjectStepList ? (
            <LoadingIndicator />
          ) : !errorProject && !errorProjectPhase && !loadingProjectStepList ? (
            <>
              <div className="flex justify-between align-center flex-wrap mrgn-bttm-md gap-sm">
                <div className="flex justify-right flex-auto gap-md">
                  {isPhaseCompleted ? (
                    <StatusTag
                      stage={dataProjectPhase?.bptProjectPhase?.stage}
                      isDelay={dataProjectPhase?.bptProjectPhase?.delayed!}
                      className={"status-tag-page"}
                    />
                  ) : (
                    <RenderWhenAuthorized
                      authorizedRoles={ROLE_ACTIONS.bptProject.CompletePhase}
                    >
                      <MarkAsCompletedBtn
                        title={t("mark_phase_as_completed")}
                        className="btn btn-default"
                        markTypeName={"BptProjectPhase"}
                        buttonText={t("mark_phase_as_completed")}
                        disabled={disabledMarkAsCompletedBtn}
                        onMarkAction={() => onMarkPhaseAsCompleted()}
                        loading={UpdatingBptProjectPhaseStage}
                      />
                    </RenderWhenAuthorized>
                  )}
                </div>
              </div>

              {/* ----- Project Phase Information Section ----- */}
              <ProjectPhaseInformationCard
                projectId={projectId}
                projectPhaseInfo={dataProjectPhase?.bptProjectPhase}
                isProjectCompleted={isProjectCompleted}
              />

              {/* ----- Project Steps Section ----- */}
              <ProjectStepsCard
                projectStepBundles={stepBundles}
                projectId={projectId}
                isOpen={isOpen}
                loading={
                  UpdatingBptProjectStepStage || UpdatingBptProjectActivityStage
                }
                isPhaseCompleted={isPhaseCompleted}
                isProjectCompleted={isProjectCompleted}
                onExpandAll={onExpandAll}
                onCollapseAll={onCollapseAll}
                onSkip={(a, b, c, d) => onSkipOrUndoskipAction(a, b, c, d)}
              />

              {/* ------------- Return to project button ------------- */}
              <ReturnToButton
                targetText={dataProject?.bptProject?.name?.text!}
                link={BptPathHelpers.Project(projectId)}
              />
            </>
          ) : null}
        </Layout.Content>
      </Layout.Root>
    </>
  );
};

export default ProjectPhase;
