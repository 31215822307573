const cosewicChangeLogTranslation = {
  en: {
    // Page titles
    cosewic_change_log: "COSEWIC change log",
    cosewic_change_details: "COSEWIC change details",

    // Cosewic name changes
    cosewic_name_changes: "COSEWIC name changes",

    // Change Log Description
    cosewicws_create: "COSEWIC wildlife species profile created by",
    cosewicws_update: "COSEWIC wildlife species profile updated by",
    cosewicws_permanent:
      'COSEWIC wildlife species profile stage made "permanent" by',
    cosewicws_published_to_registry:
      "COSEWIC wildlife species information published to registry by",
    cosewicws_delete: "COSEWIC wildlife species profile deleted by",
    cosewicws_split: "COSEWIC wildlife species split by",
    cosewicws_merge: "COSEWIC wildlife species merged by",
    assessment_update: "Assessment updated by",
    assessment_assessed: 'Assessment made "assessed" by',
    assessment_published_registry: "Assessment published to Registry by",
    assessment_delete: "Assessment deleted by",
  },

  fr: {
    // Page titles
    cosewic_change_log: "Journal des modifications du COSEPAC",
    cosewic_change_details: "Détails du changement du COSEPAC",

    // Cosewic name changes
    cosewic_name_changes: "Changements de nom du COSEPAC",

    // Change Log Description
    cosewicws_create: "Profil de l'espèce sauvage du COSEPAC créé par",
    cosewicws_update: "Profil de l'espèce sauvage du COSEPAC mis à jour par",
    cosewicws_permanent:
      "Profil de l’espèce sauvage du COSEPAC mis au stade 'permanent' par",
    cosewicws_published_to_registry:
      "Profil de l'espèce sauvage du COSEPAC publié dans le Registre par",
    cosewicws_delete: "Profil de l'espèce sauvage du COSEPAC supprimé par",
    cosewicws_split: "Espèce sauvage du COSEPAC divisée par",
    cosewicws_merge: "Espèces sauvages du COSEPAC fusionnées par",
    assessment_update: "Évaluation mise à jour par",
    assessment_assessed: "Évaluation mise 'évaluée' par",
    assessment_published_registry: "Évaluation publiée dans le Registre par",
    assessment_delete: "Évaluation supprimée par",
  },
};

export default cosewicChangeLogTranslation;
