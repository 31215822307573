import { ApolloError } from "@apollo/client";
import { RenderWhenAuthorized, ROLE_ACTIONS } from "features/auth/components";
import {
  BptTemplate,
  BptTemplatePhase,
  BptTemplateStage,
  Maybe,
} from "generated/gql-types";
import * as React from "react";
import { useTranslation } from "react-i18next";
import ReorderPhasesBtn from "../ReorderPhasesModal/ReorderPhasesBtn";

export interface PhasesCardHeaderProps {
  bptTemplate?: Maybe<BptTemplate>;
  phases?: Maybe<Maybe<BptTemplatePhase>[]>;
  onExpandAll: () => void;
  onCollapseAll: () => void;
}

export const PhasesCardHeader: React.FC<PhasesCardHeaderProps> = (props) => {
  const { t } = useTranslation();
  const { bptTemplate, phases, onExpandAll, onCollapseAll } = props;

  return (
    <div className="flex justify-between align-start">
      <h2>{t("phases")}</h2>

      <div className="flex gap-md">
        {bptTemplate?.stage === BptTemplateStage.Draft ? (
          <RenderWhenAuthorized
            authorizedRoles={ROLE_ACTIONS.administration.bptTemplate.update}
          >
            <ReorderPhasesBtn
              templateId={bptTemplate?.id}
              phases={phases}
              onCompleted={function (data: any): void {
                throw new Error("Function not implemented.");
              }}
              onError={function (error: ApolloError | undefined): void {
                throw new Error("Function not implemented.");
              }}
            />
          </RenderWhenAuthorized>
        ) : null}
        <div className="btn-group">
          <button
            type="button"
            className="btn btn-default card-header-button-link"
            onClick={onExpandAll}
            data-testid="button-expand-all"
            name="expandAll"
            disabled={phases == null || phases?.length === 0}
          >
            {t("expand_all")}
          </button>

          <button
            type="button"
            className="btn btn-default card-header-button-link"
            onClick={onCollapseAll}
            data-testid="button-collapse-all"
            name="collapseAll"
            disabled={phases == null || phases?.length === 0}
          >
            {t("collapse_all")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PhasesCardHeader;
