import { ApolloError, useMutation } from "@apollo/client";
import { OverlayContainer } from "@react-aria/overlays";
import type { OverlayTriggerState } from "@react-stately/overlays";
import { useOverlayTriggerState } from "@react-stately/overlays";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import GraphqlError from "components/GraphqlError";
import ModalDialog from "components/molecules/Modal";
import { useGlobalAlertContext } from "features/globalAlert";
import {
  BptTemplateActivity,
  Maybe,
  UpdateBptTemplateActivityDocument,
} from "generated/gql-types";
import * as React from "react";
import { SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  activityFormIntoCreateInput,
  domainModelIntoActivityForm,
} from "../../bptFormUtil";
import ActivityForm, { ActivityFormFields } from "../ActivityForm";

export interface EditActivityPopupProps {
  modalState: OverlayTriggerState;
  templateId?: Maybe<string>;
  activityInfo?: Maybe<BptTemplateActivity>;
  onCompleted: (data: any) => void;
  onError: (error: ApolloError | undefined) => void;
}

const EditActivityPopup: React.FC<EditActivityPopupProps> = (props) => {
  const { t } = useTranslation();
  const alertContext = useGlobalAlertContext();
  const { modalState, templateId, activityInfo } = props;

  const [updateBptTemplateActivity, { loading: saving, error, reset }] =
    useMutation(UpdateBptTemplateActivityDocument, {
      refetchQueries: [
        "BptTemplateActivityList",
        "BptTemplateStepList",
        "BptTemplatePhase",
      ],
      onCompleted: props.onCompleted,
      onError: props.onError,
      errorPolicy: "all",
    });

  const onSubmit: SubmitHandler<Partial<ActivityFormFields>> = async (
    formData
  ) => {
    const input = activityFormIntoCreateInput(formData);
    try {
      const res = await updateBptTemplateActivity({
        variables: {
          templateId: templateId ?? "error",
          activityId: activityInfo?.id ?? "error",
          input: input,
        },
      });
      if (res.errors == null) {
        modalState.close();
      }

      alertContext.showSuccess({
        message: t("successfully_updated_activity"),
        timeOut: 5000,
      });
    } catch (e) {
      console.warn("Error saving new activity");
      console.error(e);
    }
  };

  const onClose = () => {
    modalState.close();
    reset();
  };

  if (!modalState.isOpen) return null;

  const formDefaultValues = domainModelIntoActivityForm(activityInfo);

  return (
    <>
      <OverlayContainer>
        <ModalDialog
          title={t("edit_activity")}
          className={"modal-width-50"}
          isOpen
          onClose={onClose}
          showModalFooter={false}
        >
          <GraphqlError
            title={t("error_update_bpt_template_activity")}
            errors={error}
          />
          {saving && <LoadingIndicator centered />}
          <ActivityForm
            defaultValues={formDefaultValues}
            onSubmit={onSubmit}
            onClose={onClose}
            isSaving={saving}
          />
        </ModalDialog>
      </OverlayContainer>
    </>
  );
};

export default EditActivityPopup;

export const useActivityPopup = useOverlayTriggerState;
