import * as React from "react";
import { useTranslation } from "react-i18next";
import ReorderActivityBundlesPopup, {
  ReorderActivityBundlesPopupProps,
  useReorderActivityBundlesPopup,
} from "./ReorderActivityBundlesPopup";

interface ReorderActivityBundlesBtnProps
  extends Omit<ReorderActivityBundlesPopupProps, "modalState"> {
  buttonLabel?: string;
}

const ReorderActivityBundlesBtn: React.FC<ReorderActivityBundlesBtnProps> = ({
  buttonLabel,
  ...props
}) => {
  const { t } = useTranslation();
  const modalState = useReorderActivityBundlesPopup({});
  const label = buttonLabel ?? t("reorder_and_bundle_activities");
  const validId =
    props.activityBundles != null && props.activityBundles.length > 1;

  return (
    <>
      <button
        type="button"
        className="btn btn-link card-header-button-link"
        onClick={modalState.open}
        title={label}
        disabled={!validId}
        data-testid="button-reorder-activity"
      >
        <span
          className="glyphicon glyphicon-sort mrgn-rght-sm"
          aria-hidden="true"
        ></span>
        {label}
      </button>
      {validId ? (
        <ReorderActivityBundlesPopup modalState={modalState} {...props} />
      ) : null}
    </>
  );
};

export default ReorderActivityBundlesBtn;
