const responseStatementTranslations = {
  en: {
    add_response_statement_field: "Add response statement field",
    edit_response_statement_field: "Edit response statement field",
    statement_name_english: "Statement name (English)",
    statement_name_french: "Statement name (French)",
    statement_type: "Statement type",
    english_text: "English text",
    french_text: "French text",
    response_statement_fields: "Response statement fields",
    list_of_response_statement_fields: "List of response statement fields",
    name_in_english: "Name (English)",
    name_in_french: "Name (French)",
    select_statement_type: "Select statement type",
    response_statement_scenarios: "Response statement scenarios",
    applicable_federal_legislation: "Applicable federal legislation",
    include_the_consultation_period: "Include the consultation period",
    english_msg: "English message",
    french_msg: "French message",
    archive_response_statement_warning:
      "You will no longer be able to add this response statement type to a response statement report.",
    unarchive_response_statement_warning:
      "You will now be able to add this response statement type to a response statement report.",
    archive_a_response_statement: "Archive a response statement",
    unarchive_a_response_statement: "Unarchive a response statement",

    due_date: "Due date",
    date_published: "Date published",
    cosewic_common_name_with_population: "COSEWIC common name with population",
    cosewic_scientific_name: "COSEWIC scientific name",
    cosewic_assessment_status:
      "Status assessment by the Committee on the Status of Endangered Wildlife in Canada (COSEWIC)",
    edit_response_statement: "Edit response statement",
    minister_response:
      "How the Minister of the Environment intends to respond to the assessment",
    reason_for_status_designation:
      "Reason(s) for status designation provided by COSEWIC",
    occurrence: "Occurrence",
    competent_ministers: "Competent ministers(s)",
    conservation_activities_underway: "Conservation activities underway",
    response_statement_success: "Response statement successfully updated.",
    you_can_preview_your_changes: "You can now preview your changes.",
  },

  fr: {
    add_response_statement_field: "Ajouter un champ pour l'énoncé de réaction",
    edit_response_statement_field: "Modifier le champ de l'énoncé de réaction",
    statement_name_english: "Nom de l'énoncé (anglais)",
    statement_name_french: "Nom de l'énoncé (français)",
    statement_type: "Type d'énoncé",
    english_text: "Texte en anglais",
    french_text: "Texte en français",
    response_statement_fields: "Champs de l'énoncé de réaction",
    list_of_response_statement_fields: "Liste d'énoncés de réaction",
    name_in_english: "Nom (anglais)",
    name_in_french: "Nom (français)",
    select_statement_type: "Sélectionner le type d'énoncé",
    response_statement_scenarios: "Scénarios d'énoncés de réaction",
    applicable_federal_legislation: "Législation fédérale applicable",
    include_the_consultation_period: "Inclure la période de consultation",
    english_msg: "Message en anglais",
    french_msg: "Message en français",
    archive_response_statement_warning:
      "Vous ne pourrez plus associer ce type d’énoncé de réaction à un rapport sur l’énoncé de réaction.",
    unarchive_response_statement_warning:
      "Vous pouvez maintenant associer ce type d’énoncé de réaction à un rapport sur l’énoncé de réaction.",
    archive_a_response_statement: "Archiver un énoncé de réaction",
    unarchive_a_response_statement: "Désarchiver un énoncé de réaction",

    due_date: "Date d'échéance",
    date_published: "Date de publication ",
    cosewic_common_name_with_population:
      "Nom commun avec population du COSEPAC",
    cosewic_scientific_name: "Nom scientifique du COSEPAC",
    cosewic_assessment_status:
      "Évaluation de la situation par le Comité sur la situation des espèces en péril au Canada (COSEPAC)",
    edit_response_statement: "Modifier l'énoncé de réaction",
    minister_response:
      "Comment le Ministre de l'Environnement entend réagir à l'évaluation",
    reason_for_status_designation:
      "Raison(s) de la désignation de la situation fournie(s) par le COSEPAC",
    occurrence: "Occurrence",
    competent_ministers: "Ministre(s) compétent(s)",
    conservation_activities_underway: "Activités de conservation en cours",
    response_statement_success: "Mise à jour réussie de l'énoncé de réaction.",
    you_can_preview_your_changes:
      "Vous pouvez maintenant visualiser en avance vos modifications.",
  },
};

export default responseStatementTranslations;
