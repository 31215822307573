import { useApolloClient } from "@apollo/client";
import { useAsyncList } from "@react-stately/data";
import GraphqlError from "components/GraphqlError";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import NoResults from "components/atoms/NoResults";
import SectionCard from "components/atoms/SectionCard";
import Layout from "components/layouts/OneColumn";
import PageSizeSelect from "components/molecules/PageSizeSelect";
import Pagination from "components/molecules/Pagination";
import AddBptProjectModalWithButton from "components/molecules/bptProjects/AddBptProjectModal/AddBptProjectButtonWithModal";
import BptProjectsPageFilterForm, {
  QueryFields,
} from "components/molecules/bptProjects/BptProjectsPageFilterForm";
import ResultsTable, {
  ColumnHeader,
} from "components/organisms/search/ResultsTable/ResultsTable";
import { RenderWhenAuthorized, ROLE_ACTIONS } from "features/auth/components";
import { GlobalAlert, useGlobalAlertContext } from "features/globalAlert";
import { BptProjectListDocument } from "generated/gql-types";
import useAdminAlertMsg from "hooks/admin/useAdminAlertMsg";
import { usePagination } from "hooks/util/usePagination";
import * as React from "react";
import { flushSync } from "react-dom";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { formatTimestamp } from "util/formatTimestamp";
import htmlRemoveOuterPTag from "util/htmlRemoveOuterPTag";
import { BptPathHelpers } from "../bptRouter";

export const ProjectsPage: React.FC = () => {
  const { t, i18n } = useTranslation();
  const pagination = usePagination();
  const client = useApolloClient();
  const [totalCount, setTotalCount] = React.useState<number>(0);
  const [totalPages, setTotalPages] = React.useState<number>(0);
  const globalAlertContext = useGlobalAlertContext();
  const adminAlertMsg = useAdminAlertMsg(t("bptProject"));
  const history = useHistory();
  const getSortByOrder = (column: string, direction: string) => {
    const sortBy = () => {
      switch (column) {
        case "name":
          return "name.plainText";
        case "templateName":
          return "templateReference.name.plainText";
        case "program":
          return i18n.language === "fr"
            ? "templateReference.program.name.french"
            : "templateReference.program.name.english";
        case "startDate":
          return "estimatedTimeline.startDate";
        case "endDate":
          return "projectedTimeline.endDate";
        default:
          return column;
      }
    };
    const sortOrder = () => {
      return direction === "ascending" ? "asc" : "desc";
    };
    return `${sortBy()}:${sortOrder()}`;
  };

  const onChangePageSize = async (newPageSize: number) => {
    pagination.setPageSize(newPageSize);
    pagination.goToPage(1);
  };

  React.useEffect(() => {
    rows.reload(); // refetchQueries
  }, [pagination.currentPage, pagination.pageSize]);

  const columns: ColumnHeader[] = React.useMemo(
    () => [
      {
        name: t("project_name"),
        key: "name",
        sortable: true,
        hasLink: true,
        isHTML: true,
      },

      {
        name: t("template_name"),
        key: "templateName",
        sortable: true,
        isHTML: true,
      },
      {
        name: t("program"),
        key: "program",
      },
      {
        name: t("start_date"),
        key: "startDate",
        sortable: true,
      },
      {
        name: t("projected_end_date"),
        key: "endDate",
        sortable: true,
      },
    ],
    [t]
  );

  const rows = useAsyncList<any>({
    initialSortDescriptor: {
      column: "name",
      direction: "ascending",
    },
    async load({ cursor, filterText, sortDescriptor }: any) {
      const results = await client.query({
        errorPolicy: "all",
        query: BptProjectListDocument,
        variables: {
          params: {
            pageNumber: pagination.currentPage,
            pageSize: pagination.pageSize,
            sortBy: getSortByOrder(
              sortDescriptor.column,
              sortDescriptor.direction
            ),
            search:
              filterText.search.length > 0 ? filterText.search : undefined,
            program: filterText.program === "" ? undefined : filterText.program,
          },
        },
        fetchPolicy: "network-only",
      });

      const items =
        results.data?.bptProjectList?.projects?.map((value) => {
          return {
            _href: BptPathHelpers.Project(value?.id ?? "error"),
            id: value?.id,
            name: value?.name?.text,
            templateName:
              htmlRemoveOuterPTag(value?.templateReference?.name?.text) +
              "<i> (v." +
              value?.templateReference?.templateVersion +
              ")</i>",
            program:
              i18n.language === "fr"
                ? t(value?.templateReference?.program?.name?.french ?? "")
                : t(value?.templateReference?.program?.name?.english ?? ""),
            startDate: formatTimestamp(
              value?.estimatedTimeline?.startDate ?? ""
            ),
            endDate: formatTimestamp(value?.projectedTimeline?.endDate ?? ""),
          };
        }) ?? [];

      setTotalCount(results.data.bptProjectList?.pagination?.totalCount ?? 0);
      setTotalPages(results.data.bptProjectList?.pagination?.totalPages ?? 0);

      return {
        items,
        sortDescriptor,
      };
    },
  });

  const onFilterSubmit = (arr: QueryFields) => {
    flushSync(() => {
      rows.setFilterText(arr as any);
      pagination.goToPage(1);
    });
  };

  return (
    <>
      <Layout.Root>
        <Layout.Content>
          <GlobalAlert />
          <h1>{t("business_process_tracking_projects")}</h1>

          <GraphqlError
            title={t("error_fetching_projects")}
            errors={rows?.error}
          />

          <SectionCard
            header={
              <div className="flex justify-between align-start">
                <h2>{t("projects")}</h2>
                <RenderWhenAuthorized
                  authorizedRoles={ROLE_ACTIONS.bptProject.create}
                >
                  <AddBptProjectModalWithButton
                    onCompleted={(data: any) => {
                      rows.reload(); // refetchQueries
                      //Todo: Need to fix this message to show
                      globalAlertContext.showSuccess({
                        message: data?.createBptProject?.name?.plainText,
                        timeOut: 5000,
                      });
                      history.push({
                        pathname: BptPathHelpers.Projects,
                      });
                    }}
                    onError={adminAlertMsg.onCreateError}
                  />
                </RenderWhenAuthorized>
              </div>
            }
          >
            <div className="flex mrgn-bttm-md align-center justify-between flex-wrap gap-md">
              <BptProjectsPageFilterForm onSubmit={onFilterSubmit} />
              <div className="flex-auto flex-col gap-sm align-end">
                <div>
                  <PageSizeSelect
                    pageSize={pagination.pageSize}
                    onChangePageSize={pagination.setPageSize}
                  />
                </div>
                <div className="font-size-18">
                  {pagination.makeShowingString(totalCount)}
                </div>
              </div>
            </div>

            {/*RESULTS*/}
            {rows.isLoading ? (
              <LoadingIndicator centered className="mrgn-bttm-md" />
            ) : rows && rows?.items?.length === 0 ? (
              <NoResults centered />
            ) : (
              <>
                {rows == null || rows?.items?.length === 0 ? null : (
                  <>
                    <ResultsTable
                      rows={rows.items}
                      columns={columns}
                      sortable
                      sortDescriptor={rows.sortDescriptor}
                      onSortChange={rows.sort}
                    />

                    <Pagination
                      {...pagination.paginationComponentProps}
                      totalPages={totalPages ?? 0}
                    />
                  </>
                )}
              </>
            )}
          </SectionCard>
        </Layout.Content>
      </Layout.Root>
    </>
  );
};
