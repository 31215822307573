import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { OverlayContainer } from "@react-aria/overlays";
import type { OverlayTriggerState } from "@react-stately/overlays";
import { useOverlayTriggerState } from "@react-stately/overlays";
import GraphqlError from "components/GraphqlError";
import ContactOrOrgInUseWarning from "components/molecules/admin/ContactOrOrgInUseWarning";
import ConfirmSavePopup, {
  useConfirmSaveModalState,
} from "components/organisms/contacts/ConfirmSavePopup";
import * as React from "react";
import { SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ModalDialog from "../../../../components/molecules/Modal";
import type { OrganizationFields } from "../../../../components/molecules/admin/OrganizationForm";
import OrganizationForm from "../../../../components/molecules/admin/OrganizationForm";
import * as OrganizationFormMapper from "../../../../components/organisms/organization/OrganizationFormUtil";
import {
  ContactOrOrgInUseDocument,
  UpdateOrganizationDocument,
} from "../../../../generated/gql-types";

export interface EditOrganizationPopupProps {
  modalState: OverlayTriggerState;
  OrganizationId: string;
  defaultValues: OrganizationFields;
  refetchQueries?: string[];
  onCompleted: (data: any) => void;
  onError: (error: ApolloError | undefined) => void;
}

const EditOrganizationPopup: React.FC<EditOrganizationPopupProps> = (props) => {
  const { t } = useTranslation();
  const { modalState, OrganizationId, defaultValues } = props;
  const confirmSaveModalState = useConfirmSaveModalState({});

  const [formDataState, setFormDataState] =
    React.useState<Partial<OrganizationFields>>();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const {
    data: dataContactOrOrgInUse,
    loading: loadingContactOrOrgInUse,
    error: errorContactOrOrgInUse,
  } = useQuery(ContactOrOrgInUseDocument, {
    variables: {
      contactRefId: null,
      orgRefId: OrganizationId ?? null,
    },
    skip: OrganizationId == null,
    fetchPolicy: "network-only",
    errorPolicy: "all",
  });

  const [editOrganization, { loading: saving, error, reset }] = useMutation(
    UpdateOrganizationDocument,
    {
      refetchQueries: props.refetchQueries ?? [],
      onCompleted: props.onCompleted,
      onError: props.onError,
      errorPolicy: "all",
    }
  );

  const onSubmit: SubmitHandler<Partial<OrganizationFields>> = async (
    formData
  ) => {
    const input = OrganizationFormMapper.formIntoCreateInput(formData);
    try {
      const res = await editOrganization({
        variables: {
          updateOrganizationId: OrganizationId,
          input: input,
        },
      });

      if (res.errors == null) {
        modalState.close();
      }
    } catch (e) {
      console.warn("Error saving organization");
      console.error(e);
    }
  };

  const onConfirmSaveSubmit = (formData: Partial<OrganizationFields>) => {
    if (!dataContactOrOrgInUse?.contactOrOrgInUse) {
      onSubmit(formData);
    } else {
      setFormDataState(formData);
      confirmSaveModalState.open();
    }
  };

  const onClose = () => {
    modalState.close();
    reset();
  };

  if (!modalState.isOpen) return null;

  const formDefaultValues =
    OrganizationFormMapper.domainModelIntoForm(defaultValues);

  return (
    <>
      <OverlayContainer>
        <ModalDialog
          title={t("edit_organization")}
          className={"modal-width-70"}
          isOpen
          onClose={onClose}
          showModalFooter={false}
          // isDismissable
        >
          <GraphqlError title="" errors={error} />
          <GraphqlError title="" errors={errorContactOrOrgInUse} />
          <ContactOrOrgInUseWarning orgRefId={OrganizationId} />
          {/* {loading && <LoadingIndicator centered />} */}
          <OrganizationForm
            defaultValues={formDefaultValues}
            contactOrOrgInUse={dataContactOrOrgInUse?.contactOrOrgInUse}
            onSubmit={onConfirmSaveSubmit}
            onClose={onClose}
            isSaving={saving}
          />
        </ModalDialog>
      </OverlayContainer>

      <ConfirmSavePopup
        modalState={confirmSaveModalState}
        onConfirmSave={() => onSubmit(formDataState!)}
      />
    </>
  );
};

export default EditOrganizationPopup;

export const useOrganizationPopup = useOverlayTriggerState;
export type { OrganizationFields };
