import MissingData from "components/atoms/MissingData";
import SafeRenderHtml from "components/atoms/SafeRenderHtml";
import { TooltipAlert } from "components/atoms/Tooltip/TooltipAlert";
import { BptTemplateActivity, Maybe } from "generated/gql-types";
import * as React from "react";
import { useTranslation } from "react-i18next";

export interface ActivityInformationProps {
  templateId?: Maybe<string>;
  activityInfo?: Maybe<BptTemplateActivity>;
}

export const ActivityInformation: React.FC<ActivityInformationProps> = (
  props
) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="species-field font-size-18">
        <div className="row">
          <div className="species-data col-sm-8">
            <dl>
              {/* ------------ Description ------------ */}
              <dt className="text-muted">{t("description")}</dt>
              <dd>
                {props.activityInfo?.description == null ? (
                  <MissingData />
                ) : (
                  <SafeRenderHtml
                    htmlString={props.activityInfo?.description.text ?? ""}
                  />
                )}
              </dd>
              {/* ------------ Activity owner ------------ */}
              <dt className="text-muted">{t("activity_owner")}</dt>
              <dd>
                {props.activityInfo?.owner?.text == null ? (
                  <MissingData />
                ) : (
                  <SafeRenderHtml
                    htmlString={props.activityInfo?.owner.text ?? ""}
                  />
                )}
              </dd>
            </dl>
          </div>

          {/* ------------ Estimated duration ------------ */}
          <div className="species-data col-sm-4">
            <dl>
              <dt className="text-muted">
                {t("estimated_duration")}
                <TooltipAlert
                  isVisible={props.activityInfo?.estimatedDuration == null}
                >
                  {t("value_is_required_to_calculate_durations_tooltip")}
                </TooltipAlert>
              </dt>
              <dd>
                {props.activityInfo?.estimatedDuration == null ? (
                  <MissingData />
                ) : (
                  props.activityInfo?.estimatedDuration +
                  " " +
                  t("business_days")
                )}
              </dd>
            </dl>
          </div>

          {/* ------------ Include house of common holiday ------------ */}
          <div className="species-data col-sm-4">
            <dl>
              <dt className="text-muted">
                {t("include_house_of_common_holidays")}
              </dt>
              <dd>
                {props.activityInfo?.includeHouseOfCommons == null ? (
                  <MissingData />
                ) : props.activityInfo?.includeHouseOfCommons ? (
                  t("yes")
                ) : (
                  t("no")
                )}
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActivityInformation;
