import cc from "classcat";
import MoveDownButton from "components/atoms/MoveDownButton";
import MoveUpButton from "components/atoms/MoveUpButton";
import SafeRenderHtml from "components/atoms/SafeRenderHtml";
import {
  BptTemplateStep,
  BptTemplateStepBundle,
  Maybe,
} from "generated/gql-types";
import useCheckboxList from "hooks/util/useCheckboxList";
import * as React from "react";
import { useTranslation } from "react-i18next";
import htmlRemoveOuterPTag from "util/htmlRemoveOuterPTag";
import { getTemporaryNewBundleId } from "./ReorderStepBundlesUtil";

export interface StepBundlesReorderProps {
  value: Array<BptTemplateStepBundle>;
  onChange: (stepBundles: Array<BptTemplateStepBundle>) => void;
}

export const StepBundlesReorder: React.FC<StepBundlesReorderProps> = (
  props
) => {
  const { t } = useTranslation();
  const { value = [], onChange } = props;
  const { checkedIds, handleCheckboxChange, isIdChecked, clearCheckedIds } =
    useCheckboxList();

  // For display animation during create or delete steps.
  const [newStepAnimationState, setNewStepAnimationState] = React.useState<
    string[]
  >([]);

  function onMoveStepUp(index1: number, index2: number) {
    const list = [...value];

    if (list == null || (index1 <= 0 && index2 <= 0)) return;

    if (index2 <= 0) {
      // Swap outer step or bundle
      const List1 = list;
      if (List1 == null) return;
      const itemAbove = List1[index1 - 1];
      List1[index1 - 1] = List1[index1];
      List1[index1] = itemAbove;
    } else {
      // Swap inner step or bundle
      const List2 = list[index1].steps;
      if (List2 == null) return;
      const itemAbove = List2[index2 - 1];
      List2[index2 - 1] = List2[index2];
      List2[index2] = itemAbove;
    }
    onChange(list);
  }

  function onMoveStepDown(index1: number, index2: number) {
    const list = [...value];

    if (
      list == null ||
      (index1 >= list.length - 1 && index2 >= list[index1].steps!.length - 1)
    )
      return;

    if (index2 >= list[index1].steps!.length - 1) {
      // Swap outer step or bundle
      const List1 = list;
      if (List1 == null) return;
      const itemBelow = List1[index1 + 1];
      List1[index1 + 1] = List1[index1];
      List1[index1] = itemBelow;
    } else {
      // Swap inner step or bundle
      const List2 = list[index1].steps;
      if (List2 == null) return;
      const itemBelow = List2[index2 + 1];
      List2[index2 + 1] = List2[index2];
      List2[index2] = itemBelow;
    }
    onChange(list);
  }

  /**
   * Add the selected steps (in state) into the given bundle
   * @param stepBundle - The bundle to hold the selected steps.
   * If the given bundle is undefined, create a new bundle, add the selected steps into it,
   * and insert the new bundle at the top of the list.
   */
  const addStepsToBundle = (stepBundle: BptTemplateStepBundle | undefined) => {
    let list = [...value];
    const selectedStepIds = checkedIds as string[];

    if (list == null || selectedStepIds == null) return;

    // Get the selected steps (checked steps)
    const selectedStepBundles = list.filter(
      (sb) =>
        sb.steps?.[0]?.id != null && selectedStepIds.includes(sb.steps[0].id)
    );
    const selectedSteps = selectedStepBundles.map((x) => x.steps?.[0]);

    if (stepBundle != null) {
      // Add the selected steps into the given bundle.
      list.forEach((sb) => {
        if (sb.id === stepBundle?.id) {
          sb.steps = sb.steps?.concat(selectedSteps as BptTemplateStep[]);
        }
      });
    } else {
      // If the given bundle is undefined, create a new bundle with the given steps.
      const newStepBundle: BptTemplateStepBundle = createNewStepBundle(
        selectedSteps as BptTemplateStep[]
      );
      // Insert the new bundle at the top of the list.
      list.splice(0, 0, newStepBundle);
    }
    // Saved for display animation.
    setNewStepAnimationState(selectedStepIds);

    // Remove the selected steps from the list.
    selectedStepBundles.forEach(
      (ssb) => (list = list.filter((sb) => ssb.id !== sb.id))
    );
    // Clear checked ids state.
    clearCheckedIds();
    onChange(list);
  };

  /**
   * Remove the given step from bundle and insert the removed step below the insert index position.
   * @param step - The step to be removed from bundle.
   * @param insertIndex - The insertIndex position where the removed step to be inserted.
   */
  const removeStepFromBundle = (
    step: Maybe<BptTemplateStep>,
    insertIndex: number
  ) => {
    let list = [...value];

    if (list == null || step == null) return;

    // Create a new bundle with the given step.
    const newStepBundle: BptTemplateStepBundle = createNewStepBundle([step]);
    // Remove the given step from bundle.
    list.forEach(
      (sb) => (sb.steps = sb.steps?.filter((s) => s?.id !== step?.id))
    );
    // Insert the new bundle below the insertIndex position.
    list.splice(insertIndex + 1, 0, newStepBundle);
    // Saved for display animation.
    setNewStepAnimationState([step?.id ?? ""]);
    onChange(list);
  };

  /**
   * Create a new bundle and include the given steps
   * @param steps - The steps to be added to the new bundle.
   * @returns
   */
  const createNewStepBundle = (steps: BptTemplateStep[]) => {
    const newStepBundle: BptTemplateStepBundle = {
      id: getTemporaryNewBundleId(),
      steps: steps,
      calculatedDuration: null,
    };
    return newStepBundle;
  };

  React.useEffect(() => {
    if (newStepAnimationState.length !== 0) {
      setTimeout(() => {
        setNewStepAnimationState([]);
      }, 500);
    }
  }, [newStepAnimationState.length]);

  return (
    <>
      {/* ----- Create new bundle button ----- */}
      {checkedIds.length >= 2 && (
        <button
          type="button"
          className="btn btn-default mrgn-bttm-10"
          title={t("create_new_bundle_add_steps")}
          onClick={() => addStepsToBundle(undefined)}
        >
          <i className="fas fa-plus-circle mrgn-rght-sm"></i> Create new bundle
        </button>
      )}
      <ul className="template-list mrgn-bttm-md">
        {value.map((item1, index1) => {
          if (item1.steps == null) return null;
          const isBundle = item1.steps.length >= 2;
          return (
            <li
              key={index1}
              className={
                isBundle ? "template-list-bundle" : "template-list-single"
              }
            >
              <div className="flex justify-between gap-md align-center">
                <ul className="template-list full-width">
                  {item1.steps?.map((item2, index2) => (
                    <li
                      key={index2}
                      className={cc([
                        "template-list-item list-item",
                        newStepAnimationState?.includes(item2?.id ?? "")
                          ? "new-bundle-animation"
                          : "",
                      ])}
                    >
                      <div className="flex justify-between align-center">
                        {isBundle ? (
                          <>
                            <div>
                              <button
                                type="button"
                                className="btn btn-link hover-grey p-0 mrgn-rght-sm"
                                title={t("remove_step_from_bundle")}
                                onClick={() =>
                                  removeStepFromBundle(item2, index1)
                                }
                              >
                                <i className="fas fa-minus-circle"></i>
                              </button>
                              {/* ----- Step's name ----- */}
                              <div className="inline-flex font-size-18">
                                <SafeRenderHtml
                                  htmlString={htmlRemoveOuterPTag(
                                    item2?.name?.text ?? ""
                                  )}
                                />
                              </div>
                            </div>
                            {/* ----- Move up/down buttons ----- */}
                            <div className="flex justify-right gap-sm">
                              <MoveUpButton
                                onClick={() => onMoveStepUp(index1, index2)}
                                disabled={index2 <= 0}
                              />
                              <MoveDownButton
                                onClick={() => onMoveStepDown(index1, index2)}
                                disabled={index2 >= item1.steps!.length - 1}
                              />
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="checkbox mrgn-tp-0 mrgn-bttm-0">
                              {/* ----- Checkbox ----- */}
                              <label htmlFor={item2?.id!}>
                                <input
                                  type="checkbox"
                                  id={item2?.id!}
                                  checked={isIdChecked(item2?.id!)}
                                  onChange={handleCheckboxChange as any}
                                  title={t("select_for_bundling")}
                                />
                                {/* ----- Step's name ----- */}
                                <div className="font-size-18 mrgn-lft-sm">
                                  <SafeRenderHtml
                                    htmlString={htmlRemoveOuterPTag(
                                      item2?.name?.text ?? ""
                                    )}
                                  />
                                </div>
                              </label>
                            </div>
                            {/* ----- Move up/down buttons ----- */}
                            <div className="flex justify-right gap-sm">
                              <MoveUpButton
                                onClick={() => onMoveStepUp(index1, 0)}
                                disabled={index1 <= 0}
                              />
                              <MoveDownButton
                                onClick={() => onMoveStepDown(index1, 9999)}
                                disabled={index1 >= value.length - 1}
                              />
                            </div>
                          </>
                        )}
                      </div>
                    </li>
                  ))}
                </ul>
                {/* ----- Move up/down buttons ----- */}
                {isBundle && (
                  <div className="flex justify-right gap-sm">
                    <MoveUpButton
                      onClick={() => onMoveStepUp(index1, 0)}
                      disabled={index1 <= 0}
                    />
                    <MoveDownButton
                      onClick={() => onMoveStepDown(index1, 9999)}
                      disabled={index1 >= value.length - 1}
                    />
                  </div>
                )}
              </div>
              {/* ----- Add to bundle button ----- */}
              {isBundle && checkedIds.length >= 1 && (
                <button
                  type="button"
                  className="btn btn-default mrgn-bttm-10"
                  title={t("add_selected_steps_into_this_bundle")}
                  onClick={() => addStepsToBundle(item1)}
                >
                  <i className="fas fa-plus-circle mrgn-rght-sm"></i> Add to
                  this bundle
                </button>
              )}
            </li>
          );
        })}
      </ul>
      {/* <div>Checked IDs: {(checkedIds as string[]).join(", ")}</div> */}
      {/* <details className="mrgn-tp-md mrgn-bttm-md">
        <summary style={{ backgroundColor: "#fcf8e3" }}>
          Show data structure for debugging purposes
        </summary>
        <pre>
          <h5 className="mrgn-tp-sm">Steps and Bundles</h5>
          <div>{JSON.stringify(value, null, 2)}</div>
        </pre>
      </details> */}
    </>
  );
};
