import MissingData from "components/atoms/MissingData";
import SafeRenderHtml from "components/atoms/SafeRenderHtml";
import { BptProjectStep, Maybe } from "generated/gql-types";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { StepLinksCard } from "../../StepLink/StepLinksCard";
import { getProjectTimeline } from "../../Utility/GetProjectTimeline";

export interface ProjectStepInformationProps {
  projectId?: string;
  projectStepInfo?: Maybe<BptProjectStep>;
  isPhaseCompleted?: boolean;
}

export const ProjectStepInformation: React.FC<ProjectStepInformationProps> = (
  props
) => {
  const { t } = useTranslation();
  const { projectId, projectStepInfo, isPhaseCompleted } = props;

  const projectedTimeline = getProjectTimeline(
    projectStepInfo?.projectedTimeline
  );
  const actualTimeline = getProjectTimeline(projectStepInfo?.actualTimeline);

  return (
    <>
      <div className="species-field font-size-18">
        <div className="row">
          <dl>
            {/* ------------ Projected timeline ------------ */}
            <div className="species-data col-sm-6">
              <dt className="text-muted">{t("projected_timeline")}</dt>
              <dd>{projectedTimeline ?? <MissingData />}</dd>
            </div>

            {/* ------------ Actual timeline ------------ */}
            <div className="species-data col-sm-6">
              <dt className="text-muted">{t("actual_timeline")}</dt>
              <dd>{actualTimeline ?? <MissingData />}</dd>
            </div>
          </dl>
        </div>

        <div className="row">
          <dl>
            {/* ------------ Comments ------------ */}
            <div className="species-data col-sm-12">
              <dt className="text-muted">{t("comments")}</dt>
              <dd>
                {projectStepInfo?.comments == null ? (
                  <MissingData />
                ) : (
                  <SafeRenderHtml
                    htmlString={projectStepInfo?.comments.text ?? ""}
                  />
                )}
              </dd>
            </div>
          </dl>
        </div>
        {/* ------------ Link(s) for version tracking ------------ */}
        <StepLinksCard
          isPhaseCompleted={isPhaseCompleted}
          projectId={projectId}
          stepId={projectStepInfo?.id!}
          links={projectStepInfo?.links!}
        />
        <div className="row">
          <dl>
            {/* ------------ Last updated by ------------ */}
            <div className="species-data col-sm-12 text-right">
              <dt
                className="text-muted mrgn-rght-sm"
                style={{ display: "inline-block" }}
              >
                {t("last_updated_by")}
              </dt>
              <dd className="font-size-14" style={{ display: "inline" }}>
                {projectStepInfo?.lastModifiedBy == null ? (
                  <MissingData />
                ) : (
                  <SafeRenderHtml
                    htmlString={projectStepInfo?.lastModifiedBy ?? ""}
                  />
                )}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </>
  );
};

export default ProjectStepInformation;
