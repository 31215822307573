import { ApolloError, useMutation } from "@apollo/client";
import { OverlayContainer } from "@react-aria/overlays";
import type { OverlayTriggerState } from "@react-stately/overlays";
import { useOverlayTriggerState } from "@react-stately/overlays";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import GraphqlError from "components/GraphqlError";
import ModalDialog from "components/molecules/Modal";
import { useGlobalAlertContext } from "features/globalAlert";
import {
  BptProjectPhase,
  Maybe,
  UpdateBptProjectPhaseDocument,
} from "generated/gql-types";
import * as React from "react";
import { SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  domainModelIntoProjectPhaseForm,
  projectPhaseFormIntoCreateInput,
} from "../../Utility/bptProjectFormUtil";
import { ProjectPhaseForm, ProjectPhaseFormFields } from "../ProjectPhaseForm";

export interface EditProjectPhaseModalProps {
  modalState: OverlayTriggerState;
  projectId?: Maybe<string>;
  projectPhaseInfo?: Maybe<BptProjectPhase>;
  refetchQueries?: string[];
  onCompleted: (data: any) => void;
  onError: (error: ApolloError | undefined) => void;
}

const EditProjectPhaseModal: React.FC<EditProjectPhaseModalProps> = (props) => {
  const { t } = useTranslation();
  const alertContext = useGlobalAlertContext();
  const { modalState, projectId, projectPhaseInfo } = props;

  const [runSubmit, { loading: saving, error: savingError, reset }] =
    useMutation(UpdateBptProjectPhaseDocument, {
      refetchQueries: props.refetchQueries ?? [],
      onCompleted: props.onCompleted,
      onError: props.onError,
      errorPolicy: "all",
    });

  const onSubmit: SubmitHandler<Partial<ProjectPhaseFormFields>> = async (
    formData
  ) => {
    const input = projectPhaseFormIntoCreateInput(formData);

    try {
      const res = await runSubmit({
        variables: {
          projectId: projectId ?? "error",
          phaseId: projectPhaseInfo?.id ?? "error",
          input: input,
        },
      });

      if (res.errors == null) {
        modalState.close();
        alertContext.showSuccess({
          message: t("successfully_updated_project_phase"),
          timeOut: 5000,
        });
      }
    } catch (e) {
      console.warn("Error saving new project phase");
      console.error(e);
    }
  };

  const onClose = () => {
    modalState.close();
    reset();
  };

  if (!modalState.isOpen) return null;

  const formDefaultValues = domainModelIntoProjectPhaseForm(projectPhaseInfo);

  return (
    <>
      <OverlayContainer>
        <ModalDialog
          title={projectPhaseInfo?.name?.plainText ?? ""}
          className={"modal-width-40"}
          isOpen
          onClose={onClose}
          showModalFooter={false}
        >
          <GraphqlError
            title={t("error_update_project_phase")}
            errors={savingError}
          />
          {saving && <LoadingIndicator centered />}
          <ProjectPhaseForm
            defaultValues={formDefaultValues}
            onSubmit={onSubmit}
            onClose={onClose}
            isSaving={saving}
          />
        </ModalDialog>
      </OverlayContainer>
    </>
  );
};

export default EditProjectPhaseModal;

export const useProjectPhaseModal = useOverlayTriggerState;
