import { AlertTypes } from "components/atoms/Alert";
import * as React from "react";
import { useTranslation } from "react-i18next";
import type { OverlayTriggerState } from "@react-stately/overlays";
import {
  BptProjectActivity,
  BptProjectStage,
  BptProjectStep,
  Maybe,
} from "generated/gql-types";
import EditProjectStepModal from "../Step/EditProjectStepBtnModal/EditProjectStepModal";
import EditProjectActivityModal from "../Activity/EditProjectActivityBtnModal/EditProjectActivityModal";
import SkipPopupMenuModal from "./SkipPopupMenuModal";
import { useGlobalAlertContext } from "features/globalAlert";
import useAdminAlertMsg from "hooks/admin/useAdminAlertMsg";
import { PopupMenuKey } from "types/bpt/enums";

export interface TypeOfQueryInputProps {
  onSkip: (a: string, b: string, c: BptProjectStage, d: string) => any;
  isPhaseCompleted?: boolean;
  isProjectCompleted?: boolean;
}

export interface PopupMenuModalProps
  extends Omit<TypeOfQueryInputProps, "TypeOfQueryInputProps"> {
  __typename: string;
  menuTypeKey: string;
  modal: OverlayTriggerState;
  adminAlertMsgTitle: string;
  projectId?: Maybe<string>;
  dataInfo?: Maybe<BptProjectStep> | Maybe<BptProjectActivity>;
  hasActivities?: boolean;
  isCompleted?: boolean; // isStepCompleted OR isActivityCompleted OR isPhaseCompleted
}

export const PopupMenuModal: React.FC<PopupMenuModalProps> = (props) => {
  const { t } = useTranslation();
  const alertContext = useGlobalAlertContext();
  const adminAlertMsg = useAdminAlertMsg(props.adminAlertMsgTitle);

  const {
    __typename,
    menuTypeKey,
    modal,
    projectId,
    dataInfo,
    hasActivities,
    isCompleted,
    onSkip,
  } = props;

  const modalTitle =
    __typename != null && __typename === "BptProjectStep"
      ? menuTypeKey === PopupMenuKey.SKIP
        ? t("skip_step")
        : t("undo_skip")
      : menuTypeKey === PopupMenuKey.SKIP
      ? t("skip_activity")
      : t("undo_skip");
  const alertContent =
    __typename != null && __typename === "BptProjectStep"
      ? menuTypeKey === PopupMenuKey.SKIP
        ? t("html_skip_step_warning")
        : t("html_skip_step_warning")
      : menuTypeKey === PopupMenuKey.SKIP
      ? t("html_skip_activity_warning")
      : t("html_skip_activity_warning");
  const buttonTitle =
    menuTypeKey === PopupMenuKey.SKIP ? t("skip") : t("undo_skip");

  const validCondition = modal.isOpen && projectId != null && dataInfo != null;

  return (
    <>
      {/* --------------- Popup Menu Edit of BptProjectStep ---------------- */}
      {menuTypeKey === PopupMenuKey.EDIT &&
      __typename === "BptProjectStep" &&
      validCondition ? (
        <EditProjectStepModal
          modalState={modal}
          projectId={projectId}
          projectStepInfo={dataInfo as BptProjectStep}
          hasActivities={hasActivities}
          isStepCompleted={isCompleted}
          onCompleted={(data: any) => {
            alertContext.showSuccess({
              message: data?.name?.text, // TODO: change to project step
              timeOut: 5000,
            });
          }}
          onError={adminAlertMsg.onCreateError}
        />
      ) : null}
      {/* --------------- Popup Menu Edit of BptProjectActivity ---------------- */}
      {menuTypeKey === PopupMenuKey.EDIT &&
      __typename === "BptProjectActivity" &&
      validCondition ? (
        <EditProjectActivityModal
          modalState={modal}
          projectId={projectId}
          projectActivityInfo={dataInfo as BptProjectActivity}
          isActivityCompleted={isCompleted}
          onCompleted={(data: any) => {
            alertContext.showSuccess({
              message: data?.name?.text, // TODO: change to project activity
              timeOut: 5000,
            });
          }}
          onError={adminAlertMsg.onCreateError}
        />
      ) : null}
      {/* --------------- Popup Menu Skip of BptProjectStep and BptProjectActivity ---------------- */}
      {menuTypeKey === PopupMenuKey.SKIP && validCondition ? (
        <SkipPopupMenuModal
          confirmSkipModal={modal}
          modalTitle={modalTitle}
          alertContent={alertContent}
          alertConfirm={t("i_confirm")}
          alertType={AlertTypes.WARNING}
          buttonTitle={buttonTitle}
          onSkip={() =>
            onSkip(
              projectId!,
              dataInfo?.id!,
              BptProjectStage.Skipped,
              __typename
            )
          }
        />
      ) : null}
    </>
  );
};

export default PopupMenuModal;
