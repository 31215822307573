import { ApolloError, useMutation } from "@apollo/client";
import { OverlayContainer } from "@react-aria/overlays";
import type { OverlayTriggerState } from "@react-stately/overlays";
import { useOverlayTriggerState } from "@react-stately/overlays";
import Alert, { AlertTypes } from "components/atoms/Alert";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import ModalDialog from "components/molecules/Modal";
import type { GenusFields } from "components/molecules/admin/GenusForm";
import GenusForm from "components/molecules/admin/GenusForm";
import { EditGenusDocument } from "generated/gql-types";
import i18n from "i18n";
import * as React from "react";
import { SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";

export interface EditGenusModalProps {
  modalState: OverlayTriggerState;
  genusId: string;
  defaultValues: GenusFields;
  refetchQueries: string[];
  onCompleted: (data: any) => void;
  onError: (error: ApolloError | undefined) => void;
}

const EditGenusModal: React.FC<EditGenusModalProps> = (props) => {
  const { t } = useTranslation();
  const { modalState, genusId, defaultValues } = props;

  const [editGenus, { loading, error, reset }] = useMutation(
    EditGenusDocument,
    {
      refetchQueries: props.refetchQueries ?? [],
      onCompleted: props.onCompleted,
      onError: props.onError,
      errorPolicy: "all",
    }
  );

  const onSubmit: SubmitHandler<GenusFields> = async (formData) => {
    try {
      const res = await editGenus({
        variables: {
          id: genusId,
          name: formData.name,
        },
      });

      if (res.errors == null) {
        modalState.close();
      }
    } catch (e) {
      console.warn("Error saving genus");
      console.error(e);
    }
  };

  const onClose = () => {
    modalState.close();
    reset();
  };

  if (!modalState.isOpen) return null;

  return (
    <OverlayContainer>
      <ModalDialog
        title={t("edit_genus")}
        isOpen
        onClose={onClose}
        showModalFooter={false}
        // isDismissable
      >
        {error && (
          <Alert
            type={AlertTypes.DANGER}
            content={
              (i18n.language === "fr"
                ? error.graphQLErrors[0]?.extensions?.response?.body
                    ?.frenchMessage
                : error.graphQLErrors[0]?.extensions?.response?.body
                    ?.englishMessage) ?? "[Network Error]"
            }
          />
        )}
        {loading && <LoadingIndicator centered />}
        <GenusForm
          defaultValues={defaultValues}
          onSubmit={onSubmit}
          onClose={onClose}
        />
      </ModalDialog>
    </OverlayContainer>
  );
};

export default EditGenusModal;

export const useGenusModal = useOverlayTriggerState;
export type { GenusFields };
