import { AlertTypes } from "components/atoms/Alert";
import DeleteButtonModal from "components/atoms/DeleteButtonModal";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import SafeRenderHtml from "components/atoms/SafeRenderHtml";
import { RenderWhenAuthorized, ROLE_ACTIONS } from "features/auth/components";
import { useGlobalAlertContext } from "features/globalAlert";
import {
  BptTemplate,
  BptTemplateStage,
  BptTemplateStep,
  Maybe,
} from "generated/gql-types";
import useAdminAlertMsg from "hooks/admin/useAdminAlertMsg";
import * as React from "react";
import { useTranslation } from "react-i18next";
import htmlRemoveOuterPTag from "util/htmlRemoveOuterPTag";
import { ActivitiesSection } from "../../Activity/ActivitiesSection/ActivitiesSection";
import EditStepBtn from "../EditStepModal/EditStepBtn";
import { StepInformation } from "./StepInformation";

export interface StepDetailsProps
  extends React.DetailedHTMLProps<
    React.SelectHTMLAttributes<HTMLSelectElement>,
    HTMLSelectElement
  > {
  isOpen?: boolean;
  stepInfo?: Maybe<BptTemplateStep>;
  bptTemplate?: Maybe<BptTemplate>;
  isLoading?: boolean;
  onDeleteStep: (a: any, b: any) => void;
}

export const StepDetails: React.FC<StepDetailsProps> = (props) => {
  const { t } = useTranslation();
  const alertContext = useGlobalAlertContext();
  const adminAlertMsg = useAdminAlertMsg(t("step"));
  const { isOpen, stepInfo, bptTemplate, isLoading, onDeleteStep } = props;

  const [openId, setOpenId] = React.useState(false);

  const onToggle = (event: { preventDefault: () => void }) => {
    setOpenId(true);
  };

  const templateId = bptTemplate?.id;

  return (
    <details
      key={stepInfo?.id}
      className="detail-list detail-list-step bg-grey expand-collapse-list"
      open={isOpen}
      onToggle={onToggle}
    >
      <summary>
        <div className="flexbug-9-workaround">
          <div className="flex justify-between align-center">
            <h3>
              <SafeRenderHtml
                htmlString={htmlRemoveOuterPTag(
                  stepInfo?.name?.text ?? "Error"
                )}
              />
            </h3>
            {bptTemplate?.stage === BptTemplateStage.Draft ? (
              <div className="flex gap-sm align-center flex-wrap">
                <RenderWhenAuthorized
                  authorizedRoles={
                    ROLE_ACTIONS.administration.bptTemplate.update
                  }
                >
                  <EditStepBtn
                    templateId={templateId}
                    stepInfo={stepInfo}
                    onCompleted={(data: any) => {
                      alertContext.showSuccess({
                        message: data?.UpdateBptTemplateStep?.name?.text,
                        timeOut: 5000,
                      });
                    }}
                    onError={adminAlertMsg.onCreateError}
                  />
                </RenderWhenAuthorized>

                <RenderWhenAuthorized
                  authorizedRoles={
                    ROLE_ACTIONS.administration.bptTemplate.update
                  }
                >
                  <DeleteButtonModal
                    buttonText={t("delete")}
                    buttonTitle={t("delete_step")}
                    modalTitle={t("delete_step")}
                    alertContent={t("html_delete_step_warning")}
                    alertConfirm={t("i_confirm")}
                    alertType={AlertTypes.DANGER}
                    onDelete={() => onDeleteStep(templateId!, stepInfo?.id!)}
                    className="font-size-14 hover-grey vertical-align-baseline"
                  />
                </RenderWhenAuthorized>
              </div>
            ) : null}
          </div>
        </div>
      </summary>

      {isLoading ? (
        <LoadingIndicator centered className="mrgn-bttm-md" />
      ) : (
        <>
          <StepInformation templateId={templateId} stepInfo={stepInfo} />
          {openId && (
            <ActivitiesSection
              bptTemplate={bptTemplate}
              stepId={stepInfo?.id!}
            />
          )}
        </>
      )}
    </details>
  );
};

export default StepDetails;
