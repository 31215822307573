import * as React from "react";
import { useTranslation } from "react-i18next";
import ReorderPhasesPopup, {
  ReorderPhasesPopupProps,
  useReorderPhasesPopup,
} from "./ReorderPhasesPopup";

interface ReorderPhasesBtnProps
  extends Omit<ReorderPhasesPopupProps, "modalState"> {
  buttonLabel?: string;
}

const ReorderPhasesBtn: React.FC<ReorderPhasesBtnProps> = ({
  buttonLabel,
  ...props
}) => {
  const { t } = useTranslation();
  const modalState = useReorderPhasesPopup({});
  const label = buttonLabel ?? t("reorder_phases");
  const validId = props.phases != null && props.phases.length > 1;

  return (
    <>
      <button
        type="button"
        className="btn btn-link card-header-button-link"
        onClick={modalState.open}
        title={label}
        disabled={!validId}
        data-testid="button-reorder-phase"
      >
        <span
          className="glyphicon glyphicon-sort mrgn-rght-sm"
          aria-hidden="true"
        ></span>
        {label}
      </button>
      {validId ? (
        <ReorderPhasesPopup modalState={modalState} {...props} />
      ) : null}
    </>
  );
};

export default ReorderPhasesBtn;
