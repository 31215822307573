import AddPhaseIcon from "assets/svg/add-phase-icon.svg";
import { AlertTypes } from "components/atoms/Alert";
import DeleteButtonModal from "components/atoms/DeleteButtonModal";
import NoResults from "components/atoms/NoResults";
import SafeRenderHtml from "components/atoms/SafeRenderHtml";
import SectionCard from "components/atoms/SectionCard";
import { RenderWhenAuthorized, ROLE_ACTIONS } from "features/auth/components";
import {
  BptTemplate,
  BptTemplatePhase,
  BptTemplateStage,
  Maybe,
} from "generated/gql-types";
import useAdminAlertMsg from "hooks/admin/useAdminAlertMsg";
import useDetailsState from "hooks/util/useDetailsState";
import { AdminPathHelpers } from "pages/admin/AdminRouter";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import htmlRemoveOuterPTag from "util/htmlRemoveOuterPTag";
import TotalCalculatedDuration from "../../utility/TotalCalculatedDuration";
import AddPhaseBtn from "../CreatePhaseModal/AddPhaseBtn";
import PhaseInformation from "../PhaseInformationCard/PhaseInformation";
import PhasesCardHeader from "./PhasesCardHeader";

interface PhasesCardProps {
  bptTemplate?: Maybe<BptTemplate>;
  phases?: Maybe<BptTemplatePhase>[] | null;
  onDeletePhase: (templateId: string, phaseId: string) => Promise<boolean>;
}

export const PhasesCard: React.FC<PhasesCardProps> = (props) => {
  const { t } = useTranslation();
  const { bptTemplate, phases, onDeletePhase } = props;

  const adminAlertMsg = useAdminAlertMsg(t("phase"));
  const phaseDetailsState = useDetailsState(phases);

  return (
    <>
      <SectionCard
        header={
          <PhasesCardHeader
            bptTemplate={bptTemplate}
            phases={phases}
            onExpandAll={phaseDetailsState.expandAll}
            onCollapseAll={phaseDetailsState.collapseAll}
          />
        }
        contentAreaProps={{ className: "mrgn-tp-sm" }}
        className="pb-0"
        showLine={true}
      >
        {phases == null || phases?.length === 0 ? (
          <RenderWhenAuthorized
            authorizedRoles={ROLE_ACTIONS.administration.bptTemplate.create}
            fallbackComponent={<NoResults centered />}
          >
            <div className="text-center mrgn-tp-md">
              <div className="lead mrgn-tp-md mrgn-bttm-sm">
                {/* ------------ Add phase button ------------ */}
                {bptTemplate?.stage === BptTemplateStage.Draft ? (
                  <AddPhaseBtn
                    templateId={bptTemplate?.id ?? ""}
                    buttonLabel={t("no_phases_please_add")}
                    showImage
                    onCompleted={(data: any) => {
                      if (data?.createBptTemplatePhase?.id != null) {
                        adminAlertMsg.onCreateSuccess();
                        phaseDetailsState.addDetail(
                          data.createBptTemplatePhase.id
                        );
                      }
                    }}
                    onError={adminAlertMsg.onCreateError}
                  />
                ) : (
                  <>
                    <img src={AddPhaseIcon} alt="" />
                    <div className={"no-results-content font-size-18"}>
                      {t("no_phases")}
                    </div>
                  </>
                )}
              </div>
            </div>
          </RenderWhenAuthorized>
        ) : (
          phases?.map((item, index) => {
            return (
              <details
                key={index}
                className="detail-list detail-list-phase bg-grey expand-collapse-list"
                open={phaseDetailsState.getOpenState(item?.id)}
                onClick={(e) => {
                  e.preventDefault();
                  phaseDetailsState.toggleOpenState(item?.id);
                }}
              >
                <summary>
                  <div className="flexbug-9-workaround">
                    <div className="flex justify-between align-center">
                      <h3>
                        {t("phase")} {index! + 1}
                        {": "}
                        <SafeRenderHtml
                          htmlString={htmlRemoveOuterPTag(item?.name?.text!)}
                        />
                      </h3>
                      <div className="flex gap-sm align-start flex-wrap justify-right">
                        <RenderWhenAuthorized
                          authorizedRoles={
                            ROLE_ACTIONS.administration.bptTemplate.update
                          }
                        >
                          <Link
                            to={{
                              pathname: AdminPathHelpers.BptTemplatesPhaseIndex(
                                bptTemplate?.id ?? "error",
                                item?.id ?? "error"
                              ),
                            }}
                            title={t("view_details")}
                            className="card-header-button-link hover-underline font-size-14 hover-grey"
                            data-testid="link-view-details"
                          >
                            <i className="fas fa-eye mrgn-rght-sm"></i>
                            {t("view_details")}
                          </Link>
                        </RenderWhenAuthorized>
                        {bptTemplate?.stage === BptTemplateStage.Draft ? (
                          <RenderWhenAuthorized
                            authorizedRoles={
                              ROLE_ACTIONS.administration.bptTemplate.update
                            }
                          >
                            <DeleteButtonModal
                              buttonText={t("delete")}
                              buttonTitle={t("delete_phase")}
                              modalTitle={t("delete_phase")}
                              alertContent={t("html_delete_phase_warning")}
                              alertConfirm={t("i_confirm")}
                              alertType={AlertTypes.DANGER}
                              onDelete={async () =>
                                (await onDeletePhase(
                                  bptTemplate?.id!,
                                  item?.id!
                                ))
                                  ? phaseDetailsState.removeDetail(item?.id)
                                  : console.error("Failed to delete phase!")
                              }
                              className="font-size-14 hover-grey vertical-align-baseline"
                            />
                          </RenderWhenAuthorized>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </summary>

                <PhaseInformation
                  templateId={bptTemplate?.id!}
                  phaseInfo={item}
                />
              </details>
            );
          })
        )}

        <div className="pt-0 separator-line-top mrgn-tp-20"></div>
        <div className="flex justify-between align-center py-3">
          <div>
            {bptTemplate?.stage === BptTemplateStage.Draft ? (
              <RenderWhenAuthorized
                authorizedRoles={ROLE_ACTIONS.administration.bptTemplate.create}
              >
                {/* ------------ Add phase button ------------ */}
                <AddPhaseBtn
                  templateId={bptTemplate?.id ?? ""}
                  buttonLabel={t("add_phase")}
                  onCompleted={(data: any) => {
                    if (data?.createBptTemplatePhase?.id != null) {
                      adminAlertMsg.onCreateSuccess();
                      phaseDetailsState.addDetail(
                        data.createBptTemplatePhase.id
                      );
                    }
                  }}
                  onError={adminAlertMsg.onCreateError}
                />
              </RenderWhenAuthorized>
            ) : null}
          </div>

          {/* ------------ Total calculated duration ------------ */}
          <TotalCalculatedDuration
            calculatedDuration={bptTemplate?.calculatedDuration}
          />
        </div>
      </SectionCard>
      {/* <details className="mrgn-tp-md mrgn-bttm-md">
        <summary style={{ backgroundColor: "#fcf8e3" }}>
          Show data structure for debugging purposes
        </summary>
        <pre>
          <h5 className="mrgn-tp-sm">Phases Open States</h5>
          <div>{JSON.stringify(phaseDetailsState.openStates, null, 2)}</div>
        </pre>
      </details> */}
    </>
  );
};
