import { ApolloError, useMutation } from "@apollo/client";
import { OverlayContainer } from "@react-aria/overlays";
import type { OverlayTriggerState } from "@react-stately/overlays";
import { useOverlayTriggerState } from "@react-stately/overlays";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import GraphqlError from "components/GraphqlError";
import ModalDialog from "components/molecules/Modal";
import { useGlobalAlertContext } from "features/globalAlert";
import {
  BptProjectActivity,
  Maybe,
  UpdateBptProjectActivityDocument,
} from "generated/gql-types";
import * as React from "react";
import { SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  domainModelIntoProjectActivityForm,
  projectActivityFormIntoCreateInput,
} from "../../Utility/bptProjectFormUtil";
import ProjectActivityForm, {
  ProjectActivityFormFields,
} from "../ProjectActivityForm";

export interface ProjectEditActivityModalProps {
  modalState: OverlayTriggerState;
  projectId?: Maybe<string>;
  projectActivityInfo?: Maybe<BptProjectActivity>;
  isActivityCompleted?: boolean;
  onCompleted: (data: any) => void;
  onError: (error: ApolloError | undefined) => void;
}

const EditProjectActivityModal: React.FC<ProjectEditActivityModalProps> = (
  props
) => {
  const { t } = useTranslation();
  const alertContext = useGlobalAlertContext();
  const { modalState, projectId, projectActivityInfo, isActivityCompleted } =
    props;

  const [updateBptProjectActivity, { loading: saving, error, reset }] =
    useMutation(UpdateBptProjectActivityDocument, {
      refetchQueries: ["BptProjectActivityList", "BptProjectStepList"],
      onCompleted: props.onCompleted,
      onError: props.onError,
      errorPolicy: "all",
    });

  const onSubmit: SubmitHandler<Partial<ProjectActivityFormFields>> = async (
    formData
  ) => {
    const input = projectActivityFormIntoCreateInput(formData);
    try {
      const res = await updateBptProjectActivity({
        variables: {
          projectId: projectId ?? "error",
          activityId: projectActivityInfo?.id ?? "error",
          input: input,
        },
      });

      if (res.errors == null) {
        modalState.close();
        alertContext.showSuccess({
          message: t("successfully_updated_project_activity"),
          timeOut: 5000,
        });
      }
    } catch (e) {
      console.warn("Error saving new project activity");
      console.error(e);
    }
  };

  const onClose = () => {
    modalState.close();
    reset();
  };

  if (!modalState.isOpen) return null;

  const formDefaultValues =
    domainModelIntoProjectActivityForm(projectActivityInfo);

  return (
    <>
      <OverlayContainer>
        <ModalDialog
          title={projectActivityInfo?.name?.plainText ?? ""}
          className={"modal-width-40"}
          isOpen
          onClose={onClose}
          showModalFooter={false}
        >
          <GraphqlError
            title={t("error_update_project_activity")}
            errors={error}
          />
          {saving && <LoadingIndicator centered />}
          <ProjectActivityForm
            defaultValues={formDefaultValues}
            onSubmit={onSubmit}
            onClose={onClose}
            isSaving={saving}
            isActivityCompleted={isActivityCompleted}
          />
        </ModalDialog>
      </OverlayContainer>
    </>
  );
};

export default EditProjectActivityModal;

export const useProjectActivityModal = useOverlayTriggerState;
