const listingChangeLogTranslation = {
  en: {
    // Page titles
    listing_change_log: "Listing change log",
    listing_change_details: "Listing change details",

    // Listing name changes
    listing_name_changes: "Listing name changes",

    // Change Log Description
    listingws_update: "Listing wildlife species profile updated by",
    listingws_date_of_addition_on_schedule1_updated:
      "Date of addition on Schedule 1 updated by",
    listingprocess_manual_listing_process_added:
      "Manual listing process added by",
    listingws_parent_added: "Parent wildlife species added by",
    listingws_parent_removed: "Parent wildlife species removed by",
    responsestatement_rs_created: "Response statement created by",
    RS_CREATED: "Response statement created by",
    responsestatement_rs_updated: "Response statement updated by",
    RS_UPDATED: "Response statement updated by",
    listingws_added_to_schedule2: "Wildlife species added to Schedule 2.",
    listingws_added_to_schedule3: "Wildlife species added to Schedule 3.",
    listingws_removed_from_schedule2: "Wildlife species removed from Schedule 2.",
    listingws_removed_from_schedule3: "Wildlife species removed from Schedule 3.",
  },

  fr: {
    // Page titles
    listing_change_log:
      "Journal des modifications aux espèces du programme de l'Inscription",
    listing_change_details:
      "Détails des modifications aux espèces du programme de l'Inscription",

    // Listing name changes
    listing_name_changes:
      "Changements de nom d'espèces du programme de l'Inscription",

    // Change Log Description
    listingws_update:
      "Profil de l'espèce sauvage du programme de l'Inscription mis à jour par",
    listingws_date_of_addition_on_schedule1_updated:
      "Date de l'ajout à l'Annexe 1 mise à jour par",
    listingprocess_manual_listing_process_added:
      "Processus d'inscription manuel ajouté par",
    listingws_parent_added: "Espèces sauvages parent ajoutées par",
    listingws_parent_removed: "Espèces sauvages parent enlevées par",
    responsestatement_rs_created: "Énoncé de réaction cré par",
    RS_CREATED: "Énoncé de réaction cré par",
    responsestatement_rs_updated: "Relevé de réponses mis à jour par",
    RS_UPDATED: "Relevé de réponses mis à jour par",
    listingws_added_to_schedule2: "Espèces sauvages du programme de l’Inscription ajoutées à l'Annexe 2.",
    listingws_added_to_schedule3: "Espèces sauvages du programme de l’Inscription ajoutées à l'Annexe 3.",
    listingws_removed_from_schedule2: "Espèces sauvages du programme de l’Inscription retirées de l'Annexe 2.",
    listingws_removed_from_schedule3: "Espèces sauvages du programme de l’Inscription retirées de l'Annexe 3.",
  },
};

export default listingChangeLogTranslation;
