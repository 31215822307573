import { BptProjectStage, Maybe } from "generated/gql-types";
import * as React from "react";
import { useTranslation } from "react-i18next";
import cc from "classcat";

export interface StatusTagProps {
  showHOC?: boolean;
  stage?: Maybe<BptProjectStage>;
  isDelay?: boolean;
  className?: string;
}

export const StatusTag: React.FC<StatusTagProps> = (props) => {
  const { showHOC, stage, isDelay } = props;
  const { t, i18n } = useTranslation();

  const className = props.className ?? "status-tag";
  let _className = "";
  let _labelName = "";
  let _iconName = "";

  switch (stage) {
    case BptProjectStage.NotStarted:
      _className = cc([className, "text-muted"]); // Grey color
      _labelName = t("not_started");
      _iconName = "far fa-circle";
      break;
    case BptProjectStage.InProgress:
      _className = cc([className, "text-primary"]); // Blue color
      _labelName = t("in_progress");
      _iconName = "far fa-clock";
      break;
    case BptProjectStage.Completed:
      _className = cc([className, "text-success"]); // Green color
      _labelName = t("completed");
      _iconName = "far fa-check-circle";
      break;
    case BptProjectStage.Skipped:
      _className = className; // Black color
      _labelName = t("skipped");
      _iconName = "far fa-times-circle";
      break;
    default:
      break;
  }

  return (
    <>
      {showHOC ? (
        <div className="status-tag text-primary">
          <i className="fas fa-home"></i> {t("hoc")}
        </div>
      ) : null}
      {stage != null ? (
        <div className={_className}>
          <i className={_iconName}></i> {_labelName}
        </div>
      ) : null}
      {isDelay ? (
        <div className={cc([className, "text-warning"])}>
          <i className="fas fa-exclamation-triangle"></i> {t("delayed")}
        </div>
      ) : null}
    </>
  );
};

export default StatusTag;
