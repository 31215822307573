import { useMutation, useQuery } from "@apollo/client";
import Alert from "components/atoms/Alert";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import NoResults from "components/atoms/NoResults";
import SectionCard from "components/atoms/SectionCard";
import Layout from "components/layouts/OneColumn";
import PageSizeSelect from "components/molecules/PageSizeSelect";
import Pagination from "components/molecules/Pagination";
import {
  AdminPageFilterForm,
  QueryFields,
  defaultQueryFields,
} from "components/molecules/admin/AdminPageFilterForm";
import AdminArchiveModal from "components/organisms/admin/AdminArchiveModal";
import AdminTable from "components/organisms/admin/AdminTable";
import AdminUnarchiveModal from "components/organisms/admin/AdminUnarchiveModal";
import CreateSubspeciesModalButton from "components/organisms/admin/CreateSubspeciesModal/ButtonWithModal";
import EditSubspeciesModalButton from "components/organisms/admin/EditSubspeciesModal/ButtonWithModal";
import {
  ArchiveSubspeciesDocument,
  SubspeciesForAdminSubspeciesDocument,
  UnarchiveSubspeciesDocument,
} from "generated/gql-types";
import useAdminAlertMsg from "hooks/admin/useAdminAlertMsg";
import { usePagination } from "hooks/util/usePagination";
import * as React from "react";
import { flushSync } from "react-dom";
import { useTranslation } from "react-i18next";
import { formatTimestamp } from "util/formatTimestamp";
import GraphqlError from "../../components/GraphqlError";
import { RenderWhenAuthorized } from "../../features/auth/components";
import { ROLE_ACTIONS } from "../../features/auth/roles";

export interface AdminSubspeciesProps {}

export const AdminSubspecies: React.FC<AdminSubspeciesProps> = (props) => {
  const { t, i18n } = useTranslation();
  const [selectedRowId, setSelectedRowId] = React.useState<string>("");
  const [filters, setFilters] = React.useState<QueryFields>(defaultQueryFields);
  const adminAlertMsg = useAdminAlertMsg(t("subspecies"));

  const pagination = usePagination();

  const { data, loading, error } = useQuery(
    SubspeciesForAdminSubspeciesDocument,
    {
      variables: {
        params: {
          pageNumber: pagination.currentPage,
          pageSize: pagination.pageSize,
          status: filters.showInactive ? "inactive" : "active",
          search: filters.search.length > 0 ? filters.search : undefined,
          searchType: "contains",
          isCaseSensitive: false,
        },
      },
      fetchPolicy: "network-only",
      errorPolicy: "all",
    }
  );

  const [runArchive] = useMutation(ArchiveSubspeciesDocument, {
    refetchQueries: ["SubspeciesForAdminSubspecies"],
    onCompleted: (data) => {
      adminAlertMsg.onArchiveSuccess(
        data?.deactivateSubspecies?.name,
        data?.deactivateSubspecies?.name
      );
    },
    onError: (_err) => {
      adminAlertMsg.onArchiveError();
    },
    errorPolicy: "all",
  });
  const [runUnarchive] = useMutation(UnarchiveSubspeciesDocument, {
    refetchQueries: ["SubspeciesForAdminSubspecies"],
    onError: (_err) => {
      adminAlertMsg.onUnarchiveError();
    },
    onCompleted: (data) => {
      adminAlertMsg.onUnarchiveSuccess(
        data?.reactivateSubspecies?.name,
        data?.reactivateSubspecies?.name
      );
    },
    errorPolicy: "all",
  });

  const onSelectionChange = (keys: "all" | Set<string>) => {
    if (keys === "all") return;
    const selectedId = Array.from(keys)[0] ?? "";
    setSelectedRowId(selectedId);
  };

  const onArchive = async () => {
    if (selectedRowId == null || selectedRowId === "") return;
    await runArchive({ variables: { id: selectedRowId } });
  };

  const onUnarchive = async () => {
    if (selectedRowId == null || selectedRowId === "") return;
    await runUnarchive({ variables: { id: selectedRowId } });
  };

  React.useEffect(() => {
    setSelectedRowId("");
  }, [filters, pagination.pageSize]);

  const columns = React.useMemo(
    () => [
      { name: t("name"), key: "name" },
      { name: t("created_by"), key: "createdBy" },
      { name: t("creation_date"), key: "createdAt" },
      { name: t("updated_by"), key: "modifiedBy" },
      { name: t("updated_date"), key: "modifiedAt" },
    ],
    [i18n.language]
  );

  const rows =
    data?.subspeciesList?.subspecies.map((subspecies) => ({
      ...subspecies,
      createdAt: formatTimestamp(subspecies?.createdAt),
      modifiedAt: formatTimestamp(subspecies?.modifiedAt),
    })) ?? [];

  const selectedRow = rows.find((x) => x.id === selectedRowId);

  function onFilterSubmit(arr: QueryFields) {
    flushSync(() => {
      setFilters(arr);
      pagination.goToPage(1);
    });
  }

  return (
    <Layout.Root>
      <Layout.Content>
        <h1>{t("subspecies")}</h1>
        <GraphqlError title="Error loading Subspecies" errors={error} />

        {adminAlertMsg.alertIsVisible ? (
          <Alert
            type={adminAlertMsg.pageAlertType!}
            content={adminAlertMsg.pageAlertMsg!}
            onClose={adminAlertMsg.close}
            timeOut={5000}
          />
        ) : null}

        <SectionCard
          header={
            <div className="flex justify-between align-start">
              <h2>{t("list_of_subspecies")}</h2>
              <div className="flex gap-md">
                <RenderWhenAuthorized
                  authorizedRoles={
                    ROLE_ACTIONS.administration.subspecies.create
                  }
                >
                  <CreateSubspeciesModalButton
                    refetchQueries={["SubspeciesForAdminSubspecies"]}
                    onCompleted={(data) => {
                      data?.createSubspecies &&
                        adminAlertMsg.onCreateSuccess(
                          data?.createSubspecies?.name,
                          data?.createSubspecies?.name
                        );
                    }}
                    onError={adminAlertMsg.onCreateError}
                  />
                </RenderWhenAuthorized>
                <RenderWhenAuthorized
                  authorizedRoles={
                    ROLE_ACTIONS.administration.subspecies.update
                  }
                >
                  <EditSubspeciesModalButton
                    subspeciesId={selectedRowId}
                    refetchQueries={["SubspeciesForAdminSubspecies"]}
                    defaultValues={{
                      name: selectedRow?.name ?? "",
                    }}
                    onCompleted={(data) => {
                      data?.renameSubspecies &&
                        adminAlertMsg.onEditSuccess(
                          data?.renameSubspecies?.name,
                          data?.renameSubspecies?.name
                        );
                    }}
                    onError={adminAlertMsg.onCreateError}
                  />
                </RenderWhenAuthorized>
                <RenderWhenAuthorized
                  authorizedRoles={
                    ROLE_ACTIONS.administration.subspecies.deactivateReactivate
                  }
                >
                  {!filters.showInactive ? (
                    <AdminArchiveModal
                      onArchive={onArchive}
                      modalTitle={t("archive_a_subspecies")}
                      warningText={t("archive_subspecies_warning")}
                      selectedItemId={selectedRowId}
                    />
                  ) : null}
                </RenderWhenAuthorized>
                <RenderWhenAuthorized
                  authorizedRoles={
                    ROLE_ACTIONS.administration.subspecies.deactivateReactivate
                  }
                >
                  {filters.showInactive &&
                  (!selectedRow || selectedRow?.status === "inactive") ? (
                    <AdminUnarchiveModal
                      onUnarchive={onUnarchive}
                      modalTitle={t("unarchive_a_subspecies")}
                      warningText={t("unarchive_subspecies_warning")}
                      selectedItemId={selectedRowId}
                    />
                  ) : null}
                </RenderWhenAuthorized>
              </div>
            </div>
          }
        >
          {/*FILTERS:*/}
          <div className="flex justify-between flex-wrap mrgn-bttm-sm">
            <AdminPageFilterForm onSubmit={onFilterSubmit} />
            <div className="flex-auto flex-col gap-sm align-end">
              <div>
                <PageSizeSelect
                  pageSize={pagination.pageSize}
                  onChangePageSize={pagination.setPageSize}
                />
              </div>
              <div className="font-size-16 justify-end pb-3">
                {pagination.makeShowingString(
                  data?.subspeciesList?.pagination?.totalCount
                )}
              </div>
            </div>
          </div>

          {/*RESULTS:*/}
          {loading ? (
            <LoadingIndicator centered className="mrgn-bttm-md" />
          ) : !rows || rows?.length === 0 ? (
            <NoResults centered />
          ) : (
            <>
              <AdminTable
                aria-label="Subspecies table" /*TODO: i18n*/
                selectionMode="single"
                showSelectionCheckboxes
                onSelectionChange={onSelectionChange as any}
                selectedKeys={[selectedRowId]}
                columns={columns}
                rows={rows}
              />

              <Pagination
                {...pagination.paginationComponentProps}
                totalPages={data?.subspeciesList?.pagination?.totalPages ?? 0}
              />
            </>
          )}
        </SectionCard>
      </Layout.Content>
    </Layout.Root>
  );
};

export default AdminSubspecies;
