import ResetIcon from "assets/svg/reset-icon.svg";
import * as React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

export interface QueryFields {
  search: string;
  includeDeactivatedAndObsolete: boolean;
}

export const defaultQueryFields = {
  search: "",
  includeDeactivatedAndObsolete: false,
};

export interface BptAdminPageFilterFormProps {
  onSubmit: SubmitHandler<QueryFields>;
}

export const BptAdminPageFilterForm: React.FC<BptAdminPageFilterFormProps> = (
  props
) => {
  const { t } = useTranslation();
  const form = useForm<QueryFields>({ defaultValues: defaultQueryFields });
  const { register, handleSubmit, setFocus, watch } = form;

  const onClearSearch = () => {
    form.setValue("search", "");
    form.setValue("includeDeactivatedAndObsolete", false);
    setFocus("search");
  };

  const onSubmit: SubmitHandler<QueryFields> = (newValues) => {
    const mapped = newValues;
    props.onSubmit(mapped);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex-col">
        <div className="flex align-start flex-wrap">
          <div className="flex-col">
            <div className="input-group mrgn-rght-sm">
              <label className="wb-inv" htmlFor="adminSearch">
                {t("search")}
              </label>
              <input
                type="text"
                id="adminSearch"
                className="form-control search-box"
                placeholder={t("search")}
                {...register("search")}
              />
              <button
                type="button"
                aria-label="Clear search"
                className="search-box-reset"
                hidden={watch("search") === ""}
                onClick={onClearSearch}
                data-testid="button-clear"
              >
                <img src={ResetIcon} alt={t("reset")} />
              </button>
            </div>
            <div className="checkbox">
              <label htmlFor="includeDeactivatedAndObsoleteCheckbox">
                <input
                  type="checkbox"
                  id="includeDeactivatedAndObsoleteCheckbox"
                  {...register("includeDeactivatedAndObsolete")}
                />
                &nbsp;
                {t("include_deactivated_and_obsolete_templates")}
              </label>
            </div>
          </div>

          <button
            type="submit"
            className="btn btn-primary flex justify-center align-center mrgn-rght-md mrgn-bttm-md"
            data-testid="button-search"
          >
            <span className="glyphicon glyphicon-search mrgn-rght-sm" />
            {t("search")}
          </button>
        </div>
      </div>
    </form>
  );
};

export default BptAdminPageFilterForm;
