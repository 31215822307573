import * as React from "react";
import { useTranslation } from "react-i18next";
import htmlRemoveOuterPTag from "util/htmlRemoveOuterPTag";
import MissingData from "../../../../../components/atoms/MissingData";
import {
  BptProject,
  BptProjectStage,
  Maybe,
} from "../../../../../generated/gql-types";
import htmlIsNullOrEmpty from "../../../../../util/htmlIsNullOrEmpty";
import SafeRenderHtml from "../../../../atoms/SafeRenderHtml";
import SectionCard from "../../../../atoms/SectionCard";
import EditBptProjectModalWithButton from "../../EditBptProjectModal/EditBptProjectButtonWithModal";
import { useGlobalAlertContext } from "features/globalAlert";
import { useAdminAlertMsg } from "hooks/admin/useAdminAlertMsg";
import { useHistory } from "react-router-dom";
import { BptPathHelpers } from "pages/bpt/bptRouter";
import { domainModelIntoBptProjectForm } from "../../Utility/bptProjectFormUtil";
import { getProjectTimeline } from "../../Utility/GetProjectTimeline";
import { RenderWhenAuthorized, ROLE_ACTIONS } from "features/auth/components";

export interface ProjectInformationCardProps {
  project?: Maybe<BptProject>;
}

export const ProjectInformationCard: React.FC<ProjectInformationCardProps> = (
  props
) => {
  const { project } = props;
  const { t, i18n } = useTranslation();
  const globalAlertContext = useGlobalAlertContext();
  const adminAlertMsg = useAdminAlertMsg(t("bptProject"));
  const history = useHistory();

  const program =
    i18n.language === "fr"
      ? project?.templateReference?.program?.name?.french
      : project?.templateReference?.program?.name?.english;
  const templateName = project?.templateReference?.name?.text;
  const templateVersion = project?.templateReference?.templateVersion;
  const projectLead = project?.projectLead?.text;
  const estimatedProjectTimeline = getProjectTimeline(
    project?.estimatedTimeline
  );
  const projectedProjectTimeline = getProjectTimeline(
    project?.projectedTimeline
  );
  const actualProjectTimeline = getProjectTimeline(project?.actualTimeline);

  const isProjectCompleted = project?.stage === BptProjectStage.Completed;

  return (
    <>
      <SectionCard
        header={
          <div className="flex justify-between align-start">
            <h2 className="mrgn-tp-sm">{t("project_information")}</h2>

            {isProjectCompleted ? null : (
              <RenderWhenAuthorized
                authorizedRoles={ROLE_ACTIONS.bptProject.update}
              >
                <div className="flex gap-sm">
                  <EditBptProjectModalWithButton
                    onCompleted={(data: any) => {
                      //Todo: Need to fix this message to show
                      globalAlertContext.showSuccess({
                        message: data?.createBptProject?.name?.plainText,
                        timeOut: 5000,
                      });
                      history.push({
                        pathname: BptPathHelpers.Project(
                          project?.id?.toString() ?? ""
                        ),
                      });
                    }}
                    refetchQueries={["BptProject"]}
                    projectId={project?.id ?? ""}
                    initialValues={domainModelIntoBptProjectForm(project)}
                    onError={adminAlertMsg.onCreateError}
                  />
                </div>
              </RenderWhenAuthorized>
            )}
          </div>
        }
      >
        <div className="species-field">
          <dl className="row">
            {/* ----- Program ----- */}
            <div className="species-data col-sm-4">
              <dt className="text-muted">{t("program")}</dt>
              <dd>
                {htmlIsNullOrEmpty(program) ? (
                  <MissingData />
                ) : (
                  <SafeRenderHtml htmlString={htmlRemoveOuterPTag(program)} />
                )}
              </dd>
            </div>
            {/* ----- Template name (and version) ----- */}
            <div className="species-data col-sm-4">
              <dt className="text-muted">{t("template_name_and_version")}</dt>
              <dd>
                {htmlIsNullOrEmpty(templateName) ? (
                  <MissingData />
                ) : (
                  <SafeRenderHtml
                    htmlString={htmlRemoveOuterPTag(templateName)}
                  />
                )}{" "}
                {
                  <i>
                    {"(v."}
                    {templateVersion ?? <MissingData />}
                    {")"}
                  </i>
                }
              </dd>
            </div>
            {/* ----- Project lead ----- */}
            <div className="species-data col-sm-4">
              <dt className="text-muted">{t("project_lead")}</dt>
              <dd>
                {htmlIsNullOrEmpty(projectLead) ? (
                  <MissingData />
                ) : (
                  <SafeRenderHtml
                    htmlString={htmlRemoveOuterPTag(projectLead)}
                  />
                )}
              </dd>
            </div>
          </dl>

          <dl className="row">
            {/* ----- Estimated project timeline ----- */}
            <div className="species-data col-sm-4">
              <dt className="text-muted">{t("estimated_project_timeline")}</dt>
              <dd>{estimatedProjectTimeline ?? <MissingData />}</dd>
            </div>
            {/* ----- Projected project timeline ----- */}
            <div className="species-data col-sm-4">
              <dt className="text-muted">{t("projected_project_timeline")}</dt>
              <dd>{projectedProjectTimeline ?? <MissingData />}</dd>
            </div>
            {/* ----- Actual project timeline ----- */}
            <div className="species-data col-sm-4">
              <dt className="text-muted">{t("actual_project_timeline")}</dt>
              <dd>{actualProjectTimeline ?? <MissingData />}</dd>
            </div>
          </dl>
        </div>
      </SectionCard>
    </>
  );
};

export default ProjectInformationCard;
