import { useQuery } from "@apollo/client";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import { BptProjectActivityListDocument } from "generated/gql-types";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { TypeOfQueryInputProps } from "../../Utility/PopupMenuModal";
import { ProjectActivityBundles } from "./ProjectActivityBundles";

export interface ProjectActivitiesSectionProps
  extends Omit<TypeOfQueryInputProps, "TypeOfQueryInputProps"> {
  projectId?: string;
  projectStepId?: string;
}

export const ProjectActivitiesSection: React.FC<
  ProjectActivitiesSectionProps
> = (props) => {
  const { t } = useTranslation();
  const { projectId, projectStepId } = props;

  // Query project activity list information
  const {
    loading: loadingProjectActivityList,
    error: errorProjectActivityList,
    data: dataProjectActivityList,
  } = useQuery(BptProjectActivityListDocument, {
    variables: {
      params: {
        projectId: projectId ?? "error",
        stepId: projectStepId ?? "error",
        pageNumber: 1,
        pageSize: 50,
      },
    },
    skip: projectId == null,
    fetchPolicy: "network-only",
    errorPolicy: "all",
  });

  const projectActivityBundles =
    dataProjectActivityList?.bptProjectActivityList?.activityBundles;

  return (
    <>
      {projectActivityBundles != null && projectActivityBundles?.length > 0 ? (
        <>
          <div className="pt-0 separator-line-top mrgn-tp-md"></div>
          <section>
            <header className="py-2_5">
              <div className="flex justify-between align-center">
                {/* ------- Title of activities ------- */}
                <h4 className="mrgn-0">{t("activities")}</h4>
              </div>
            </header>

            {/* ------- Activities (List) ------- */}
            {loadingProjectActivityList ? (
              <LoadingIndicator className="mrgn-bttm-md" />
            ) : errorProjectActivityList ? (
              <span className="label label-danger">
                {t("error_loading_bpt_project_activity_list")}
              </span>
            ) : (
              <>
                {
                  <ProjectActivityBundles
                    projectId={projectId}
                    projectActivityBundles={projectActivityBundles}
                    isLoading={loadingProjectActivityList}
                    {...props}
                  />
                }
              </>
            )}
          </section>
        </>
      ) : null}
    </>
  );
};

export default ProjectActivitiesSection;
