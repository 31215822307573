import Layout from "components/layouts/TwoColumn";
import SideNav from "components/molecules/SideNav";
import AdminLandsForm, {
  AdminLandsFormFields,
} from "components/organisms/admin/lands/AdminLandsForm";
import * as React from "react";
import { SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";
import {
  mapDomainModelToForm,
  mapFormToUpdateInput,
} from "components/organisms/admin/lands/mappers";
import { useHistory, useRouteMatch } from "react-router-dom";
import { LandDocument, UpdateLandDocument } from "generated/gql-types";
import LoadingIndicator from "../../../../components/atoms/LoadingIndicator";
import GraphqlError from "../../../../components/GraphqlError";
import AdminLandsEditForm from "../../../../components/organisms/admin/lands/AdminLandsEditForm";

export interface LandsEditPageProps {}

export const LandsEditPage: React.FC<LandsEditPageProps> = (props) => {
  const { t } = useTranslation();
  const { params } = useRouteMatch();
  const { landId } = params as any;
  const history = useHistory();

  const { loading, error, data } = useQuery(LandDocument, {
    variables: { id: landId },
  });

  const [updateLand, { loading: saving, error: savingError }] = useMutation(
    UpdateLandDocument,
    {
      refetchQueries: ["LandsForAdminLand"],
      errorPolicy: "all",
    }
  );

  const onSubmit: SubmitHandler<AdminLandsFormFields> = async (formData) => {
    const updateInput = mapFormToUpdateInput(formData);

    try {
      const res = await updateLand({
        variables: {
          id: landId,
          input: updateInput,
        },
      });
      if (res.errors) throw res.errors;
      history.push({
        pathname: "/admin/lands",
      });
    } catch (e) {
      console.error(e);
    }
  };

  const initialValues = mapDomainModelToForm(data?.land);

  return (
    <>
      <Layout.Root>
        <Layout.Content>
          <h1>{t("edit_land")}</h1>

          <GraphqlError title="Error updating land" errors={savingError} />

          {loading ? (
            <LoadingIndicator centered />
          ) : (
            <AdminLandsEditForm
              onSubmit={onSubmit}
              initialValues={initialValues}
            />
          )}
        </Layout.Content>

        <Layout.Sidebar>
          <SideNav />
        </Layout.Sidebar>
      </Layout.Root>
    </>
  );
};
