import { ApolloError, useMutation as useMutationApollo } from "@apollo/client";
import { useMutation } from "@tanstack/react-query";
import { getApiDocumentManagementServiceUrl } from "azure/environment";
import DeleteButtonModal from "components/atoms/DeleteButtonModal";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import NoResults from "components/atoms/NoResults";
import SectionCard from "components/atoms/SectionCard";
import { getUserAccessToken } from "features/auth/CustomMsalProvider";
import { ROLE_ACTIONS, RenderWhenAuthorized } from "features/auth/components";
import { useGlobalAlertContext } from "features/globalAlert";
import {
  DeleteDocumentAttachmentDocument,
  DeleteDocumentLinkDocument,
  Document,
  DocumentStage,
  Maybe,
} from "generated/gql-types";
import * as React from "react";
import { useTranslation } from "react-i18next";
import AddFilesBtn from "../AddFilesModal/AddFilesBtn";
import AddLinkBtn from "../AddLinkModal/AddLinkBtn";
import EditLinkBtn from "../EditLinkModal/EditLinkBtn";
import SafeRenderHtml from "components/atoms/SafeRenderHtml";

interface FilesCardProps {
  documentData?: Maybe<Document>;
  refetchQuery: () => void;
}

export const FilesCard: React.FC<FilesCardProps> = (props) => {
  const { documentData, refetchQuery } = props;
  const { t } = useTranslation();
  const alertContext = useGlobalAlertContext();
  const [downloadAttachmentId, setDownloadAttachmentId] = React.useState("");
  const [removeLinkId, setRemoveLinkId] = React.useState("");
  const [removeAttachmentId, setRemoveAttachmentId] = React.useState("");

  const attachments = documentData?.attachments;
  const links = documentData?.links;
  const fetchDownload = async (params: {
    documentId: string;
    attachmentId: string;
    attachmentName: string;
  }) => {
    const token = await getUserAccessToken();
    const baseUrl =
      getApiDocumentManagementServiceUrl("7080") +
      `document/${params.documentId}/attachment/${params.attachmentId}`;

    return fetch(baseUrl, {
      method: "GET",
      body: null, // Not required.
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        // 204 = No content
        if (res.status === 204 || res.status < 200 || res.status >= 300) {
          throw new Error(res.statusText);
        }
        return res;
      })
      .then((res) => res.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", params.attachmentName);
        document.body.appendChild(link);
        link.click();
        link?.parentNode?.removeChild(link);
      });
  };

  const downloadMutation = useMutation(
    ["document", "file", "download"],
    fetchDownload,
    {
      onError: () => {
        alertContext.showError({
          title: t("download_failure"),
          message: (downloadMutation.error as any)?.message ?? undefined,
          timeOut: 5000,
        });
        downloadMutation.reset();
        window.scrollTo(0, 260); // Scroll to page header to show error message.
      },
    }
  );

  const runDownload = async (
    documentId: Maybe<string> | undefined,
    attachmentId: Maybe<string> | undefined,
    attachmentName: Maybe<string> | undefined
  ) => {
    if (documentId == null || attachmentId == null || attachmentName == null)
      return;
    return downloadMutation.mutate({
      documentId,
      attachmentId,
      attachmentName,
    });
  };

  const [removeDocumentLink, { loading: loadingRemoveDocumentLink }] =
    useMutationApollo(DeleteDocumentLinkDocument, {
      refetchQueries: ["Document"],
      errorPolicy: "all",
    });

  const onRemoveLink = async (documentId: string, linkId: string) => {
    try {
      const res = await removeDocumentLink({
        variables: {
          documentId,
          linkId,
        },
      });

      if (res.errors) throw res.errors;

      alertContext.showSuccess({
        message: t("successfully_removed_link"),
        timeOut: 5000,
      });
    } catch (e) {
      console.error("Remove document link failed!");
      alertContext.showError({
        message: t("remove_fail"),
        timeOut: 5000,
      });
    }
  };

  const [
    removeDocumentAttachment,
    { loading: loadingRemoveDocumentAttachment },
  ] = useMutationApollo(DeleteDocumentAttachmentDocument, {
    refetchQueries: ["Document"],
    errorPolicy: "all",
  });

  const onRemoveAttachment = async (
    documentId: string,
    attachmentId: string
  ) => {
    try {
      const res = await removeDocumentAttachment({
        variables: {
          documentId,
          attachmentId,
        },
      });

      if (res.errors) throw res.errors;

      alertContext.showSuccess({
        message: t("successfully_removed_file"),
        timeOut: 5000,
      });
    } catch (e) {
      console.error("Remove document attachment file failed!");
      alertContext.showError({
        message: t("remove_fail"),
        timeOut: 5000,
      });
    }
  };

  return (
    <SectionCard
      header={
        <div className="flex justify-between align-start">
          <h2>{t("files")}</h2>
          <div className="flex gap-md">
            {documentData?.stage === DocumentStage.Active && (
              <RenderWhenAuthorized
                authorizedRoles={ROLE_ACTIONS.documents.update}
              >
                <AddFilesBtn
                  defaultValues={undefined}
                  documentId={documentData?.id ?? ""}
                  buttonLabel={t("add_files")}
                  refetchQuery={refetchQuery}
                  onCompleted={function (data: any): void {
                    throw new Error("Function not implemented.");
                  }}
                  onError={function (error: ApolloError | undefined): void {
                    throw new Error("Function not implemented.");
                  }}
                />
                <AddLinkBtn
                  showIcon={true}
                  defaultValues={undefined}
                  documentId={documentData?.id ?? ""}
                  buttonLabel={t("add_link")}
                  refetchQuery={refetchQuery}
                  onCompleted={function (data: any): void {
                    return;
                  }}
                  onError={function (error: ApolloError | undefined): void {
                    throw new Error("Function not implemented.");
                  }}
                />
              </RenderWhenAuthorized>
            )}
          </div>
        </div>
      }
      showLine={
        (attachments == null || attachments.length <= 0) &&
        (links == null || links.length <= 0)
      }
    >
      {(attachments == null || attachments.length <= 0) &&
      (links == null || links.length <= 0) ? (
        <>
          {documentData?.stage === DocumentStage.Active ? (
            <RenderWhenAuthorized
              authorizedRoles={ROLE_ACTIONS.documents.update}
              fallbackComponent={<NoResults centered />}
            >
              <div className="text-center mrgn-tp-md">
                <div className="lead mrgn-tp-md mrgn-bttm-sm">
                  <AddFilesBtn
                    defaultValues={undefined}
                    documentId={documentData?.id ?? ""}
                    buttonLabel={t("add_files_to_your_document")}
                    showImage
                    refetchQuery={refetchQuery}
                    onCompleted={function (data: any): void {
                      throw new Error("Function not implemented.");
                    }}
                    onError={function (error: ApolloError | undefined): void {
                      throw new Error("Function not implemented.");
                    }}
                  />
                </div>
              </div>
            </RenderWhenAuthorized>
          ) : (
            <NoResults centered />
          )}
        </>
      ) : (
        <>
          {attachments?.map((attachment, index) => (
            <div
              className="row list-item separator-line species-field px-0 py-2_5"
              key={"file - " + index}
            >
              <div className="col-sm-12 species-data">
                <dl>
                  {/* ---------- File name ---------- */}
                  <dt className="text-muted wb-inv">
                    {t("file")} - {index + 1}
                  </dt>
                  <dd className="mrgn-bttm-0">
                    <div className="flex justify-between align-start">
                      <div>
                        <div className="mrgn-bttm-3">
                          <button
                            type="button"
                            className="btn btn-link p-0 min-height-fit-content mrgn-rght-md text-left"
                            onClick={() => {
                              setDownloadAttachmentId(
                                attachment?.attachmentId ?? ""
                              );
                              runDownload(
                                documentData?.id,
                                attachment?.attachmentId,
                                attachment?.reference?.name
                              );
                            }}
                            title={t("download_file")}
                          >
                            <span className="font-size-16">
                              {attachment?.reference?.name}
                            </span>
                          </button>
                          {downloadMutation.isLoading &&
                            downloadAttachmentId ===
                              attachment?.attachmentId && <LoadingIndicator />}
                        </div>
                        <div className="font-size-14 text-muted">
                          <i className="far fa-file mrgn-rght-sm" />
                          {t("file")}&nbsp;&nbsp;|&nbsp;&nbsp;
                          {t(attachment?.language ?? "error")}
                        </div>
                      </div>
                      {documentData?.stage === DocumentStage.Active && (
                        <RenderWhenAuthorized
                          authorizedRoles={ROLE_ACTIONS.documents.update}
                        >
                          <div className="flex justify-right gap-sm flex-wrap">
                            <DeleteButtonModal
                              showButtonText={false}
                              buttonTitle={t("remove_file")}
                              modalTitle={t("remove_file")}
                              alertContent={t("remove_file_warning")}
                              alertConfirm={t("i_confirm")}
                              onDelete={() => {
                                setRemoveAttachmentId(
                                  attachment?.attachmentId ?? ""
                                );
                                onRemoveAttachment(
                                  documentData?.id ?? "error",
                                  attachment?.attachmentId ?? "error"
                                );
                              }}
                              loading={
                                loadingRemoveDocumentAttachment &&
                                removeAttachmentId === attachment?.attachmentId
                              }
                              disabled={
                                documentData?.id == null ||
                                attachment?.attachmentId == null ||
                                loadingRemoveDocumentAttachment
                              }
                              className="font-size-14 hover-grey"
                              dataTestid={`button-remove-file-${index}`}
                            />
                          </div>
                        </RenderWhenAuthorized>
                      )}
                    </div>
                  </dd>
                </dl>
              </div>
            </div>
          ))}
          {links?.map((link, index) => (
            <div
              className="row list-item separator-line species-field px-0 py-2_5"
              key={"link - " + index}
            >
              <div className="col-sm-12 species-data">
                <dl>
                  <dt className="text-muted wb-inv">
                    {t("link")} - {index + 1}
                  </dt>
                  <dd className="mrgn-bttm-0">
                    <div className="flex justify-between align-start">
                      <div>
                        <div className="mrgn-bttm-3">
                          <a
                            href={link?.reference?.uri ?? ""}
                            className="hover-underline"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <span className="font-size-16">
                              <SafeRenderHtml
                                htmlString={link?.reference?.name?.text ?? ""}
                              />
                            </span>
                          </a>
                        </div>
                        <div className="font-size-14 text-muted">
                          <i className="fas fa-link mrgn-rght-sm" />
                          {t("link")}&nbsp;&nbsp;|&nbsp;&nbsp;
                          {t(link?.language ?? "error")}
                        </div>
                      </div>
                      {documentData?.stage === DocumentStage.Active && (
                        <RenderWhenAuthorized
                          authorizedRoles={ROLE_ACTIONS.documents.update}
                        >
                          <div className="flex justify-right gap-sm flex-wrap">
                            <EditLinkBtn
                              documentId={documentData?.id}
                              linkId={link?.linkId}
                              // defaultValues={{
                              //   name: link?.reference?.name?.text,
                              //   urlToDocument: link?.reference?.uri,
                              //   language: link?.language,
                              // }}
                              defaultValues={link}
                              refetchQuery={refetchQuery}
                              onCompleted={function (data: any): void {
                                return;
                              }}
                              onError={function (
                                error: ApolloError | undefined
                              ): void {
                                throw new Error("Function not implemented.");
                              }}
                            />
                            <DeleteButtonModal
                              showButtonText={false}
                              buttonTitle={t("remove_link")}
                              modalTitle={t("remove_link")}
                              alertContent={t("remove_link_warning")}
                              alertConfirm={t("i_confirm")}
                              onDelete={() => {
                                setRemoveLinkId(link?.linkId ?? "");
                                onRemoveLink(
                                  documentData?.id ?? "error",
                                  link?.linkId ?? "error"
                                );
                              }}
                              loading={
                                loadingRemoveDocumentLink &&
                                removeLinkId === link?.linkId
                              }
                              disabled={
                                documentData?.id == null ||
                                link?.linkId == null ||
                                loadingRemoveDocumentLink
                              }
                              className="font-size-14 hover-grey"
                              dataTestid={`button-remove-link-${index}`}
                            />
                          </div>
                        </RenderWhenAuthorized>
                      )}
                    </div>
                  </dd>
                </dl>
              </div>
            </div>
          ))}
        </>
      )}
    </SectionCard>
  );
};

export default FilesCard;
