import MissingData from "components/atoms/MissingData";
import SafeRenderHtml from "components/atoms/SafeRenderHtml";
import { BptProjectPhase, Maybe } from "generated/gql-types";
import * as React from "react";
import { useTranslation } from "react-i18next";

export interface PhaseInfoProps {
  dataInfo?: Maybe<BptProjectPhase>;
}

export const PhaseInfo: React.FC<PhaseInfoProps> = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="species-field font-size-18">
        {/* ------------ Description ------------ */}
        <div className="row">
          <dl>
            <div className="species-data col-sm-12">
              <dt className="text-muted">{t("description")}</dt>
              <dd>
                {props.dataInfo?.description == null ? (
                  <MissingData />
                ) : (
                  <SafeRenderHtml
                    htmlString={props.dataInfo?.description.text ?? ""}
                  />
                )}
              </dd>
            </div>
          </dl>
        </div>

        {/* ------------ Calculated duration ------------ */}
        <div className="row">
          <dl>
            <div className="species-data col-sm-12">
              <dt className="text-muted">{t("calculated_duration")}</dt>
              <dd>
                {props.dataInfo?.calculatedDuration == null ? (
                  <MissingData />
                ) : (
                  <SafeRenderHtml
                    htmlString={
                      props.dataInfo?.calculatedDuration +
                      " " +
                      t("business_days")
                    }
                  />
                )}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </>
  );
};

export default PhaseInfo;
