import SectionCard from "components/atoms/SectionCard";
import InfoButtonAndModal from "components/organisms/InfoButtonAndModal/InfoButtonAndModal";
import { RenderWhenAuthorized } from "features/auth/components";
import { ROLE_ACTIONS } from "features/auth/roles";
import { useGlobalAlertContext } from "features/globalAlert";
import { BptProjectPhase, Maybe } from "generated/gql-types";
import useAdminAlertMsg from "hooks/admin/useAdminAlertMsg";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { PhaseInfo } from "../../Information/PhaseInfo";
import EditProjectPhaseBtn from "../EditProjectPhaseBtnModal/EditProjectPhaseBtn";
import ProjectPhaseInformation from "./ProjectPhaseInformation";

export interface ProjectPhaseInformationCardProps {
  projectId?: string;
  projectPhaseInfo?: Maybe<BptProjectPhase>;
  isProjectCompleted?: boolean;
}

export const ProjectPhaseInformationCard: React.FC<
  ProjectPhaseInformationCardProps
> = (props) => {
  const { t } = useTranslation();
  const { projectId, projectPhaseInfo, isProjectCompleted } = props;
  const alertContext = useGlobalAlertContext();
  const adminAlertMsg = useAdminAlertMsg(t("project_phase"));

  return (
    <>
      <SectionCard
        header={
          <div className="flex justify-between align-start">
            <h2>
              {t("phase_details")}
              <InfoButtonAndModal
                title={projectPhaseInfo?.name?.plainText}
                buttonTitle={t("template_information")}
                buttonIcon={<i className="fas fa-info-circle"></i>}
              >
                <PhaseInfo dataInfo={projectPhaseInfo} />
              </InfoButtonAndModal>
            </h2>

            {isProjectCompleted ? null : (
              <RenderWhenAuthorized
                authorizedRoles={ROLE_ACTIONS.bptProject.update}
              >
                <EditProjectPhaseBtn
                  refetchQueries={["BptProjectPhase"]}
                  projectId={projectId}
                  projectPhaseInfo={projectPhaseInfo}
                  onCompleted={(data: any) => {
                    alertContext.showSuccess({
                      message: data?.name?.text,
                      timeOut: 5000,
                    });
                  }}
                  onError={adminAlertMsg.onCreateError}
                />
              </RenderWhenAuthorized>
            )}
          </div>
        }
        showLine={true}
      >
        <ProjectPhaseInformation projectPhaseInfo={projectPhaseInfo} />
      </SectionCard>
    </>
  );
};

export default ProjectPhaseInformationCard;
