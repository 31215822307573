import { BptProjectActivityBundle, Maybe } from "generated/gql-types";
import * as React from "react";
import ProjectActivityDetails from "./ProjectActivityDetails";
import { TypeOfQueryInputProps } from "../../Utility/PopupMenuModal";

export interface ProjectActivityBundlesProps
  extends Omit<TypeOfQueryInputProps, "TypeOfQueryInputProps"> {
  projectId?: string;
  projectActivityBundles?: Maybe<Maybe<BptProjectActivityBundle>[]>;
  isLoading?: boolean;
}

export const ProjectActivityBundles: React.FC<ProjectActivityBundlesProps> = (
  props
) => {
  const { projectActivityBundles, projectId, isLoading } = props;

  return (
    <>
      {projectActivityBundles?.map((projectActivityBundles, n) => {
        const isBundle =
          projectActivityBundles?.activities != null &&
          projectActivityBundles?.activities?.length >= 2;

        return (
          <div
            key={n}
            className={
              isBundle ? "template-list-bundle" : "template-list-single"
            }
          >
            {projectActivityBundles?.activities?.map((activity, index) => {
              return (
                <ProjectActivityDetails
                  key={index}
                  projectId={projectId}
                  projectActivityInfo={activity}
                  isLoading={isLoading}
                  {...props}
                />
              );
            })}
          </div>
        );
      })}
    </>
  );
};
