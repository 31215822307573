import * as React from "react";
import { useTranslation } from "react-i18next";
import EditBptTemplatePopup, {
  EditBptTemplatePopupProps,
  useEditBptTemplateModal,
} from ".";

interface EditBptTemplateModalWithButtonProps
  extends Omit<EditBptTemplatePopupProps, "modalState"> {
  buttonLabel?: string;
  disabled?: boolean;
}

const EditBptTemplateModalWithButton: React.FC<
  EditBptTemplateModalWithButtonProps
> = ({ buttonLabel, disabled = false, ...props }) => {
  const { t } = useTranslation();
  const modalState = useEditBptTemplateModal({});
  const label = buttonLabel ?? t("edit");
  return (
    <>
      <button
        type="button"
        className="btn btn-link mrgn-0 py-0 px-2 card-header-button-link"
        onClick={modalState.open}
        disabled={disabled}
        data-testid="button-edit-bpt-template"
        title={t("edit_bpt_template")}
      >
        <i className="fas fa-pen mrgn-rght-sm"></i>
        {label}
      </button>
      {!disabled ? (
        <EditBptTemplatePopup modalState={modalState} {...props} />
      ) : null}
    </>
  );
};

export default EditBptTemplateModalWithButton;
