import AddFiles from "assets/svg/add.svg";
import * as React from "react";
import { useTranslation } from "react-i18next";
import AddCommunicationsPopup, {
  AddFilesPopupProps,
  useAddFilesPopup,
} from "./AddCommunicationsPopup";

interface AddCommunicationsBtnProps
  extends Omit<AddFilesPopupProps, "modalState"> {
  buttonLabel?: string;
  showImage?: boolean;
}

const AddCommunicationsBtn: React.FC<AddCommunicationsBtnProps> = ({
  buttonLabel,
  showImage,
  ...props
}) => {
  const { t } = useTranslation();
  const modalState = useAddFilesPopup({});
  const validProjectId = props.projectId != null && props.projectId.length > 0;

  return (
    <>
      <button
        type="button"
        className="btn btn-link card-header-button-link"
        onClick={modalState.open}
        disabled={!validProjectId}
        data-testid="button-add-files"
      >
        {showImage ? (
          <>
            <img src={AddFiles} alt={t("add_files")} />
            <div className={"no-results-content"}>
              {buttonLabel ? t(buttonLabel) : t("add_files")}
            </div>
          </>
        ) : (
          <>
            <i className="far fa-file mrgn-rght-sm" />
            {buttonLabel ? t(buttonLabel) : t("add_files")}
          </>
        )}
      </button>
      {validProjectId ? (
        <AddCommunicationsPopup modalState={modalState} {...props} />
      ) : null}
    </>
  );
};

export default AddCommunicationsBtn;
