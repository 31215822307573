import Alert, { AlertTypes } from "components/atoms/Alert";
import ConfirmationModal from "components/organisms/ConfirmationModal";
import * as React from "react";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import type { OverlayTriggerState } from "@react-stately/overlays";

export interface SkipPopupMenuModalProps {
  confirmSkipModal: OverlayTriggerState;
  modalTitle: string | ReactElement;
  alertContent: string;
  alertConfirm: string;
  buttonTitle?: string;
  onSkip: () => any;
  disabled?: boolean;
  alertType?: AlertTypes;
}

export const SkipPopupMenuModal: React.FC<SkipPopupMenuModalProps> = (
  props
) => {
  const { t } = useTranslation();
  const {
    confirmSkipModal,
    modalTitle,
    alertContent,
    alertConfirm,
    buttonTitle,
    onSkip,
    disabled,
    alertType,
  } = props;
  const [confirmSkipState, setConfirmSkipState] =
    React.useState<boolean>(false);

  return (
    <>
      {disabled ? null : (
        <ConfirmationModal
          modalState={confirmSkipModal}
          title={modalTitle}
          confirmBtnEnabled={confirmSkipState}
          onConfirm={() => {
            onSkip();
            setConfirmSkipState(false);
          }}
          onCancel={() => {
            confirmSkipModal.close();
            setConfirmSkipState(false);
          }}
          buttonTitle={buttonTitle}
        >
          <Alert
            type={alertType != null ? alertType : AlertTypes.WARNING}
            content={alertContent}
          />

          <div className="checkbox">
            <label htmlFor="checkboxInput">
              <input
                id="checkboxInput"
                type="checkbox"
                checked={confirmSkipState}
                onChange={() => setConfirmSkipState((x) => !x)}
                data-testid="modal-checkbox-confirm-skip"
              />
              &nbsp;
              {alertConfirm}
            </label>
          </div>
        </ConfirmationModal>
      )}
    </>
  );
};

export default SkipPopupMenuModal;
