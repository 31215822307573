import { useMutation } from "@apollo/client";
import { OverlayContainer } from "@react-aria/overlays";
import type { OverlayTriggerState } from "@react-stately/overlays";
import { useOverlayTriggerState } from "@react-stately/overlays";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import SafeRenderHtml from "components/atoms/SafeRenderHtml";
import GraphqlError from "components/GraphqlError";
import type { RoleFields } from "components/molecules/admin/RoleForm";
import RoleForm from "components/molecules/admin/RoleForm";
import ModalDialog from "components/molecules/Modal";
import { UpdateAuthorizationUserDocument } from "generated/gql-types";
import * as React from "react";
import { SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";

export interface EditRolePopupProps {
  modalState: OverlayTriggerState;
  userId: string; // SarDmts User id
  userEmail: string; // SarDmts User email
  currentUserProgram: string;
  defaultValues: RoleFields;
  refetchQueries?: string[];
  onCompleted: (data: any) => void;
}

const EditRolePopup: React.FC<EditRolePopupProps> = (props) => {
  const { t } = useTranslation();
  const { modalState, userId, userEmail, currentUserProgram, defaultValues } =
    props;

  const [editRole, { loading, error, reset }] = useMutation(
    UpdateAuthorizationUserDocument,
    {
      refetchQueries: props.refetchQueries ?? [],
      onCompleted: props.onCompleted,
      errorPolicy: "all",
    }
  );

  const onSubmit: SubmitHandler<Partial<RoleFields>> = async (formData) => {
    try {
      const res = await editRole({
        variables: {
          userId: userId,
          input: {
            roles: formData.roles,
          },
        },
      });

      if (res.errors == null) {
        modalState.close();
      }
    } catch (e) {
      console.warn("Error saving roles");
      console.error(e);
    }
  };

  const onClose = () => {
    modalState.close();
    reset();
  };

  if (!modalState.isOpen) return null;

  return (
    <OverlayContainer>
      <ModalDialog
        title={t("edit_roles")}
        isOpen
        onClose={onClose}
        showModalFooter={false}
        className="modal-dropdown-visible"
        // isDismissable
      >
        <GraphqlError title={t("update_fail")} errors={error} />
        <SafeRenderHtml htmlString={t("edit_roles_modal_description")} />

        {loading && <LoadingIndicator centered />}

        <dl>
          <dt>{t("user")}</dt>
          <dd className="well well-sm bg-lightgrey">{userEmail}</dd>
        </dl>
        <RoleForm
          currentUserProgram={currentUserProgram}
          initialValues={defaultValues}
          onSubmit={onSubmit}
          onClose={onClose}
        />
      </ModalDialog>
    </OverlayContainer>
  );
};

export default EditRolePopup;

export const useRolePopup = useOverlayTriggerState;
