import { useMutation, useQuery } from "@apollo/client";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import GraphqlError from "components/GraphqlError";
import Layout from "components/layouts/TwoColumn";
import SideNav from "components/molecules/SideNav";
import Ribbon from "components/organisms/cosewic/Ribbon";
import {
  mapDomainModelToForm,
  mapFormToDomainModel,
} from "components/organisms/publicProfile/publicProfileForm/mappers";
import PublicProfileForm, {
  SpeciesProfileFormFields,
} from "components/organisms/publicProfile/publicProfileForm/PublicProfileForm";

import { useOverviewContext } from "features/overview";
import {
  OverviewWsSpeciesProfileDocument,
  UpdateSpeciesProfileDocument,
} from "generated/gql-types";
import * as React from "react";
import { SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";
import { PublicProfilePathHelpers } from "../PublicProfileRouter";

export interface PublicProfileEditPageProps {}

export const PublicProfileEditPage: React.FC<
  PublicProfileEditPageProps
> = () => {
  const { t } = useTranslation();
  const { params } = useRouteMatch();
  const { speciesProfileId } = params as any;
  const history = useHistory();
  const overviewContext = useOverviewContext();

  const [updateSpeciesProfile, updateSpeciesProfileMutationStatus] =
    useMutation(UpdateSpeciesProfileDocument, {
      refetchQueries: ["OverviewWsSpeciesProfile"],
      errorPolicy: "all",
    });

  const { loading, error, data } = useQuery(OverviewWsSpeciesProfileDocument, {
    variables: { overviewWsSpeciesProfileId: speciesProfileId },
  });

  const overviewProgram = "cosewicws";
  const overviewId = data?.overviewWsSpeciesProfile?.references?.find(
    (x) => x?.name?.toLowerCase() === "cosewicws"
  )?.referenceId;

  // Update Ribbon and SideNav.
  React.useEffect(() => {
    overviewContext.updateOverview(overviewProgram, overviewId);
  }, [overviewProgram, overviewId]);

  const onSubmit: SubmitHandler<Partial<SpeciesProfileFormFields>> = async (
    formData
  ) => {
    const values = mapFormToDomainModel(formData);
    try {
      const res = await updateSpeciesProfile({
        variables: {
          updateSpeciesProfileId: speciesProfileId,
          input: values,
        },
      });

      if (res.errors) throw res.errors;

      history.push({
        pathname: PublicProfilePathHelpers.PublicProfile(speciesProfileId),
      });
    } catch (e) {
      console.error(e);
    }
  };

  const speciesDetails = data?.overviewWsSpeciesProfile?.speciesDetails;

  const initialFormFields = mapDomainModelToForm(speciesDetails);

  return (
    <>
      <Ribbon />
      <Layout.Root>
        <Layout.Content>
          <h1>{t("edit_public_profile")}</h1>

          <GraphqlError title="Error fetching public profile" errors={error} />
          <GraphqlError
            title="Error saving public profile"
            errors={updateSpeciesProfileMutationStatus.error}
          />
          {loading ? (
            <LoadingIndicator />
          ) : (
            <PublicProfileForm
              speciesProfile={data?.overviewWsSpeciesProfile}
              initialValues={initialFormFields}
              onSubmit={onSubmit}
            />
          )}
        </Layout.Content>
        <Layout.Sidebar>
          <SideNav />
        </Layout.Sidebar>
      </Layout.Root>
    </>
  );
};

export default PublicProfileEditPage;
