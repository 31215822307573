import * as React from "react";
import { useTranslation } from "react-i18next";
import { EditBptProjectPopupProps, useEditBptProjectModal } from ".";
import EditBptProjectPopup from ".";
import { BptProjectFormFields } from "components/molecules/bptProjects/Project/BptProjectForm";
import { Maybe, Scalars } from "generated/gql-types";

interface EditBptProjectModalWithButtonProps
  extends Omit<EditBptProjectPopupProps, "modalState"> {
  buttonLabel?: string;
  disabled?: boolean;
  refetchQueries?: string[];
  projectId: Maybe<Scalars["ID"]>;
  initialValues?: BptProjectFormFields;
}

const EditBptProjectModalWithButton: React.FC<
  EditBptProjectModalWithButtonProps
> = ({ buttonLabel, disabled = false, ...props }) => {
  const { t } = useTranslation();
  const modalState = useEditBptProjectModal({});
  const label = buttonLabel ?? t("edit");

  return (
    <>
      <button
        type="button"
        className={"btn btn-link mrgn-0 py-0 px-2 card-header-button-link"}
        onClick={modalState.open}
        disabled={disabled}
        data-testid="button-edit-bpt-project"
        title={label}
      >
        <i className="fas fa-pen mrgn-rght-sm"></i>
        {label}
      </button>
      {!disabled ? (
        <EditBptProjectPopup modalState={modalState} {...props} />
      ) : null}
    </>
  );
};

export default EditBptProjectModalWithButton;
