import { BptTemplatePhase, Maybe } from "generated/gql-types";
import * as React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import isNullOrEmpty from "util/isNullOrEmpty";

export interface PhaseSelectionFields {
  phaseId: string;
}

export interface PhaseSelectionDropdownFormProps {
  onSubmit: SubmitHandler<PhaseSelectionFields>;
  phaseList?: Maybe<BptTemplatePhase>[] | null;
  currentPhaseId: string;
}

export const PhaseSelectionDropdownForm: React.FC<
  PhaseSelectionDropdownFormProps
> = (props) => {
  const { t } = useTranslation();
  const { phaseList, currentPhaseId } = props;

  const [selectedPhaseId, SetSelectedPhaseId] = React.useState(currentPhaseId);

  const form = useForm<PhaseSelectionFields>({
    defaultValues: { phaseId: "" },
  });
  const { register, handleSubmit } = form;

  const onSubmit: SubmitHandler<PhaseSelectionFields> = (newValues) => {
    const mapped = newValues;
    props.onSubmit(mapped);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex align-center flex-wrap gap-sm">
        <div className="flex align-center flex-wrap">
          <label htmlFor="phaseSelect" className="mrgn-rght-sm">
            {t("switch_to_other_phase")}
          </label>
          <select
            id="phaseSelect"
            {...register("phaseId", {
              onChange: (e) => SetSelectedPhaseId(e.target.value),
            })}
            disabled={phaseList == null || phaseList.length <= 0}
            className="form-control"
          >
            <option value="" disabled hidden>
              {t("select_phase")}
            </option>
            {phaseList?.map((value, index) => {
              return (
                <option
                  key={index}
                  value={value?.id!}
                  // disabled={value?.id === props.currentPhaseId}
                  className={
                    value?.id === props.currentPhaseId
                      ? "font-weight-bold"
                      : undefined
                  }
                >
                  {t("phase")} {index + 1}
                  {": "}
                  {value?.name?.plainText}
                </option>
              );
            })}
          </select>
        </div>

        <button
          type="submit"
          className="btn btn-primary"
          data-testid="button-apply"
          disabled={
            isNullOrEmpty(currentPhaseId) || selectedPhaseId === currentPhaseId
          }
        >
          {t("apply")}
        </button>
      </div>
    </form>
  );
};

export default PhaseSelectionDropdownForm;
