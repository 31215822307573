import { useMutation, useQuery } from "@apollo/client";
import CreatedUpdatedInfo from "components/atoms/CreatedUpdatedInfo";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import SafeRenderHtml from "components/atoms/SafeRenderHtml";
import GraphqlError from "components/GraphqlError";
import Layout from "components/layouts/OneColumn";
import ReturnToButton from "components/molecules/admin/bptTemplates/utility/ReturnToButton";
import AttachedCommunicationsCard from "components/molecules/bptProjects/AttachedCommunicationsCard/AttachedCommunicationsCard";
import { ProjectPhasesCard } from "components/molecules/bptProjects/Phase/ProjectPhasesCard/ProjectPhasesCard";
import ProjectInformationCard from "components/molecules/bptProjects/Project/ProjectInformationCard/ProjectInformationCard";
import MarkAsCompletedBtn from "components/molecules/bptProjects/Utility/MarkAsCompletedBtn";
import StatusTag from "components/molecules/bptProjects/Utility/StatusTag";
import { RenderWhenAuthorized, ROLE_ACTIONS } from "features/auth/components";
import { GlobalAlert, useGlobalAlertContext } from "features/globalAlert";
import {
  BptProjectDocument,
  BptProjectPhaseListDocument,
  BptProjectStage,
  UpdateBptProjectStageDocument,
} from "generated/gql-types";
import { BptPathHelpers } from "pages/bpt/bptRouter";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useRouteMatch } from "react-router-dom";
import htmlRemoveOuterPTag from "util/htmlRemoveOuterPTag";

export const ProjectPage: React.FC = () => {
  const { t } = useTranslation();
  const { params } = useRouteMatch();
  const { projectId } = params as any;
  const alertContext = useGlobalAlertContext();

  const {
    loading,
    error,
    data,
    refetch: refetchBptProject,
  } = useQuery(BptProjectDocument, {
    errorPolicy: "all",
    variables: {
      projectId: projectId,
    },
    fetchPolicy: "network-only",
  });

  const {
    loading: loadingPhaseList,
    error: errorPhaseList,
    data: dataPhaseList,
  } = useQuery(BptProjectPhaseListDocument, {
    variables: {
      params: {
        projectId: projectId,
        pageNumber: 1,
        pageSize: 200,
      },
    },
    skip: projectId == null,
    fetchPolicy: "network-only",
    errorPolicy: "all",
  });

  // Mark project as completed
  const [
    updateBptProjectStage,
    { loading: UpdatingBptProjectStage, error: errorBptProjectStage },
  ] = useMutation(UpdateBptProjectStageDocument, {
    refetchQueries: ["BptProject", "BptProjectPhaseList"],
    errorPolicy: "all",
  });

  const onMarkProjectAsCompleted = async () => {
    try {
      const res = await updateBptProjectStage({
        variables: {
          projectId: projectId,
          stage: BptProjectStage.Completed,
        },
      });

      if (res.errors) throw res.errors;

      alertContext.showSuccess({
        message: t("successfully_update_bpt_project_stage"),
        timeOut: 5000,
      });
    } catch (e) {
      console.error(e);
    }
  };

  const phases = dataPhaseList?.bptProjectPhaseList?.phases;

  const disabledMarkAsCompletedBtn =
    phases == null ||
    (phases != null && phases.length == 0) ||
    phases?.find((x) => x?.stage !== BptProjectStage.Completed) != null;

  const isProjectCompleted =
    data?.bptProject?.stage === BptProjectStage.Completed;

  return (
    <>
      <Layout.Root>
        <Layout.Content>
          <GlobalAlert />
          <h1>
            {!loading && !loadingPhaseList && (
              <SafeRenderHtml
                htmlString={htmlRemoveOuterPTag(data?.bptProject?.name?.text)}
              />
            )}
          </h1>
          <GraphqlError title={t("error_loading_bpt_project")} errors={error} />
          <GraphqlError
            title={t("error_loading_bpt_project_phase_list")}
            errors={errorPhaseList}
          />
          <GraphqlError
            title={t("error_update_bpt_project_stage")}
            errors={errorBptProjectStage}
          />

          {loading && loadingPhaseList ? (
            <LoadingIndicator />
          ) : !error && !errorPhaseList ? (
            <>
              <div className="flex justify-between align-center mrgn-bttm-md gap-sm">
                {/* ----- Created Updated Info ----- */}
                <CreatedUpdatedInfo
                  createdBy={data?.bptProject?.createdBy}
                  createdDate={data?.bptProject?.createdAt}
                  updatedBy={data?.bptProject?.modifiedBy}
                  updatedDate={data?.bptProject?.modifiedAt}
                />

                <div className="flex justify-right flex-auto gap-md">
                  {isProjectCompleted ? (
                    <StatusTag
                      stage={data?.bptProject?.stage}
                      isDelay={data?.bptProject?.delayed!}
                      className={"status-tag-page"}
                    />
                  ) : (
                    <RenderWhenAuthorized
                      authorizedRoles={ROLE_ACTIONS.bptProject.CompleteProject}
                    >
                      <MarkAsCompletedBtn
                        title={t("mark_project_as_completed")}
                        className={"btn btn-primary white-space-nowrap"}
                        markTypeName={"BptProject"}
                        buttonText={t("mark_project_as_completed")}
                        disabled={disabledMarkAsCompletedBtn}
                        onMarkAction={() => onMarkProjectAsCompleted()}
                        loading={UpdatingBptProjectStage}
                      />
                    </RenderWhenAuthorized>
                  )}
                </div>
              </div>

              {/* ----- Project information card ----- */}
              <ProjectInformationCard project={data?.bptProject} />

              {/* ----- Attached communications card ----- */}
              <AttachedCommunicationsCard
                bptProject={data?.bptProject}
                refetchBptProject={refetchBptProject}
              />

              {/* ----- Project Phases card ----- */}
              <ProjectPhasesCard
                bptProject={data?.bptProject}
                projectPhases={phases}
              />

              {/* ------------- Return to Projects page (button) ------------- */}
              <ReturnToButton
                targetText={t("projects")}
                link={BptPathHelpers.Projects}
              />
            </>
          ) : null}
        </Layout.Content>
      </Layout.Root>
    </>
  );
};
