const cosewicAssessmentTranslation = {
  en: {
    cosewic_assessment: "COSEWIC assessment",
    date_examined: "Date examined",
    assessment_date: "Assessment date",
    date_of_assessment: "Date of assessment",
    order_of_assessment: "Order of assessment",
    assessment_type: "Assessment type",
    type_of_report: "Type of report",
    stage_of_assessment: "Stage of assessment",
    assessment_stage: "Assessment stage",
    assessment: "Assessment",
    assessments: "Assessments",
    year_to_be_sent_to_minister: "Year to be sent to Minister",
    add_your_first_assessment: "Add first assessment",
    stage: "Stage",
    assessment_summary: "Assessment summary",
    assessment_information: "Assessment information",
    cosewic_ws_status: "COSEWIC WS Status",
    status_change: "Status change",
    designation: "Designation",
    history_status_designation: "History of status designation",
    reason_designation: "Reason for designation",
    extirpitated_comment: "Extirpated / extinct comment",
    status_criteria: "Status criteria",
    applicability_criteria: "Applicability criteria",
    make_assessed: 'Make stage "assessed"',
    delete_an_assessment: "Delete assessment",
    delete_assessment_warning:
      "You will not be able to recover this COSEWIC wildlife species assessment",
    edit_ws_status_and_criteria: "Edit wildlife species status and criteria",
    cosewic_assessments_search: "COSEWIC assessments search",
    assessment_filters: "Assessment filters",
    wildlife_species_assessments: "Wildlife species assessments",
    ws_stage: "WS stage",
    ws_state: "WS state",
    publish_to_registry: "Publish to Registry",
    publish_assessment_to_registry_warning:
      "You will be publishing this assessment to the Registry",
    related_ws_are_still_draft_alert_title:
      "Related wildlife species are still draft",
    related_ws_are_still_draft_alert_content:
      'In order to add a new assessment, all of the related children must be made "permanent". You can access all of the related species in the side navigation under COSEWIC.',
    type_of_status_change: "Type of status change",
    extinct_or_extirpated_since: "Extinct / extirpated since",
    make_assessed_warning:
      'Are you sure you want to make this assessment stage "assessed"?',
    confirm_make_assessed:
      "I confirm that I'd like to make the assessment stage 'assessed'.",
    new_assessment_could_not_be_added_alert_title:
      "New assessment could not be added",
    no_more_than_one_draft_assessment_alert_content:
      'Cannot have more than one "draft" assessment per COSEWIC wildlife species.',
    only_one_assessment_cosewic_draft_alert_content:
      'Only one assessment is allowed when COSEWIC wildlife species is in "draft".',
    required_to_make_as_assessed_tooltip:
      'Required to make the assessment stage "assessed".',
    publish_changes: "Publish changes",
    publish_changes_warning_title:
      "Wildlife species information will be published to the Registry.",
    publish_changes_warning_message:
      "All of the changes will be visible on the Registry.",
    publish_changes_success_message:
      "Wildlife species information successfully published to Registry.",
    publish_changes_view_on_registry: "View on Registry",
    endemic: "Endemic",
    wsInfoChangesUnpublished: "WS info changes unpublished",
  },

  fr: {
    cosewic_assessment: "Évaluation du COSEPAC",
    date_examined: "Date examinée",
    assessment_date: "Date de l'évaluation",
    date_of_assessment: "Date de l'évaluation",
    order_of_assessment: "Ordre de l'évaluation",
    assessment_type: "Type d'évaluation",
    type_of_report: "Type de rapport",
    stage_of_assessment: "Stade de l'évaluation",
    assessment_stage: "Stade de l'évaluation",
    assessment: "Évaluation",
    assessments: "Évaluations",
    year_to_be_sent_to_minister: "Année d'envoi au Ministre",
    add_your_first_assessment: "Ajouter une première évaluation",
    stage: "Stade",
    assessment_summary: "Résumé de l'évaluation",
    assessment_information: "Information sur l'évaluation",
    cosewic_ws_status: "Statut de l'ES du COSEPAC",
    status_change: "Modification du statut",
    designation: "Désignation",
    history_status_designation: "Historique du statut de désignation",
    reason_designation: "Justification de la désignation",
    extirpitated_comment:
      "Commentaire sur la catégorie disparue du pays/disparue",
    status_criteria: "Critères de désignation du statut",
    applicability_criteria: "Applicabilité des critères",
    make_assessed: "Mettre le stade a 'évaluer'",
    delete_an_assessment: "Supprimez l'évaluation",
    delete_assessment_warning:
      "Vous ne pouvez pas récupérer l'évaluation du COSEPAC de cette espèce sauvage",
    edit_ws_status_and_criteria:
      "Modifier le statut et critères d'évaluation de l'espèce sauvage",
    cosewic_assessments_search: "Recherche d'évaluations du COSEPAC",
    assessment_filters: "Filtres d'évaluation",
    wildlife_species_assessments: "Évaluations de l'espèce sauvage",
    ws_stage: "Stade de l'ES",
    ws_state: "État de l'ES",
    publish_to_registry: "Publiez au Registre",
    publish_assessment_to_registry_warning:
      "Vous allez publier cette évaluation au Registre",
    related_ws_are_still_draft_alert_title:
      "Les espèces sauvages apparentées sont encore en ébauche",
    related_ws_are_still_draft_alert_content:
      "Pour ajouter une nouvelle évaluation, il faut mettre l'évaluation de tous les enfants au stade 'permanent'. Vous pouvez accéder à toutes les espèces apparentées dans la barre de navigation latérale, sous COSEPAC.",
    type_of_status_change: "Type de changement de statut",
    extinct_or_extirpated_since: "Disparue/disparue du pays depuis",
    make_assessed_warning:
      "Êtes-vous certain de vouloir mettre cette évaluation au stade 'évalué'?",
    confirm_make_assessed:
      "Je confirme que je souhaite mettre cette évaluation au stade 'évalué'.",
    new_assessment_could_not_be_added_alert_title:
      "La nouvelle évaluation n’a pas pu être ajoutée",
    no_more_than_one_draft_assessment_alert_content:
      "Impossible d'avoir plus d'une évaluation au stade d'ébauche par espèce sauvage du COSEPAC.",
    only_one_assessment_cosewic_draft_alert_content:
      "Il ne peut y avoir qu'une évaluation au stade 'ébauche' par espèce sauvage du COSEPAC.",
    required_to_make_as_assessed_tooltip:
      "Nécessaire pour rendre cette évaluation au stade 'évaluée'.",
    publish_changes: "Publier les changements",
    publish_changes_warning_title:
      "L'informations sur l'espèce sauvage sera publiées sur le Registre.",
    publish_changes_warning_message:
      "Toutes les modifications seront visibles sur le Registre.",
    publish_changes_success_message:
      "L'information sur l'espèce sauvage a été publiée avec succès sur le Registre.",
    publish_changes_view_on_registry: "Afficher sur le Registre.",
    endemic: "Endémique",
    wsInfoChangesUnpublished: "Changements aux ES non-publiés",
  },
};

export default cosewicAssessmentTranslation;
