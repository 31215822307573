import { BptProjectPhase, Maybe } from "generated/gql-types";
import * as React from "react";
import { useTranslation } from "react-i18next";

export interface ProjectPhasesCardHeaderProps {
  projectPhases?: Maybe<Maybe<BptProjectPhase>[]>;
  onExpandAll: () => void;
  onCollapseAll: () => void;
}

export const ProjectPhasesCardHeader: React.FC<ProjectPhasesCardHeaderProps> = (
  props
) => {
  const { t } = useTranslation();
  const { projectPhases, onExpandAll, onCollapseAll } = props;

  return (
    <div className="flex justify-between align-start">
      <h2>{t("Phases")}</h2>

      <div className="flex gap-md">
        <div className="btn-group">
          <button
            type="button"
            className="btn btn-default card-header-button-link"
            onClick={onExpandAll}
            data-testid="button-expand-all"
            name="expandAll"
            disabled={projectPhases == null || projectPhases?.length === 0}
          >
            {t("expand_all")}
          </button>

          <button
            type="button"
            className="btn btn-default card-header-button-link"
            onClick={onCollapseAll}
            data-testid="button-collapse-all"
            name="collapseAll"
            disabled={projectPhases == null || projectPhases?.length === 0}
          >
            {t("collapse_all")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProjectPhasesCardHeader;
