import { useCallback, useEffect, useState } from "react";

const useDetailsState = <T>(initialDetails: T[] | null = []) => {
  const [openStates, setOpenStates] = useState<{ [x: string]: any }>({});

  useEffect(() => {
    if (initialDetails && initialDetails.length > 0) {
      setOpenStates(
        initialDetails?.reduce((acc: any, detail: any) => {
          acc[detail.id] = false;
          return acc;
        }, {})
      );
    }
  }, [initialDetails]);

  const getOpenState = useCallback(
    (id) => {
      return openStates[id];
    },
    [openStates]
  );

  const toggleOpenState = useCallback((id) => {
    setOpenStates((prevState: any) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  }, []);

  const expandAll = useCallback(() => {
    const allOpen = Object.keys(openStates).reduce((acc: any, key) => {
      acc[key] = true;
      return acc;
    }, {});
    setOpenStates(allOpen);
  }, [openStates]);

  const collapseAll = useCallback(() => {
    const allClosed = Object.keys(openStates).reduce((acc: any, key) => {
      acc[key] = false;
      return acc;
    }, {});
    setOpenStates(allClosed);
  }, [openStates]);

  const addDetail = useCallback((id) => {
    setOpenStates((prevState: any) => ({
      ...prevState,
      [id]: false,
    }));
  }, []);

  const removeDetail = useCallback((id) => {
    setOpenStates((prevState: { [x: string]: any }) => {
      const { [id]: _, ...newState } = prevState;
      return newState;
    });
  }, []);

  return {
    openStates,
    getOpenState,
    toggleOpenState,
    expandAll,
    collapseAll,
    addDetail,
    removeDetail,
  };
};

export default useDetailsState;
