import Alert, { AlertTypes } from "components/atoms/Alert";
import ConfirmationModal, {
  useConfirmationModal,
} from "components/organisms/ConfirmationModal";
import useCheckbox from "hooks/util/useCheckbox";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export const MakeActivateDeactivateButtonAndModal: React.FC<{
  canMakeActivatedDeactivated: boolean;
  onMakeActivatedDeActivated: () => Promise<void>;
  title: string;
  buttonText: string;
  buttonIcon?: React.ReactElement;
  warningMessage: string;
  ConfirmationMessage: string;
  className?: string;
}> = (props) => {
  const { t } = useTranslation();
  const confirmMakeActivatedModal = useConfirmationModal({});
  const checkboxState = useCheckbox(false);

  const [showButtonSpinner, setShowButtonSpinner] = useState(false);
  const onMakeActivatedWithSpinner = () => {
    setShowButtonSpinner(true);

    props.onMakeActivatedDeActivated().finally(() => {
      setShowButtonSpinner(false);
    });
  };

  return (
    <>
      <button
        type="button"
        className={props.className ?? "btn btn-default btn-sm"}
        disabled={!props.canMakeActivatedDeactivated}
        onClick={confirmMakeActivatedModal.open}
        title={props.title}
        data-testid="button-activate-or-deactivate"
      >
        {showButtonSpinner ? (
          <>
            <i className="fa fa-spinner fa-spin mrgn-rght-sm" />
            <span className="wb-inv">&nbsp;{t("loading")}...</span>
          </>
        ) : (
          props.buttonIcon
        )}
        {t(props.buttonText)}
      </button>

      <ConfirmationModal
        modalState={confirmMakeActivatedModal}
        title={props.title}
        confirmBtnEnabled={checkboxState.checked}
        onConfirm={onMakeActivatedWithSpinner}
        onCancel={() => {
          confirmMakeActivatedModal.close();
          checkboxState.reset();
        }}
      >
        <Alert type={AlertTypes.INFO} content={props.warningMessage} />

        <div className="checkbox">
          <label>
            <input
              type="checkbox"
              checked={checkboxState.checked}
              onChange={checkboxState.toggle}
              data-testid="modal-checkbox-confirm-activate-or-deactivate"
            />
            &nbsp;
            {props.ConfirmationMessage}
          </label>
        </div>
      </ConfirmationModal>
    </>
  );
};

export default MakeActivateDeactivateButtonAndModal;
