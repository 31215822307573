import MissingData from "components/atoms/MissingData";
import SafeRenderHtml from "components/atoms/SafeRenderHtml";
import { BptProjectPhase, Maybe } from "generated/gql-types";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { getProjectTimeline } from "../../Utility/GetProjectTimeline";
import { TooltipAlert } from "components/atoms/Tooltip/TooltipAlert";

export interface ProjectPhaseInformationProps {
  projectPhaseInfo?: Maybe<BptProjectPhase>;
}

export const ProjectPhaseInformation: React.FC<ProjectPhaseInformationProps> = (
  props
) => {
  const { t } = useTranslation();
  const { projectPhaseInfo } = props;

  const estimatedTimeline = getProjectTimeline(
    projectPhaseInfo?.estimatedTimeline
  );
  const projectedTimeline = getProjectTimeline(
    projectPhaseInfo?.projectedTimeline
  );
  const actualTimeline = getProjectTimeline(projectPhaseInfo?.actualTimeline);

  return (
    <>
      <div className="species-field font-size-18">
        <div className="row">
          <dl>
            {/* ------------ Estimated phase timeline ------------ */}
            <div className="species-data col-sm-4">
              <dt className="text-muted">
                {t("estimated_phase_timeline")}
                <TooltipAlert isVisible={estimatedTimeline == null}>
                  {t("the_timeline_calculation_cannot_be_done_tooltip")}
                </TooltipAlert>
              </dt>
              <dd>{estimatedTimeline ?? <MissingData />}</dd>
            </div>

            {/* ------------ Projected phase timeline ------------ */}
            <div className="species-data col-sm-4">
              <dt className="text-muted">
                {t("projected_phase_timeline")}
                <TooltipAlert isVisible={projectedTimeline == null}>
                  {t("the_timeline_calculation_cannot_be_done_tooltip")}
                </TooltipAlert>
              </dt>
              <dd>{projectedTimeline ?? <MissingData />}</dd>
            </div>

            {/* ------------ Actual phase timeline ------------ */}
            <div className="species-data col-sm-4">
              <dt className="text-muted">{t("actual_phase_timeline")}</dt>
              <dd>{actualTimeline ?? <MissingData />}</dd>
            </div>
          </dl>
        </div>

        {/* ------------ Comments ------------ */}
        <div className="row">
          <dl>
            <div className="species-data col-sm-12">
              <dt className="text-muted">{t("comments")}</dt>
              <dd>
                {projectPhaseInfo?.comments == null ? (
                  <MissingData />
                ) : (
                  <SafeRenderHtml
                    htmlString={projectPhaseInfo?.comments.text ?? ""}
                  />
                )}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </>
  );
};

export default ProjectPhaseInformation;
