import Alert, { AlertTypes } from "components/atoms/Alert";
import ConfirmationModal, {
  useConfirmationModal,
} from "components/organisms/ConfirmationModal";
import React from "react";
import { useTranslation } from "react-i18next";

const MarkAsCompletedBtn: React.FC<{
  title: string;
  className: string;
  markTypeName: string;
  buttonText: string;
  disabled?: boolean;
  loading?: boolean;
  onMarkAction: () => void;
}> = (props) => {
  const { t } = useTranslation();
  const confirmMarkModal = useConfirmationModal({});
  const [confirmMarkState, setConfirmMarkState] =
    React.useState<boolean>(false);

  return (
    <>
      <button
        type="button"
        className={props.className}
        onClick={confirmMarkModal.open}
        title={t(props.title)}
        data-testid="button-mark-as-completed"
        disabled={props.disabled}
      >
        {props.loading ? (
          <i className="fa fa-spinner fa-spin mrgn-rght-sm" />
        ) : (
          <i className="far fa-check-circle mrgn-rght-sm"></i>
        )}
        {t(props.buttonText)}
      </button>

      <ConfirmationModal
        modalState={confirmMarkModal}
        title={t(props.title)}
        confirmBtnEnabled={confirmMarkState}
        onConfirm={props.onMarkAction}
        onCancel={() => {
          confirmMarkModal.close();
          setConfirmMarkState(false);
        }}
      >
        <Alert
          type={AlertTypes.WARNING}
          content={
            props.markTypeName === "BptProject"
              ? t("html_mark_project_as_completed_warning")
              : t("html_mark_phase_as_completed_warning")
          }
        />

        <div className="checkbox">
          <label>
            <input
              type="checkbox"
              checked={confirmMarkState}
              onChange={(e) => setConfirmMarkState((x) => !x)}
              data-testid="modal-checkbox-confirm-mark-as-completed"
            />
            &nbsp;
            {t("i_confirm")}
          </label>
        </div>
      </ConfirmationModal>
    </>
  );
};

export default MarkAsCompletedBtn;
