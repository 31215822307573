import { useApolloClient } from "@apollo/client";
import { useAsyncList } from "@react-stately/data";
import GraphqlError from "components/GraphqlError";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import NoResults from "components/atoms/NoResults";
import SectionCard from "components/atoms/SectionCard";
import Layout from "components/layouts/TwoColumn";
import PageSizeSelect from "components/molecules/PageSizeSelect";
import Pagination from "components/molecules/Pagination";
import ResponseStatementSearchForm, {
  QueryFields,
  defaultQueryFields,
} from "components/molecules/responseStatement/ResponseStatementSearchForm";
import ResponseStatementSideNav from "components/molecules/responseStatement/ResponseStatementSideNav";
import ResultsTable from "components/organisms/search/ResultsTable/ResultsTable";
import { GlobalAlert } from "features/globalAlert";
import { ResponseStatementListDocument } from "generated/gql-types";
import { usePagination } from "hooks/util/usePagination";
import * as React from "react";
import { flushSync } from "react-dom";
import { useTranslation } from "react-i18next";
import { ResponseStatementPathHelpers } from "./responseStatementRouter";

interface ResponseStatementColumns {
  title: String;
  stage: string;
  state: string;
}

const ResponseStatementBrowsePage: React.FC = () => {
  const { t, i18n } = useTranslation();
  const client = useApolloClient();
  const pagination = usePagination();

  const [filters, setFilters] = React.useState<QueryFields>(defaultQueryFields);
  const [totalCount, setTotalCount] = React.useState<number>(0);
  const [totalPages, setTotalPages] = React.useState<number>(0);

  const getSortByOrder = (column: string, direction: string) => {
    const sortBy = () => {
      switch (column) {
        case "title":
          if (i18n.language === "fr") return "title.french";
          return "title.english";
        default:
          return column;
      }
    };
    const sortOrder = () => {
      return direction === "ascending" ? "asc" : "desc";
    };
    return `${sortBy()}:${sortOrder()}`;
  };

  const rows = useAsyncList<any>({
    initialSortDescriptor: {
      column: "title",
      direction: "ascending",
    },
    async load({ cursor, filterText, sortDescriptor }: any) {
      const results = await client.query({
        query: ResponseStatementListDocument,
        variables: {
          params: {
            ministerReceiptDate: filters.ministerReceiptDate,
            sortBy: getSortByOrder(
              sortDescriptor.column,
              sortDescriptor.direction
            ),
            pageNumber: pagination.currentPage,
            pageSize: pagination.pageSize,
          },
        },
        fetchPolicy: "network-only",
        errorPolicy: "all",
      });

      const items =
        results.data.responseStatementList?.responseStatements.map((x) => ({
          id: x?.id,
          title:
            i18n.language === "fr"
              ? x?.title?.french?.text
              : x?.title?.english?.text,
          stage: t(x?.stage as string),
          state: t(x?.state as string),
          _href: ResponseStatementPathHelpers.ResponseStatementProfile(
            x?.id ?? "error"
          ),
        })) ?? [];

      setTotalCount(
        results.data.responseStatementList?.pagination?.totalCount ?? 0
      );
      setTotalPages(
        results.data.responseStatementList?.pagination?.totalPages ?? 0
      );

      return {
        items,
        sortDescriptor,
      };
    },
  });

  React.useEffect(() => {
    rows.reload(); // refetchQueries
  }, [filters, pagination.currentPage, pagination.pageSize]);

  const columns: Array<{
    name: string;
    key: keyof ResponseStatementColumns;
    sortable: boolean;
    isHTML?: boolean;
  }> = React.useMemo(
    () => [
      {
        name: t("common_name_with_population"),
        key: "title",
        sortable: true,
        isHTML: true,
        hasLink: true,
      },
      { name: t("stage"), key: "stage", sortable: true },
      { name: t("state"), key: "state", sortable: true },
    ],
    [i18n.language]
  );

  function onFilterSubmit(queryFields: QueryFields) {
    flushSync(() => {
      setFilters(queryFields);
      pagination.goToPage(1);
    });
  }

  return (
    <>
      <Layout.Root>
        <Layout.Content>
          <GlobalAlert />
          <h1>{t("response_statements")}</h1>
          <GraphqlError
            title="Error loading Response Statements"
            errors={rows.error}
          />
          <SectionCard header={<h2>{t("enter_a_minister_receipt_date")}</h2>}>
            <ResponseStatementSearchForm onSubmit={onFilterSubmit} />
          </SectionCard>
          <SectionCard header={<h2>{t("active_wildlife_species")}</h2>}>
            {rows.isLoading ? (
              <LoadingIndicator centered className="mrgn-bttm-md" />
            ) : !rows.items || rows?.items.length === 0 ? (
              <NoResults centered />
            ) : (
              <>
                <div className="flex mrgn-bttm-md align-center justify-between flex-wrap">
                  <div className="font-size-18">
                    {pagination.makeShowingString(totalCount)}
                  </div>
                  <PageSizeSelect
                    pageSize={pagination.pageSize}
                    onChangePageSize={pagination.setPageSize}
                  />
                </div>
                <ResultsTable
                  rows={rows.items}
                  columns={columns}
                  sortable
                  sortDescriptor={rows.sortDescriptor}
                  onSortChange={rows.sort}
                />
                <Pagination
                  {...pagination.paginationComponentProps}
                  totalPages={totalPages ?? 0}
                />
              </>
            )}
          </SectionCard>
        </Layout.Content>
        <Layout.Sidebar>
          <ResponseStatementSideNav />
        </Layout.Sidebar>
      </Layout.Root>
    </>
  );
};

export default ResponseStatementBrowsePage;
