import { useMutation as useMutationApollo } from "@apollo/client";
import DeleteButtonModal from "components/atoms/DeleteButtonModal";
import SafeRenderHtml from "components/atoms/SafeRenderHtml";
import SectionCard from "components/atoms/SectionCard";
import { RenderWhenAuthorized, ROLE_ACTIONS } from "features/auth/components";
import { useGlobalAlertContext } from "features/globalAlert";
import {
  DeleteBptProjectStepLinkDocument,
  Maybe,
  ResourceLink,
} from "generated/gql-types";
import useAdminAlertMsg from "hooks/admin/useAdminAlertMsg";
import * as React from "react";
import { useTranslation } from "react-i18next";
import AddStepLinkBtn from "./AddStepLinkBtnModal/AddStepLinkBtn";
import EditStepLinkBtn from "./EditStepLinkBtnModal/EditStepLinkBtn";

export interface StepLinksCardProps {
  projectId?: string;
  stepId?: string;
  links?: Maybe<ResourceLink>[];
  isPhaseCompleted?: boolean;
}

export const StepLinksCard: React.FC<StepLinksCardProps> = (props) => {
  const { t } = useTranslation();
  const alertContext = useGlobalAlertContext();
  const adminAlertMsg = useAdminAlertMsg(t("project_step_link"));
  const { projectId, stepId, links, isPhaseCompleted } = props;
  const [removeLinkId, setRemoveLinkId] = React.useState("");

  const [
    deleteBptProjectStepLink,
    { loading: loadingDeleteBptProjectStepLink },
  ] = useMutationApollo(DeleteBptProjectStepLinkDocument, {
    refetchQueries: ["BptProjectStepList"],
  });

  const onRemoveLink = async (
    projectId: string,
    stepId: string,
    linkId: string
  ) => {
    try {
      const res = await deleteBptProjectStepLink({
        variables: {
          projectId,
          stepId,
          linkId,
        },
      });

      if (res.errors) throw res.errors;

      alertContext.showSuccess({
        message: t("successfully_deleted_project_step_link"),
        timeOut: 5000,
      });
    } catch (e) {
      console.error("Delete BPT project step link failed!");
      alertContext.showError({
        message: t("remove_fail"),
        timeOut: 5000,
      });
    }
  };

  return (
    <>
      <SectionCard
        header={
          <div className="flex justify-between align-center">
            <h4 className="mrgn-tp-0 mrgn-bttm-sm font-size-16 font-weight-bold">
              {/* {isPhaseCompleted
                  ? t("links_for_version_tracking")
                  : t("upload_link_for_tracking")} */}
              {t("links_for_version_tracking")}
            </h4>
            {isPhaseCompleted ? null : (
              <RenderWhenAuthorized
                authorizedRoles={ROLE_ACTIONS.bptProject.create}
              >
                <div className="flex gap-md">
                  <AddStepLinkBtn
                    projectId={projectId}
                    stepId={stepId}
                    defaultValues={undefined}
                    buttonLabel={t("add_link")}
                    onCompleted={(data: any) => {
                      data?.name &&
                        adminAlertMsg.onCreateSuccess(data?.name?.text);
                    }}
                    onError={adminAlertMsg.onCreateError}
                  />
                </div>
              </RenderWhenAuthorized>
            )}
          </div>
        }
        contentAreaProps={{ className: "mrgn-tp-sm" }}
        className="pt-2_5 pb-2"
        showLine={false}
      >
        <>
          {links?.map((link, index) => (
            <div
              className="row list-item separator-line species-field px-0 py-2_5"
              key={"link - " + index}
            >
              <div className="col-sm-12 species-data">
                <dl>
                  <dt className="text-muted wb-inv">
                    {t("link")} - {index + 1}
                  </dt>
                  <dd className="mrgn-bttm-0">
                    <div className="flex justify-between align-start">
                      <div>
                        <div className="mrgn-bttm-3">
                          <a
                            href={link?.reference?.uri ?? ""}
                            className="hover-underline"
                            target="_blank"
                            rel="noreferrer"
                            data-testid={"step-link" + index}
                          >
                            <span className="font-size-16">
                              <SafeRenderHtml
                                htmlString={link?.reference?.name?.text ?? ""}
                              />
                            </span>
                          </a>
                        </div>
                        <div className="font-size-14 text-muted">
                          <i className="fas fa-link mrgn-rght-sm" />
                          {t("link")}&nbsp;&nbsp;|&nbsp;&nbsp;
                          {t(link?.language ?? "error")}
                        </div>
                      </div>

                      {isPhaseCompleted ? null : (
                        <RenderWhenAuthorized
                          authorizedRoles={ROLE_ACTIONS.bptProject.update}
                        >
                          <div className="flex justify-right gap-sm flex-wrap">
                            <EditStepLinkBtn
                              projectId={projectId}
                              stepId={stepId}
                              linkId={link?.linkId}
                              defaultValues={link}
                              onCompleted={(data: any) => {
                                data?.name &&
                                  adminAlertMsg.onCreateSuccess(
                                    data?.name?.text
                                  );
                              }}
                              onError={adminAlertMsg.onCreateError}
                            />
                            <DeleteButtonModal
                              showButtonText={false}
                              buttonTitle={t("remove_link")}
                              modalTitle={t("remove_link")}
                              alertContent={t("remove_link_warning")}
                              alertConfirm={t("i_confirm")}
                              onDelete={() => {
                                setRemoveLinkId(link?.linkId ?? "");
                                onRemoveLink(
                                  projectId ?? "error",
                                  stepId ?? "error",
                                  link?.linkId ?? "error"
                                );
                              }}
                              loading={
                                loadingDeleteBptProjectStepLink &&
                                removeLinkId === link?.linkId
                              }
                              disabled={
                                projectId == null ||
                                stepId == null ||
                                link?.linkId == null ||
                                loadingDeleteBptProjectStepLink
                              }
                              className="font-size-14 hover-grey"
                              dataTestid={`button-remove-link-${index}`}
                            />
                          </div>
                        </RenderWhenAuthorized>
                      )}
                    </div>
                  </dd>
                </dl>
              </div>
            </div>
          ))}
        </>
      </SectionCard>
    </>
  );
};

export default StepLinksCard;
