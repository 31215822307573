const searchfieldSelectorTranslation = {
  en: {
    // COSEWIC
    assessment_state: "Assessment state",
    assessment_status_criteria_status_change:
      "Status and criteria - status change",
    assessment_status_criteria_status_criteria:
      "Status and criteria - status criteria",
    assessment_status_criteria_status: "Status and criteria - status",
    assessment_status_criteria_status_comment:
      "Status and criteria - status comment",
    assessment_status_criteria_applicability_criteria_key:
      "Status and criteria - applicability of criteria key",
    assessment_status_criteria_applicability_criteria_value:
      "Status and criteria - applicability of criteria value",

    // Listing
    conservation_activities: "Conservation activities",
    sara_status_history: "SARA status history",
    naics_code: "NAICS code",
    naics_classname: "NAICS class name",
    threats_classname: "Threats class name",
    document_location: "Document location",
    document_type: "Document type",
    publication_date: "Publication date",
    ministers_opinion_date: "Ministers opinion date",
  },

  fr: {
    // COSEWIC
    assessment_state: "État de l'évaluation",
    assessment_status_criteria_status_change:
      "Statut et critères - changement de statut",
    assessment_status_criteria_status_criteria:
      "Statut et critères - critères de statut",
    assessment_status_criteria_status: "Statut et critères - statut",
    assessment_status_criteria_status_comment:
      "Statut et critères - commentaires sur le statut",
    assessment_status_criteria_applicability_criteria_key:
      "Statut et critères - clé d'applicabilité des critères",
    assessment_status_criteria_applicability_criteria_value:
      "Statut et critères - valeur d’applicabilité des critères",

    // Listing
    conservation_activities: "Activités de conservation",
    sara_status_history: "Historique du statut de la LEP",
    naics_code: "Code SCIAN",
    naics_classname: "Nom de la classe du SCIAN",
    threats_classname: "Nom de la classe des menaces",
    document_location: "Emplacement du document",
    document_type: "Type de document",
    publication_date: "Date de publication",
    ministers_opinion_date: "Date de l'opinion du ministre",
  },
};

export default searchfieldSelectorTranslation;
