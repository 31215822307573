import * as React from "react";
import { useTranslation } from "react-i18next";
import EditActivityPopup, {
  EditActivityPopupProps,
  useActivityPopup,
} from "./EditActivityModal";

interface EditActivityModalWithButtonProps
  extends Omit<EditActivityPopupProps, "modalState"> {
  buttonLabel?: string;
}

const EditActivityBtn: React.FC<EditActivityModalWithButtonProps> = ({
  buttonLabel,
  ...props
}) => {
  const { t } = useTranslation();
  const modalState = useActivityPopup({});
  const label = buttonLabel ?? t("edit");
  const validActivityId =
    props.templateId != null && props.activityInfo != null;

  return (
    <>
      <button
        type="button"
        className="btn btn-link font-size-14 hover-grey card-header-button-link"
        onClick={modalState.open}
        title={t("edit_activity")}
        disabled={!validActivityId}
        data-testid="button-edit"
      >
        <i className="fas fa-pen mrgn-rght-sm"></i>
        {label}
      </button>
      {validActivityId ? (
        <EditActivityPopup modalState={modalState} {...props} />
      ) : null}
    </>
  );
};

export default EditActivityBtn;
