import { useDialog } from "@react-aria/dialog";
import { FocusScope } from "@react-aria/focus";
import {
  OverlayProps,
  useModal,
  useOverlay,
  usePreventScroll,
} from "@react-aria/overlays";
import { AriaLabelingProps, DOMProps } from "@react-types/shared";
import cc from "classcat";
import type { ReactElement } from "react";
import * as React from "react";
import { useTranslation } from "react-i18next";

// function ModalDialog(props: any) {
//   let { title, children } = props;
//
//   // Handle interacting outside the dialog and pressing
//   // the Escape key to close the modal.
//   let ref = React.useRef(null);
//   let { overlayProps, underlayProps } = useOverlay(props, ref);
//
//   // Prevent scrolling while the modal is open, and hide content
//   // outside the modal from screen readers.
//   usePreventScroll();
//   let { modalProps } = useModal();
//
//   // Get props for the dialog and its title
//   let { dialogProps, titleProps } = useDialog(props, ref);
//
//   return (
//     <div
//       style={{
//         position: "fixed",
//         zIndex: 100,
//         top: 0,
//         left: 0,
//         bottom: 0,
//         right: 0,
//         background: "rgba(0, 0, 0, 0.5)",
//         display: "flex",
//         alignItems: "center",
//         justifyContent: "center",
//       }}
//       {...underlayProps}
//     >
//       <FocusScope contain restoreFocus autoFocus>
//         <div
//           {...overlayProps}
//           {...dialogProps}
//           {...modalProps}
//           ref={ref}
//           style={{
//             background: "white",
//             color: "black",
//             padding: 30,
//           }}
//         >
//           <h3 {...titleProps} style={{ marginTop: 0 }}>
//             {title}
//           </h3>
//           {children}
//         </div>
//       </FocusScope>
//     </div>
//   );
// }

/* from react-aria @react-types/dialog */
export interface AriaDialogProps extends DOMProps, AriaLabelingProps {
  /**
   * The accessibility role for the dialog.
   * @default 'dialog'
   */
  role?: "dialog" | "alertdialog";
}

type BaseProps = OverlayProps & AriaDialogProps;
export interface ModalDialogProps extends BaseProps {
  title: string | ReactElement;
  extraButtons?: ReactElement;
  className?: string;
  id?: string;
  showModalFooter?: boolean;
  buttonText?: string;
}

const ModalDialog: React.FC<ModalDialogProps> = (props) => {
  let {
    title,
    children,
    className,
    showModalFooter = true,
    buttonText = "cancel",
  } = props;
  const { t } = useTranslation();

  // Handle interacting outside the dialog and pressing
  // the Escape key to close the modal.
  let ref = React.useRef(null);
  let { overlayProps, underlayProps } = useOverlay(props, ref);

  // Prevent scrolling while the modal is open, and hide content
  // outside the modal from screen readers.
  usePreventScroll();
  let { modalProps } = useModal();

  // Get props for the dialog and its title
  let { dialogProps /*titleProps*/ } = useDialog(props, ref);

  const id = props.id;

  return (
    <>
      <div className="mfp-bg mfp-ready" {...underlayProps}></div>
      <div
        className="mfp-wrap mfp-auto-cursor mfp-ready"
        tabIndex={-1}
        style={{ overflow: "hidden auto" }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="mfp-container mfp-s-ready mfp-inline-holder">
          <div
            className="mfp-content"
            aria-labelledby="lbx-title"
            role="document"
          >
            <FocusScope contain restoreFocus autoFocus>
              <section
                id={id}
                className={cc([
                  "modal-dialog modal-content overlay-def",
                  className,
                ])}
                {...overlayProps}
                {...dialogProps}
                {...modalProps}
                ref={ref}
              >
                <header className="modal-header">
                  <h2 className="modal-title" id="lbx-title">
                    {title}
                  </h2>
                </header>
                <div className="modal-body">{children}</div>
                {showModalFooter && (
                  <div className="modal-footer">
                    {/* Note: According to the WET Style Guide,
                    Order by frequency of use from left to right, and most
                    frequent to least frequent (left-most button = primary action
                    button) */}
                    {props.extraButtons ? props.extraButtons : null}
                    <button
                      type="button"
                      className="btn btn-sm btn-default pull-left popup-modal-dismiss mrgn-rght-sm"
                      title={t(buttonText)}
                      // aria-controls={id}
                      onClick={props.onClose}
                      data-testid="modal-button-cancel"
                    >
                      {t(buttonText)}
                      <span className="wb-inv">{t(buttonText)}</span>
                    </button>
                  </div>
                )}
                <button
                  title={t(buttonText)}
                  type="button"
                  className="mfp-close"
                  onClick={props.onClose}
                >
                  ×<span className="wb-inv">{t(buttonText)}</span>
                </button>
              </section>
            </FocusScope>
          </div>
          <div className="mfp-preloader">loading...</div>
        </div>
        <span tabIndex={0} className="lbx-end wb-inv"></span>
      </div>
    </>
  );
};

export default ModalDialog;
