const adminRangeTranslation = {
  en: {
    range: "Range",
    list_of_ranges: "List of ranges",
    select_range: "Select range",
    create_a_range: "Create a range",
    enter_range_name: "Enter a range name",
    enter_range_abbreviation_en: "Enter an English abbreviation",
    enter_range_abbreviation_fr: "Enter a French abbreviation",
    edit_range: "Edit range",
    reactivate_a_range: "Reactivate a range",
    deactivate_a_range: "Deactivate a range",
    unarchive_a_range: "Unarchive a range",
    archive_a_range: "Archive a range",
    archive_range_warning:
      "You will no longer be able to add this range to a wildlife species.",
    unarchive_range_warning:
      "You will be able to add this range to a wildlife species.",
    no_range_selected: "No range was selected",
    region: "Region",
  },
  fr: {
    range: "Aire de répartition",
    list_of_ranges: "Liste des aires de répartition",
    select_range: "Sélectionnez l'aire de répartition",
    create_a_range: "Créer une aire de répartition",
    enter_range_name: "Entrez le nom d'une aire de répartition",
    enter_range_abbreviation_en: "Entrez une abréviation en anglais",
    enter_range_abbreviation_fr: "Entrez une abréviation en français",
    edit_range: "Modifier l’aire de répartition",
    reactivate_a_range: "Réactiver une aire de répartition",
    deactivate_a_range: "Désactiver une aire de répartition",
    unarchive_a_range: "Désarchiver une aire de répartition",
    archive_a_range: "Archiver une aire de répartition",
    archive_range_warning:
      "Vous ne pourrez plus ajouter cette aire de répartition à une espèce sauvage.",
    unarchive_range_warning:
      "Vous pouvez associer cette aire de répartition à une espèce sauvage.",
    no_range_selected: "Aucune aire de répartition n'a été sélectionnée",
    region: "Région",
  },
};

export default adminRangeTranslation;
