import { ApolloError, useMutation } from "@apollo/client";
import { OverlayContainer } from "@react-aria/overlays";
import type { OverlayTriggerState } from "@react-stately/overlays";
import { useOverlayTriggerState } from "@react-stately/overlays";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import GraphqlError from "components/GraphqlError";
import ModalDialog from "components/molecules/Modal";
import { useGlobalAlertContext } from "features/globalAlert";
import {
  BptTemplatePhase,
  Maybe,
  UpdateBptTemplatePhaseDocument,
} from "generated/gql-types";
import * as React from "react";
import { SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  domainModelIntoPhaseForm,
  phaseFormIntoCreateInput,
} from "../../bptFormUtil";
import PhaseForm, { PhaseFormFields } from "../PhaseForm";

export interface EditPhasePopupProps {
  modalState: OverlayTriggerState;
  templateId?: Maybe<string>;
  phaseInfo?: Maybe<BptTemplatePhase>;
  refetchQueries?: string[];
  onCompleted: (data: any) => void;
  onError: (error: ApolloError | undefined) => void;
}

const EditPhasePopup: React.FC<EditPhasePopupProps> = (props) => {
  const { t } = useTranslation();
  const alertContext = useGlobalAlertContext();
  const { modalState, templateId, phaseInfo } = props;

  const [runSubmit, { loading: saving, error: savingError, reset }] =
    useMutation(UpdateBptTemplatePhaseDocument, {
      refetchQueries: props.refetchQueries ?? [],
      onCompleted: props.onCompleted,
      onError: props.onError,
      errorPolicy: "all",
    });

  const onSubmit: SubmitHandler<Partial<PhaseFormFields>> = async (
    formData
  ) => {
    const input = phaseFormIntoCreateInput(formData);

    try {
      const res = await runSubmit({
        variables: {
          templateId: templateId ?? "error",
          phaseId: phaseInfo?.id ?? "error",
          input: input,
        },
      });

      if (res.errors == null) {
        modalState.close();
      }

      alertContext.showSuccess({
        message: t("successfully_updated_phase"),
        timeOut: 5000,
      });
    } catch (e) {
      console.error(e);
    }
  };

  const onClose = () => {
    modalState.close();
    reset();
  };

  if (!modalState.isOpen) return null;

  const formDefaultValues = domainModelIntoPhaseForm(phaseInfo);

  return (
    <>
      <OverlayContainer>
        <ModalDialog
          title={t("edit_phase")}
          className={"modal-width-50"}
          isOpen
          onClose={onClose}
          showModalFooter={false}
        >
          <GraphqlError
            title={t("error_update_bpt_template_phase")}
            errors={savingError}
          />
          {saving && <LoadingIndicator centered />}
          <PhaseForm
            defaultValues={formDefaultValues}
            onSubmit={onSubmit}
            onClose={onClose}
            isSaving={saving}
          />
        </ModalDialog>
      </OverlayContainer>
    </>
  );
};

export default EditPhasePopup;

export const usePhasePopup = useOverlayTriggerState;
