import * as React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import Layout from "components/layouts/TwoColumn";
import SideNav from "components/molecules/SideNav";
import { useTranslation } from "react-i18next";
import ListingSectorForm, {
  ListingSectorFormFields,
} from "components/organisms/listing/ListingSectorForm/ListingSectorForm";
import { useOverviewContext } from "../../../../features/overview";
import { useMutation } from "@apollo/client";
import { ListingPathHelpers } from "pages/listing/ListingRouter";
import { CreateListingWsSectorDocument } from "../../../../generated/gql-types";
import { mapFormToCreateInput } from "../../../../components/organisms/listing/ListingSectorForm/mappers";
import Ribbon from "../../../../components/organisms/cosewic/Ribbon";

export interface ListingSectorsCreatePageProps {}

export const ListingSectorsCreatePage: React.FC<
  ListingSectorsCreatePageProps
> = (props) => {
  const { t } = useTranslation();
  const { params } = useRouteMatch();
  const { listingWsId } = params as any;
  const history = useHistory();
  const overviewContext = useOverviewContext();

  React.useEffect(() => {
    overviewContext.updateOverview("listingws", listingWsId);
  }, [listingWsId]);

  const [createListingWsSector, { loading: saving, error: savingError }] =
    useMutation(CreateListingWsSectorDocument, {
      refetchQueries: ["ListingWsSectorsList"],
      errorPolicy: "all",
    });

  const onSubmit = async (formData: Partial<ListingSectorFormFields>) => {
    const converted = mapFormToCreateInput(formData);

    try {
      const res = await createListingWsSector({
        variables: {
          listingWsId,
          input: {
            ...converted,
          },
        },
      });
      if (res.errors) throw res.errors;

      history.push({
        pathname: ListingPathHelpers.ListingSectors(listingWsId),
      });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <Ribbon />
      <Layout.Root>
        <Layout.Content>
          <h1>{t("add_a_sector")}</h1>
          <ListingSectorForm onSubmit={onSubmit} />
        </Layout.Content>
        <Layout.Sidebar>
          <SideNav />
        </Layout.Sidebar>
      </Layout.Root>
    </>
  );
};

export default ListingSectorsCreatePage;
