import { AlertTypes } from "components/atoms/Alert";
import DeleteButtonModal from "components/atoms/DeleteButtonModal";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import SafeRenderHtml from "components/atoms/SafeRenderHtml";
import { useGlobalAlertContext } from "features/globalAlert";
import {
  BptTemplate,
  BptTemplateActivity,
  BptTemplateStage,
  Maybe,
} from "generated/gql-types";
import useAdminAlertMsg from "hooks/admin/useAdminAlertMsg";
import * as React from "react";
import { useTranslation } from "react-i18next";
import htmlRemoveOuterPTag from "util/htmlRemoveOuterPTag";
import isNullOrEmpty from "util/isNullOrEmpty";
import { ActivityInformation } from "../ActivityInformation/ActivityInformation";
import EditActivityBtn from "../EditActivityModal/EditActivityBtn";

export interface ActivityDetailsProps {
  bptTemplate?: Maybe<BptTemplate>;
  activityInfo?: Maybe<BptTemplateActivity>;
  isLoading?: boolean;
  onDeleteActivity: (a: any, b: any) => void;
}

export const ActivityDetails: React.FC<ActivityDetailsProps> = (props) => {
  const { t } = useTranslation();
  const alertContext = useGlobalAlertContext();
  const adminAlertMsg = useAdminAlertMsg(t("activity"));
  const { bptTemplate, activityInfo, isLoading, onDeleteActivity } = props;

  const templateId = bptTemplate?.id;

  return (
    <details
      key={activityInfo?.id}
      className="detail-list detail-list-activity expand-collapse-list"
    >
      <summary>
        <div className="flexbug-9-workaround">
          <div className="flex justify-between align-center">
            <h5>
              <SafeRenderHtml
                htmlString={htmlRemoveOuterPTag(
                  activityInfo?.name?.text ?? "Error"
                )}
              />
            </h5>
            {bptTemplate?.stage === BptTemplateStage.Draft ? (
              <div className="flex gap-sm align-center flex-wrap">
                <EditActivityBtn
                  templateId={templateId}
                  activityInfo={activityInfo}
                  onCompleted={(data: any) => {
                    alertContext.showSuccess({
                      message: data?.UpdateBptTemplateActivity?.name?.text,
                      timeOut: 5000,
                    });
                  }}
                  onError={adminAlertMsg.onCreateError}
                />

                <DeleteButtonModal
                  buttonText={t("delete")}
                  buttonTitle={t("delete_activity")}
                  modalTitle={t("delete_activity")}
                  alertContent={t("html_delete_activity_warning")}
                  alertConfirm={t("i_confirm")}
                  alertType={AlertTypes.DANGER}
                  onDelete={() =>
                    onDeleteActivity(templateId!, activityInfo?.id!)
                  }
                  className="font-size-14 hover-grey vertical-align-baseline"
                  disabled={
                    isNullOrEmpty(templateId) ||
                    isNullOrEmpty(activityInfo?.id!)
                  }
                />
              </div>
            ) : null}
          </div>
        </div>
      </summary>

      {isLoading ? (
        <LoadingIndicator centered className="mrgn-bttm-md" />
      ) : (
        <ActivityInformation
          templateId={templateId}
          activityInfo={activityInfo}
        />
      )}
    </details>
  );
};

export default ActivityDetails;
