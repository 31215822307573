const homeTranslation = {
  en: {
    // SAR-DMTS Home
    sardmts_home_line1: "Species at Risk",
    sardmts_home_line2: "Data Management Tracking System",
    cosewic_program: "COSEWIC program",
    cosewic_tools: "COSEWIC tools",
    listing_program: "Listing program",
    listing_tools: "Listing tools",
    recovery_program: "Recovery program",
    recovery_tools: "Recovery tools",
    protection_program: "Protection program",
    protection_tools: "Protection tools",
    permits_program: "Permit program",
    permits_tools: "Permit tools",
    url_to_document: "URL to document",

    // COSEWIC Home
    cosewic_home: "COSEWIC home",
    cosewic_wildlife_species_search: "COSEWIC wildlife species search",
    cosewic_wildlife_species_search_content:
      "Find any wildlife species managed by the COSEWIC program.",
    add_cosewic_wildlife_species: "Add COSEWIC wildlife species",
    add_a_new_wildlife_species: "Add a new wildlife species",
    cosewic_assessments_search: "COSEWIC assessments search",
    cosewic_assessments_search_content:
      "Search assessments and view the related wildlife species.",
    cosewic_wildlife_species: "COSEWIC wildlife species",
    communications: "Communications",
    manage_distribution_lists: "Manage distribution lists",
    manage_notifications: "Manage notifications",

    // Listing Home
    listing_home: "Listing home",
    listing_wildlife_species_search: "Listing wildlife species search",
    listing_wildlife_species_search_content:
      "Find any wildlife species managed by the Listing program.",
    add_listing_wildlife_species: "Add Listing wildlife species",

    // Permit Home
    permit_home: "Permit home",
    add_new_permit_description: "Add new permit description",
    new_permit_description: "New permit description",
    browse_permit_descriptions: "Browse permit descriptions",
    browse_all_permit_descriptions: "Browse all permit descriptions",
  },

  fr: {
    // SAR-DMTS Home
    sardmts_home_line1: "Espèces en péril",
    sardmts_home_line2:
      "Système de gestion et de suivi des données sur les espèces en péril",
    cosewic_program: "Programme du COSEPAC",
    cosewic_tools: "Outils du COSEPAC",
    listing_program: "Programme de l'Inscription",
    listing_tools: "Outils du programme de l'Inscription",
    recovery_program: "Programme de Rétablissement",
    recovery_tools: "Outils du programme de Rétablissement",
    protection_program: "Programme de Protection",
    protection_tools: "Outils de Protection",
    permits_program: "Programme de Permis",
    permits_tools: "Outils du programme de Permis",
    url_to_document: "URL vers le document",

    // COSEWIC Home
    cosewic_home: "Accueil du COSEPAC",
    cosewic_wildlife_species_search: "Recherche d'espèces sauvages du COSEPAC",
    cosewic_wildlife_species_search_content:
      "Trouvez n'importe quel espèce sauvage gérée par le programme du COSEPAC.",
    add_cosewic_wildlife_species: "Ajouter espèce sauvage du COSEPAC",
    add_a_new_wildlife_species: "Ajouter une nouvelle espèce sauvage",
    cosewic_assessments_search: "Recherche d'évaluations du COSEPAC",
    cosewic_assessments_search_content:
      "Recherchez évaluations et visualisez les espèces sauvages correspondantes.",
    cosewic_wildlife_species: "Espèces sauvages du COSEPAC",
    communications: "Communications",
    manage_distribution_lists: "Gérer les listes de distribution",
    manage_notifications: "Gérer les notifications",

    // Listing Home
    listing_home: "Accueil du programme de l'Inscription",
    listing_wildlife_species_search:
      "Recherche d'espece sauvage du programme de l'Inscription",
    listing_wildlife_species_search_content:
      "Trouvez une espèce sauvage gérée par le programme de l’Inscription.",
    add_listing_wildlife_species:
      "Ajout d'espèce sauvage au programme de l'Inscription",

    // Permit Home
    permit_home: "Accueil du programme de Permis",
    add_new_permit_description: "Ajouter une nouvelle description de permis",
    new_permit_description: "Nouvelle description de permis",
    browse_permit_descriptions: "Parcourir les descriptions de permis",
    browse_all_permit_descriptions:
      "Parcourir toutes les descriptions de permis",
  },
};

export default homeTranslation;
