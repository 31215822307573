import { useMutation, useQuery } from "@apollo/client";
import DeleteButtonModal from "components/atoms/DeleteButtonModal";
import EditLink from "components/atoms/EditLink";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import MissingData from "components/atoms/MissingData";
import NoResults from "components/atoms/NoResults";
import SectionCard from "components/atoms/SectionCard";
import PageSizeSelect from "components/molecules/PageSizeSelect";
import Pagination from "components/molecules/Pagination";
import { DistinctLandManagerDropdown } from "components/organisms/listing/DistinctLandManagerDropDown/DistinctLandManagerDropdown";
import { ROLE_ACTIONS, RenderWhenAuthorized } from "features/auth/components";
import { useGlobalAlertContext } from "features/globalAlert";
import {
  DeleteListingWsLandDocument,
  LandType,
  ListingWsLandListDocument,
  ProvinceAndTerritory,
} from "generated/gql-types";
import { usePagination } from "hooks/util/usePagination";
import { ListingPathHelpers } from "pages/listing/ListingRouter";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import htmlIsNullOrEmpty from "../../../../util/htmlIsNullOrEmpty";
import SafeRenderHtml from "../../../atoms/SafeRenderHtml";

export interface ListingLandListSectionProps {
  sectionTitle: string;
  listingWsId: string;
  landType: LandType;
}

export const ListingLandListSection: React.FC<ListingLandListSectionProps> = (
  props
) => {
  const { t, i18n } = useTranslation();
  const alertContext = useGlobalAlertContext();
  const pagination = usePagination();

  const [selectedLandManager, setSelectedLandManager] =
    React.useState<string>("");
  const [selectedProvinceTerritory, setSelectedProvinceTerritory] =
    React.useState<string>("");

  useEffect(() => {
    setSelectedLandManager("");
    setSelectedProvinceTerritory("");
    pagination.goToPage(1);
  }, [props.landType]);

  const getFilters = (
    landType?: LandType,
    landManager?: string,
    provinceAndTerritory?: string
  ) => {
    const landTypeKeyValue = landType
      ? `lands.landRef.landType=${landType}`
      : "";
    const landManagerKeyValue = landManager
      ? i18n.language === "fr"
        ? `&lands.landRef.landManager.french=${landManager}`
        : `&lands.landRef.landManager.english=${landManager}`
      : "";
    const provinceAndTerritoryKeyValue = provinceAndTerritory
      ? `&lands.landRef.provinceAndTerritory=${provinceAndTerritory}`
      : "";

    return `${landTypeKeyValue}${landManagerKeyValue}${provinceAndTerritoryKeyValue}`;
  };

  const { loading, error, data } = useQuery(ListingWsLandListDocument, {
    variables: {
      params: {
        listingWsId: props.listingWsId,
        filters: getFilters(
          props.landType,
          selectedLandManager,
          selectedProvinceTerritory
        ),
        pageNumber: pagination.currentPage,
        pageSize: pagination.pageSize,
      },
    },
  });

  const [removeLand, removeLandStatus] = useMutation(
    DeleteListingWsLandDocument,
    {
      refetchQueries: ["ListingWsLandList", "ListingWsLandSummaryCounts"],
      errorPolicy: "all",
    }
  );

  // TODO: better error handling; send the error to a page error context or something?
  if (error) {
    console.error(error);
    return <p>{t("loading_error")}</p>;
  }
  if (removeLandStatus.error) {
    console.error(error);
    return <p>Error: Failed to delete</p>;
  }

  const onChangeLandManager = (option: string) => {
    setSelectedLandManager(option);
  };

  const onChangeProvinceTerritory = (e: any) => {
    const option = e.currentTarget.value;
    setSelectedProvinceTerritory(option);
  };

  const onRemoveLand = async (landId: string) => {
    console.log("Removed landId = ", landId);
    try {
      const res = await removeLand({
        variables: {
          listingWsId: props.listingWsId,
          landId: landId,
        },
      });

      if (res.errors) throw res.errors;

      // alertContext.showSuccess({
      //   // TODO:i18n
      //   message: "Successfully deleted a Land",
      // });
    } catch (e) {}
  };

  const lands = data?.listingWsLandList?.land;

  return (
    <>
      <SectionCard header={<h2>{props.sectionTitle}</h2>}>
        <div className="flex justify-between flex-wrap">
          <div className="flex flex-wrap">
            <div className="form-group width-230 mrgn-rght-md">
              <label htmlFor="sel_land_manager">{t("land_manager")}:</label>
              <DistinctLandManagerDropdown
                listingWsId={props.listingWsId}
                landType={props.landType}
                id="sel_land_manager"
                placeholder={t("ALL")}
                defaultValue={selectedLandManager}
                onChange={onChangeLandManager}
              />
            </div>
            <div className="form-group width-230">
              <label htmlFor="sel_province_territory">
                {t("province_territory")}:
              </label>
              <select
                id="sel_province_territory"
                className="form-control"
                value={selectedProvinceTerritory}
                onChange={onChangeProvinceTerritory}
              >
                <option value={""}>{t("ALL")}</option>
                <option value={ProvinceAndTerritory.Alberta}>
                  {t(ProvinceAndTerritory.Alberta)}
                </option>
                <option value={ProvinceAndTerritory.BritishColumbia}>
                  {t(ProvinceAndTerritory.BritishColumbia)}
                </option>
                <option value={ProvinceAndTerritory.Manitoba}>
                  {t(ProvinceAndTerritory.Manitoba)}
                </option>
                <option value={ProvinceAndTerritory.NewBrunswick}>
                  {t(ProvinceAndTerritory.NewBrunswick)}
                </option>
                <option value={ProvinceAndTerritory.NewfoundlandAndLabrador}>
                  {t(ProvinceAndTerritory.NewfoundlandAndLabrador)}
                </option>
                <option value={ProvinceAndTerritory.NorthwestTerritories}>
                  {t(ProvinceAndTerritory.NorthwestTerritories)}
                </option>
                <option value={ProvinceAndTerritory.NovaScotia}>
                  {t(ProvinceAndTerritory.NovaScotia)}
                </option>
                <option value={ProvinceAndTerritory.Nunavut}>
                  {t(ProvinceAndTerritory.Nunavut)}
                </option>
                <option value={ProvinceAndTerritory.Ontario}>
                  {t(ProvinceAndTerritory.Ontario)}
                </option>
                <option value={ProvinceAndTerritory.PrinceEdwardIsland}>
                  {t(ProvinceAndTerritory.PrinceEdwardIsland)}
                </option>
                <option value={ProvinceAndTerritory.Quebec}>
                  {t(ProvinceAndTerritory.Quebec)}
                </option>
                <option value={ProvinceAndTerritory.Saskatchewan}>
                  {t(ProvinceAndTerritory.Saskatchewan)}
                </option>
                <option value={ProvinceAndTerritory.Yukon}>
                  {t(ProvinceAndTerritory.Yukon)}
                </option>
                <option value={ProvinceAndTerritory.CanadianWaters}>
                  {t(ProvinceAndTerritory.CanadianWaters)}
                </option>
              </select>
            </div>
          </div>

          <div className="flex-col gap-sm align-end">
            <div>
              <PageSizeSelect
                pageSize={pagination.pageSize}
                onChangePageSize={pagination.setPageSize}
              />
            </div>
            <div className="font-size-16 justify-end pb-3">
              {pagination.makeShowingString(
                data?.listingWsLandList?.pagination?.totalCount
              )}
            </div>
          </div>
        </div>

        {/* Filter results */}
        {loading ? (
          <LoadingIndicator centered className="mrgn-bttm-md" />
        ) : !lands || lands?.length === 0 ? (
          <NoResults centered />
        ) : null}

        {lands?.map((item: any) => (
          <div className="list-item separator-line species-field" key={item.id}>
            <div className="row">
              <div className="flex justify-between align-start">
                <h3 className="mrgn-tp-0 mrgn-lft-md">
                  {item?.landRef?.landName ?? <MissingData />}
                </h3>
                <div className="flex gap-sm align-start flex-wrap justify-right mrgn-rght-md">
                  <RenderWhenAuthorized
                    authorizedRoles={ROLE_ACTIONS.land.update}
                  >
                    <EditLink
                      linkTo={ListingPathHelpers.ListingLandsEdit(
                        props.listingWsId,
                        item.listingWsLandId
                      )}
                      linkTitle={t("edit_land")}
                      className="font-size-14 hover-grey"
                    ></EditLink>
                  </RenderWhenAuthorized>
                  <RenderWhenAuthorized
                    authorizedRoles={ROLE_ACTIONS.land.delete}
                  >
                    <DeleteButtonModal
                      buttonText={t("remove")}
                      buttonTitle={t("remove_land")}
                      modalTitle={t("remove_land")}
                      alertContent={t("remove_land_warning")}
                      alertConfirm={t("remove_confirm")}
                      onDelete={() => onRemoveLand(item.listingWsLandId)}
                      className="font-size-14 hover-grey"
                    />
                  </RenderWhenAuthorized>
                </div>
              </div>
              <dl className="row mrgn-lft-0 mrgn-rght-0">
                <div className="col-sm-3 species-data">
                  <dt className="text-muted">{t("land_manager")}</dt>
                  <dd>
                    {(i18n.language === "fr"
                      ? item?.landRef?.landManager?.french
                      : item?.landRef?.landManager?.english) ?? <MissingData />}
                  </dd>
                </div>
                <div className="col-sm-3 species-data">
                  <dt className="text-muted">{t("province_territory")}</dt>
                  <dd>
                    {item?.landRef?.provinceAndTerritory ? (
                      t(item?.landRef?.provinceAndTerritory)
                    ) : (
                      <MissingData />
                    )}
                  </dd>
                </div>
                <div className="col-sm-3 species-data">
                  <dt className="text-muted">{t("dfrp")}</dt>
                  <dd>{item?.landRef?.dfrp ?? <MissingData />}</dd>
                </div>
                <div className="col-sm-3 species-data">
                  <dt className="text-muted">{t("land_size")}</dt>
                  <dd>{item?.landRef?.landSize ?? <MissingData />}</dd>
                </div>
              </dl>
            </div>

            <details className="species-details">
              <summary className="mrgn-bttm-sm">
                {t("related_wildlife_species_information")}
              </summary>
              <dl className="row">
                <div className="col-sm-4 species-data">
                  <dt className="text-muted">
                    {t("wildlife_species_presence")}
                  </dt>
                  <dd>
                    {item?.wildlifeSpeciesPresence ? (
                      t(item?.wildlifeSpeciesPresence)
                    ) : (
                      <MissingData />
                    )}
                  </dd>
                </div>
                <div className="col-sm-4 species-data">
                  <dt className="text-muted">{t("number_of_sites")}</dt>
                  <dd>{item?.numberOfSites ?? <MissingData />}</dd>
                </div>
                <div className="col-sm-4 species-data">
                  <dt className="text-muted">{t("approx_num_individuals")}</dt>
                  <dd>
                    {item?.approximateNumberOfIndividuals ? (
                      item?.approximateNumberOfIndividuals
                    ) : (
                      <MissingData />
                    )}
                  </dd>
                </div>
              </dl>
              <dl className="row">
                <div className="col-sm-12 species-data">
                  <dt className="text-muted">{t("circumstance")}</dt>
                  <dd>
                    {htmlIsNullOrEmpty(item?.circumstance?.text) ? (
                      <MissingData />
                    ) : (
                      <SafeRenderHtml htmlString={item?.circumstance!.text} />
                    )}
                  </dd>
                </div>
              </dl>
              <dl className="row">
                <div className="col-sm-12 species-data">
                  <dt className="text-muted">{t("source")}</dt>
                  <dd>
                    {htmlIsNullOrEmpty(item?.source?.text) ? (
                      <MissingData />
                    ) : (
                      <SafeRenderHtml htmlString={item?.source!.text!} />
                    )}
                  </dd>
                </div>
              </dl>
            </details>
          </div>
        ))}

        <Pagination
          {...pagination.paginationComponentProps}
          totalPages={data?.listingWsLandList?.pagination?.totalPages ?? 0}
        />
      </SectionCard>
    </>
  );
};

export default ListingLandListSection;
