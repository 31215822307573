import * as React from "react";
import { useTranslation } from "react-i18next";
import EditProjectPhaseModal, {
  EditProjectPhaseModalProps,
  useProjectPhaseModal,
} from "./EditProjectPhaseModal";

interface EditProjectPhaseBtnModalProps
  extends Omit<EditProjectPhaseModalProps, "modalState"> {
  buttonLabel?: string;
}

const EditProjectPhaseBtn: React.FC<EditProjectPhaseBtnModalProps> = ({
  buttonLabel,
  ...props
}) => {
  const { t } = useTranslation();
  const modalState = useProjectPhaseModal({});
  const label = buttonLabel ?? t("edit");
  const validPhaseId =
    props.projectId != null && props.projectPhaseInfo?.id != null;

  return (
    <>
      <button
        type="button"
        className="btn btn-link card-header-button-link"
        onClick={modalState.open}
        title={t("edit_project_phase")}
        disabled={!validPhaseId}
        data-testid="button-edit-project-phase"
      >
        <i className="fas fa-pen mrgn-rght-sm"></i>
        {label}
      </button>
      {validPhaseId ? (
        <EditProjectPhaseModal modalState={modalState} {...props} />
      ) : null}
    </>
  );
};

export default EditProjectPhaseBtn;
