import { ApolloError, useMutation } from "@apollo/client";
import { OverlayContainer } from "@react-aria/overlays";
import type { OverlayTriggerState } from "@react-stately/overlays";
import { useOverlayTriggerState } from "@react-stately/overlays";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import ModalDialog from "components/molecules/Modal";
import {
  BilingualAbbreviationAcronymTagsLookupText,
  BptTemplateVisibility,
  CreateBptTemplateDocument,
} from "generated/gql-types";
import * as React from "react";
import { SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import GraphqlError from "components/GraphqlError";
import { useGlobalAlertContext } from "features/globalAlert";
import BptTemplateForm, { BptTemplateFormFields } from "../BptTemplateForm";
import {
  BptTemplateDomainModelIntoForm,
  formIntoCreateInputBptTemplate,
} from "../../bptFormUtil";

export interface AddBptTemplatePopupProps {
  program?: BilingualAbbreviationAcronymTagsLookupText | null;
  modalState: OverlayTriggerState;
  refetchQueries?: string[];
  onCompleted?: (data: any) => void;
  onError?: (error: ApolloError | undefined) => void;
}

const AddBptTemplatePopup: React.FC<AddBptTemplatePopupProps> = (props) => {
  const { t } = useTranslation();
  const { modalState } = props;
  const alertContext = useGlobalAlertContext();

  const [createBptTemplate, { loading, error, reset }] = useMutation(
    CreateBptTemplateDocument,
    {
      refetchQueries: props.refetchQueries ?? [],
      onCompleted: props.onCompleted,
      onError: props.onError,
      errorPolicy: "all",
    }
  );

  const onSubmit: SubmitHandler<Partial<BptTemplateFormFields>> = async (
    formData
  ) => {
    try {
      const input = formIntoCreateInputBptTemplate(
        formData,
        {
          program: props.program,
          visibility: BptTemplateVisibility.AllPrograms,
        },
        true
      );
      const res = await createBptTemplate({
        variables: {
          input: input,
        },
      });
      alertContext.showSuccess({
        title: t("added_new_bpt_template"),
        timeOut: 5000,
      });
      if (res.errors == null) {
        modalState.close();
      }
    } catch (e) {
      console.warn("Error adding BptTemplate");
      console.error(e);
    }
  };

  const onClose = () => {
    modalState.close();
    reset();
  };

  if (!modalState.isOpen) return null;

  const initialValues = BptTemplateDomainModelIntoForm({
    program: props.program,
    visibility: BptTemplateVisibility.AllPrograms,
  });

  return (
    <OverlayContainer>
      <ModalDialog
        title={t("create_new_bpt_template")}
        isOpen
        onClose={onClose}
        showModalFooter={false}
        className="modal-dropdown-visible"
      >
        <GraphqlError errors={error} />
        {loading && <LoadingIndicator centered />}
        <BptTemplateForm
          intialValues={initialValues}
          onSubmit={onSubmit}
          onClose={onClose}
        />
      </ModalDialog>
    </OverlayContainer>
  );
};

export default AddBptTemplatePopup;

export const useAddBptTemplateModal = useOverlayTriggerState;
export type { BptTemplateFormFields as AddBptTemplateFields };
