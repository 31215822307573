import { useMutation, useQuery } from "@apollo/client";
import GraphqlError from "components/GraphqlError";
import Layout from "components/layouts/TwoColumn";
import SideNav from "components/molecules/SideNav";
import { AssessmentFormOutput } from "components/organisms/cosewic/assessment/AssessmentForm";
import Ribbon from "components/organisms/cosewic/Ribbon";
import AssessmentDataEntry from "components/templates/cosewic/assessment/DataEntry";
import { ChangeTrackingProvider } from "features/changeTracking";
import { useOverviewContext } from "features/overview";
import {
  AssessmentStage,
  AssessmentState,
  CosewicAssessmentProfileDocument,
  CosewicWsChildrenCountDocument,
  CosewicWsState,
  CosewicWsStatus,
  UpdateAssessmentDocument,
  UpdateAssessmentStageDocument,
  UpdateAssessmentStateDocument,
} from "generated/gql-types";
import { CosewicPathHelpers } from "pages/cosewic/CosewicRouter";
import * as React from "react";
import { SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";
import { convertFormToUpdateInput } from "util/cosewic/assessment/forms";

export interface AssessmentEditProps {}

const AssessmentEdit: React.FC<AssessmentEditProps> = (props) => {
  const { t } = useTranslation();
  const { params } = useRouteMatch();
  const { cosewicWsId, assessmentId } = params as any;
  const history = useHistory();
  const overviewContext = useOverviewContext();

  React.useEffect(() => {
    overviewContext.updateOverview("cosewicws", cosewicWsId);
  }, [cosewicWsId]);

  const {
    loading: loadingCosewicDraftChildren,
    error: errorCosewicDraftChildren,
    data: dataCosewicDraftChildren,
  } = useQuery(CosewicWsChildrenCountDocument, {
    variables: {
      cosewicWsChildrenCountParams: { id: cosewicWsId, fields: "status=DRAFT" },
    },
  });

  // Disable the COSEWIC WS status and status change dropdowns
  // if there ARE children and ANY are non-permanent. (Task 8296)
  const areRelatedWildlifeSpeciesStillDraft =
    (dataCosewicDraftChildren?.cosewicWsChildrenCount ?? 0) > 0;

  const { loading, error, data } = useQuery(CosewicAssessmentProfileDocument, {
    variables: { cosewicWsId, assessmentId },
  });

  const isLatestAssessment =
    data?.assessmentList?.assessment[0]?.id === assessmentId;

  // Show or hide the "COSEWIC reports sent to Minister date" field based on these conditions (US 7252).
  // Case added Nov 1 2022 (Bug 36575) - hide the field unless this is the latest assessment
  const showDateSentToMinister =
    isLatestAssessment &&
    data?.cosewicWs?.status === CosewicWsStatus.Permanent &&
    data.cosewicWs.state === CosewicWsState.Live &&
    data.assessment?.stage === AssessmentStage.Assessed &&
    data.assessment.state === AssessmentState.Published;

  const [updateAssessment, updateAssessmentMutation] = useMutation(
    UpdateAssessmentDocument,
    {
      refetchQueries: ["CosewicAssessmentProfile"],
      errorPolicy: "all",
    }
  );

  const [, changeAssessmentStageMutation] = useMutation(
    UpdateAssessmentStageDocument,
    {
      refetchQueries: ["CosewicAssessmentProfile"],
      errorPolicy: "all",
    }
  );

  const [, changeAssessmentStateMutation] = useMutation(
    UpdateAssessmentStateDocument,
    {
      refetchQueries: ["CosewicAssessmentProfile"],
      errorPolicy: "all",
    }
  );

  const onSubmit: SubmitHandler<Partial<AssessmentFormOutput>> = async (
    data,
    dirtyFields?: any
  ) => {
    const converted = convertFormToUpdateInput(data, dirtyFields);
    try {
      const res = await updateAssessment({
        variables: {
          assessmentId,
          input: converted,
        },
      });

      if (res.errors) throw res.errors;

      history.push({
        pathname: CosewicPathHelpers.AssessmentProfile(
          cosewicWsId ?? "error",
          assessmentId ?? "error"
        ),
      });

      // Refetch overviewContext to update ribbon and sideNav
      overviewContext.refetch();
    } catch (e) {
      console.error(e);
    }
  };

  if (!loading && (!data || !data.assessment)) {
    console.error(
      "Assessment edit page received empty data for: COSEWIC WS ID:",
      cosewicWsId,
      "Assessment ID",
      assessmentId
    );
  }

  const DataView =
    data != null && data.assessment != null ? (
      <>
        {updateAssessmentMutation.loading ? <p>Saving...</p> : null}
        <ChangeTrackingProvider
          isEnabled={
            data.assessment.state === AssessmentState.Published &&
            data.cosewicWs?.status === CosewicWsStatus.Permanent
          }
        >
          <AssessmentDataEntry
            assessment={data?.assessment}
            areRelatedWildlifeSpeciesStillDraft={
              areRelatedWildlifeSpeciesStillDraft
            }
            showDateSentToMinister={showDateSentToMinister}
            onSubmit={onSubmit}
          />
        </ChangeTrackingProvider>
        {updateAssessmentMutation.loading ? <p>Saving...</p> : null}
      </>
    ) : null;

  return (
    <>
      <Ribbon />

      <Layout.Root className="mrgn-tp-md">
        <Layout.Content>
          <h1>{t("edit_assessment")}</h1>

          <GraphqlError title="Error fetching assessment data" errors={error} />
          <GraphqlError
            title="Error fetching CosewicWS children count data"
            errors={errorCosewicDraftChildren}
          />
          <GraphqlError
            title="Error saving assessment"
            errors={updateAssessmentMutation.error}
          />
          <GraphqlError
            title="Error making assessment assessed"
            errors={changeAssessmentStageMutation.error}
          />
          <GraphqlError
            title="Error publishing assessment"
            errors={changeAssessmentStateMutation.error}
          />

          {loading || loadingCosewicDraftChildren ? <p>Loading...</p> : null}
          <div>{DataView}</div>
        </Layout.Content>
        <Layout.Sidebar>
          <SideNav />
        </Layout.Sidebar>
      </Layout.Root>
    </>
  );
};

export default AssessmentEdit;
