import MissingData from "components/atoms/MissingData";
import SafeRenderHtml from "components/atoms/SafeRenderHtml";
import { BptProjectActivity, Maybe } from "generated/gql-types";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { getProjectTimeline } from "../../Utility/GetProjectTimeline";

export interface ProjectActivityInformationProps {
  projectActivityInfo?: Maybe<BptProjectActivity>;
}

export const ProjectActivityInformation: React.FC<
  ProjectActivityInformationProps
> = (props) => {
  const { t } = useTranslation();
  const { projectActivityInfo } = props;

  const projectedTimeline = getProjectTimeline(
    projectActivityInfo?.projectedTimeline
  );
  const actualTimeline = getProjectTimeline(
    projectActivityInfo?.actualTimeline
  );

  return (
    <>
      <div className="species-field font-size-18">
        <div className="row">
          <dl>
            {/* ------------ Projected timeline ------------ */}
            <div className="species-data col-sm-6">
              <dt className="text-muted">{t("projected_timeline")}</dt>
              <dd>{projectedTimeline ?? <MissingData />}</dd>
            </div>

            {/* ------------ Actual timeline ------------ */}
            <div className="species-data col-sm-6">
              <dt className="text-muted">{t("actual_timeline")}</dt>
              <dd>{actualTimeline ?? <MissingData />}</dd>
            </div>
          </dl>
        </div>

        <div className="row">
          <dl>
            {/* ------------ Comments ------------ */}
            <div className="species-data col-sm-12">
              <dt className="text-muted">{t("comments")}</dt>
              <dd>
                {projectActivityInfo?.comments == null ? (
                  <MissingData />
                ) : (
                  <SafeRenderHtml
                    htmlString={projectActivityInfo?.comments.text ?? ""}
                  />
                )}
              </dd>
            </div>
          </dl>
        </div>

        <div className="row">
          <dl>
            {/* ------------ Last updated by ------------ */}
            <div className="species-data col-sm-12 text-right">
              <dt
                className="text-muted mrgn-rght-sm"
                style={{ display: "inline-block" }}
              >
                {t("last_updated_by")}
              </dt>
              <dd className="font-size-14" style={{ display: "inline" }}>
                {projectActivityInfo?.lastModifiedBy == null ? (
                  <MissingData />
                ) : (
                  <SafeRenderHtml
                    htmlString={projectActivityInfo?.lastModifiedBy ?? ""}
                  />
                )}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </>
  );
};

export default ProjectActivityInformation;
