import { ApolloError, useMutation } from "@apollo/client";
import { OverlayContainer } from "@react-aria/overlays";
import type { OverlayTriggerState } from "@react-stately/overlays";
import { useOverlayTriggerState } from "@react-stately/overlays";
import GraphqlError from "components/GraphqlError";
import ModalDialog from "components/molecules/Modal";
import ResponseStatementForm from "components/molecules/admin/ResponseStatementForm";
import {
  AdminResponseStatementType,
  CreateAdminResponseStatementDocument,
} from "generated/gql-types";
import * as React from "react";
import { SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import type { ResponseStatementFields } from "../../../../components/molecules/admin/ResponseStatementForm";
import LoadingIndicator from "../../../atoms/LoadingIndicator";

export interface AddResponseStatementPopupProps {
  modalState: OverlayTriggerState;
  refetchQueries?: string[];
  onCompleted?: (data: any) => void;
  onError?: (error: ApolloError | undefined) => void;
}

const AddResponseStatementPopup: React.FC<AddResponseStatementPopupProps> = (
  props
) => {
  const { t } = useTranslation();
  const { modalState } = props;

  const [createAdminResponseStatement, { loading, error, reset }] = useMutation(
    CreateAdminResponseStatementDocument,
    {
      refetchQueries: props.refetchQueries ?? [],
      onCompleted: props.onCompleted,
      onError: props.onError,
      errorPolicy: "all",
    }
  );

  const onSubmit: SubmitHandler<ResponseStatementFields> = async (formData) => {
    try {
      const res = await createAdminResponseStatement({
        variables: {
          input: {
            name: {
              english: formData.nameEnglish,
              french: formData.nameFrench,
            },
            type: formData.statementType,
            includeConsultationPeriod: formData.includeConsultationPeriod,
            message: {
              english: {
                text: formData.englishMessage,
              },
              french: {
                text: formData.frenchMessage,
              },
            },
          },
        },
      });

      if (res.errors == null) {
        modalState.close();
      }
    } catch (e) {
      console.warn("Error saving new Response Statement");
      console.error(e);
    }
  };

  const onClose = () => {
    modalState.close();
    reset();
  };

  if (!modalState.isOpen) return null;

  return (
    <OverlayContainer>
      <ModalDialog
        title={t("add_response_statement_field")}
        className={"modal-width-70"}
        isOpen
        onClose={onClose}
        showModalFooter={false}
        // isDismissable
      >
        <GraphqlError title="" errors={error} />
        {loading && <LoadingIndicator centered />}
        <ResponseStatementForm
          defaultValues={{
            nameEnglish: "",
            nameFrench: "",
            statementType:
              AdminResponseStatementType.ResponseStatementScenarios,
            includeConsultationPeriod: false,
            englishMessage: "",
            frenchMessage: "",
          }}
          onSubmit={onSubmit}
          onClose={onClose}
        />
      </ModalDialog>
    </OverlayContainer>
  );
};

export default AddResponseStatementPopup;

export const useResponseStatementPopup = useOverlayTriggerState;
export type { ResponseStatementFields };
