import { useMutation, useQuery } from "@apollo/client";
import GraphqlError from "components/GraphqlError";
import Alert, { AlertTypes } from "components/atoms/Alert";
import CreatedUpdatedInfo from "components/atoms/CreatedUpdatedInfo";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import MissingData from "components/atoms/MissingData";
import SafeRenderHtml from "components/atoms/SafeRenderHtml";
import Layout from "components/layouts/OneColumn";
import { PhasesCard } from "components/molecules/admin/bptTemplates/Phase/PhasesCard/PhasesCard";
import CreateNewVersionButtonAndModal from "components/molecules/admin/bptTemplates/Template/CreateNewVersionButtonAndModal";
import GoToNewVersionModal, {
  goToNewVersionState,
} from "components/molecules/admin/bptTemplates/Template/GoToNewVersionModal";
import MakeActivateDeactivateButtonAndModal from "components/molecules/admin/bptTemplates/Template/MakeActivateDeactivateButtonAndModal";
import ReturnToButton from "components/molecules/admin/bptTemplates/utility/ReturnToButton";
import AddBptProjectModalWithButton from "components/molecules/bptProjects/AddBptProjectModal/AddBptProjectButtonWithModal";
import ConfirmationModal, {
  useConfirmationModal,
} from "components/organisms/ConfirmationModal";
import { RenderWhenAuthorized, ROLE_ACTIONS } from "features/auth/components";
import RenderWhenFeatureIsEnabled from "features/featureFlags/RenderWhenFeatureIsEnabled";
import { FeatureFlagKeys } from "features/featureFlags/config";
import { GlobalAlert, useGlobalAlertContext } from "features/globalAlert";
import {
  BptTemplateDocument,
  BptTemplateLatestDocument,
  BptTemplatePhaseListDocument,
  BptTemplateStage,
  CopyToDraftBptTemplateDocument,
  DeleteBptTemplateDocument,
  DeleteBptTemplatePhaseDocument,
  Scalars,
  UpdateBptTemplateStageDocument,
} from "generated/gql-types";
import useAdminAlertMsg from "hooks/admin/useAdminAlertMsg";
import { BptPathHelpers } from "pages/bpt/bptRouter";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";
import htmlRemoveOuterPTag from "util/htmlRemoveOuterPTag";
import { TemplateInformationCard } from "../../../../components/molecules/admin/bptTemplates/Template/TemplateInformationCard/TemplateInformationCard";
import { AdminPathHelpers } from "../../AdminRouter";

const BptTemplateIndexPage: React.FC = (props) => {
  const { t } = useTranslation();
  const { params } = useRouteMatch();
  const { templateId } = params as any;
  const [newVersionTemplateId, setNewVersionTemplateId] = useState("");
  const [draftAlreadyExist, setDraftAlreadyExist] = useState(false);

  const alertContext = useGlobalAlertContext();
  const adminAlertMsg = useAdminAlertMsg(t("bptProject"));
  const history = useHistory();
  const goToNewVersionPopupState = goToNewVersionState({});

  // Call the back-end graphQL services
  const { loading, error, data } = useQuery(BptTemplateDocument, {
    variables: {
      templateId: templateId,
    },
    skip: templateId == null,
    fetchPolicy: "network-only",
    errorPolicy: "all",
  });

  const {
    loading: loadingLatestDraft,
    error: errorLatestDraft,
    data: dataLatestDraft,
  } = useQuery(BptTemplateLatestDocument, {
    variables: {
      templateId: templateId,
    },
    skip: templateId == null,
    fetchPolicy: "network-only",
    errorPolicy: "all",
  });

  const {
    loading: loadingPhaseList,
    error: errorPhaseList,
    data: dataPhaseList,
  } = useQuery(BptTemplatePhaseListDocument, {
    variables: {
      params: {
        templateId: templateId,
        pageNumber: 1,
        pageSize: 200,
      },
    },
    skip: templateId == null,
    fetchPolicy: "network-only",
    errorPolicy: "all",
  });

  const [
    deletePhase,
    { loading: loadingDeletePhase, error: errorDeletePhase },
  ] = useMutation(DeleteBptTemplatePhaseDocument, {
    refetchQueries: ["BptTemplatePhaseList"],
    errorPolicy: "all",
  });

  const [updateBptTemplateStage, UpdateBptTemplateStageMutation] = useMutation(
    UpdateBptTemplateStageDocument,
    {
      refetchQueries: ["BptTemplate"],
      errorPolicy: "all",
    }
  );

  const [deleteBptTemplate, DeleteBptTemplateMutation] = useMutation(
    DeleteBptTemplateDocument,
    {
      refetchQueries: ["BptTemplateList"],
      errorPolicy: "all",
    }
  );

  const [copyToDraftBptTemplate, CopyToDraftBptTemplateMutation] = useMutation(
    CopyToDraftBptTemplateDocument,
    {
      refetchQueries: ["BptTemplateList"],
      errorPolicy: "all",
    }
  );

  // Active the template
  const onActivate = async () => {
    try {
      const res = await updateBptTemplateStage({
        variables: {
          templateId: templateId,
          stage: BptTemplateStage.Active,
        },
      });
      if (res.errors) throw res.errors;
      alertContext.showSuccess({
        message: t("bpt_template_successfully_activated"),
        timeOut: 5000,
      });

      history.push({
        pathname: AdminPathHelpers.BptTemplatesIndex(templateId),
      });
    } catch (e) {
      console.error(e);
    }
  };

  // Deactivate the template
  const onDeactivate = async () => {
    try {
      const res = await updateBptTemplateStage({
        variables: {
          templateId: templateId,
          stage: BptTemplateStage.Inactive,
        },
      });
      if (res.errors) throw res.errors;
      alertContext.showSuccess({
        message: t("bpt_template_successfully_deactivated"),
        timeOut: 5000,
      });

      history.push({
        pathname: AdminPathHelpers.BptTemplatesIndex(templateId),
      });
    } catch (e) {
      console.error(e);
    }
  };

  // Delete template draft
  const onDeleteTemplate = async (templateId: Scalars["ID"]) => {
    try {
      const res = await deleteBptTemplate({
        variables: {
          templateId: templateId,
        },
      });
      if (res.errors) throw res.errors;

      history.push({
        pathname: AdminPathHelpers.BptTemplates(),
      });

      alertContext.showSuccess({
        message: t("bpt_template_successfully_deleted"),
        timeOut: 5000,
      });
    } catch (e) {
      console.error(e);
    }
  };

  // Create new version
  const onCreateNewVersion = async () => {
    try {
      if (!draftAlreadyExist) {
        const res = await copyToDraftBptTemplate({
          variables: {
            templateId: templateId,
          },
        });

        if (res.errors) throw res.errors;

        var newDraftVersionTemplateId = res?.data?.copyToDraftBptTemplate?.id;
        if (newDraftVersionTemplateId != null) {
          setNewVersionTemplateId(newDraftVersionTemplateId);
        }
      }
      goToNewVersionPopupState.open();
    } catch (e) {
      console.error(e);
    }
  };

  // Goto new version
  const onGotoNewVersion = async () => {
    try {
      if (newVersionTemplateId != null) {
        history.push({
          pathname: AdminPathHelpers.BptTemplatesIndex(newVersionTemplateId),
        });
      } else {
        history.push({
          pathname: AdminPathHelpers.BptTemplates(),
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  // Delete phase
  const onDeletePhase = async (
    templateId: Scalars["ID"],
    phaseId: Scalars["ID"]
  ) => {
    try {
      const res = await deletePhase({
        variables: {
          templateId: templateId,
          phaseId: phaseId,
        },
      });

      if (res.errors) throw res.errors;

      alertContext.showSuccess({
        message: t("successfully_delete_phase"),
        timeOut: 5000,
      });
      return true;
    } catch (e) {
      console.error(e);
    }
    return false;
  };

  React.useMemo(() => {
    var newVersionBptTemplateId = dataLatestDraft?.bptTemplateLatest?.id;
    if (
      newVersionBptTemplateId !== undefined &&
      newVersionBptTemplateId !== templateId
    ) {
      setDraftAlreadyExist(true);
      setNewVersionTemplateId(newVersionBptTemplateId ?? "");
    }
  }, [dataLatestDraft, templateId]);

  return (
    <>
      <Layout.Root>
        <Layout.Content>
          {/* ----- Template name header ----- */}
          <h1>
            <SafeRenderHtml
              htmlString={htmlRemoveOuterPTag(data?.bptTemplate?.name?.text)}
            />
          </h1>
          {/* ----- Alert / Errors ----- */}
          <GlobalAlert />
          <GraphqlError
            title={t("error_loading_bpt_Template")}
            errors={error}
          />
          <GraphqlError
            title={t("activate_fail")}
            errors={UpdateBptTemplateStageMutation.error}
          />
          <GraphqlError
            title={t("error_loading_bpt_template_phase_list")}
            errors={errorPhaseList}
          />
          <GraphqlError title={t("delete_fail")} errors={errorDeletePhase} />
          <GraphqlError
            title={t("error_loading_latest_bpt_template_draft")}
            errors={errorLatestDraft}
          />
          <GraphqlError
            title={t("error_deleting_bpt_template")}
            errors={DeleteBptTemplateMutation.error}
          />
          <GraphqlError
            title={t("error_copying_to_latest_draft_bpt_template")}
            errors={CopyToDraftBptTemplateMutation.error}
          />
          {loading || loadingPhaseList || loadingLatestDraft ? (
            <LoadingIndicator />
          ) : !error && !errorPhaseList && !errorLatestDraft ? (
            <>
              <div className="flex justify-between align-center mrgn-bttm-md">
                <div className="flex font-size-18">
                  {/* ----- Stage ----- */}
                  <div>
                    {t("stage")}{" "}
                    <div className="label label-info">
                      {data?.bptTemplate?.stage ? (
                        t(data?.bptTemplate?.stage.toString())
                      ) : (
                        <MissingData />
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex justify-right flex-auto gap-sm">
                  {data?.bptTemplate?.stage === BptTemplateStage.Active && (
                    <RenderWhenFeatureIsEnabled
                      flagKey={FeatureFlagKeys.BusinessProcessTrackingProject}
                    >
                      <RenderWhenAuthorized
                        authorizedRoles={ROLE_ACTIONS.bptProject.create}
                      >
                        <AddBptProjectModalWithButton
                          bptTemplate={data?.bptTemplate}
                          buttonLabel={t("create_project")}
                          onCompleted={(data: any) => {
                            //Todo: Need to fix this message to show
                            alertContext.showSuccess({
                              message: data?.createBptProject?.name?.plainText,
                              timeOut: 5000,
                            });
                            history.push({
                              pathname: BptPathHelpers.Projects,
                            });
                          }}
                          onError={adminAlertMsg.onCreateError}
                        />
                      </RenderWhenAuthorized>
                    </RenderWhenFeatureIsEnabled>
                  )}
                  {/* ----- Activate button ----- */}
                  {data?.bptTemplate?.stage === BptTemplateStage.Draft && (
                    <RenderWhenAuthorized
                      authorizedRoles={
                        ROLE_ACTIONS.administration.bptTemplate.update
                      }
                    >
                      <MakeActivateDeactivateButtonAndModal
                        canMakeActivatedDeactivated={true}
                        onMakeActivatedDeActivated={onActivate}
                        title={t("activate_template")}
                        buttonText={t("activate")}
                        warningMessage={t("html_activate_template_warning")}
                        ConfirmationMessage={t("i_confirm")}
                        className="btn btn-primary btn-sm"
                      />
                    </RenderWhenAuthorized>
                  )}

                  {/* ----- Delete draft button ----- */}
                  {data?.bptTemplate?.stage === BptTemplateStage.Draft && (
                    <RenderWhenAuthorized
                      authorizedRoles={
                        ROLE_ACTIONS.administration.bptTemplate.update
                      }
                    >
                      <DeleteTemplateButtonAndModal
                        title={t("delete_draft_template")}
                        buttonText={t("delete_draft")}
                        onDelete={() => onDeleteTemplate(templateId)}
                      />
                    </RenderWhenAuthorized>
                  )}

                  {/* ----- Deactivate button ----- */}
                  {data?.bptTemplate?.stage === BptTemplateStage.Active && (
                    <RenderWhenAuthorized
                      authorizedRoles={
                        ROLE_ACTIONS.administration.bptTemplate.update
                      }
                    >
                      <MakeActivateDeactivateButtonAndModal
                        canMakeActivatedDeactivated={true}
                        onMakeActivatedDeActivated={onDeactivate}
                        title={t("deactivate_template")}
                        buttonText={t("deactivate_template")}
                        buttonIcon={
                          <i className="far fa-times-circle mrgn-rght-sm" />
                        }
                        warningMessage={t("html_deactivate_template_warning")}
                        ConfirmationMessage={t("i_confirm")}
                      />
                    </RenderWhenAuthorized>
                  )}

                  {/* ----- Create New Version button ----- */}
                  {data?.bptTemplate?.stage === BptTemplateStage.Active && (
                    <RenderWhenAuthorized
                      authorizedRoles={
                        ROLE_ACTIONS.administration.bptTemplate.update
                      }
                    >
                      <CreateNewVersionButtonAndModal
                        canCreateNewVersion={true}
                        onCreateNewVersion={onCreateNewVersion}
                        title={t("create_new_version")}
                        warningMessage={t("html_create_new_version_warning")}
                        buttonText={t("create_new_version")}
                        ConfirmationMessage={t("i_confirm")}
                      />
                      <GoToNewVersionModal
                        title={t("create_new_version")}
                        successMessage={
                          draftAlreadyExist
                            ? t("draft_Already_Exist")
                            : t("create_new_draft_success")
                        }
                        onNavigate={onGotoNewVersion}
                        modalState={goToNewVersionPopupState}
                      />
                    </RenderWhenAuthorized>
                  )}
                </div>
              </div>

              {/* ----- Created Updated Info ----- */}
              <CreatedUpdatedInfo
                createdBy={data?.bptTemplate?.createdBy}
                createdDate={data?.bptTemplate?.createdAt}
                updatedBy={data?.bptTemplate?.modifiedBy}
                updatedDate={data?.bptTemplate?.modifiedAt}
                version={data?.bptTemplate?.templateVersion}
                className="mrgn-bttm-md"
              />

              {/* ----- Template information section ----- */}
              <TemplateInformationCard bptTemplate={data?.bptTemplate} />

              {/* ----- Phases section ----- */}
              <PhasesCard
                bptTemplate={data?.bptTemplate}
                phases={dataPhaseList?.bptTemplatePhaseList?.phases}
                onDeletePhase={onDeletePhase}
              />

              {/* ------------- Return to Manage templates page (button) ------------- */}
              <ReturnToButton
                targetText={t("manage_templates")}
                link={AdminPathHelpers.BptTemplates()}
              />
            </>
          ) : null}
        </Layout.Content>
      </Layout.Root>
    </>
  );
};

const DeleteTemplateButtonAndModal: React.FC<{
  title: string;
  buttonText: string;
  onDelete: () => void;
}> = (props) => {
  const { t } = useTranslation();
  const confirmDeleteModal = useConfirmationModal({});
  const [confirmDeleteState, setConfirmDeleteState] =
    React.useState<boolean>(false);

  return (
    <>
      <button
        type="button"
        className="btn btn-danger btn-sm"
        onClick={confirmDeleteModal.open}
        title={t(props.title)}
        data-testid="button-delete-draft"
      >
        {t(props.buttonText)}
      </button>

      <ConfirmationModal
        modalState={confirmDeleteModal}
        title={t(props.title)}
        confirmBtnEnabled={confirmDeleteState}
        onConfirm={props.onDelete}
        onCancel={() => {
          confirmDeleteModal.close();
          setConfirmDeleteState(false);
        }}
      >
        <Alert type={AlertTypes.WARNING} content={t("delete_draft_warning")} />

        <div className="checkbox">
          <label>
            <input
              type="checkbox"
              checked={confirmDeleteState}
              onChange={(e) => setConfirmDeleteState((x) => !x)}
              data-testid="modal-checkbox-confirm-delete-draft"
            />
            &nbsp;
            {t("i_confirm")}
          </label>
        </div>
      </ConfirmationModal>
    </>
  );
};

export default BptTemplateIndexPage;
