import AddPhaseIcon from "assets/svg/add-phase-icon.svg";
import * as React from "react";
import { useTranslation } from "react-i18next";
import AddActivityPopup, {
  AddActivityPopupProps,
  useActivityPopup,
} from "./AddActivityModal";

interface AddActivityBtnProps
  extends Omit<AddActivityPopupProps, "modalState"> {
  buttonLabel?: string;
  showImage?: boolean;
}

const AddActivityBtn: React.FC<AddActivityBtnProps> = ({
  buttonLabel,
  showImage,
  ...props
}) => {
  const { t } = useTranslation();
  const popupState = useActivityPopup({});
  const validTemplateId =
    props.templateId != null && props.templateId.length > 0;

  return (
    <>
      <button
        type="button"
        className="btn btn-link min-height-auto p-0"
        title={t("add_activity")}
        onClick={popupState.open}
        disabled={!validTemplateId}
        data-testid="button-add-phase"
      >
        {showImage ? (
          <>
            <img src={AddPhaseIcon} alt="" />
            <div className={"no-results-content font-size-18"}>
              {buttonLabel ? t(buttonLabel) : t("add_activity")}
            </div>
          </>
        ) : (
          <>
            <i className="fas fa-plus-circle mrgn-rght-sm" />
            {buttonLabel ? t(buttonLabel) : t("add_activity")}
          </>
        )}
      </button>
      {validTemplateId ? (
        <AddActivityPopup modalState={popupState} {...props} />
      ) : null}
    </>
  );
};

export default AddActivityBtn;
