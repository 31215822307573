import { ApolloError, useMutation } from "@apollo/client";
import { OverlayContainer } from "@react-aria/overlays";
import type { OverlayTriggerState } from "@react-stately/overlays";
import { useOverlayTriggerState } from "@react-stately/overlays";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import ModalDialog from "components/molecules/Modal";
import UserForm, { UserFields } from "components/molecules/admin/UserForm";
import {
  BilingualAbbreviationAcronymLookupTextInput,
  CreateAuthorizationUserDocument,
} from "generated/gql-types";
import * as React from "react";
import { SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import SafeRenderHtml from "components/atoms/SafeRenderHtml";
import GraphqlError from "components/GraphqlError";
import { useGlobalAlertContext } from "features/globalAlert";

export interface AddUserPopupProps {
  program?: BilingualAbbreviationAcronymLookupTextInput;
  modalState: OverlayTriggerState;
  refetchQueries?: string[];
  onCompleted?: (data: any) => void;
  onError?: (error: ApolloError | undefined) => void;
}

const AddUserPopup: React.FC<AddUserPopupProps> = (props) => {
  const { t } = useTranslation();
  const { modalState } = props;
  const alertContext = useGlobalAlertContext();

  const [createUser, { loading, error, reset }] = useMutation(
    CreateAuthorizationUserDocument,
    {
      refetchQueries: props.refetchQueries ?? [],
      onCompleted: props.onCompleted,
      onError: props.onError,
      errorPolicy: "all",
    }
  );

  const onSubmit: SubmitHandler<UserFields> = async (formData) => {
    try {
      const res = await createUser({
        variables: {
          input: {
            adUserId: formData?.user?.id,
            displayName: formData?.user?.displayName,
            email: formData?.user?.email,
            program: formData?.program,
          },
        },
      });
      alertContext.showSuccess({
        title: t("added_new_user"),
        timeOut: 5000,
      });
      if (res.errors == null) {
        modalState.close();
      }
    } catch (e) {
      console.warn("Error adding User");
      console.error(e);
    }
  };

  const onClose = () => {
    modalState.close();
    reset();
  };

  if (!modalState.isOpen) return null;

  return (
    <OverlayContainer>
      <ModalDialog
        title={t("add_new_user")}
        isOpen
        onClose={onClose}
        showModalFooter={false}
        className="modal-dropdown-visible"
      >
        <GraphqlError errors={error} />
        {loading && <LoadingIndicator centered />}
        <SafeRenderHtml htmlString={t("user_form_heading1")} />
        <SafeRenderHtml htmlString={t("user_form_heading2")} />
        <UserForm
          intialValues={{
            user: undefined,
            program: props.program,
          }}
          onSubmit={onSubmit}
          onClose={onClose}
        />
      </ModalDialog>
    </OverlayContainer>
  );
};

export default AddUserPopup;

export const useAddUserModal = useOverlayTriggerState;
export type { UserFields as AddUserFields };
