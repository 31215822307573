import MissingData from "components/atoms/MissingData";
import SafeRenderHtml from "components/atoms/SafeRenderHtml";
import { TooltipAlert } from "components/atoms/Tooltip/TooltipAlert";
import { BptTemplateStep, Maybe } from "generated/gql-types";
import * as React from "react";
import { useTranslation } from "react-i18next";

export interface StepInformationProps {
  templateId?: Maybe<string>;
  stepInfo?: Maybe<BptTemplateStep>;
}

export const StepInformation: React.FC<StepInformationProps> = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="species-field font-size-18">
        <div className="row">
          <div className="species-data col-sm-8">
            <dl>
              {/* ------------ Description ------------ */}
              <dt className="text-muted">{t("description")}</dt>
              <dd>
                {props.stepInfo?.description == null ? (
                  <MissingData />
                ) : (
                  <SafeRenderHtml
                    htmlString={props.stepInfo?.description.text ?? ""}
                  />
                )}
              </dd>
              {/* ------------ Step owner ------------ */}
              <dt className="text-muted">{t("step_owner")}</dt>
              <dd>
                {props.stepInfo?.owner?.text == null ? (
                  <MissingData />
                ) : (
                  <SafeRenderHtml
                    htmlString={props.stepInfo?.owner.text ?? ""}
                  />
                )}
              </dd>
            </dl>
          </div>

          <div className="species-data col-sm-4">
            <dl>
              {!props.stepInfo?.hasActivities ? (
                <>
                  {/* ------- Estimated duration (No activities) ------- */}
                  <dt className="text-muted">
                    {t("estimated_duration")}
                    <TooltipAlert
                      isVisible={props.stepInfo?.estimatedDuration == null}
                    >
                      {t("value_is_required_to_calculate_durations_tooltip")}
                    </TooltipAlert>
                  </dt>
                  <dd>
                    {props.stepInfo?.estimatedDuration == null ? (
                      <MissingData />
                    ) : (
                      props.stepInfo?.estimatedDuration +
                      " " +
                      t("business_days")
                    )}
                  </dd>
                </>
              ) : (
                <>
                  {/* ------- Calculated duration (Has activities) ------- */}
                  <dt className="text-muted">
                    {t("calculated_duration")}
                    <TooltipAlert
                      isVisible={props.stepInfo?.calculatedDuration == null}
                    >
                      {t("can_not_be_calculated_tooltip")}
                    </TooltipAlert>
                  </dt>
                  <dd>
                    {props.stepInfo?.calculatedDuration == null ? (
                      <MissingData />
                    ) : (
                      props.stepInfo?.calculatedDuration +
                      " " +
                      t("business_days")
                    )}
                  </dd>
                </>
              )}

              {/* ------------ Include house of common holidays ------------ */}
              <dt className="text-muted">
                {t("include_house_of_common_holidays")}
              </dt>
              <dd>
                {props.stepInfo?.includeHouseOfCommons == null ? (
                  <MissingData />
                ) : props.stepInfo?.includeHouseOfCommons ? (
                  t("yes")
                ) : (
                  t("no")
                )}
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </>
  );
};

export default StepInformation;
