import {
  BilingualAbbreviationAcronymTagsLookupText,
  BptTemplateVisibility,
  Maybe,
  RichText,
} from "generated/gql-types";
import * as React from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import FieldValidationError from "components/atoms/forms/FieldValidationError";
import FormButtons from "components/molecules/FormButtons/FormButtons";
import { HTMLItalicsInputWithController } from "components/molecules/HTMLItalicsInput";
import ProgramAutocomplete from "components/organisms/DistributionLists/ProgramAutocomplete";
import { FullHTMLEditorWithController } from "components/organisms/FullHTMLEditor";
import { DMTS_ADMIN_PROGRAM_ACRONYM } from "config/constants";
import { processBptTemplateFormValues } from "../bptFormUtil";

import { ObjectTypeDropdownWithController } from "components/organisms/admin/ObjectTypeDropdown/ObjectTypeDropdown";

const BptTemplateForm: React.FC<BptTemplateFormProps> = (props) => {
  const defaultValues = props.intialValues;

  const form = useForm<BptTemplateFormFields>({
    defaultValues: defaultValues,
  });

  const { control, handleSubmit, formState, register } = form;
  const { isDirty, isSubmitting, errors, dirtyFields } = formState;
  const { t } = useTranslation();

  const onSubmit: SubmitHandler<BptTemplateFormFields> = (formData) => {
    const cleanedValues = processBptTemplateFormValues(
      formData,
      dirtyFields,
      defaultValues ?? {}
    );
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        props.onSubmit(cleanedValues);
        resolve();
      }, 500);
    });
  };

  return (
    <form
      onSubmit={(e) => {
        e.stopPropagation();
        handleSubmit(onSubmit)(e);
      }}
    >
      {/*---------------Program---------------------*/}
      <div className="form-group">
        <Controller
          control={control}
          name="program"
          rules={{
            required: true,
          }}
          render={({ field: { onChange, value } }) => (
            <ProgramAutocomplete
              label={t("program")}
              isRequired={true}
              onSelectionChange={onChange}
              disabled={
                props?.intialValues?.program?.acronym !==
                DMTS_ADMIN_PROGRAM_ACRONYM
              }
              initialValue={value}
            />
          )}
        />
        {errors.program && (
          <FieldValidationError>{t("field_is_required")}</FieldValidationError>
        )}
      </div>
      {/*---------------Visibility ---------------------*/}
      <div className="form-group">
        <fieldset className="chkbxrdio-grp">
          <legend>
            <span className="field-name">{t("visibility")}</span>{" "}
          </legend>
          {Object.values(BptTemplateVisibility).map((value) => {
            return (
              <label
                htmlFor={`radio-visibility-${value}`}
                className="radio-inline"
                key={value}
              >
                <input
                  type="radio"
                  value={value}
                  id={`radio-visibility-${value}`}
                  {...register("visibility")}
                />
                &#160;{t(value)}
              </label>
            );
          })}
        </fieldset>
        {errors.visibility && (
          <FieldValidationError>{t("field_is_required")}</FieldValidationError>
        )}
      </div>
      {/* =============== Template name =============== */}
      <div className="form-group">
        <label htmlFor="nameInput" className="required">
          {t("template_name")}{" "}
          <strong className="required">({t("required")})</strong>
        </label>
        <HTMLItalicsInputWithController
          control={control}
          rules={{
            required: true,
          }}
          defaultValue={props?.intialValues?.name?.text ?? ""}
          id="nameInput"
          name="name.text"
          placeholder={`${t("template_name")}`}
          disabled={props.templateNameDisabled}
        />
        {errors.name && (errors.name as any)?.text?.type === "required" && (
          <FieldValidationError>{t("field_is_required")}</FieldValidationError>
        )}
      </div>
      {/* =============== Description =============== */}
      <div className="form-group">
        <label htmlFor="description">
          <span className="field-name">{t("description")}</span>{" "}
        </label>
        <FullHTMLEditorWithController
          control={control}
          defaultValue={props?.intialValues?.description?.text ?? ""}
          id="description"
          name="description.text"
        />
        {errors.description && (
          <FieldValidationError>{errors.description}</FieldValidationError>
        )}
      </div>
      {/*---------------objectType---------------------*/}
      <div className="form-group">
        <label htmlFor="sel-objectType">
          <span className="field-name">{t("object_type")}</span>{" "}
        </label>
        <ObjectTypeDropdownWithController
          control={control}
          name="objectType"
          id="sel-objectType"
          data-testid="sel-objectType"
          disabled={false}
        />
      </div>
      <hr className="row mrgn-tp-0 mrgn-bttm-md" />
      <FormButtons
        isDirty={isDirty}
        isSubmitting={isSubmitting}
        onCancel={props.onClose}
      />
    </form>
  );
};

export default BptTemplateForm;

export interface BptTemplateFormFields {
  program?: Maybe<BilingualAbbreviationAcronymTagsLookupText>;
  visibility?: BptTemplateVisibility;
  name?: Maybe<RichText>;
  description?: Maybe<RichText>;
  objectType?: Maybe<BilingualAbbreviationAcronymTagsLookupText>;
}

interface BptTemplateFormProps {
  intialValues: Partial<BptTemplateFormFields>;
  templateNameDisabled?: boolean;
  onSubmit: SubmitHandler<Partial<BptTemplateFormFields>>;
  onClose: () => void;
}
// TODO:TEST: ensure clicking 'submit' without modifying any fields returns an empty object.
