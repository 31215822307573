const organizationTranslations = {
  en: {
    organizations: "Organizations",
    list_of_organizations: "List of organizations",
    archive_organization_warning:
      "Are you sure you would like to Archive this organization?",
    unarchive_organization_warning:
      "Are you sure you would like to Unarchive this organization?",
    archive_an_organization: "Archive an organization",
    unarchive_an_organization: "Unarchive an organization",
    enter_organization_name:
      "Enter organization name, email, country, city, or province/territory/state/etc.",

    // Modal form
    add_new_organization: "Add new organization",
    edit_organization: "Edit organization",
    organization_name_level_1: "Organization name, level 1",
    organization_name_level_2_english: "Organization name, level 2 (English)",
    organization_name_level_3_english: "Organization name, level 3 (English)",
    organization_name_level_4_english: "Organization name, level 4 (English)",
    organization_name_level_1_french: "Organization name, level 1 (French)",
    organization_name_level_2_french: "Organization name, level 2 (French)",
    organization_name_level_3_french: "Organization name, level 3 (French)",
    organization_name_level_4_french: "Organization name, level 4 (French)",
    organization_name_level_number: "Organization name, level {{number}}",
    organization_tag: "Organization tags",
  },

  fr: {
    organizations: "Organisations",
    list_of_organizations: "Liste des organisations",
    archive_organization_warning:
      "Êtes-vous certain de vouloir archiver cette organisation ?",
    unarchive_organization_warning:
      "Êtes-vous certain de vouloir désarchiver cette organisation ?",
    archive_an_organization: "Archiver une organisation",
    unarchive_an_organization: "Désarchiver une organisation",
    enter_organization_name:
      "Saisissez le nom de l'organisation, l'adresse électronique, le pays, la ville ou la province/territoire/état/etc.",

    // Modal form
    add_new_organization: "Ajouter une nouvelle organisation",
    edit_organization: "Modifier l'organisation",
    organization_name_level_1: "Nom de l'organisation, niveau 1 ",
    organization_name_level_2_english:
      "Nom de l'organisation, niveau 2 (anglais)",
    organization_name_level_3_english:
      "Nom de l'organisation, niveau 3 (anglais)",
    organization_name_level_4_english:
      "Nom de l'organisation, niveau 4 (anglais)",
    organization_name_level_1_french:
      "Nom de l'organisation, niveau 1 (français)",
    organization_name_level_2_french:
      "Nom de l'organisation, niveau 2 (français)",
    organization_name_level_3_french:
      "Nom de l'organisation, niveau 3 (français)",
    organization_name_level_4_french:
      "Nom de l'organisation, niveau 4 (français)",
    organization_name_level_number: "Nom de l'organisation, niveau {{number}}",
    organization_tag: "Etiquettes d'organisation",
  },
};

export default organizationTranslations;
