import { ROLE_ACTIONS, RenderWhenAuthorized } from "features/auth/components";
import RenderWhenFeatureIsEnabled from "features/featureFlags/RenderWhenFeatureIsEnabled";
import { FeatureFlagKeys } from "features/featureFlags/config";
import * as React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { makeRelativeNestedRoute } from "../../util/router";
import { ProjectsPage } from "./projects";
import { ProjectPage } from "./projects/[id]";
import ProjectPhase from "./projects/[id]/phases/[id]/ProjectPhase";

export interface BptRouterProps {}

export const BptPathHelpers = {
  Projects: "/bpt/projects",
  Project: (projectId: string) => `/bpt/project/${projectId}`,
  ProjectPhaseIndex: (id: string, phaseId: string) =>
    `/bpt/project/${id}/phases/${phaseId}`,
};

export const BptRouter: React.FC<BptRouterProps> = (props) => {
  const match = useRouteMatch();
  const relative = React.useMemo(
    () => makeRelativeNestedRoute(match.url),
    [match.url]
  );

  return (
    <Switch>
      <Route path={relative("/project/:projectId/phases/:phaseId")}>
        <RenderWhenFeatureIsEnabled
          flagKey={FeatureFlagKeys.BusinessProcessTracking}
        >
          <RenderWhenAuthorized
            authorizedRoles={ROLE_ACTIONS.bptProject.view}
            fallbackComponent={<Redirect to={"/forbidden"} />}
          >
            <ProjectPhase />
          </RenderWhenAuthorized>
        </RenderWhenFeatureIsEnabled>
      </Route>

      <Route path={relative("/project/:projectId")}>
        <RenderWhenFeatureIsEnabled
          flagKey={FeatureFlagKeys.BusinessProcessTracking}
        >
          <RenderWhenAuthorized
            authorizedRoles={ROLE_ACTIONS.bptProject.view}
            fallbackComponent={<Redirect to={"/forbidden"} />}
          >
            <ProjectPage />
          </RenderWhenAuthorized>
        </RenderWhenFeatureIsEnabled>
      </Route>

      <Route path={relative("/projects")}>
        <RenderWhenFeatureIsEnabled
          flagKey={FeatureFlagKeys.BusinessProcessTracking}
        >
          <RenderWhenAuthorized
            authorizedRoles={ROLE_ACTIONS.bptProject.view}
            fallbackComponent={<Redirect to={"/forbidden"} />}
          >
            <ProjectsPage />
          </RenderWhenAuthorized>
        </RenderWhenFeatureIsEnabled>
      </Route>
    </Switch>
  );
};

export default BptRouter;
