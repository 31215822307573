import { useMutation, useQuery } from "@apollo/client";
import GraphqlError from "components/GraphqlError";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import AffectedWSCard from "components/molecules/permit/AffectedWSCard";
import PDInfoCard from "components/molecules/permit/PDInfoCard";
import PermitIssuersCard from "components/molecules/permit/PermitIssuersCard";
import PermitSideNav from "components/molecules/permit/PermitSideNav";
import { GlobalAlert, useGlobalAlertContext } from "features/globalAlert";
import {
  DeletePermitDescriptionDocument,
  PermitDescriptionDocument,
  PermitDescriptionStage,
  PermitDescriptionState,
  UpdatePermitDescriptionStageDocument,
  UpdatePermitDescriptionStateDocument,
} from "generated/gql-types";

import Alert, { AlertTypes } from "components/atoms/Alert";
import ConfirmationModal, {
  useConfirmationModal,
} from "components/organisms/ConfirmationModal";
import FinalizeButtonAndModal from "components/organisms/FinalizeButtonAndModal/FinalizeButtonAndModal";
import MakePublishedButtonAndModal from "components/organisms/MakePublishedButtonAndModal/MakePublishedButtonAndModal";
import UnPublishButtonAndModal from "components/organisms/UnPublishButtonAndModal/UnPublishButtonAndModal";
import { ROLE_ACTIONS, RenderWhenAuthorized } from "features/auth/components";
import i18n from "i18n";
import {
  bilingualRichTextForLanguage,
  bilingualRichTextObjectForLanguage,
} from "mappers";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";
import MissingData from "../../../components/atoms/MissingData";
import SafeRenderHtml from "../../../components/atoms/SafeRenderHtml";
import Layout from "../../../components/layouts/TwoColumn";
import { PermitPathHelpers } from "../PermitRouter";

const PermitProfilePage: React.FC = (props) => {
  const { t } = useTranslation();
  const { params } = useRouteMatch();
  const { permitId } = params as any;
  const history = useHistory();
  const alertContext = useGlobalAlertContext();

  const [deletePermitDescription] = useMutation(
    DeletePermitDescriptionDocument,
    {
      refetchQueries: ["PermitDescription"],
      errorPolicy: "all",
    }
  );

  const { loading, error, data } = useQuery(PermitDescriptionDocument, {
    errorPolicy: "all",
    variables: {
      permitDescriptionId: permitId,
    },
  });

  const [updatePermitDescriptionStage, updatePermitStageMutationStatus] =
    useMutation(UpdatePermitDescriptionStageDocument, {
      refetchQueries: ["PermitDescription"],
      errorPolicy: "all",
    });

  const canFinalize = React.useMemo(() => {
    if (loading) return false;

    if (
      data?.permitDescription?.stage === PermitDescriptionStage.Draft &&
      data?.permitDescription?.permitIdDetails &&
      data?.permitDescription?.permitIdDetails.length > 0 &&
      data?.permitDescription?.type &&
      data?.permitDescription?.explanationFor &&
      data?.permitDescription?.permitDates?.fromDate &&
      data?.permitDescription?.permitDates?.toDate &&
      data?.permitDescription?.purposes &&
      data?.permitDescription?.purposes.length > 0 &&
      data?.permitDescription?.description?.english?.plainText &&
      data?.permitDescription?.description?.french?.plainText &&
      data?.permitDescription?.preConditions?.english?.plainText &&
      data?.permitDescription?.preConditions?.french?.plainText &&
      data?.permitDescription?.authoritiesUsed &&
      data?.permitDescription?.authoritiesUsed.length > 0 &&
      data?.permitDescription?.locationOfActivities &&
      data?.permitDescription?.locationOfActivities.length > 0 &&
      data?.permitDescription?.affectedSpecies &&
      data?.permitDescription?.affectedSpecies.length > 0
    ) {
      return true;
    }
    return false;
  }, [
    loading,
    data?.permitDescription?.stage,
    data?.permitDescription?.permitIdDetails,
    data?.permitDescription?.type,
    data?.permitDescription?.explanationFor,
    data?.permitDescription?.permitDates,
    data?.permitDescription?.purposes,
    data?.permitDescription?.description,
    data?.permitDescription?.preConditions,
    data?.permitDescription?.authoritiesUsed,
    data?.permitDescription?.locationOfActivities,
    data?.permitDescription?.affectedSpecies,
  ]);

  const showFinalize = React.useMemo(() => {
    if (loading) return false;

    if (data?.permitDescription?.stage === PermitDescriptionStage.Draft) {
      return true;
    }
    return false;
  }, [loading, data?.permitDescription?.stage]);

  const canPublish = React.useMemo(() => {
    if (loading) return false;

    if (
      data?.permitDescription?.stage === PermitDescriptionStage.Final &&
      data?.permitDescription?.state === PermitDescriptionState.NotPublished
    ) {
      return true;
    }
    return false;
  }, [loading, data?.permitDescription?.stage, data?.permitDescription?.state]);

  const canUnPublish = React.useMemo(() => {
    if (loading) return false;

    if (data?.permitDescription?.state === PermitDescriptionState.Published) {
      return true;
    }
    return false;
  }, [loading, data?.permitDescription?.state]);

  const canDelete = React.useMemo(() => {
    if (loading) return false;

    if (
      data?.permitDescription?.state === PermitDescriptionState.NotPublished
    ) {
      return true;
    }
    return false;
  }, [loading, data?.permitDescription?.state]);

  const onFinalize = async () => {
    try {
      const res = await updatePermitDescriptionStage({
        variables: {
          updatePermitDescriptionStageId: permitId,
          stage: PermitDescriptionStage.Final,
        },
      });
      if (res.errors) throw res.errors;
    } catch (e) {
      console.error(e);
    }
  };

  const onPublish = async () => {
    try {
      const res = await updatePermitDescriptionState({
        variables: {
          updatePermitDescriptionStateId: permitId,
          state: PermitDescriptionState.Published,
        },
      });
      if (res.errors) throw res.errors;
    } catch (e) {
      console.error(e);
    }
  };

  const onUnPublish = async () => {
    try {
      const res = await updatePermitDescriptionState({
        variables: {
          updatePermitDescriptionStateId: permitId,
          state: PermitDescriptionState.NotPublished,
        },
      });
      if (res.errors) throw res.errors;
    } catch (e) {
      console.error(e);
    }
  };

  const onDelete = async (permitId: string) => {
    try {
      const res = await deletePermitDescription({
        variables: {
          id: permitId,
        },
      });

      if (res.errors) throw res.errors;

      alertContext.showSuccess({
        title: t("permit_description_successfully_deleted"),
        message:
          bilingualRichTextObjectForLanguage(
            i18n.language,
            data?.permitDescription?.title
          )?.plainText ?? "",
        timeOut: 5000,
      });

      history.push({
        pathname: PermitPathHelpers.PermitBrowse,
      });
    } catch (e) {
      console.error(e);
    }
  };

  const [updatePermitDescriptionState, updatePermitStateMutationStatus] =
    useMutation(UpdatePermitDescriptionStateDocument, {
      refetchQueries: ["PermitDescription"],
      errorPolicy: "all",
    });

  return (
    <>
      <Layout.Root>
        <Layout.Content>
          <GlobalAlert />
          {loading ? (
            <LoadingIndicator centered className="mrgn-tp-xl" />
          ) : error ? (
            <GraphqlError
              title="Error fetching Permit description profile data"
              errors={error}
            />
          ) : (
            <>
              <h1>
                <SafeRenderHtml
                  htmlString={bilingualRichTextForLanguage(
                    i18n.language,
                    data?.permitDescription?.title
                  )}
                />
              </h1>
              <GraphqlError
                title="Error fetching Permit description profile data"
                errors={error}
              />
              <GraphqlError
                title="Error setting Permit Stage"
                errors={updatePermitStageMutationStatus.error}
              />

              <GraphqlError
                title="Error setting Permit State"
                errors={updatePermitStateMutationStatus.error}
              />

              <div className="flex justify-between align-center mrgn-bttm-md">
                <div className="flex font-size-18">
                  <div>
                    {t("stage")}{" "}
                    <div className="label label-info">
                      {data?.permitDescription?.stage ? (
                        t(data?.permitDescription?.stage)
                      ) : (
                        <MissingData />
                      )}
                    </div>
                  </div>
                  <div className="mrgn-lft-md">
                    {t("state")}{" "}
                    <div className="label label-info">
                      {data?.permitDescription?.state ? (
                        t(data?.permitDescription?.state)
                      ) : (
                        <MissingData />
                      )}
                    </div>
                  </div>
                </div>
                {data?.permitDescription?.requiresValidation && (
                  <div>
                    <RenderWhenAuthorized
                      authorizedRoles={ROLE_ACTIONS.permit.publish}
                    >
                      {canPublish ? (
                        <MakePublishedButtonAndModal
                          canMakePublished={true}
                          onMakePublished={onPublish}
                          warningMessage={t(
                            "publish_permit_to_registry_warning"
                          )}
                          buttonText="publish"
                          ConfirmationMessage="confirm"
                        />
                      ) : null}
                      {canUnPublish ? (
                        <UnPublishButtonAndModal
                          canUnPublish={true}
                          onUnPublish={onUnPublish}
                          warningMessage={t(
                            "unpublish_permit_from_registry_warning"
                          )}
                          buttonText="unpublish"
                          ConfirmationMessage="confirm"
                        />
                      ) : null}
                    </RenderWhenAuthorized>

                    <RenderWhenAuthorized
                      authorizedRoles={ROLE_ACTIONS.permit.finalize}
                    >
                      {showFinalize ? (
                        <FinalizeButtonAndModal
                          canFinalize={canFinalize}
                          onFinalize={onFinalize}
                          warningMessage={t(
                            "finalize_permit_to_registry_warning"
                          )}
                          buttonText="finalize"
                          ConfirmationMessage="finalize_permit_to_registry_confirmation"
                        />
                      ) : null}
                    </RenderWhenAuthorized>

                    <RenderWhenAuthorized
                      authorizedRoles={ROLE_ACTIONS.permit.delete}
                    >
                      {canDelete ? (
                        <DeleteButtonAndModal
                          onDelete={() => onDelete(permitId)}
                        ></DeleteButtonAndModal>
                      ) : null}
                    </RenderWhenAuthorized>
                  </div>
                )}
              </div>
              <PDInfoCard
                permitDescription={data?.permitDescription}
              ></PDInfoCard>
              <AffectedWSCard
                pdId={data?.permitDescription?.id}
                affectedSpecies={data?.permitDescription?.affectedSpecies}
              ></AffectedWSCard>
            </>
          )}
        </Layout.Content>
        <Layout.Sidebar>
          <PermitSideNav />
        </Layout.Sidebar>
      </Layout.Root>
    </>
  );
};

const DeleteButtonAndModal: React.FC<{
  onDelete: () => void;
}> = (props) => {
  const { t } = useTranslation();
  const confirmDeleteModal = useConfirmationModal({});
  const [confirmDeleteState, setConfirmDeleteState] =
    React.useState<boolean>(false);

  return (
    <>
      <button
        type="button"
        className="btn btn-danger btn-sm"
        onClick={confirmDeleteModal.open}
        data-testid="button-delete"
      >
        {t("delete")}
      </button>

      <ConfirmationModal
        modalState={confirmDeleteModal}
        title={t("delete_a_permit")}
        confirmBtnEnabled={confirmDeleteState}
        onConfirm={props.onDelete}
        onCancel={() => {
          confirmDeleteModal.close();
          setConfirmDeleteState(false);
        }}
      >
        <Alert type={AlertTypes.WARNING} content={t("delete_permit_warning")} />

        <label>
          <input
            type="checkbox"
            checked={confirmDeleteState}
            onChange={(e) => setConfirmDeleteState((x) => !x)}
            data-testid="modal-checkbox-confirm-delete"
          />{" "}
          {t("delete_confirm")}
        </label>
      </ConfirmationModal>
    </>
  );
};

export default PermitProfilePage;
