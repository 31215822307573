import { OverlayContainer } from "@react-aria/overlays";
import {
  OverlayTriggerState,
  useOverlayTriggerState,
} from "@react-stately/overlays";
import Alert, { AlertTypes } from "components/atoms/Alert";
import ModalDialog from "components/molecules/Modal";
import * as React from "react";
import { useTranslation } from "react-i18next";

export interface GoToNewVersionModalProps {
  title: string;
  successMessage: string;
  modalState: OverlayTriggerState;
  onNavigate: () => void;
}

const GoToNewVersionModal: React.FC<GoToNewVersionModalProps> = (props) => {
  const { t } = useTranslation();
  const { modalState } = props;

  const onNavigate = () => {
    props.onNavigate();
    modalState.close();
  };

  const onCloseDialog = () => {
    modalState.close();
  };

  if (!modalState.isOpen) return null;

  return (
    <OverlayContainer>
      <ModalDialog
        title={props.title}
        isOpen
        onClose={onCloseDialog}
        isDismissable
        showModalFooter={false}
      >
        <Alert type={AlertTypes.SUCCESS} content={props.successMessage} />
        <button
          type="button"
          className="btn btn-sm btn-primary pull-left mrgn-tp-md"
          data-testid="modal-button-goto-new-version"
          onClick={onNavigate}
        >
          {t("Go_To_New_Version")}
        </button>
        <button
          type="button"
          className="btn btn-sm btn-default mrgn-tp-md mrgn-lft-10"
          data-testid="modal-button-close"
          onClick={onCloseDialog}
        >
          {t("close")}
        </button>
      </ModalDialog>
    </OverlayContainer>
  );
};

export const goToNewVersionState = useOverlayTriggerState;

export default GoToNewVersionModal;
