import cc from "classcat";
import MissingData from "components/atoms/MissingData";
import { Scalars } from "generated/gql-types";
import { Maybe } from "graphql/jsutils/Maybe";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { formatTimestamp } from "util/formatTimestamp";
import isNullOrEmpty from "util/isNullOrEmpty";

export interface CreatedUpdatedInfoProps {
  createdBy?: Maybe<Scalars["String"]>;
  createdDate?: Maybe<Scalars["String"]>;
  updatedBy?: Maybe<Scalars["String"]>;
  updatedDate?: Maybe<Scalars["String"]>;
  version?: Maybe<number>;
  className?: string;
}

export const CreatedUpdatedInfo: React.FC<CreatedUpdatedInfoProps> = (
  props
) => {
  const { t } = useTranslation();
  const postmanStyle = props.createdBy?.startsWith("PostmanRuntime")
    ? { maxWidth: 450 }
    : {};

  const divider = <span className="px-2_5">{"|"}</span>;
  const createdByDiv = props.createdBy !== undefined && (
    <div style={postmanStyle}>
      {t("created_by")}
      {": "}
      {!isNullOrEmpty(props.createdBy) ? props.createdBy : <MissingData />}
    </div>
  );
  const createdDateDiv = props.createdDate !== undefined && (
    <div>
      {t("created_date")}
      {": "}
      {formatTimestamp(props.createdDate) ?? <MissingData />}
    </div>
  );
  const updatedByDiv = props.updatedBy !== undefined && (
    <div>
      {t("updated_by")}
      {": "}
      {!isNullOrEmpty(props?.updatedBy) ? props?.updatedBy : <MissingData />}
    </div>
  );
  const updatedDateDiv = props.updatedDate !== undefined && (
    <div>
      {t("updated_date")}
      {": "}
      {formatTimestamp(props?.updatedDate) ?? <MissingData />}
    </div>
  );
  const versionDiv = props.version !== undefined && (
    <div>
      {t("version")}
      {": "}
      {props?.version != null ? props?.version : <MissingData />}
    </div>
  );

  const list = [
    createdByDiv,
    createdDateDiv,
    versionDiv,
    updatedByDiv,
    updatedDateDiv,
  ].filter((x) => x !== false);

  return (
    <div
      className={cc([
        "flex flex-wrap font-size-16 text-muted",
        props.className,
      ])}
    >
      {list.map((item, index) => (
        <React.Fragment key={index}>
          {!!index && divider}
          {item}
        </React.Fragment>
      ))}
    </div>
  );
};

export default CreatedUpdatedInfo;
