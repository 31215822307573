const searchTranslation = {
  en: {
    // Basic search
    wildlife_species_search: "Wildlife species search",
    cosewic_search: "COSEWIC search",
    listing_search: "Listing search",
    listing_lands_search: "Listing lands search",

    keyword_search: "Keyword search",
    enter_a_keyword: "Enter a keyword",
    wildlife_species_search_results: "Wildlife species search results",
    cosewic_search_results: "COSEWIC search results",
    listing_search_results: "Listing search results",
    change_log_search_results: "Change log search results",
    lands_search_results: "Lands search results",
    search_results: "Search results",
    file_search_results: "File search results",
    search_filters: "Search filters",
    cosewic_filters: "COSEWIC filters",
    listing_filters: "Listing filters",
    land_filters: "Land filters",
    land_province_territory: "Land province/ territory",

    show_all: "Show all",
    hide_all: "Hide all",
    reset_all: "Reset all",
    apply_filters: "Apply filters",

    cosewic_ws_id_stage: "COSEWIC WS ID stage",
    cosewic_ws_id_state: "COSEWIC WS ID state",
    cosewic_assessment_stage: "COSEWIC assessment stage",
    common_name_with_population: "Common name with population",

    active_status: "Active status",
    active_status_info_message:
      "Other means wildlife species for which the last COSEWIC assessment status was non-active, or whose assessments and reports were always Deferred/Rejected/Withdrawn.",
    active_status_info: "Active status info",
    active: "Active",
    inactive: "Inactive",

    info_header:
      "You can use symbols or words to make your search more precise.",
    use_and: "Using plus sign symbol +",
    and_msg1: "Narrow your results with the + symbol between keywords.",
    and_msg2:
      "Tell the database that ALL search terms must be present in the resulting records.",
    and_msg3: "Example: large + wildlife + bear.",
    use_or: "Using vertical line symbol |",
    or_msg1:
      "Connect two or more similar concepts (synonyms) with the | symbol.",
    or_msg2:
      "Broaden your results by telling the database that ANY of your search terms can be present in the resulting records.",
    or_msg3: "Example: large | bear | northern",
    use_not: "Using hyphen symbol -",
    not_msg1:
      "Exclude words from your search with the - symbol without a space between the symbol and the word.",
    not_msg2:
      "Narrow your search by telling the database to ignore concepts that may be implied by your search terms.",
    not_msg3: "Example: brown -sheep",
    use_trunc: "Using truncation symbol *",
    trunc_msg1:
      "To use truncation, enter the root of a word and put the * symbol at the end.",
    trunc_msg2:
      "The database will return results that include any ending of that root word.",
    trunc_msg3:
      "Examples: child* = child, childs, children, childrens, childhood.",
    use_quote: 'Using quotation symbol "',
    quote_msg1: "To use phrase search, enter the word or phrase in quotations.",
    quote_msg2:
      "The database will return results that include the exact match of the word or phrase that is in between quotations.",
    quote_msg3: 'Examples: "Black bear" = Black bear, Big black bear.',
    use_brackets: "Using parentheses symbol ()",
    brackets_msg1:
      "To use complex search, enter words with operators to create a query and wrap brackets.",
    brackets_msg2:
      "The database will return results and process the formula that is placed in brackets first.",
    brackets_msg3:
      "Examples: Large + (Black -bear) = Large Black Bird, Large Black Fish.",
    use_contain_operator: "Use contain operator symbols /.*",
    contain_operator_msg1:
      "To use the contain search, enter the word between a group of symbols. The symbols include a forward slash, period, and asterix at the beginning followed by a period, asterix, and forward slash at the end.",
    contain_operator_msg2:
      "The database will return results that include the word anywhere in the common name, population or scientific name.",
    contain_operator_msg3:
      "Examples: /.*Kiy.*/, /.*central.*/ = Atlantic Walrus Central population, Lake Ontario Kiyi",

    // Query tool
    query_tool: "Query tool",
    your_saved_queries: "Your saved queries",
    shared: "Shared",
    date_created: "Date created",
    date_updated: "Date updated",
    could_not_find_results: "Sorry, we couldn't find any results.",
    create_new_query: "Create a new query to get started.",

    create_query: "Create query",
    settings: "Settings",
    set_conditions: "Set conditions",
    select_columns_for_output: "Select columns for output",

    name_in_english: "Name (English)",
    name_in_french: "Name (French)",
    sharing_settings: "Sharing settings",
    private: "Private",
    global: "Global",
    share_with_user_or_group: "Share with a user or user group",
    enter_user_name_group_name_or_email:
      "Enter a user name, group name or email address",
    set_fixed_property: "Set fixed property",

    select_program: "Select program",
    property: "Property",
    action: "Action",
    detail: "Detail",
    combinator: "Combinator",
    add_condition: "Add condition",
    add_group: "Add group",
    remove_condition: "Remove condition",
    remove_group: "Remove group",
    drag_handle: "Drag handle",
    and: "AND",
    or: "OR",

    cosewic_common_name_with_population_en:
      "COSEWIC common name with population (English)",
    cosewic_common_name_with_population_fr:
      "COSEWIC common name with population (French)",
    listing_common_name_with_population_en:
      "Listing common name with population (English)",
    listing_common_name_with_population_fr:
      "Listing common name with population (French)",
    full_export: "Full export",

    // Export
    cosewic_english_common_name: "COSEWIC common name (English)",
    cosewic_french_common_name: "COSEWIC common name (French)",
    cosewic_population_english: "COSEWIC population (English)",
    cosewic_population_french: "COSEWIC population (French)",
    listing_English_common_name: "Listing common name (English)",
    listing_French_common_name: "Listing Common Name (French)",
    listing_population_english: "Listing population (English) ",
    listing_population_french: "Listing population (French)",

    // Changelog basic search:
    change_log_search: "Change log search",
    change_log_filters: "Change log filters",
    change_type: "Change log type",
    type_of_property_change: "Type of property change",
    type_of_operation_change: "Type of operation change",
    OPERATION: "Operation",
    CHANGE: "Property",
    DATE_OF_ADDITION_ON_SCHEDULE1_UPDATED:
      "Date of addition on Schedule 1 updated",
    MANUAL_LISTING_PROCESS_ADDED: "Manual listing process added",
    PUBLISHED_TO_REGISTRY: "Published to Registry",
    select_a_program: "Select a program",
    user: "User",
    PARENT_ADDED: "Parent added",
    MERGE: "Merge",
    SPLIT: "Split",
    PROFILE_SPECIES_DETAILS_PUBLISHED_TO_REGISTRY:
      "Wildlife species information published to Registry",
    PROFILE_SPECIES_DETAILS_UNPUBLISHED_FROM_REGISTRY:
      "Wildlife species information unpublished from Registry",

    // Species By Land Search:
    listing_wildlife_species: "Listing wildlife species",
    listing_wildlife_species_located_in:
      "Listing wildlife species located in {{landName}}",

    // Assessment search:
    assessment_search: "Assessment search",
    assessment_search_results: "Assessment search results",

    // Cosewic search:
    cosewic_status_on_the_registry: "COSEWIC WS status",
    latest_assessment_decision: "Latest assessment decision",
    historical_assessment_decision: "Historical assessment decision",

    // Explanations of wildlife species search, cosewic search and listing search
    wildlife_species_search_explanation:
      "Search all species covered under COSEWIC program and/or Listing program.",
    cosewic_search_explanation:
      "Search all species covered under the COSEWIC program.",
    listing_search_explanation:
      "Search all species covered under the Listing program.",
  },

  fr: {
    // Basic search
    wildlife_species_search: "Recherche d'espèces sauvages",
    cosewic_search: "Recherchez COSEPAC",
    listing_search: "Recherchez le programme de l'Inscription",
    listing_lands_search: "Recherchez les terres du programme de l'Inscription",

    keyword_search: "Recherche par mots-clés",
    enter_a_keyword: "Entrez un mot-clé",
    wildlife_species_search_results:
      "Résultats de la recherche d'espèces sauvages",
    cosewic_search_results: "Résultats de la recherche du COSEPAC",
    listing_search_results:
      "Résultats de la recherche du programme de l'Inscription",
    change_log_search_results:
      "Résultats de la recherche dans le journal des modifications.",
    lands_search_results: "Résultats de la recherche de terres",
    search_results: "Rechercher les résultats",
    file_search_results: "Résultats de recherche de fichiers",
    search_filters: "Filtres de recherche",
    cosewic_filters: "Filtres du COSEPAC",
    listing_filters: "Filtres du programme de l'Inscription",
    land_filters: "Filtres des terres",
    land_province_territory: "Province/ territoire où se trouve la terre",

    show_all: "Afficher tout",
    hide_all: "Masquer tout",
    reset_all: "Réinitialiser tout",
    apply_filters: "Appliquer les filtres",

    cosewic_ws_id_stage: "Stade de l'ID de l'espèce sauvage du COSEPAC",
    cosewic_ws_id_state: "État de l'ID de l'espèce sauvage du COSEPAC",
    cosewic_assessment_stage: "Stade de l'évaluation du COSEPAC",
    common_name_with_population: "Nom commun avec population",

    active_status: "Statut actif",
    active_status_info_message:
      "Autre signifie les espèces sauvages pour lesquelles le dernier statut d'évaluation du COSEPAC est non actif ou toutes les évaluations et les rapports ont été Différé/ rejeté/ retiré.",
    active_status_info: "Informations sur le Statut actif",
    active: "Actif",
    inactive: "Inactif",

    info_header:
      "Vous pouvez utiliser des symboles ou des mots pour préciser votre recherche.",
    use_and: "Utilisation du symbole de l'addition +",
    and_msg1:
      "Affinez vos résultats en utilisant le symbole + entre les mots-clés.",
    and_msg2:
      "Indiquez à la base de données que TOUS les termes recherchés doivent être présents dans les enregistrements obtenus.",
    and_msg3: "Exemple : grand + espèce sauvage + ours.",
    use_or: "Utilisation du symbole de la ligne verticale |",
    or_msg1:
      "Relier deux ou plusieurs concepts similaires (synonymes) avec le symbole |.",
    or_msg2:
      "Élargissez vos résultats en indiquant à la base de données que N'IMPORTE LEQUEL de vos termes de recherche peut être présent dans les résultats.",
    or_msg3: "Exemple : grand | ours | nord",
    use_not: "Utilisation du symbole du trait d'union -",
    not_msg1:
      "Exclure les mots de votre recherche avec le symbole - sans espace entre le symbole et le mot.",
    not_msg2:
      "Limitez votre recherche en indiquant à la base de données d'ignorer les concepts qui peuvent être implicites dans vos termes de recherche.",
    not_msg3: "Exemple : brun -mouton",
    use_trunc: "Utilisation du symbole de troncature *",
    trunc_msg1:
      "Pour utiliser la troncature, saisissez la racine d'un mot, puis insérez le symbole * à la fin.",
    trunc_msg2:
      "La base de données renverra les résultats comprenant toutes les terminaisons de ce mot racine.",
    trunc_msg3:
      "Exemples : terr* = terrain, terrains, terre, terres, terrestre, terrestres, territoire, territoires, territorial, territoriale, territoriales, territoriaux.",
    use_quote: 'Utilisation du symbole du guillemet "',
    quote_msg1:
      "Pour utiliser la recherche par expression, saisissez le mot ou l'expression entre guillemets.",
    quote_msg2:
      "La base de données retournera des résultats comprenant la correspondance exacte du mot ou de la phrase qui se trouve entre guillemets.",
    quote_msg3: 'Exemples : "Ours noir" = ours noir, grand ours noir.',
    use_brackets: "Utilisation du symbole des parenthèses ()",
    brackets_msg1:
      "Pour utiliser la recherche complexe, saisissez des mots avec des opérateurs pour créer une requête et mettez-les entre parenthèses.",
    brackets_msg2:
      "La base de données renverra les résultats et traitera la formule qui est placée entre parenthèses en premier.",
    brackets_msg3:
      "Exemples : grand + (noir -ours) = grand oiseau noir, grand poisson noir.",
    use_contain_operator: "Utilisation des syboles d'opérateur de contenu  /.*",
    contain_operator_msg1:
      "Pour utiliser la recherche par contenu, encadrez le mot entre un groupe de symboles. Les symboles comprennent une barre oblique, un point et un astérisque au début, suivis d'un point, d'un astérisque et d'une barre oblique à la fin.",
    contain_operator_msg2:
      "La base de données retournera les résultats qui incluent le mot n'importe où dans le nom commun, le nom de la population ou le nom scientifique.",
    contain_operator_msg3:
      "Exemples : /.*Kiy.*/, /.*central.*/ = morse de l'Atlantique, population centrale, lac Ontario Kiyi",

    // Query tool
    query_tool: "Outil de recherche",
    your_saved_queries: "Vos requêtes enregistrées",
    shared: "Partagé",
    date_created: "Date de création",
    date_updated: "Date de mise à jour",
    could_not_find_results: "Désolé, nous n'avons pas trouver de résultats.",
    create_new_query: "Créez une nouvelle requête pour commencer.",

    create_query: "Créez une requête",
    settings: "Paramètres",
    set_conditions: "Définir les conditions",
    select_columns_for_output: "Sélectionnez les colonnes de résultat",

    name_in_english: "Nom (anglais)",
    name_in_french: "Nom (français)",
    sharing_settings: "Paramètres de partage",
    private: "Privé",
    global: "Global",
    share_with_user_or_group:
      "Partager avec un utilisateur ou un groupe d'utilisateurs",
    enter_user_name_group_name_or_email:
      "Saisissez un nom d'utilisateur, un nom de groupe ou un courriel",
    set_fixed_property: "Définir la propriété fixe",

    select_program: "Sélectionner le programme",
    property: "Propriété",
    action: "Action",
    detail: "Détail",
    combinator: "Combinateur",
    add_condition: "Ajouter une condition",
    add_group: "Ajouter un groupe",
    remove_condition: "Supprimer la condition",
    remove_group: "Supprimer le groupe",
    drag_handle: "Glisser-déposer",
    and: "ET",
    or: "OU",

    cosewic_common_name_with_population_en:
      "Nom commun du COSEPAC avec population (anglais)",
    cosewic_common_name_with_population_fr:
      "Nom commun du COSEPAC avec population (français)",
    listing_common_name_with_population_en:
      "Nom commun du programme de l'Inscription avec population (anglais)",
    listing_common_name_with_population_fr:
      "Nom commun du programme de l’Inscription avec population (français)",
    full_export: "Exportation complète",

    // Export
    cosewic_english_common_name: "Nom commun du COSEPAC (anglais)",
    cosewic_french_common_name: "Nom commun du COSEPAC (français)",
    cosewic_population_english: "Population du COSEPAC (anglais)",
    cosewic_population_french: "Population du COSEPAC (français)",
    listing_English_common_name:
      "Nom commun du programme de l'Inscription (anglais)",
    listing_French_common_name: "Nom commun de l'inscription (français)",
    listing_population_english:
      "Population du programme de l'Inscription (anglais)",
    listing_population_french:
      "Population du programme de l'Inscription (français)",
    change_log_search: "Recherche dans le journal des modifications",
    change_log_filters: "Filtre du journal des modifications",

    // Changelog basic search:
    // Changelog basic search:
    change_type: "Type de changement",
    type_of_property_change: "Type de changement de propriété",
    type_of_operation_change: "Type de changement d'operation",
    OPERATION: "Opération",
    CHANGE: "Propriété",
    DATE_OF_ADDITION_ON_SCHEDULE1_UPDATED:
      "Date de l'ajout à l'Annexe 1 mise à jour",
    MANUAL_LISTING_PROCESS_ADDED:
      "Ajout d'un processus du programme de l'Inscription manuel",
    PUBLISHED_TO_REGISTRY: "Publié sur le Registre",
    select_a_program: "Sélectionnez un programme",
    user: "Utilisateur",
    PARENT_ADDED: "Parent ajouté",
    MERGE: "Fusion",
    SPLIT: "Division",
    PROFILE_SPECIES_DETAILS_PUBLISHED_TO_REGISTRY:
      "Informations de l'espéce sauvage publié sur le Registre",
    PROFILE_SPECIES_DETAILS_UNPUBLISHED_FROM_REGISTRY:
      "Informations de l'espéce sauvage dépublié du Registre",

    // Species By Land Search:
    listing_wildlife_species: "Espèces sauvages du programme de l'Inscription",
    listing_wildlife_species_located_in:
      "Espèces sauvages du programme de l'Inscription se trouve sur {{landName}}",

    // Assessment search:
    assessment_search: "Recherche d'évaluation",
    assessment_search_results: "Résultats de la recherche d'évaluation",

    // Cosewic search:
    cosewic_status_on_the_registry: "Statut de l'ES du COSEPAC",
    latest_assessment_decision: "Dernière décision d'évaluation",
    historical_assessment_decision: "Décisions d'évaluation historiques",

    // Explanations of wildlife species search, cosewic search and listing search
    wildlife_species_search_explanation:
      "Chercher toutes les espèces qui sont dans la collection du programme du COSEPAC et ou dans le programme de l'Inscription.",
    cosewic_search_explanation:
      "Chercher toutes les espèces qui sont dans la collection du programme du COSEPAC.",
    listing_search_explanation:
      "Chercher toutes les espèces qui sont dans la collection du programme de l'Inscription.",
  },
};

export default searchTranslation;
