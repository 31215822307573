import FieldValidationError from "components/atoms/forms/FieldValidationError";
import FormButtons from "components/molecules/FormButtons/FormButtons";
import { HTMLItalicsInputWithController } from "components/molecules/HTMLItalicsInput";
import { FullHTMLEditorWithController } from "components/organisms/FullHTMLEditor";
import { RichText } from "generated/gql-types";
import { Maybe } from "graphql/jsutils/Maybe";
import * as React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { processPhaseFormValues } from "../bptFormUtil";

export interface PhaseFormFields {
  name?: Maybe<RichText>;
  description?: Maybe<RichText>;
  // estimatedDuration?: Maybe<number>;
}

export interface PhaseFormProps {
  defaultValues?: Partial<PhaseFormFields>;
  onSubmit: SubmitHandler<Partial<PhaseFormFields>>;
  onClose: () => void;
  isSaving?: boolean;
}

export const PhaseForm: React.FC<PhaseFormProps> = (props) => {
  const defaultValues = props.defaultValues;
  const { t } = useTranslation();

  const form = useForm<PhaseFormFields>({
    defaultValues: defaultValues,
    mode: "onChange",
  });
  const { handleSubmit, formState, control } = form;
  const { dirtyFields, isDirty, isSubmitting, errors } = formState;

  const onSubmit: SubmitHandler<Partial<PhaseFormFields>> = async (
    formData
  ) => {
    const cleanedValues = processPhaseFormValues(
      formData,
      dirtyFields,
      defaultValues ?? {}
    );

    return new Promise<void>((resolve) => {
      setTimeout(() => {
        props.onSubmit(cleanedValues);
        resolve();
      }, 500);
    });
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <fieldset className="pt-0" disabled={props.isSaving}>
          {/* ---------- Phase name ---------- */}
          <div className="form-group">
            <label htmlFor="nameInput" className="required">
              {t("phase_name")}
              <strong className="required"> ({"required"})</strong>
            </label>
            <HTMLItalicsInputWithController
              control={control}
              rules={{
                required: true,
              }}
              name="name.text"
              id="nameInput"
              defaultValue={defaultValues?.name?.text ?? ""}
              placeholder={t("phase_name")}
            />
            {errors.name && (errors.name as any)?.text?.type === "required" && (
              <FieldValidationError>
                {t("field_is_required")}
              </FieldValidationError>
            )}
          </div>

          {/* ---------- Phase Description ---------- */}
          <div className="form-group">
            <label htmlFor="descriptionField">{t("phase_description")}</label>
            <FullHTMLEditorWithController
              control={control}
              id="descriptionField"
              name="description.text"
              defaultValue={defaultValues?.description?.text ?? ""}
              placeholder={t("enter_your_text")}
            />
          </div>
        </fieldset>

        <hr className="row mrgn-tp-0 mrgn-bttm-md" />
        <FormButtons
          isDirty={isDirty}
          isSubmitting={isSubmitting || props.isSaving}
          onCancel={props.onClose}
          errors={errors}
        />
      </form>
    </>
  );
};

export default PhaseForm;
