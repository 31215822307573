import { ApolloError, useMutation } from "@apollo/client";
import { OverlayContainer } from "@react-aria/overlays";
import type { OverlayTriggerState } from "@react-stately/overlays";
import { useOverlayTriggerState } from "@react-stately/overlays";
import ModalDialog from "components/molecules/Modal";
import * as React from "react";
import { useTranslation } from "react-i18next";

import GraphqlError from "components/GraphqlError";
import { UpdatePhotoDocument } from "generated/gql-types";
import { SubmitHandler } from "react-hook-form";
import PhotoForm, { PhotoFormFields } from "../PhotoForm/PhotoForm";
import { mapFormToDomainModel } from "../PhotoForm/mappers";

export interface EditPhotoPopupProps {
  modalState: OverlayTriggerState;
  photoLicenseId: string;
  photoId: string;
  defaultValues: Partial<PhotoFormFields>;
  refetchQuery: () => void;
  onCompleted: (data: any) => void;
  onError: (error: ApolloError | undefined) => void;
}

const EditPhotoPopup: React.FC<EditPhotoPopupProps> = (props) => {
  const { t } = useTranslation();
  const { modalState, photoLicenseId, defaultValues, refetchQuery } = props;

  const [updatePhoto, { loading, error, reset }] = useMutation(
    UpdatePhotoDocument,
    {
      onCompleted: props.onCompleted,
      onError: props.onError,
      errorPolicy: "all",
    }
  );

  const onSubmit: SubmitHandler<Partial<PhotoFormFields>> = async (
    filteredFormData
  ) => {
    try {
      const mappedDomainModel = mapFormToDomainModel(filteredFormData);

      const res = await updatePhoto({
        variables: {
          photoLicenseId: props?.photoLicenseId,
          photoId: props?.photoId,
          input: mappedDomainModel,
        },
      });
      if (res.errors) throw res.errors;

      refetchQuery();
      modalState.close();
      reset();
    } catch (e) {
      console.error("Error editing Photo", e);
    }
  };

  const onClose = () => {
    modalState.close();
    // reset();
    refetchQuery();
  };

  if (!modalState.isOpen) return null;
  return (
    <OverlayContainer>
      <ModalDialog
        title={t("edit_photo")}
        className={"modal-width-70"}
        isOpen
        onClose={onClose}
        showModalFooter={false}
      >
        <GraphqlError title={t("update_fail")} errors={error} />
        <PhotoForm
          photoLicenseId={photoLicenseId}
          defaultValues={defaultValues}
          onClose={onClose}
          onSubmit={onSubmit}
        />
      </ModalDialog>
    </OverlayContainer>
  );
};

export default EditPhotoPopup;

export const useManagePhotoPopup = useOverlayTriggerState;
