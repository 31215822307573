import UploadCloud from "assets/svg/upload-cloud.svg";
import { getApiBptServiceUrl } from "azure/environment";
import FieldValidationError from "components/atoms/forms/FieldValidationError";
import FormButtons from "components/molecules/FormButtons/FormButtons";
import {
  DocumentAttachment,
  DocumentLanguage,
  Maybe,
} from "generated/gql-types";
import { zip } from "lodash";
import prettyBytes from "pretty-bytes";
import * as React from "react";
import { useRef, useState } from "react";
import { FileError, useDropzone } from "react-dropzone";
import { SubmitHandler, useForm } from "react-hook-form";
import { getI18n, useTranslation } from "react-i18next";
import { getUserAccessToken } from "../../../../features/auth/CustomMsalProvider";

interface AddCommunicationsFormProps {
  defaultValues?: AttachmentsFields;
  projectId: string;
  onClose: () => void;
}

enum UploadStatus {
  Success,
  Error,
}

type UploadResponse = {
  status: UploadStatus;
  message?: string;
};

const AddCommunicationsForm: React.FC<AddCommunicationsFormProps> = (props) => {
  const { t, i18n } = useTranslation();
  const form = useForm<AttachmentsFields>({
    defaultValues: props.defaultValues,
  });

  const [saveButtonText, setSaveButtonText] = useState<string>(t("save"));
  const [uploadStatuses, setUploadStatuses] = useState<
    Record<string, UploadResponse>
  >({});
  const [uploadCompleted, setUploadCompleted] = useState<boolean>(false);

  const { register, trigger, handleSubmit, formState, getValues, setValue } =
    form;
  const { isDirty, errors, isSubmitting } = formState;
  const closeBtnRef = useRef<HTMLButtonElement>(null);
  const setFileStatusComplete = (
    fileName: string,
    uploadResponse: UploadResponse
  ) => {
    setUploadStatuses((x) => ({ ...x, [fileName]: uploadResponse }));
  };

  const removeFileStatus = (fileName: string) => {
    setUploadStatuses((x) => {
      const temp = { ...x };
      delete temp[fileName];
      return temp;
    });
  };

  const onSubmit: SubmitHandler<AttachmentsFields> = async (formData) => {
    const token = await getUserAccessToken();
    const baseUrl =
      getApiBptServiceUrl("7094") + `project/${props.projectId}/communication`;
    const all = zip(files, formData.attachments) as any;

    const promises = all.map(([file, languageField]: any) => {
      if (!file) return Promise.resolve(false);
      if (!languageField) return Promise.resolve(false);
      if (uploadStatuses[file.name]?.status === UploadStatus.Success)
        return Promise.resolve(true);

      const formData = new FormData();
      formData.append("files", file);

      const url = new URL(baseUrl);
      url.searchParams.set("language", languageField.language);

      return fetch(url, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then(async (x) => {
          if (x.status >= 200 && x.status <= 299) {
            setFileStatusComplete(file.name, {
              status: UploadStatus.Success,
            });
            return true;
          } else {
            const bodyRes = await x.text();
            const bodyResObj = JSON.parse(bodyRes);
            const errorMessage =
              i18n.language === "fr"
                ? bodyResObj?.messages[0]?.frenchMessage
                : bodyResObj?.messages[0]?.englishMessage;
            throw new Error(errorMessage);
          }
        })
        .catch((e) => {
          setFileStatusComplete(file.name, {
            status: UploadStatus.Error,
            message: e.message,
          });
          return false;
        });
    });

    const statuses = await Promise.allSettled(promises);

    if (statuses.every((x) => x.status === "fulfilled" && x.value === true)) {
      setUploadCompleted(true);
    } else {
      setSaveButtonText(t("retry"));
    }
  };

  React.useEffect(() => {
    if (
      uploadCompleted === true &&
      closeBtnRef != null &&
      closeBtnRef.current != null &&
      isSubmitting === false
    ) {
      closeBtnRef.current.focus();
    }
  }, [uploadCompleted, isSubmitting]);

  const [files, setFiles] = React.useState<File[]>([]);

  const onDrop = React.useCallback((acceptedFiles, rejectedFiles) => {
    if (acceptedFiles?.length > 0) {
      setFiles((previousFiles) => [...previousFiles, ...acceptedFiles]);
      setUploadCompleted(false);
    }
    if (rejectedFiles.length > 0) {
      console.warn("Rejected files:", rejectedFiles);
    }
    console.log("onDrop!");
    trigger();
  }, []);

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    onDrop,
    validator: (file) => findMatchingFileNameIn(files, file),
  });

  const removeFile = (name: string, indexRemovedFile: number) => {
    removeFileStatus(name);
    setFiles((files) => files.filter((file) => file.name !== name));
    if (
      files.every(
        (file) =>
          uploadStatuses[file.name]?.status === UploadStatus.Success ||
          file.name === name
      )
    ) {
      setUploadCompleted(true);
    }

    // Before form reset, backup language of the remaining files.
    let remainingLanguages: (Maybe<DocumentLanguage> | undefined)[] = [];
    getValues("attachments").forEach((attachment, index) => {
      if (index !== indexRemovedFile) {
        remainingLanguages.push(attachment.language);
      }
    });
    // Form reset will re-sync the state of the Files (useState) and the Hook form.
    form.reset();
    // After form reset, restore language back to the remaining files.
    files.forEach((file, index) => {
      if (remainingLanguages[index]) {
        setValue(`attachments.${index}.language`, remainingLanguages[index], {
          shouldTouch: true,
        });
      }
    });
  };

  const renderUploadStatusIndicator = (uploadResponse: UploadResponse) => {
    if (uploadResponse == null) return null;
    switch (uploadResponse.status) {
      case UploadStatus.Success:
        return <i className="fa fa-check text-success mrgn-lft-sm" />;
      case UploadStatus.Error:
        return (
          <>
            <span className="label label-danger">{uploadResponse.message}</span>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {/* -------------- Drag and drop files -------------- */}
      <section className="mrgn-bttm-lg">
        <div {...getRootProps({ className: "dropzone" })}>
          <input
            {...getInputProps()}
            data-testid="dropzone-add-file"
            onChange={() => trigger()}
          />
          <p>
            <img className="mrgn-rght-lg" src={UploadCloud} alt="" />
            {t("drag_and_drop_some_files_here")}
          </p>
        </div>
      </section>

      {/* -------------- Form -------------- */}
      <form
        onSubmit={(e) => {
          e.stopPropagation();
          handleSubmit(onSubmit)(e);
        }}
      >
        {files.map((file, indexFile) => (
          <div key={file.name} className="well-normal">
            <div className="flex justify-between align-start mrgn-bttm-10">
              <span className="font-size-16">
                <b>{file.name}</b> (
                {prettyBytes(file.size, { locale: i18n.language })}){" "}
                {renderUploadStatusIndicator(uploadStatuses[file.name])}
              </span>
              <button
                type="button"
                title={t("remove_file")}
                className="btn btn-link btn-xs p-0 hover-grey"
                data-testid={`button-remove-file-${file.name}`}
                onClick={() => removeFile(file.name, indexFile)}
                hidden={
                  isSubmitting ||
                  uploadStatuses[file.name]?.status === UploadStatus.Success
                }
              >
                <i className="fas fa-times font-size-14 color-danger"></i>
              </button>
            </div>
            <div className="border-light mrgn-bttm-10 bg-white display-none">
              {/* -------------- Language -------------- */}
              <div className="form-group mrgn-lft-md">
                <fieldset
                  className="chkbxrdio-grp"
                  disabled={
                    isSubmitting ||
                    uploadStatuses[file.name]?.status === UploadStatus.Success
                  }
                >
                  <legend className="required">
                    <span className="field-name">{t("language")}</span>{" "}
                    <strong className="required">({t("required")})</strong>
                  </legend>

                  {Object.values(DocumentLanguage).map((lang) => {
                    return (
                      <label
                        key={`radio-attachment-language-${file.name}-${lang}`}
                        htmlFor={`radio-attachment-language-${file.name}-${lang}`}
                        className="radio-inline"
                      >
                        <input
                          type="radio"
                          value={lang}
                          id={`radio-attachment-language-${file.name}-${lang}`}
                          {...register(`attachments.${indexFile}.language`, {
                            required: true,
                            onChange: () => {
                              removeFileStatus(file.name);
                            },
                            value: DocumentLanguage.NotRequired,
                          })}
                        />
                        &#160;{t(lang)}
                      </label>
                    );
                  })}
                </fieldset>
                {errors.attachments &&
                  (errors.attachments[indexFile] as any)?.language && (
                    <FieldValidationError>
                      {t("field_is_required")}
                    </FieldValidationError>
                  )}
              </div>
            </div>
          </div>
        ))}

        {/* -------------- List of rejected files -------------- */}
        {/* ------------- (usually due to duplicate file name) -------------- */}
        {fileRejections != null && fileRejections.length > 0 ? (
          <div className="width-fit-content">
            {fileRejections.map((x, index) => (
              <div
                key={index}
                className="label label-danger mrgn-bttm-md"
                style={{ display: "block" }}
              >
                {x.file.name} {t("rejected")}{" "}
                {x.errors?.length > 0
                  ? `(${x.errors.map((e) => e.message).join(", ")})`
                  : null}
              </div>
            ))}
          </div>
        ) : null}

        {!uploadCompleted ? (
          <FormButtons
            isDirty={files && files.length > 0} // Note: not fully using React hook form.
            isSubmitting={isSubmitting}
            onCancel={props.onClose}
            errors={errors}
            submitBtnText={saveButtonText}
            submitBtnIcon="fas fa-cloud-upload-alt"
          />
        ) : (
          <button
            ref={closeBtnRef}
            type="button"
            onClick={props.onClose}
            className="btn btn-primary"
            data-testid="form-button-close"
            disabled={isSubmitting}
          >
            {t("close")}
          </button>
        )}
      </form>
    </>
  );
};

export default AddCommunicationsForm;

/////////////////////////////////////////////

export interface AttachmentsFields {
  attachments: DocumentAttachment[]; // Note: Only the language field is being used.
}

function findMatchingFileNameIn<T extends File>(
  allFiles: T[],
  file: T
): FileError | FileError[] | null {
  if (allFiles.find((x) => x.name === file.name)) {
    return {
      code: "duplicate-file-name",
      message: getI18n().t("duplicate_filename_error"),
    };
  }

  return null;
}
