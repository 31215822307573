import { CdtsMenuLink } from "@arcnovus/wet-boew-utils";
import { FeatureFlagKeys } from "features/featureFlags/config";
import { useFeatureFlag } from "features/featureFlags/useFeatureFlag";
import { BptPathHelpers } from "pages/bpt/bptRouter";
import { CommunicationsPathHelpers } from "pages/communications/CommunicationsRouter";
import { DocumentsPathHelpers } from "pages/documents/DocumentsRouter";
import { SearchPathHelpers } from "pages/search/SearchRouter";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ROLE_ACTIONS } from "../auth/roles";
import { useUserAuthorization } from "../auth/useUserAuthorization";

export const useAppTopMenuLinks = () => {
  const { t } = useTranslation();
  const { checkUserIsAuthorized } = useUserAuthorization();

  // const isQueryToolEnabled = false;
  // NOTE: when this flag doesn't exist in azure app config, this page will crash! it only happens in this file.
  const { enabled: isQueryToolEnabled } = useFeatureFlag(
    FeatureFlagKeys.QueryTool
  );

  const { enabled: isPermitDescriptionEnabled } = useFeatureFlag(
    FeatureFlagKeys.PermitDescription
  );

  const { enabled: isDocumentManagerEnabled } = useFeatureFlag(
    FeatureFlagKeys.DocumentManager
  );

  const { enabled: isRegistryPageEnabled } = useFeatureFlag(
    FeatureFlagKeys.RegistryPage
  );

  const { enabled: isDistributionListEnabled } = useFeatureFlag(
    FeatureFlagKeys.DistributionList
  );

  const { enabled: isBusinessProcessTrackingProjectEnabled } = useFeatureFlag(
    FeatureFlagKeys.BusinessProcessTrackingProject
  );

  // Note on 'any' type below: Technically we are building a CdtsMenuLink[],
  // but the code below doesn't type check with it, since some of our items
  // may be undefined. The code works fine but the type doesn't support it,
  // so we override with any, and leave the "| CdtsMenuLink[]" in-tact to
  // enable autocomplete and serve as documentation.
  const globalMenuLinks: any | CdtsMenuLink[] = useMemo(() => {
    //----------- Home ----------------
    const homeLinks = {
      href: "/",
      text: t("home"),
    };

    //----------- Program ----------------
    const programLinks = () => {
      const subLinks = [];

      if (checkUserIsAuthorized(ROLE_ACTIONS.cosewic.view)) {
        subLinks.push({
          subhref: "/cosewic",
          subtext: t("cosewic"),
        });
      }

      if (checkUserIsAuthorized(ROLE_ACTIONS.listing.view)) {
        subLinks.push({
          subhref: "/listing",
          subtext: t("listing"),
        });
      }

      if (isPermitDescriptionEnabled) {
        if (checkUserIsAuthorized(ROLE_ACTIONS.listing.view)) {
          subLinks.push({
            subhref: "/permit",
            subtext: t("permit"),
          });
        }
      }

      if (subLinks.length > 0) {
        return {
          href: "/",
          text: t("programs"),
          id: "submenu_programs",
          subLinks: subLinks,
        };
      }
    };

    //----------- Search ----------------
    const searchLinks = () => {
      if (
        checkUserIsAuthorized(
          ROLE_ACTIONS.cosewic.view && ROLE_ACTIONS.listing.view
        )
      ) {
        const subLinks = [
          {
            subhref: SearchPathHelpers.WildlifeSpecies(),
            subtext: t("wildlife_species_search"),
          },
          {
            subhref: SearchPathHelpers.Cosewic(),
            subtext: t("cosewic_search"),
          },
          {
            subhref: SearchPathHelpers.Listing(),
            subtext: t("listing_search"),
          },
        ];

        subLinks.push({
          subhref: SearchPathHelpers.Assessment(),
          subtext: t("assessment_search"),
        });

        subLinks.push({
          subhref: SearchPathHelpers.ChangeLog(),
          subtext: t("change_log_search"),
        });

        subLinks.push({
          subhref: SearchPathHelpers.ListingLands(),
          subtext: t("listing_lands_search"),
        });

        if (isQueryToolEnabled) {
          subLinks.push({
            subhref: SearchPathHelpers.QueryTool(),
            subtext: t("query_tool"),
          });
        }

        if (subLinks.length > 0) {
          return {
            href: "/search",
            text: t("search"),
            id: "submenu_search",
            subLinks: subLinks,
          };
        }
      }
    };

    //----------- Administration ----------------
    const administrationLinks = () => {
      if (
        checkUserIsAuthorized(ROLE_ACTIONS.administration.viewAllAdminPages)
      ) {
        return {
          href: "/admin",
          text: t("administration"),
          id: "submenu_administration",
        };
      }
    };

    //----------- Documents ----------------
    const documentsLinks = () => {
      if (
        checkUserIsAuthorized(
          ROLE_ACTIONS.cosewic.view && ROLE_ACTIONS.listing.view
        ) &&
        isDocumentManagerEnabled
      ) {
        const subLinks = [
          {
            subhref: DocumentsPathHelpers.DocumentsSearch,
            subtext: t("search_documents"),
          },
        ];

        if (checkUserIsAuthorized(ROLE_ACTIONS.documents.create)) {
          subLinks.push({
            subhref: DocumentsPathHelpers.DocumentsCreate,
            subtext: t("create_a_document"),
          });
        }

        if (checkUserIsAuthorized(ROLE_ACTIONS.photos.create)) {
          subLinks.push({
            subhref: DocumentsPathHelpers.PhotoLicenseCreate,
            subtext: t("create_photo_licence_document"),
          });
        }

        if (
          checkUserIsAuthorized(ROLE_ACTIONS.registry.create) &&
          isRegistryPageEnabled
        ) {
          subLinks.push({
            subhref: DocumentsPathHelpers.RegistryCreate,
            subtext: t("create_registry_page"),
          });
        }

        if (subLinks.length > 0) {
          return {
            href: "/documents",
            text: t("documents"),
            id: "submenu_documents",
            subLinks: subLinks,
          };
        }
      }
    };

    //----------- Communications ----------------
    const communicationsLinks = () => {
      const subLinks = [];

      if (
        checkUserIsAuthorized(ROLE_ACTIONS.distributionList.view) &&
        isDistributionListEnabled
      ) {
        subLinks.push({
          subhref: CommunicationsPathHelpers.DistributionLists,
          subtext: t("distribution_lists"),
        });
      }

      if (subLinks.length > 0) {
        return {
          href: "/",
          text: t("communications"),
          id: "submenu_communications",
          subLinks: subLinks,
        };
      }
    };

    //----------- Business Process Tracking ----------------
    const businessProcessTrackingLinks = () => {
      const subLinks = [];

      if (
        checkUserIsAuthorized(ROLE_ACTIONS.bptProject.view) &&
        isBusinessProcessTrackingProjectEnabled
      ) {
        subLinks.push({
          subhref: BptPathHelpers.Projects,
          subtext: t("projects"),
        });
      }

      if (subLinks.length > 0) {
        return {
          href: "/",
          text: t("business_process_tracking"),
          id: "submenu_business_process_tracking",
          subLinks: subLinks,
        };
      }
    };

    const menuLinks = [
      homeLinks,
      programLinks(),
      searchLinks(),
      documentsLinks(),
      communicationsLinks(),
      businessProcessTrackingLinks(),
      administrationLinks(),
    ].filter((x) => x != null);

    return menuLinks;
  }, [
    checkUserIsAuthorized,
    isDocumentManagerEnabled,
    isPermitDescriptionEnabled,
    isQueryToolEnabled,
    isRegistryPageEnabled,
    isDistributionListEnabled,
    isBusinessProcessTrackingProjectEnabled,
    t,
  ]);

  return globalMenuLinks;
};
