import ResetIcon from "assets/svg/reset-icon.svg";
import { DocumentPrograms } from "generated/gql-types";
import * as React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

export interface QueryFields {
  search: string;
  program?: string;
}

export const defaultQueryFields = {
  search: "",
  program: "",
};

export interface BptProjectsPageFilterFormProps {
  onSubmit: SubmitHandler<QueryFields>;
}

export const BptProjectsPageFilterForm: React.FC<
  BptProjectsPageFilterFormProps
> = (props) => {
  const { t } = useTranslation();
  const form = useForm<QueryFields>({ defaultValues: defaultQueryFields });
  const { register, handleSubmit, setFocus, watch } = form;

  const onClearSearch = () => {
    form.setValue("search", "");
    form.setValue("program", "");
    setFocus("search");
  };

  const onSubmit: SubmitHandler<QueryFields> = (newValues) => {
    const mapped = newValues;

    // Don't send a program filter if the default value (empty) is selected in the dropdown
    if (mapped.program === "") delete mapped["program"];

    props.onSubmit(mapped);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-1-1-auto">
      <div className="flex flex-1-1-auto gap-sm align-end flex-wrap">
        <div>
          <label className="" htmlFor="sel_program">
            {t("select_program")}
          </label>
          <select
            id="sel_program"
            className="form-control"
            {...register("program")}
          >
            <option value="">{t("select_a_program")}</option>
            <option value={DocumentPrograms.Cosewic}>
              {t(DocumentPrograms.Cosewic)}
            </option>
            <option value={DocumentPrograms.Listing}>
              {t(DocumentPrograms.Listing)}
            </option>
            <option value={DocumentPrograms.Permits}>
              {t(DocumentPrograms.Permits)}
            </option>
            <option value={DocumentPrograms.Protection}>
              {t(DocumentPrograms.Protection)}
            </option>
            <option value={DocumentPrograms.Recovery}>
              {t(DocumentPrograms.Recovery)}
            </option>
            <option value={"SAR Registry"}>{t("sar_registry")}</option>
            <option value={DocumentPrograms.Other}>
              {t(DocumentPrograms.Other)}
            </option>
          </select>
        </div>

        <div className="flex-grow">
          <label htmlFor="project_template_search">
            {t("enter_project_or_template_name")}
          </label>
          <div className="input-group">
            <input
              type="text"
              id="project_template_search"
              className="form-control search-box"
              placeholder={t("project_or_template_name")}
              {...register("search")}
            />
            <button
              type="button"
              aria-label="Clear search"
              className="search-box-reset"
              hidden={watch("search") === ""}
              onClick={onClearSearch}
              data-testid="button-clear"
            >
              <img src={ResetIcon} alt={t("reset")} />
            </button>
          </div>
        </div>

        <button
          type="submit"
          className="btn btn-primary flex justify-center align-center mrgn-tp-sm"
          data-testid="button-search"
        >
          <span className="glyphicon glyphicon-search mrgn-rght-sm" />
          {t("search")}
        </button>
      </div>
    </form>
  );
};

export default BptProjectsPageFilterForm;
