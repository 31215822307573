import { useMutation, useQuery } from "@apollo/client";
import GraphqlError from "components/GraphqlError";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import MissingData from "components/atoms/MissingData";
import { PhotoImage } from "components/atoms/PhotoImage/PhotoImage";
import SafeRenderHtml from "components/atoms/SafeRenderHtml";
import Layout from "components/layouts/TwoColumn";
import SideNav from "components/molecules/SideNav";
import MakePhotoPublishButtonAndModal, {
  PhotoPublishFormFields,
} from "components/organisms/MakePhotoPublishButtonAndModal/MakePhotoPublishButtonAndModal";
import PublishUnPublishButtonAndModal from "components/organisms/PublishUnPublishButtonAndModal/PublishUnPublishButtonAndModal";
import Ribbon from "components/organisms/cosewic/Ribbon";
import PhotoInformationCard from "components/organisms/documents/PhotoLicense/PhotoInformationCard/PhotoInformationCard";
import { ROLE_ACTIONS, RenderWhenAuthorized } from "features/auth/components";
import { GlobalAlert, useGlobalAlertContext } from "features/globalAlert";
import { useOverviewContext } from "features/overview";
import {
  PhotoGalleryByReferenceDocument,
  PhotoType,
  PublishState,
  RichTextInput,
  UpdatePhotoPublishDocument,
  UpdatePhotoUnPublishDocument,
} from "generated/gql-types";
import useQueryParams from "hooks/util/useQueryParams";
import * as React from "react";
import { useMemo } from "react";
import { SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { delayRefetchQuery } from "util/delayRefetchQuery";
import htmlIsNullOrEmpty from "util/htmlIsNullOrEmpty";
import htmlRemoveOuterPTag from "util/htmlRemoveOuterPTag";
import { GalleryPathHelpers } from "../galleryRouter";

const PhotoProfilePageByReference: React.FC = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const overviewContext = useOverviewContext();
  const query = useQueryParams() as any;
  const alertContext = useGlobalAlertContext();

  const overviewProgram = query.get("program");
  const overviewId = query.get("id");
  const photoId = query.get("photoId");

  const [updatePhotoPublish, { loading: loadingPublish, error: errorPublish }] =
    useMutation(UpdatePhotoPublishDocument, {
      refetchQueries: ["PhotoGalleryByReference"],
      onQueryUpdated: delayRefetchQuery,
      awaitRefetchQueries: true,
      errorPolicy: "all",
    });

  const [
    updatePhotoUnpublish,
    { loading: loadingUnpublish, error: errorUnpublish },
  ] = useMutation(UpdatePhotoUnPublishDocument, {
    refetchQueries: ["PhotoGalleryByReference"],
    onQueryUpdated: delayRefetchQuery,
    awaitRefetchQueries: true,
    errorPolicy: "all",
  });

  React.useEffect(() => {
    overviewContext.updateOverview(overviewProgram, overviewId);
  }, [overviewProgram, overviewId]);

  const { loading, error, data } = useQuery(PhotoGalleryByReferenceDocument, {
    errorPolicy: "all",
    variables: {
      referenceId: overviewId as any,
      referenceName: overviewProgram as any,
      // photoId: photoId as string,
    },
    skip: overviewId == null || overviewProgram == null || photoId == null,
    fetchPolicy: "network-only",
  });

  const photoData = useMemo(
    () => data?.photoGalleryByReference?.find((x) => x?.photo?.id === photoId),
    [data?.photoGalleryByReference, photoId]
  );

  const onPublishSubmit: SubmitHandler<
    Partial<PhotoPublishFormFields>
  > = async (formData) => {
    try {
      const res = await updatePhotoPublish({
        variables: {
          photoLicenseId: photoData?.id ?? "error",
          photoId: photoData?.photo?.id ?? "error",
          input: {
            associatedSpeciesRef: {
              cosewicRefId: photoData?.photo?.photoSpecies?.cosewicRefId,
              listingRefId: photoData?.photo?.photoSpecies?.listingRefId,
            },
            usageHistory: { text: formData?.usageHistory?.text },
          },
        },
      });
      if (res.errors) throw res.errors;

      alertContext.showSuccess({
        message: t("publish_photo_success_message"),
        timeOut: 5000,
      });

      history.push({
        pathname: GalleryPathHelpers.Gallery(),
        search: `?program=${overviewProgram}&id=${overviewId}`,
      });
    } catch (e) {
      console.error(e);
      alertContext.showError({
        message: t("publish_fail"),
        timeOut: 5000,
      });
    }
  };

  const onUnpublish = async () => {
    try {
      const res = await updatePhotoUnpublish({
        variables: {
          photoLicenseId: photoData?.id ?? "error",
          photoId: photoData?.photo?.id ?? "error",
          input: {
            associatedSpeciesRef: {
              cosewicRefId: photoData?.photo?.photoSpecies?.cosewicRefId,
              listingRefId: photoData?.photo?.photoSpecies?.listingRefId,
            },
          },
        },
      });
      if (res.errors) throw res.errors;

      alertContext.showSuccess({
        message: t("unpublish_photo_success_message"),
        timeOut: 5000,
      });

      history.push({
        pathname: GalleryPathHelpers.Gallery(),
        search: `?program=${overviewProgram}&id=${overviewId}`,
      });
    } catch (e) {
      console.error(e);
      alertContext.showError({
        message: t("unpublish_fail"),
        timeOut: 5000,
      });
    }
  };

  const title = useMemo(() => {
    if (i18n.language === "fr") {
      if (!htmlIsNullOrEmpty(photoData?.photo?.title?.french?.text)) {
        return photoData?.photo?.title?.french?.text;
      } else {
        return photoData?.photo?.title?.english?.text ?? "MISSING TITLE";
      }
    }

    if (!htmlIsNullOrEmpty(photoData?.photo?.title?.english?.text)) {
      return photoData?.photo?.title?.english?.text;
    } else {
      return photoData?.photo?.title?.french?.text ?? "MISSING TITLE";
    }
  }, [i18n.language, photoData?.photo?.title]);

  const copyright = useMemo(() => {
    return i18n.language === "fr"
      ? photoData?.copyright?.french?.text
      : photoData?.copyright?.english?.text;
  }, [
    i18n.language,
    photoData?.copyright?.english?.text,
    photoData?.copyright?.french?.text,
  ]);

  const canPublish = React.useMemo(() => {
    if (loading || loadingPublish || error) return false;
    if (
      photoData?.photo?.state === PublishState.NotPublished ||
      photoData?.photo?.state === PublishState.PublishFailed
    )
      return true;
    return false;
  }, [loading, loadingPublish, error, photoData?.photo?.state]);

  const canUnpublish = React.useMemo(() => {
    if (loading || loadingUnpublish || error) return false;
    if (
      photoData?.photo?.state === PublishState.Published ||
      photoData?.photo?.state === PublishState.UnpublishFailed
    )
      return true;
    return false;
  }, [loading, loadingUnpublish, error, photoData?.photo?.state]);

  return (
    <>
      <Ribbon />
      <Layout.Root>
        <Layout.Content>
          <GlobalAlert />
          <h1>
            {!loading && !loadingPublish && !loadingUnpublish && (
              <SafeRenderHtml htmlString={htmlRemoveOuterPTag(title)} />
            )}
          </h1>
          <GraphqlError
            title="Error fetching PhotoGalleryByReference"
            errors={error}
          />
          <GraphqlError
            title="Error publishing to Registry"
            errors={errorPublish}
          />
          <GraphqlError
            title="Error unpublishing from Registry"
            errors={errorUnpublish}
          />
          {loading || loadingPublish || loadingUnpublish ? (
            <LoadingIndicator />
          ) : (
            <>
              <div className="flex justify-between align-center mrgn-bttm-md">
                <div className="flex font-size-18">
                  <div>
                    {t("state")}{" "}
                    <div className="label label-info">
                      {photoData?.photo?.state ? (
                        t(photoData.photo.state)
                      ) : (
                        <MissingData />
                      )}
                    </div>
                  </div>
                </div>
                {canPublish && (
                  <RenderWhenAuthorized
                    authorizedRoles={ROLE_ACTIONS.photos.publish}
                  >
                    <MakePhotoPublishButtonAndModal
                      defaultValues={{
                        usageHistory: photoData?.photo
                          ?.usageHistory as RichTextInput,
                      }}
                      onPublishSubmit={onPublishSubmit}
                    />
                  </RenderWhenAuthorized>
                )}
                {canUnpublish && (
                  <RenderWhenAuthorized
                    authorizedRoles={ROLE_ACTIONS.photos.publish}
                  >
                    <PublishUnPublishButtonAndModal
                      canAction={canUnpublish}
                      onAction={onUnpublish}
                      warningTitle={t("unpublish_modal_warning_title")}
                      warningMessage={t(
                        "unpublish_photo_modal_warning_message"
                      )}
                      buttonText={t("unpublish_from_registry")}
                      modalTitle={t("unpublish_from_registry")}
                    />
                  </RenderWhenAuthorized>
                )}
              </div>
              <div className="mrgn-bttm-md">
                <figure>
                  <PhotoImage
                    name={photoData?.photo?.name ?? ""}
                    size={PhotoType.Large}
                    className="img-rounded img-responsive"
                  />
                  <figcaption className="caption font-size-14 text-break">
                    <SafeRenderHtml
                      htmlString={htmlRemoveOuterPTag(copyright)}
                    />
                  </figcaption>
                </figure>
              </div>
              <PhotoInformationCard photoData={photoData} />
            </>
          )}
        </Layout.Content>
        <Layout.Sidebar>
          <SideNav />
        </Layout.Sidebar>
      </Layout.Root>
    </>
  );
};

export default PhotoProfilePageByReference;
