import { useMutation, useQuery } from "@apollo/client";
import { PageTitle } from "@arcnovus/wet-boew-react";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import GraphqlError from "components/GraphqlError";
import Layout from "components/layouts/TwoColumn";
import SideNav from "components/molecules/SideNav";
import Ribbon from "components/organisms/cosewic/Ribbon";
import ListingLandForm, {
  ListingLandFormFields,
} from "components/organisms/listing/ListingLandForm/ListingLandForm";
import {
  mapDomainModelToForm,
  mapFormToUpdateInput,
} from "components/organisms/listing/ListingLandForm/mappers";
import { ChangeTrackingProvider } from "features/changeTracking";
import { useOverviewContext } from "features/overview";
import {
  ListingLandForEditPageDocument,
  UpdateListingWsLandDocument,
} from "generated/gql-types";
import { ListingPathHelpers } from "pages/listing/ListingRouter";
import * as React from "react";
import { useMemo } from "react";
import { SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";

export interface ListingLandsEditPageProps {}

export const ListingLandsEditPage: React.FC<ListingLandsEditPageProps> = (
  props
) => {
  const { t } = useTranslation();
  const { params } = useRouteMatch();
  const { listingWsId, landId } = params as any;
  const history = useHistory();
  const overviewContext = useOverviewContext();

  const { loading, error, data } = useQuery(ListingLandForEditPageDocument, {
    variables: { listingWsId, landId },
  });

  React.useEffect(() => {
    overviewContext.updateOverview("listingws", listingWsId);
  }, [listingWsId]);

  const [updateListingLand, { loading: saving, error: savingError }] =
    useMutation(UpdateListingWsLandDocument, { errorPolicy: "all" });

  const onSubmit: SubmitHandler<Partial<ListingLandFormFields>> = async (
    formData
  ) => {
    const mapped = mapFormToUpdateInput(formData);

    // NOTE: The graphQL endpoint looks at the input.landRef.id to determine the land ID to update.
    //  normally, if the UI user hasn't changed the land in their form, it won't be included in the request;
    //  here we make sure to add the initial value back in so the graphQL request doesn't fail.
    if (mapped.landRef == null) {
      mapped.landRef = data?.listingWsLand?.landRef;
    }

    try {
      const res = await updateListingLand({
        variables: {
          listingWsId,
          listingWsLandId: landId,
          input: mapped,
        },
      });

      if (res.errors) throw res.errors;
      history.push({
        pathname: ListingPathHelpers.ListingLands(listingWsId ?? "error"),
      });
    } catch (e) {
      console.error(e);
    }
  };

  const onCancel = () => {
    history.push({
      pathname: ListingPathHelpers.ListingLands(listingWsId ?? "error"),
    });
  };

  const defaultValues = useMemo(() => {
    return mapDomainModelToForm(data?.listingWsLand as any);
  }, [data?.listingWsLand]);

  return (
    <>
      <Ribbon />
      <Layout.Root>
        <Layout.Content>
          <GraphqlError
            title="Error loading listing WS land data"
            errors={error}
          />
          <GraphqlError
            title="Error saving listing WS land data"
            errors={savingError}
          />

          {saving ? <p>Saving...</p> : null}

          <ChangeTrackingProvider isEnabled={false}>
            <h1>{t("edit_land")}</h1>
            {loading ? (
              <LoadingIndicator centered />
            ) : (
              <ListingLandForm
                onSubmit={onSubmit}
                onCancel={onCancel}
                defaultValues={defaultValues}
              />
            )}
          </ChangeTrackingProvider>
        </Layout.Content>
        <Layout.Sidebar>
          <SideNav />
        </Layout.Sidebar>
      </Layout.Root>
    </>
  );
};

export default ListingLandsEditPage;
