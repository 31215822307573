import {
  BptProjectActivity,
  BptProjectActivityUpdateInput,
  BptProjectPhase,
  BptProjectPhaseUpdateInput,
  BptProjectStep,
  BptProjectStepUpdateInput,
  BptProject,
  BptProjectCreateInput,
  BptProjectUpdateInput,
  BptTemplate,
  BptTemplateReference,
  ResourceLinkInput,
} from "generated/gql-types";
import { set } from "lodash";
import { FieldNamesMarkedBoolean, UnpackNestedValue } from "react-hook-form";
import * as FormMappers from "util/formMappers";
import htmlIsNullOrEmpty from "util/htmlIsNullOrEmpty";
import { filterForDirtyFields } from "../../../../util/forms";
import isNullOrEmpty from "../../../../util/isNullOrEmpty";
import { ProjectPhaseFormFields } from "../Phase/ProjectPhaseForm";
import { ProjectStepFormFields } from "../Step/ProjectStepForm";
import { ProjectActivityFormFields } from "../Activity/ProjectActivityForm";
import { Maybe } from "graphql/jsutils/Maybe";
import { ResourceLink } from "../../../../generated/gql-types";
import { StepLinkFields } from "../StepLink/StepLinkForm";
import { BptProjectFormFields } from "../Project/BptProjectForm";

export const processProjectPhaseFormValues = (
  formData: UnpackNestedValue<Partial<ProjectPhaseFormFields>>,
  dirtyFields: FieldNamesMarkedBoolean<any>,
  initialValues: Partial<ProjectPhaseFormFields>
): Partial<ProjectPhaseFormFields> => {
  const values = filterForDirtyFields(formData, dirtyFields);
  setNullForProjectPhaseEmptyFields(values, dirtyFields, initialValues);
  return values;
};

const setNullForProjectPhaseEmptyFields = (
  formData: UnpackNestedValue<Partial<ProjectPhaseFormFields>>,
  dirtyFields: FieldNamesMarkedBoolean<any>,
  initialValues: Partial<ProjectPhaseFormFields>
) => {
  // We use lodash set() in order to make sure each level of a nested path exists when we set a value.
  // ProjectPhaseFormFields

  if (
    dirtyFields?.description &&
    htmlIsNullOrEmpty(formData?.comments?.text) &&
    !isNullOrEmpty(initialValues?.comments?.text)
  ) {
    set(formData, "comments.text", null);
  }
};

export const domainModelIntoProjectPhaseForm = (
  projectPhase?: BptProjectPhase | null
): Partial<ProjectPhaseFormFields> => {
  const out: Partial<ProjectPhaseFormFields> = {};

  // Comments
  FormMappers.mapRichText(out, "comments", projectPhase?.comments);
  return out;
};

export const projectPhaseFormIntoCreateInput = (
  formData: Partial<ProjectPhaseFormFields>
): BptProjectPhaseUpdateInput => {
  const out: BptProjectPhaseUpdateInput = {};
  // Comments
  FormMappers.mapRichTextInput(out, "comments", formData?.comments);

  return out;
};

export const processProjectStepFormValues = (
  formData: UnpackNestedValue<Partial<ProjectStepFormFields>>,
  dirtyFields: FieldNamesMarkedBoolean<any>,
  initialValues: Partial<ProjectStepFormFields>
): Partial<ProjectStepFormFields> => {
  const values = filterForDirtyFields(formData, dirtyFields);
  setNullForProjectStepEmptyFields(values, dirtyFields, initialValues);
  return values;
};

const setNullForProjectStepEmptyFields = (
  formData: UnpackNestedValue<Partial<ProjectStepFormFields>>,
  dirtyFields: FieldNamesMarkedBoolean<any>,
  initialValues: Partial<ProjectStepFormFields>
) => {
  // We use lodash set() in order to make sure each level of a nested path exists when we set a value.
  // ProjectStepFormFields

  if (
    dirtyFields?.actualTimeline?.startDate != null &&
    isNullOrEmpty(formData?.actualTimeline?.startDate) &&
    !isNullOrEmpty(initialValues?.actualTimeline?.startDate)
  ) {
    set(formData, "actualTimeline.startDate", null);
  }

  if (
    dirtyFields?.actualTimeline?.endDate != null &&
    isNullOrEmpty(formData?.actualTimeline?.endDate) &&
    !isNullOrEmpty(initialValues?.actualTimeline?.endDate)
  ) {
    set(formData, "actualTimeline.endDate", null);
  }

  if (
    dirtyFields?.comments != null &&
    isNullOrEmpty(formData?.comments?.text) &&
    !isNullOrEmpty(initialValues?.comments?.text)
  ) {
    set(formData, "comments.text", null);
  }
};

export const domainModelIntoProjectStepForm = (
  step?: Maybe<BptProjectStep> | null
): Partial<ProjectStepFormFields> => {
  const out: Partial<ProjectStepFormFields> = {};

  // ActualTimeline
  FormMappers.mapTimeline(out, "actualTimeline", step?.actualTimeline);

  // Comments
  FormMappers.mapRichText(out, "comments", step?.comments);

  return out;
};

export const projectStepFormIntoCreateInput = (
  formData: Partial<ProjectStepFormFields>
): BptProjectStepUpdateInput => {
  const out: BptProjectStepUpdateInput = {};

  // ActualTimeline
  FormMappers.mapTimelineInput(out, "actualTimeline", formData.actualTimeline);

  // Comments
  FormMappers.mapRichTextInput(out, "comments", formData?.comments);

  return out;
};

export const processProjectActivityFormValues = (
  formData: UnpackNestedValue<Partial<ProjectActivityFormFields>>,
  dirtyFields: FieldNamesMarkedBoolean<any>,
  initialValues: Partial<ProjectActivityFormFields>
): Partial<ProjectActivityFormFields> => {
  const values = filterForDirtyFields(formData, dirtyFields);
  setNullForProjectActivityEmptyFields(values, dirtyFields, initialValues);
  return values;
};

const setNullForProjectActivityEmptyFields = (
  formData: UnpackNestedValue<Partial<ProjectActivityFormFields>>,
  dirtyFields: FieldNamesMarkedBoolean<any>,
  initialValues: Partial<ProjectActivityFormFields>
) => {
  // We use lodash set() in order to make sure each level of a nested path exists when we set a value.
  // ProjectActivityFormFields
  if (
    dirtyFields?.actualTimeline?.startDate != null &&
    isNullOrEmpty(formData?.actualTimeline?.startDate) &&
    !isNullOrEmpty(initialValues?.actualTimeline?.startDate)
  ) {
    set(formData, "actualTimeline.startDate", null);
  }

  if (
    dirtyFields?.actualTimeline?.endDate != null &&
    isNullOrEmpty(formData?.actualTimeline?.endDate) &&
    !isNullOrEmpty(initialValues?.actualTimeline?.endDate)
  ) {
    set(formData, "actualTimeline.endDate", null);
  }
  if (
    dirtyFields?.comments != null &&
    isNullOrEmpty(formData?.comments?.text) &&
    !isNullOrEmpty(initialValues?.comments?.text)
  ) {
    set(formData, "comments.text", null);
  }
};

export const domainModelIntoProjectActivityForm = (
  activity?: Maybe<BptProjectActivity> | null
): Partial<ProjectActivityFormFields> => {
  const out: Partial<ProjectActivityFormFields> = {};

  // ActualTimeline
  FormMappers.mapTimeline(out, "actualTimeline", activity?.actualTimeline);

  // Comments
  FormMappers.mapRichText(out, "comments", activity?.comments);

  return out;
};

export const projectActivityFormIntoCreateInput = (
  formData: Partial<ProjectActivityFormFields>
): BptProjectActivityUpdateInput => {
  const out: BptProjectActivityUpdateInput = {};

  // ActualTimeline
  FormMappers.mapTimelineInput(out, "actualTimeline", formData.actualTimeline);

  // Comments
  FormMappers.mapRichTextInput(out, "comments", formData?.comments);

  return out;
};

export const processStepLinkFormValues = (
  formData: UnpackNestedValue<Partial<StepLinkFields>>,
  dirtyFields: FieldNamesMarkedBoolean<any>,
  initialValues: Partial<StepLinkFields>
): Partial<StepLinkFields> => {
  const values = filterForDirtyFields(formData, dirtyFields);
  setNullForStepLinkEmptyFields(values, dirtyFields, initialValues);
  return values;
};

const setNullForStepLinkEmptyFields = (
  formData: UnpackNestedValue<Partial<StepLinkFields>>,
  dirtyFields: FieldNamesMarkedBoolean<any>,
  initialValues: Partial<StepLinkFields>
) => {
  // We use lodash set() in order to make sure each level of a nested path exists when we set a value.
  // StepLinkFields
  if (
    dirtyFields?.name != null &&
    isNullOrEmpty(formData?.reference?.name?.text) &&
    !isNullOrEmpty(initialValues?.reference?.name?.text)
  ) {
    set(formData, "reference.name.text", null);
  }

  if (
    dirtyFields?.urlToDocument &&
    isNullOrEmpty(formData?.reference?.uri) &&
    !isNullOrEmpty(initialValues?.reference?.uri)
  ) {
    set(formData, "reference.uri", null);
  }

  if (
    dirtyFields?.language &&
    isNullOrEmpty(formData?.language) &&
    !isNullOrEmpty(initialValues?.language)
  ) {
    set(formData, "language", null);
  }
};

export function mapDomainModelToStepLinkForm(
  model?: Partial<Maybe<ResourceLink>>
) {
  const out: Partial<StepLinkFields> = {};

  FormMappers.mapResourceLink(out, "", {
    reference: {
      name: model?.reference?.name,
      uri: model?.reference?.uri,
    },
    language: model?.language,
  });

  return out;
}

export const stepLinkFormIntoCreateInput = (
  formData: Partial<StepLinkFields>
): ResourceLinkInput => {
  const out: ResourceLinkInput = {};

  FormMappers.mapResourceLinkInput(out, "", {
    reference: {
      name: formData?.reference?.name,
      uri: formData?.reference?.uri,
    },
    language: formData?.language,
  });

  return out;
};

export const processBptProjectFormValues = (
  formData: UnpackNestedValue<Partial<BptProjectFormFields>>,
  dirtyFields: FieldNamesMarkedBoolean<any>,
  initialValues: Partial<BptProjectFormFields>
): Partial<BptProjectFormFields> => {
  const values = filterForDirtyFields(formData, dirtyFields);
  setNullForBptProjectEmptyFields(values, dirtyFields, initialValues);
  return values;
};

const setNullForBptProjectEmptyFields = (
  formData: UnpackNestedValue<Partial<BptProjectFormFields>>,
  dirtyFields: FieldNamesMarkedBoolean<any>,
  initialValues: Partial<BptProjectFormFields>
) => {
  // We use lodash set() in order to make sure each level of a nested path exists when we set a value.

  // BptProjectFormFields

  if (
    dirtyFields?.name?.text != null &&
    isNullOrEmpty(formData?.name?.text) &&
    !isNullOrEmpty(initialValues?.name?.text)
  ) {
    set(formData, "name.text", null);
  }
  if (
    dirtyFields?.projectLead?.text != null &&
    isNullOrEmpty(formData?.projectLead?.text) &&
    !isNullOrEmpty(initialValues?.projectLead?.text)
  ) {
    set(formData, "projectLead.text", null);
  }

  if (
    dirtyFields?.templateReference?.name != null &&
    isNullOrEmpty(formData?.templateReference?.name) &&
    !isNullOrEmpty(initialValues?.templateReference?.name)
  ) {
    set(formData, "templateReference", null);
  }
};

export const domainModelIntoBptProjectForm = (
  bptProject?: BptProject | null
): Partial<BptProjectFormFields> => {
  const out: Partial<BptProjectFormFields> = {};
  // name
  FormMappers.mapRichText(out, "name", bptProject?.name);
  // projectLead
  FormMappers.mapRichText(out, "projectLead", bptProject?.projectLead);
  // bptTemplateId
  FormMappers.mapBptTemplateReference(
    out,
    "templateReference",
    bptProject?.templateReference
  );
  return out;
};

export const mapBptTemplateToBptTemplateRefrence = (
  bptTemplate?: BptTemplate | null
): Partial<BptTemplateReference> => {
  const out = {};
  FormMappers.mapBptTemplateToTemplateReference(out, "", bptTemplate);
  return out;
};

export const bptProjectFormIntoCreateInput = (
  formData: Partial<BptProjectFormFields>,
  initialValues?: Partial<BptProjectFormFields>
): BptProjectCreateInput => {
  const out: BptProjectCreateInput = {};
  // name
  FormMappers.mapRichTextInput(out, "name", formData?.name);
  // projectLead
  FormMappers.mapRichTextInput(
    out,
    "projectLead",
    isNullOrEmpty(formData?.projectLead?.text) ? null : formData?.projectLead
  );

  // templateId
  if (formData?.templateReference != null) {
    FormMappers.mapPrimitiveType(
      out,
      "templateId",
      formData?.templateReference?.id
    );
  } else {
    FormMappers.mapPrimitiveType(
      out,
      "templateId",
      initialValues?.templateReference?.id
    );
  }
  return out;
};

export const bptProjectFormIntoUpdateInput = (
  formData: Partial<BptProjectFormFields>
): BptProjectUpdateInput => {
  const out: BptProjectUpdateInput = {};
  // name
  FormMappers.mapRichTextInput(out, "name", formData?.name);
  // projectLead
  FormMappers.mapRichTextInput(
    out,
    "projectLead",
    isNullOrEmpty(formData?.projectLead?.text) ? null : formData?.projectLead
  );

  return out;
};
