import cc from "classcat";
import * as React from "react";
import { Controller, ControllerProps } from "react-hook-form";
import { Control, FieldValues } from "react-hook-form/dist/types";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { sanitizeAndTrimHtml } from "../../util/richtext";

const modules = {
  keyboard: { bindings: { tab: false } },
  toolbar: {
    container: ["italic"],
  },
};

// TODO: support ID props etc
interface HTMLItalicsInputProps {
  defaultValue: string;
  placeholder?: string;
  onBlur?: (newMd: string) => void;
  onChange?: (newMd: string) => void;
  className?: string;
  id: string;
  disabled?: boolean;
}

/**
 * HTMLItalicsInput
 *
 * @param {string} defaultValue - Markdown string
 * @param {string} placeholder
 */
export const HTMLItalicsInput: React.FC<HTMLItalicsInputProps> = (props) => {
  const className = cc(["form-control pl-2 pt-2", props.className]);

  const onChange = (newValue: string) => {
    if (props.onChange) {
      const sanitized = sanitizeAndTrimHtml(newValue);
      props.onChange(sanitized);
    }
  };

  return (
    <ReactQuill
      theme="bubble"
      id={props.id}
      defaultValue={props.defaultValue}
      className={className}
      style={{ padding: "2px 0 0 0" }}
      onChange={onChange}
      placeholder={props.placeholder}
      modules={modules}
      formats={["italic"]}
      readOnly={props.disabled}
    />
  );
};

// Wrapper component to work with react-hook-form's <Controller />
export const HTMLItalicsInputWithController = <
  TFieldValues extends FieldValues
>(
  props: HTMLItalicsInputWithControllerProps<TFieldValues>
) => {
  const { id, placeholder, defaultValue, disabled, ...controllerProps } = props;

  return (
    <Controller
      {...controllerProps}
      render={({ field: { onChange } }) => (
        <HTMLItalicsInput
          id={id}
          defaultValue={defaultValue}
          placeholder={placeholder}
          onChange={onChange}
          disabled={disabled}
        />
      )}
    />
  );
};

export interface HTMLItalicsInputWithControllerProps<
  TFieldValues extends FieldValues
> extends HTMLItalicsInputProps,
    Omit<ControllerProps<TFieldValues>, "render" | "defaultValue"> {
  defaultValue: string; // defaultValue type should match that of HTMLItalicsInput, not the one in Controller, so we re-define it here.
  render?: never; // Make editor hints be quiet
  control: Control<TFieldValues>;
}
