import { useQuery } from "@apollo/client";
import {
  OverviewWsByReferenceResourcesDocument,
  OverviewWsReference,
  OverviewWsResourcesResponse,
  RangeListResponse,
} from "generated/gql-types";
import * as React from "react";

interface IOverviewContext {
  refetch: () => void;
  updateOverview: (
    name: string,
    referenceId: string | null | undefined
  ) => void;
  clearOverview: () => void;
  overviewReference: OverviewReferenceState | null;
  loading: boolean;
  someErrors: boolean;
  cosewic: OverviewWsResourcesResponse["cosewicWs"];
  listing: OverviewWsResourcesResponse["listingWs"];
  latestListingProcess: OverviewWsResourcesResponse["latestListingProcess"];
  latestAssessment: OverviewWsResourcesResponse["latestAssessment"];
  permitDescriptions: OverviewWsResourcesResponse["permitDescriptions"];
  rangeList: RangeListResponse["range"] | undefined; // Admin "rangeList"
}

const OverviewContext = React.createContext<IOverviewContext | undefined>(
  undefined
);

interface OverviewReferenceState extends OverviewWsReference {}

const OverviewProvider: React.FC = ({ children }) => {
  const [overviewReference, setOverviewReference] =
    React.useState<OverviewReferenceState | null>(null);

  const overviewWsByReferenceResourcesQuery = useQuery(
    OverviewWsByReferenceResourcesDocument,
    {
      variables: {
        referenceId: overviewReference?.referenceId as any,
        referenceName: overviewReference?.name as any,
      },
      skip: overviewReference == null,
      fetchPolicy: "network-only",
      errorPolicy: "all",
    }
  );

  function updateOverview(
    name: string,
    referenceId: string | null | undefined
  ) {
    if (referenceId != null) {
      setOverviewReference({
        name,
        referenceId,
      });
    }
  }

  function clearOverview() {
    setOverviewReference(null);
  }

  const cosewic =
    overviewWsByReferenceResourcesQuery.data?.overviewWsByReferenceResources
      ?.cosewicWs;
  const listing =
    overviewWsByReferenceResourcesQuery.data?.overviewWsByReferenceResources
      ?.listingWs;
  const latestListingProcess =
    overviewWsByReferenceResourcesQuery.data?.overviewWsByReferenceResources
      ?.latestListingProcess;
  const latestAssessment =
    overviewWsByReferenceResourcesQuery.data?.overviewWsByReferenceResources
      ?.latestAssessment;
  const permitDescriptions =
    overviewWsByReferenceResourcesQuery.data?.overviewWsByReferenceResources
      ?.permitDescriptions;
  const rangeList = overviewWsByReferenceResourcesQuery.data?.rangeList?.range;

  const someErrors =
    (overviewWsByReferenceResourcesQuery.data &&
      overviewWsByReferenceResourcesQuery.data.overviewWsByReferenceResources ==
        null) ||
    overviewWsByReferenceResourcesQuery.error !== undefined;

  // TODO: Better error handling
  if (someErrors) {
    console.error(
      "OverviewContext - GraphQL error - OverviewWsByReferenceResources"
    );
  }

  const api = {
    refetch: () => {
      overviewWsByReferenceResourcesQuery.refetch();
    },
    updateOverview,
    clearOverview,
    overviewReference,
    loading: overviewWsByReferenceResourcesQuery.loading,
    someErrors,
    cosewic,
    listing,
    latestListingProcess,
    latestAssessment,
    permitDescriptions,
    rangeList,
  };

  return (
    <OverviewContext.Provider value={api}>{children}</OverviewContext.Provider>
  );
};

const useOverviewContext = () => {
  const context = React.useContext(OverviewContext);
  if (context === undefined) {
    throw new Error(
      "useOverviewContext must be used within a OverviewProvider"
    );
  }
  return context;
};

export { OverviewProvider, useOverviewContext };
