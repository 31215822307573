import FormButtons from "components/molecules/FormButtons/FormButtons";
import { FullHTMLEditorWithController } from "components/organisms/FullHTMLEditor";
import { RichText } from "generated/gql-types";
import { Maybe } from "graphql/jsutils/Maybe";
import * as React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { processProjectPhaseFormValues } from "../Utility/bptProjectFormUtil";

export interface ProjectPhaseFormFields {
  comments?: Maybe<RichText>;
}

export interface ProjectPhaseFormProps {
  defaultValues?: Partial<ProjectPhaseFormFields>;
  onSubmit: SubmitHandler<Partial<ProjectPhaseFormFields>>;
  onClose: () => void;
  isSaving?: boolean;
}

export const ProjectPhaseForm: React.FC<ProjectPhaseFormProps> = (props) => {
  const defaultValues = props.defaultValues;
  const { t } = useTranslation();

  const form = useForm<ProjectPhaseFormFields>({
    defaultValues: defaultValues,
    mode: "onChange",
  });
  const { handleSubmit, formState, control } = form;
  const { dirtyFields, isDirty, isSubmitting, errors } = formState;

  const onSubmit: SubmitHandler<Partial<ProjectPhaseFormFields>> = async (
    formData
  ) => {
    const cleanedValues = processProjectPhaseFormValues(
      formData,
      dirtyFields,
      defaultValues ?? {}
    );

    return new Promise<void>((resolve) => {
      setTimeout(() => {
        props.onSubmit(cleanedValues);
        resolve();
      }, 500);
    });
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <fieldset className="pt-0" disabled={props.isSaving}>
          {/* ---------- Comments ---------- */}
          <div className="form-group">
            <label htmlFor="commentsField">{t("comments")}</label>
            <FullHTMLEditorWithController
              control={control}
              id="commentsField"
              name="comments.text"
              defaultValue={defaultValues?.comments?.text ?? ""}
              placeholder={t("enter_comments")}
            />
          </div>
        </fieldset>

        <hr className="row mrgn-tp-0 mrgn-bttm-md" />
        <FormButtons
          isDirty={isDirty}
          isSubmitting={isSubmitting || props.isSaving}
          onCancel={props.onClose}
          errors={errors}
        />
      </form>
    </>
  );
};

export default ProjectPhaseForm;
