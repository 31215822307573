import { useMutation, useQuery } from "@apollo/client";
import EditLink from "components/atoms/EditLink";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import MissingData from "components/atoms/MissingData";
import SectionCard from "components/atoms/SectionCard";
import GraphqlError from "components/GraphqlError";
import Layout from "components/layouts/TwoColumn";
import WSNameChangesCard from "components/molecules/changeLog/WSNameChangesCard";
import ConsultationConsiderationSection from "components/molecules/listing/information/ConsultationConsiderationSection/ConsultationConsiderationSection";
import LegalProtectionSection from "components/molecules/listing/information/LegalProtectionSection/LegalProtectionSection";
import LegalStatusSection from "components/molecules/listing/information/LegalStatusSection/LegalStatusSection";
import OverallAssessmentSection from "components/molecules/listing/information/OverallAssessmentSection/OverallAssessmentSection";
import WildlifeSpeciesInformationSection from "components/molecules/listing/information/WildlifeSpeciesInformationSection/WildlifeSpeciesInformationSection";
import WSNameDiffWarning from "components/molecules/listing/information/WSNameDiffWarning/WSNameDiffWarning";
import NoteCard from "components/molecules/NoteCard/NoteCard";
import SideNav from "components/molecules/SideNav";
import Ribbon from "components/organisms/cosewic/Ribbon";
import MakePublishedButtonAndModal from "components/organisms/MakePublishedButtonAndModal/MakePublishedButtonAndModal";
import { useNoteModal } from "components/organisms/NoteModal";
import { GlobalAlert } from "features/globalAlert";
import { useOverviewContext } from "features/overview";
import {
  ChangeLog,
  ListingProfileDocument,
  ListingWsStage,
  ListingWsState,
  UpdateListingDocument,
  UpdateListingWsStateDocument,
} from "generated/gql-types";
import { ListingPathHelpers } from "pages/listing/ListingRouter";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useRouteMatch } from "react-router-dom";
import {
  RenderWhenAuthorized,
  ROLE_ACTIONS,
} from "../../../features/auth/components";
import { FeatureFlagKeys } from "../../../features/featureFlags/config";
import RenderWhenFeatureIsEnabled from "../../../features/featureFlags/RenderWhenFeatureIsEnabled";
import htmlIsNullOrEmpty from "../../../util/htmlIsNullOrEmpty";

const Index: React.FC = () => {
  const { t } = useTranslation();
  const { params } = useRouteMatch();
  const { listingWsId } = params as any;
  const noteModalState = useNoteModal({});
  const overviewContext = useOverviewContext();

  React.useEffect(() => {
    overviewContext.updateOverview("listingws", listingWsId);
  }, [listingWsId]);

  const [updateListingWs, { error: savingError }] = useMutation(
    UpdateListingDocument,
    {
      refetchQueries: ["ListingProfile"],
      errorPolicy: "all",
    }
  );

  const [runUpdateListingWsState, updateListingWsStateMutationStatus] =
    useMutation(UpdateListingWsStateDocument, {
      refetchQueries: ["ListingProfile"],
      errorPolicy: "all",
    });

  const {
    loading,
    error,
    data,
    refetch: refetchListingProfile,
  } = useQuery(ListingProfileDocument, {
    variables: {
      id: listingWsId,
    },
  });

  const onUpdateNote = async (newNoteText: string) => {
    try {
      const note = htmlIsNullOrEmpty(newNoteText)
        ? null
        : { text: { text: newNoteText } };

      const res = await updateListingWs({
        variables: {
          id: listingWsId,
          input: { note },
        },
      });

      if (res.errors) throw res.errors;
    } catch (e) {}

    console.log("Saved Note for Listing", listingWsId);
  };

  const onPublish = async () => {
    try {
      const res = await runUpdateListingWsState({
        variables: {
          id: listingWsId,
          state: ListingWsState.Published,
        },
      });
      if (res.errors) throw res.errors;
      // Refetch overviewContext to update ribbon and sideNav
      overviewContext.refetch();
    } catch (e) {
      console.error(e);
    }
  };

  const canPublish = React.useMemo(() => {
    if (loading) return false;

    if (data?.listingWs?.stage !== ListingWsStage.Active) {
      console.log("[WS Publish Btn] Disabled - Listing stage is not active");
      return false;
    }

    if (data?.listingWs?.state === ListingWsState.Published) {
      console.log(
        "[WS Publish Btn] Disabled - Listing WS is already published"
      );
      return false;
    }

    if (data?.listingWs?.scientificName?.species?.id == null) {
      console.log("[WS Publish Btn] Disabled - Species field is empty");
      return false;
    }

    if (data?.listingWs?.commonNameFrench?.name == null) {
      console.log(
        "[WS Publish Btn] Disabled - Common Name French field is empty"
      );
      return false;
    }
    return true;
  }, [
    loading,
    data?.listingWs?.stage,
    data?.listingWs?.state,
    data?.listingWs?.scientificName?.species?.id,
    data?.listingWs?.commonNameFrench?.name,
  ]);

  return (
    <>
      <Ribbon />
      <Layout.Root>
        <Layout.Content>
          <h1>{t("listing_information")}</h1>

          <GlobalAlert />
          <GraphqlError
            title="Error fetching Listing WS profile data"
            errors={error}
          />
          <GraphqlError
            title="Error setting Listing WS State"
            errors={updateListingWsStateMutationStatus.error}
          />
          <GraphqlError
            title="Error updating Listing WS"
            errors={savingError}
          />
          <WSNameDiffWarning listingWsId={listingWsId} />

          {/* TODO: Better error handling. ex. when data?.listingWs is null */}
          {/* {!loading && !data?.listingWs && (
            <Alert
              type={AlertTypes.DANGER}
              content={"Error fetching Listing WS profile data."}
            />
          )} */}

          <div className="flex justify-between align-center mrgn-bttm-md">
            <div className="flex font-size-18">
              <div>
                {t("stage")}{" "}
                <div className="label label-info">
                  {data?.listingWs?.stage ? (
                    t(data?.listingWs?.stage)
                  ) : (
                    <MissingData />
                  )}
                </div>
              </div>
              <div className="mrgn-lft-md">
                {t("state")}{" "}
                <div className="label label-info">
                  {data?.listingWs?.state ? (
                    t(data?.listingWs?.state)
                  ) : (
                    <MissingData />
                  )}
                </div>
              </div>
            </div>
            <div>
              <RenderWhenAuthorized
                authorizedRoles={ROLE_ACTIONS.listing.publish}
              >
                {canPublish ? (
                  <MakePublishedButtonAndModal
                    canMakePublished={canPublish}
                    onMakePublished={onPublish}
                    warningMessage={t("publish_ws_to_registry_warning")}
                  />
                ) : null}
              </RenderWhenAuthorized>
            </div>
          </div>

          {/* ------------- Wildlife Species Information ------------- */}
          <SectionCard
            header={
              <div className="flex justify-between align-start">
                <h2>{t("wildlife_species_information")}</h2>
                <RenderWhenAuthorized
                  authorizedRoles={ROLE_ACTIONS.listing.update}
                >
                  {!loading ? (
                    <EditLink
                      linkTo={ListingPathHelpers.ListingEdit(
                        data?.listingWs?.id ?? "error"
                      )}
                      state={t("wildlife_species_information")}
                    />
                  ) : null}
                </RenderWhenAuthorized>
              </div>
            }
          >
            {loading ? (
              <LoadingIndicator centered className="mrgn-bttm-md" />
            ) : (
              <WildlifeSpeciesInformationSection listingWs={data?.listingWs} />
            )}
          </SectionCard>

          {/* ------------- Legal Status ------------- */}
          <SectionCard
            header={
              <div className="flex justify-between align-start">
                <h2>{t("sara_status")}</h2>
                <RenderWhenAuthorized
                  authorizedRoles={ROLE_ACTIONS.listing.update}
                >
                  {!loading ? (
                    <EditLink
                      linkTo={ListingPathHelpers.ListingEdit(
                        listingWsId ?? "error"
                      )}
                      state={t("sara_status")}
                    />
                  ) : null}
                </RenderWhenAuthorized>
              </div>
            }
          >
            {loading ? (
              <LoadingIndicator centered className="mrgn-bttm-md" />
            ) : (
              <LegalStatusSection
                legalStatus={data?.listingWs?.legalStatus}
                listingWsId={listingWsId}
              />
            )}
          </SectionCard>
          {/* ------------- Consultation Considerations ------------- */}
          <SectionCard
            header={
              <div className="flex justify-between align-start">
                <h2>{t("consultation_considerations")}</h2>
                <RenderWhenAuthorized
                  authorizedRoles={ROLE_ACTIONS.listing.update}
                >
                  {!loading ? (
                    <EditLink
                      linkTo={ListingPathHelpers.ListingEdit(
                        listingWsId ?? "error"
                      )}
                      state={t("consultation_considerations")}
                    />
                  ) : null}
                </RenderWhenAuthorized>
              </div>
            }
          >
            {loading ? (
              <LoadingIndicator centered className="mrgn-bttm-md" />
            ) : (
              <ConsultationConsiderationSection
                consultationConsideration={
                  data?.listingWs?.consultationConsideration
                }
                listingWsId={listingWsId}
              />
            )}
          </SectionCard>
          {/* ------------- Legal Protection ------------- */}
          <SectionCard
            header={
              <div className="flex justify-between align-start">
                <h2>{t("legal_protection")}</h2>
                <RenderWhenAuthorized
                  authorizedRoles={ROLE_ACTIONS.listing.update}
                >
                  {!loading ? (
                    <EditLink
                      linkTo={ListingPathHelpers.ListingEdit(
                        listingWsId ?? "error"
                      )}
                      state={t("legal_protection")}
                    />
                  ) : null}
                </RenderWhenAuthorized>
              </div>
            }
          >
            {loading ? (
              <LoadingIndicator centered className="mrgn-bttm-md" />
            ) : (
              <LegalProtectionSection
                legalProtection={data?.listingWs?.legalProtection}
                listingWsId={listingWsId}
              />
            )}
          </SectionCard>
          {/* ------------- Overall Assessment ------------- */}
          <SectionCard
            header={
              <div className="flex justify-between align-start">
                <h2>{t("overall_assessment")}</h2>
                <RenderWhenAuthorized
                  authorizedRoles={ROLE_ACTIONS.listing.update}
                >
                  {!loading ? (
                    <EditLink
                      linkTo={ListingPathHelpers.ListingEdit(
                        listingWsId ?? "error"
                      )}
                      state={t("overall_assessment")}
                    />
                  ) : null}
                </RenderWhenAuthorized>
              </div>
            }
          >
            {loading ? (
              <LoadingIndicator centered className="mrgn-bttm-md" />
            ) : (
              <OverallAssessmentSection
                overallAssessment={data?.listingWs?.overallAssessment}
                listingWsId={listingWsId}
              />
            )}
          </SectionCard>

          {/* ------------- Listing Name Changes ------------- */}
          <WSNameChangesCard
            changeLogList={data?.changeLogList?.changeLog as Array<ChangeLog>}
            sectionTitle={t("listing_name_changes")}
            correlationId={listingWsId}
            loading={loading}
            changeLogDetailsLinkCallback={ListingPathHelpers.ChangeLogDetails}
            refetchProfile={() => refetchListingProfile()}
          />

          {/* ------------- Listing Note ------------- */}
          <NoteCard
            header={t("listing_note")}
            note={data?.listingWs?.note}
            loading={loading}
            onUpdateNote={onUpdateNote}
            role={ROLE_ACTIONS.listing.update}
          />
        </Layout.Content>
        <Layout.Sidebar>
          <SideNav />
        </Layout.Sidebar>
      </Layout.Root>
    </>
  );
};

export default Index;
