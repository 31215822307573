import NoResults from "components/atoms/NoResults";
import SectionCard from "components/atoms/SectionCard";
import { BptProjectStepBundle, Maybe } from "generated/gql-types";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { TypeOfQueryInputProps } from "../../Utility/PopupMenuModal";
import { ProjectStepBundlesSection } from "./ProjectStepBundles";
import StepsCardHeader from "./ProjectStepsCardHeader";

export interface StepsCardProps
  extends Omit<TypeOfQueryInputProps, "TypeOfQueryInputProps"> {
  projectStepBundles?: Maybe<Array<Maybe<BptProjectStepBundle>>>;
  projectId?: string;
  isOpen?: boolean;
  loading?: boolean;
  onExpandAll: () => void;
  onCollapseAll: () => void;
}

export const ProjectStepsCard: React.FC<StepsCardProps> = (props) => {
  const { t } = useTranslation();
  const {
    projectStepBundles,
    projectId,
    isOpen,
    loading,
    onExpandAll,
    onCollapseAll,
  } = props;

  return (
    <>
      <SectionCard
        header={
          <StepsCardHeader
            stepBundles={projectStepBundles}
            onExpandAll={onExpandAll}
            onCollapseAll={onCollapseAll}
          />
        }
        contentAreaProps={{ className: "mrgn-tp-sm" }}
        showLine={false}
      >
        {/* ------------ Step section message ------------ */}
        <p>{t("steps_section_message")}</p>

        {projectStepBundles == null || projectStepBundles?.length === 0 ? (
          <NoResults className="font-size-18 opct-70" />
        ) : (
          <ProjectStepBundlesSection
            isOpen={isOpen}
            projectId={projectId}
            projectStepBundles={projectStepBundles}
            isLoading={loading}
            {...props}
          />
        )}
      </SectionCard>
    </>
  );
};

export default ProjectStepsCard;
