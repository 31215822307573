import FormButtons from "components/molecules/FormButtons/FormButtons";
import { FullHTMLEditorWithController } from "components/organisms/FullHTMLEditor";
import { RichText, Scalars, Timeline } from "generated/gql-types";
import { Maybe } from "graphql/jsutils/Maybe";
import * as React from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { processProjectStepFormValues } from "../Utility/bptProjectFormUtil";
import DatePicker, {
  formatDateForDatePicker,
} from "components/molecules/DatePicker";
import isNullOrEmpty from "util/isNullOrEmpty";

export interface ProjectStepFormFields {
  id?: Maybe<Scalars["ID"]>;
  comments?: Maybe<RichText>;
  actualTimeline?: Maybe<Timeline>;
}

export interface ProjectStepFormProps {
  defaultValues?: Partial<ProjectStepFormFields>;
  hasProjectActivities?: boolean;
  onSubmit: SubmitHandler<Partial<ProjectStepFormFields>>;
  onClose: () => void;
  isSaving?: boolean;
  isStepCompleted?: boolean;
}

export const ProjectStepForm: React.FC<ProjectStepFormProps> = (props) => {
  const defaultValues = props.defaultValues;
  const { t } = useTranslation();

  const form = useForm<ProjectStepFormFields>({
    defaultValues: defaultValues,
    mode: "onChange",
  });
  const { handleSubmit, formState, control, getValues } = form;
  const { dirtyFields, isDirty, isSubmitting, errors } = formState;

  const onSubmit: SubmitHandler<Partial<ProjectStepFormFields>> = async (
    formData
  ) => {
    const cleanedValues = processProjectStepFormValues(
      formData,
      dirtyFields,
      defaultValues ?? {}
    );

    return new Promise<void>((resolve) => {
      setTimeout(() => {
        props.onSubmit(cleanedValues);
        resolve();
      }, 500);
    });
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <fieldset className="pt-0" disabled={props.isSaving}>
          {!props.hasProjectActivities ? (
            <div className="form-group">
              <label htmlFor="actualTimeline">{t("actual_timeline")}</label>
              <div className="well-normal">
                <div className="flex justify-between align-top">
                  <div className="row mrgn-0 full-width">
                    {/* ------ Start Date Picker ------ */}
                    <div className="col-sm-6 form-group">
                      <Controller
                        control={control}
                        name={"actualTimeline.startDate"}
                        render={({ field: { value, onChange } }) => (
                          <DatePicker
                            label={t("from")}
                            value={formatDateForDatePicker(value)}
                            onInput={onChange}
                            id={`startDate`}
                            className="min-width-fit-available line-height-normal"
                            disabled={props.isStepCompleted}
                          />
                        )}
                      />
                    </div>

                    {/* ------ End Date Picker ------ */}
                    <div className="col-sm-6 form-group">
                      <Controller
                        control={control}
                        name={`actualTimeline.endDate`}
                        render={({ field: { value, onChange } }) => (
                          <DatePicker
                            label={t("to")}
                            value={formatDateForDatePicker(value)}
                            onInput={onChange}
                            id={`endDate`}
                            className="min-width-fit-available line-height-normal"
                            disabled={
                              props.isStepCompleted ||
                              isNullOrEmpty(
                                getValues("actualTimeline.startDate")
                              )
                            }
                            min={
                              getValues("actualTimeline.startDate") ??
                              "1900-01-01"
                            }
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {/* ---------- Comments ---------- */}
          <div className="form-group">
            <label htmlFor="commentsField">{t("comments")}</label>
            <FullHTMLEditorWithController
              control={control}
              defaultValue={defaultValues?.comments?.text ?? ""}
              id="commentsField"
              name="comments.text"
              placeholder={t("enter_comments")}
            />
          </div>
        </fieldset>

        <hr className="row mrgn-tp-0 mrgn-bttm-md" />
        <FormButtons
          isDirty={isDirty}
          isSubmitting={isSubmitting || props.isSaving}
          onCancel={props.onClose}
          errors={errors}
        />
      </form>
    </>
  );
};

export default ProjectStepForm;
