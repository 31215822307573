import { BptTemplate, BptTemplateStepBundle, Maybe } from "generated/gql-types";
import * as React from "react";
import StepDetails from "./StepDetails";

export interface StepBundlesProps {
  isOpen?: boolean;
  stepBundles?: Maybe<Maybe<BptTemplateStepBundle>[]>;
  bptTemplate?: Maybe<BptTemplate>;
  isLoading?: boolean;
  onDeleteStep: (a: any, b: any) => void;
}

export const StepBundles: React.FC<StepBundlesProps> = (props) => {
  const { stepBundles, isOpen, bptTemplate, isLoading, onDeleteStep } = props;

  return (
    <>
      {stepBundles?.map((stepBundle, n) => {
        const isBundle =
          stepBundle?.steps != null && stepBundle?.steps?.length >= 2;
        return (
          <div
            key={n}
            className={
              isBundle ? "template-list-bundle" : "template-list-single"
            }
          >
            {stepBundle?.steps?.map((step, index) => {
              return (
                <StepDetails
                  key={index}
                  isOpen={isOpen}
                  stepInfo={step}
                  bptTemplate={bptTemplate}
                  isLoading={isLoading}
                  onDeleteStep={onDeleteStep}
                />
              );
            })}
          </div>
        );
      })}
    </>
  );
};
