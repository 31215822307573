import { useMutation, useQuery } from "@apollo/client";
import GraphqlError from "components/GraphqlError";
import Layout from "components/layouts/TwoColumn";
import SideNav from "components/molecules/SideNav";
import Ribbon from "components/organisms/cosewic/Ribbon";
import ListingForm, {
  ListingFormOutput,
} from "components/organisms/listing/ListingForm";
import { mapListingToListingFormFields } from "components/organisms/listing/ListingForm/util";
import { ChangeTrackingProvider } from "features/changeTracking";
import { useOverviewContext } from "features/overview";
import {
  ListingProfileDocument,
  ListingWsState,
  UpdateListingDocument,
} from "generated/gql-types";
import { ListingPathHelpers } from "pages/listing/ListingRouter";
import * as React from "react";
import { SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";
import LoadingIndicator from "../../../components/atoms/LoadingIndicator";

export interface ListingEditPageProps {}

export const ListingEditPage: React.FC<ListingEditPageProps> = () => {
  const { t } = useTranslation();
  const { params } = useRouteMatch();
  const { listingWsId } = params as any;
  const history = useHistory();
  const overviewContext = useOverviewContext();

  React.useEffect(() => {
    overviewContext.updateOverview("listingws", listingWsId);
  }, [listingWsId]);

  const { loading, error, data } = useQuery(ListingProfileDocument, {
    variables: { id: listingWsId },
  });

  const [updateListing, { error: savingError }] = useMutation(
    UpdateListingDocument,
    { errorPolicy: "all" }
  );

  const onSubmit: SubmitHandler<Partial<ListingFormOutput>> = async (
    values
  ) => {
    try {
      const res = await updateListing({
        variables: {
          id: listingWsId,
          input: values,
        },
      });

      if (res.errors) throw res.errors;

      history.push({
        pathname: ListingPathHelpers.ListingProfile(listingWsId ?? "error"),
      });

      // Refetch overviewContext to update ribbon and sideNav
      overviewContext.refetch();
    } catch (e) {
      console.error(e);
    }
  };

  const initialFormFields = mapListingToListingFormFields(data?.listingWs);

  if (loading) return <LoadingIndicator centered />;

  if (!data || !data.listingWs) {
    console.error(
      "Listing edit page received empty data for Listing ID:",
      listingWsId
    );
    return <p>Invalid data!</p>;
  }

  return (
    <>
      <Ribbon />
      <Layout.Root>
        <Layout.Content>
          <GraphqlError title="Error fetching listing profile" errors={error} />
          <GraphqlError
            title="Error saving listing profile"
            errors={savingError}
          />

          <h1>{t("edit_listing_information")}</h1>
          {/*{saving ? <p>Saving...</p> : null}*/}
          <ChangeTrackingProvider
            isEnabled={data.listingWs.state === ListingWsState.Published}
          >
            <ListingForm
              listingWs={data.listingWs}
              onSubmit={onSubmit}
              initialValues={initialFormFields}
            />
          </ChangeTrackingProvider>
        </Layout.Content>
        <Layout.Sidebar>
          <SideNav />
        </Layout.Sidebar>
      </Layout.Root>
    </>
  );
};

export default ListingEditPage;
