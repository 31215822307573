import cc from "classcat";
import MoveDownButton from "components/atoms/MoveDownButton";
import MoveUpButton from "components/atoms/MoveUpButton";
import SafeRenderHtml from "components/atoms/SafeRenderHtml";
import {
  BptTemplateActivity,
  BptTemplateActivityBundle,
  Maybe,
} from "generated/gql-types";
import useCheckboxList from "hooks/util/useCheckboxList";
import * as React from "react";
import { useTranslation } from "react-i18next";
import htmlRemoveOuterPTag from "util/htmlRemoveOuterPTag";
import { getTemporaryNewBundleId } from "./ReorderActivityBundlesUtil";

export interface ActivityBundlesReorderProps {
  value: Array<BptTemplateActivityBundle>;
  onChange: (activityBundles: Array<BptTemplateActivityBundle>) => void;
}

export const ActivityBundlesReorder: React.FC<ActivityBundlesReorderProps> = (
  props
) => {
  const { t } = useTranslation();
  const { value = [], onChange } = props;
  const { checkedIds, handleCheckboxChange, isIdChecked, clearCheckedIds } =
    useCheckboxList();

  // For display animation during create or delete activities.
  const [newActivityAnimationState, setNewActivityAnimationState] =
    React.useState<string[]>([]);

  function onMoveActivityUp(index1: number, index2: number) {
    const list = [...value];

    if (list == null || (index1 <= 0 && index2 <= 0)) return;

    if (index2 <= 0) {
      // Swap outer activity or bundle
      const List1 = list;
      if (List1 == null) return;
      const itemAbove = List1[index1 - 1];
      List1[index1 - 1] = List1[index1];
      List1[index1] = itemAbove;
    } else {
      // Swap inner activity or bundle
      const List2 = list[index1].activities;
      if (List2 == null) return;
      const itemAbove = List2[index2 - 1];
      List2[index2 - 1] = List2[index2];
      List2[index2] = itemAbove;
    }
    onChange(list);
  }

  function onMoveActivityDown(index1: number, index2: number) {
    const list = [...value];

    if (
      list == null ||
      (index1 >= list.length - 1 &&
        index2 >= list[index1].activities!.length - 1)
    )
      return;

    if (index2 >= list[index1].activities!.length - 1) {
      // Swap outer activity or bundle
      const List1 = list;
      if (List1 == null) return;
      const itemBelow = List1[index1 + 1];
      List1[index1 + 1] = List1[index1];
      List1[index1] = itemBelow;
    } else {
      // Swap inner activity or bundle
      const List2 = list[index1].activities;
      if (List2 == null) return;
      const itemBelow = List2[index2 + 1];
      List2[index2 + 1] = List2[index2];
      List2[index2] = itemBelow;
    }
    onChange(list);
  }

  /**
   * Add the selected activities (in state) into the given bundle
   * @param activityBundle - The bundle to hold the selected activities.
   * If the given bundle is undefined, create a new bundle, add the selected activities into it,
   * and insert the new bundle at the top of the list.
   */
  const addActivitiesToBundle = (
    activityBundle: BptTemplateActivityBundle | undefined
  ) => {
    let list = [...value];
    const selectedActivityIds = checkedIds as string[];

    if (list == null || selectedActivityIds == null) return;

    // Get the selected activities (checked activities)
    const selectedActivityBundles = list.filter(
      (sb) =>
        sb.activities?.[0]?.id != null &&
        selectedActivityIds.includes(sb.activities[0].id)
    );
    const selectedActivities = selectedActivityBundles.map(
      (x) => x.activities?.[0]
    );

    if (activityBundle != null) {
      // Add the selected activities into the given bundle.
      list.forEach((sb) => {
        if (sb.id === activityBundle?.id) {
          sb.activities = sb.activities?.concat(
            selectedActivities as BptTemplateActivity[]
          );
        }
      });
    } else {
      // If the given bundle is undefined, create a new bundle with the given activities.
      const newActivityBundle: BptTemplateActivityBundle =
        createNewActivityBundle(selectedActivities as BptTemplateActivity[]);
      // Insert the new bundle at the top of the list.
      list.splice(0, 0, newActivityBundle);
    }
    // Saved for display animation.
    setNewActivityAnimationState(selectedActivityIds);

    // Remove the selected activities from the list.
    selectedActivityBundles.forEach(
      (ssb) => (list = list.filter((sb) => ssb.id !== sb.id))
    );
    // Clear checked ids state.
    clearCheckedIds();
    onChange(list);
  };

  /**
   * Remove the given activity from bundle and insert the removed activity below the insert index position.
   * @param activity - The activity to be removed from bundle.
   * @param insertIndex - The insertIndex position where the removed activity to be inserted.
   */
  const removeActivityFromBundle = (
    activity: Maybe<BptTemplateActivity>,
    insertIndex: number
  ) => {
    let list = [...value];

    if (list == null || activity == null) return;

    // Create a new bundle with the given activity.
    const newActivityBundle: BptTemplateActivityBundle =
      createNewActivityBundle([activity]);
    // Remove the given activity from bundle.
    list.forEach(
      (sb) =>
        (sb.activities = sb.activities?.filter((s) => s?.id !== activity?.id))
    );
    // Insert the new bundle below the insertIndex position.
    list.splice(insertIndex + 1, 0, newActivityBundle);
    // Saved for display animation.
    setNewActivityAnimationState([activity?.id ?? ""]);
    onChange(list);
  };

  /**
   * Create a new bundle and include the given activities
   * @param activities - The activities to be added to the new bundle.
   * @returns
   */
  const createNewActivityBundle = (activities: BptTemplateActivity[]) => {
    const newActivityBundle: BptTemplateActivityBundle = {
      id: getTemporaryNewBundleId(),
      activities: activities,
      calculatedDuration: null,
    };
    return newActivityBundle;
  };

  React.useEffect(() => {
    if (newActivityAnimationState.length !== 0) {
      setTimeout(() => {
        setNewActivityAnimationState([]);
      }, 500);
    }
  }, [newActivityAnimationState.length]);

  return (
    <>
      {/* ----- Create new bundle button ----- */}
      {checkedIds.length >= 2 && (
        <button
          type="button"
          className="btn btn-default mrgn-bttm-10"
          title={t("create_new_bundle_add_activities")}
          onClick={() => addActivitiesToBundle(undefined)}
        >
          <i className="fas fa-plus-circle mrgn-rght-sm"></i> Create new bundle
        </button>
      )}
      <ul className="template-list mrgn-bttm-md">
        {value.map((item1, index1) => {
          if (item1.activities == null) return null;
          const isBundle = item1.activities.length >= 2;
          return (
            <li
              key={index1}
              className={
                isBundle ? "template-list-bundle" : "template-list-single"
              }
            >
              <div className="flex justify-between gap-md align-center">
                <ul className="template-list full-width">
                  {item1.activities?.map((item2, index2) => (
                    <li
                      key={index2}
                      className={cc([
                        "template-list-item list-item",
                        newActivityAnimationState?.includes(item2?.id ?? "")
                          ? "new-bundle-animation"
                          : "",
                      ])}
                    >
                      <div className="flex justify-between align-center">
                        {isBundle ? (
                          <>
                            <div>
                              <button
                                type="button"
                                className="btn btn-link hover-grey p-0 mrgn-rght-sm"
                                title={t("remove_activity_from_bundle")}
                                onClick={() =>
                                  removeActivityFromBundle(item2, index1)
                                }
                              >
                                <i className="fas fa-minus-circle"></i>
                              </button>
                              {/* ----- activity's name ----- */}
                              <div className="inline-flex font-size-18">
                                <SafeRenderHtml
                                  htmlString={htmlRemoveOuterPTag(
                                    item2?.name?.text ?? ""
                                  )}
                                />
                              </div>
                            </div>
                            {/* ----- Move up/down buttons ----- */}
                            <div className="flex justify-right gap-sm">
                              <MoveUpButton
                                onClick={() => onMoveActivityUp(index1, index2)}
                                disabled={index2 <= 0}
                              />
                              <MoveDownButton
                                onClick={() =>
                                  onMoveActivityDown(index1, index2)
                                }
                                disabled={
                                  index2 >= item1.activities!.length - 1
                                }
                              />
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="checkbox mrgn-tp-0 mrgn-bttm-0">
                              {/* ----- Checkbox ----- */}
                              <label htmlFor={item2?.id!}>
                                <input
                                  type="checkbox"
                                  id={item2?.id!}
                                  checked={isIdChecked(item2?.id!)}
                                  onChange={handleCheckboxChange as any}
                                  title={t("select_for_bundling")}
                                />
                                {/* ----- Activity's name ----- */}
                                <div className="font-size-18 mrgn-lft-sm">
                                  <SafeRenderHtml
                                    htmlString={htmlRemoveOuterPTag(
                                      item2?.name?.text ?? ""
                                    )}
                                  />
                                </div>
                              </label>
                            </div>
                            {/* ----- Move up/down buttons ----- */}
                            <div className="flex justify-right gap-sm">
                              <MoveUpButton
                                onClick={() => onMoveActivityUp(index1, 0)}
                                disabled={index1 <= 0}
                              />
                              <MoveDownButton
                                onClick={() => onMoveActivityDown(index1, 9999)}
                                disabled={index1 >= value.length - 1}
                              />
                            </div>
                          </>
                        )}
                      </div>
                    </li>
                  ))}
                </ul>
                {/* ----- Move up/down buttons ----- */}
                {isBundle && (
                  <div className="flex justify-right gap-sm">
                    <MoveUpButton
                      onClick={() => onMoveActivityUp(index1, 0)}
                      disabled={index1 <= 0}
                    />
                    <MoveDownButton
                      onClick={() => onMoveActivityDown(index1, 9999)}
                      disabled={index1 >= value.length - 1}
                    />
                  </div>
                )}
              </div>
              {/* ----- Add to bundle button ----- */}
              {isBundle && checkedIds.length >= 1 && (
                <button
                  type="button"
                  className="btn btn-default mrgn-bttm-10"
                  title={t("add_selected_activities_into_this_bundle")}
                  onClick={() => addActivitiesToBundle(item1)}
                >
                  <i className="fas fa-plus-circle mrgn-rght-sm"></i> Add to
                  this bundle
                </button>
              )}
            </li>
          );
        })}
      </ul>
      {/* <div>Checked IDs: {(checkedIds as string[]).join(", ")}</div> */}
      {/* <details className="mrgn-tp-md mrgn-bttm-md">
        <summary style={{ backgroundColor: "#fcf8e3" }}>
          Show data structure for debugging purposes
        </summary>
        <pre>
          <h5 className="mrgn-tp-sm">Activities and Bundles</h5>
          <div>{JSON.stringify(value, null, 2)}</div>
        </pre>
      </details> */}
    </>
  );
};
