const communicationsTranslation = {
  en: {
    // Distribution lists
    distribution_lists: "Distribution lists",
    all_lists: "All distribution lists",
    contacts_organizations: "Contacts / Organizations",
    manage_contacts_organizations: "Manage contacts/organizations",
    contact_organization_information: "Contact/Organization information",
    include_exclude: "Include / exclude",
    create_distribution_list: "Create distribution list",
    edit_list: "Edit list",
    edit_distribution_list: "Edit distribution list",
    delete_list: "Delete list",
    delete_distribution_list: "Delete distribution list",
    add_contact_organization: "Add contact/organization",
    remove_contact_organization: "Remove contact/organization",
    delete_distribution_list_warning:
      "You will not be able to recover this distribution list",
    contact_organization_successfully_added:
      "Contact/organization successfully added to the list.",
    remove_contact_member_warning:
      "Are you sure you want to remove this contact/organization from the list?",
    successfully_removed_contact_member: "Successfully removed from the list.",
    delete_distribution_list_modal_warning_title:
      "Deleting a distribution list will remove it from the system.",
    delete_distribution_list_modal_warning_message:
      "The contacts associated to the distribution list will not be deleted.",
    member: "Member",
    email_list: "Email list",
    Outlook_button_disabled_warning:
      "As your distribution list has more than {{number}} characters, the system cannot connect to outlook",
    copy: "Copy",
    open_in_outlook: "Open in Outlook",
    export_all_data: "Export all data (.csv)",
    export_emails_for_GC_Notify: "Export emails for GC Notify (.csv)",
    export_emails_for_Outlook: "Export emails for Outlook",

    // Notifications - Section for future use
  },

  fr: {
    // Distribution lists
    distribution_lists: "Liste de distribution",
    all_lists: "Toutes les listes de distribution",
    contacts_organizations: "Contacts / Organisations",
    manage_contacts_organizations: "Gérer les contacts/organisations",
    contact_organization_information:
      "Informations sur le contact/l'organisation",
    include_exclude: "Inclure / exclure",
    create_distribution_list: "Créer une liste de distribution",
    edit_list: "Modifier la liste",
    edit_distribution_list: "Modifier la liste de distribution",
    delete_list: "Supprimer la liste",
    delete_distribution_list: "Supprimer la liste de distribution",
    add_contact_organization: "Ajouter un contact/une organisation",
    remove_contact_organization: "Enlever un contact/une organisation",
    delete_distribution_list_warning:
      "Vous ne pourrez pas récupérer cette liste de distribution",
    contact_organization_successfully_added:
      "Contact/organisation ajouté(e) à la liste avec succès",
    remove_contact_member_warning:
      "Êtes-vous certain de vouloir retiré ce contact ou cette organisation de la liste ?",
    successfully_removed_contact_member: "Retiré de la liste avec succès",
    delete_distribution_list_modal_warning_title:
      "Supprimer une liste de distribution la retirera du système.",
    delete_distribution_list_modal_warning_message:
      "Les contacts associés à la liste de distribution ne seront pas supprimés.",
    member: "Membre",
    email_list: "Liste de distribution",
    copy: "Copie",
    open_in_outlook: "Ouvrir dans Outlook",
    Outlook_button_disabled_warning:
      "Comme votre liste de distribution contient plus de {{number}} caractères, le système ne peut pas se connecter à Outlook",
    export_all_data: "Exporter toutes les données (.csv)",
    export_emails_for_GC_Notify:
      "Exporter les adresses courriels pour Notification GC (.csv)",
    export_emails_for_Outlook: "Exporter les adresses courriels pour Outlook",

    // Notifications - Section for future use
  },
};

export default communicationsTranslation;
