import { ApolloError, useMutation, useQuery } from "@apollo/client";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import NoResults from "components/atoms/NoResults";
import { RenderWhenAuthorized, ROLE_ACTIONS } from "features/auth/components";
import { useGlobalAlertContext } from "features/globalAlert";
import {
  BptTemplate,
  BptTemplateActivityListDocument,
  BptTemplateStage,
  DeleteBptTemplateActivityDocument,
  Maybe,
  Scalars,
} from "generated/gql-types";
import useAdminAlertMsg from "hooks/admin/useAdminAlertMsg";
import * as React from "react";
import { useTranslation } from "react-i18next";
import AddActivityBtn from "../AddActivityModal/AddActivityBtn";
import ReorderActivityBundlesBtn from "../ReorderActivityBundlesModal/ReorderActivityBundlesBtn";
import { ActivityBundles } from "./ActivityBundles";

export interface ActivitiesSectionProps {
  bptTemplate?: Maybe<BptTemplate>;
  stepId: string;
}

export const ActivitiesSection: React.FC<ActivitiesSectionProps> = (props) => {
  const { t } = useTranslation();
  const alertContext = useGlobalAlertContext();
  const adminAlertMsg = useAdminAlertMsg(t("activity"));
  const { bptTemplate, stepId } = props;

  const templateId = bptTemplate?.id;

  // Query activity list information of the template/phase
  const {
    loading: loadingActivityList,
    error: errorActivityList,
    data: dataActivityList,
  } = useQuery(BptTemplateActivityListDocument, {
    variables: {
      params: {
        templateId: templateId ?? "error",
        stepId: stepId,
        pageNumber: 1,
        pageSize: 50,
      },
    },
    skip: templateId == null,
    fetchPolicy: "network-only",
    errorPolicy: "all",
  });

  const activityBundles =
    dataActivityList?.bptTemplateActivityList?.activityBundles;

  const [
    deleteActivity,
    { loading: deletingActivity, error: deletingActivityError },
  ] = useMutation(DeleteBptTemplateActivityDocument, {
    refetchQueries: [
      "BptTemplateActivityList",
      "BptTemplateStepList",
      "BptTemplatePhase",
    ],
    errorPolicy: "all",
  });

  const onDeleteActivity = async (
    templateId: Scalars["ID"],
    activityId: Scalars["ID"]
  ) => {
    try {
      const res = await deleteActivity({
        variables: {
          templateId: templateId,
          activityId: activityId,
        },
      });

      if (res.errors) throw res.errors;

      alertContext.showSuccess({
        message: t("successfully_deleted_activity"),
        timeOut: 5000,
      });
    } catch (e) {
      console.error(e);
      alertContext.showError({
        message: t("remove_fail"),
        timeOut: 5000,
      });
    }
  };

  return (
    <>
      <div className="pt-0 separator-line-top"></div>
      <section>
        <header className="py-2_5">
          <div className="flex justify-between align-center">
            {/* ------- Title of activities ------- */}
            <h4 className="mrgn-0">{t("activities")}</h4>
            <div>
              {/* ------- Reorder activity bundles (Button) ------- */}
              {bptTemplate?.stage === BptTemplateStage.Draft ? (
                <RenderWhenAuthorized
                  authorizedRoles={
                    ROLE_ACTIONS.administration.bptTemplate.update
                  }
                >
                  <ReorderActivityBundlesBtn
                    templateId={templateId}
                    activityBundles={activityBundles}
                    buttonLabel={t("reorder_and_bundle_activities")}
                    onCompleted={function (data: any): void {
                      throw new Error("Function not implemented.");
                    }}
                    onError={function (error: ApolloError | undefined): void {
                      throw new Error("Function not implemented.");
                    }}
                  />
                </RenderWhenAuthorized>
              ) : null}
            </div>
          </div>
        </header>

        {/* ------- Activities (List) ------- */}
        {loadingActivityList ? (
          <LoadingIndicator className="mrgn-bttm-md" />
        ) : errorActivityList ? (
          <span className="label label-danger">{t("fetch_fail")}</span>
        ) : (
          <>
            {activityBundles == null || activityBundles?.length === 0 ? (
              <NoResults className="font-size-18 opct-70" />
            ) : (
              <ActivityBundles
                bptTemplate={bptTemplate}
                activityBundles={activityBundles}
                onDeleteActivity={onDeleteActivity}
              />
            )}
          </>
        )}

        <div className="flex justify-between align-center py-2">
          {bptTemplate?.stage === BptTemplateStage.Draft ? (
            <RenderWhenAuthorized
              authorizedRoles={ROLE_ACTIONS.administration.bptTemplate.create}
            >
              {/* ------------ Add activity (button) ------------ */}
              <AddActivityBtn
                templateId={templateId}
                stepId={stepId}
                buttonLabel={t("add_activity")}
                onCompleted={(data: any) => {
                  data?.name && adminAlertMsg.onCreateSuccess(data?.name?.text);
                }}
                onError={adminAlertMsg.onCreateError}
              />
            </RenderWhenAuthorized>
          ) : null}
        </div>
      </section>
    </>
  );
};

export default ActivitiesSection;
