import { useMutation, useQuery } from "@apollo/client";
import Alert, { AlertTypes } from "components/atoms/Alert";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import SafeRenderHtml from "components/atoms/SafeRenderHtml";
import SectionCard from "components/atoms/SectionCard";
import GraphqlError from "components/GraphqlError";
import {
  maybeParseBilingualName,
  maybeParseIdNameType,
  maybeParseRichText,
} from "components/molecules/changeLog/changelogUtil";
import ConfirmationModal, {
  useConfirmationModal,
} from "components/organisms/ConfirmationModal";
import { REASON_FOR_CHANGE_ACCEPTED_COSEWIC_NAME_CHANGE } from "config/constants";
import { RenderWhenAuthorized } from "features/auth/components";
import { Roles } from "features/auth/roles";
import { useOverviewContext } from "features/overview";
import {
  ChangeTypeType,
  CosewicWsNameChange,
  ListingWsNameChangeDocument,
  ListingWsNameChangeInput,
  WsNameDiffWarningDocument,
} from "generated/gql-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { convertPathToTag } from "util/changeLog/convertPathToTag";
import { formatTimestamp } from "util/formatTimestamp";
import htmlRemoveOuterPTag from "util/htmlRemoveOuterPTag";

interface WSNameDiffWarningProps {
  listingWsId: string;
}

const WSNameDiffWarning: React.FC<WSNameDiffWarningProps> = (props) => {
  const { listingWsId } = props;
  const { t, i18n } = useTranslation();
  const overviewContext = useOverviewContext();

  const {
    data,
    loading: loadingQuery,
    error: errorLoading,
  } = useQuery(WsNameDiffWarningDocument, {
    variables: {
      listingWsId: listingWsId,
    },
  });

  const [updateListingWs, { loading: loadingMutation, error: errorSaving }] =
    useMutation(ListingWsNameChangeDocument, {
      refetchQueries: ["ListingProfile", "WSNameDiffWarning"],
      errorPolicy: "all",
    });

  const onAcceptNameChange = async (
    listingNameChange: CosewicWsNameChange | null
  ) => {
    if (listingNameChange == null) {
      console.error("Error - No name changes found!");
      return;
    }
    const _changeMetaData = {
      changeType: ChangeTypeType.Minor,
      reasonForChange: REASON_FOR_CHANGE_ACCEPTED_COSEWIC_NAME_CHANGE,
    };

    let nameChange: Partial<ListingWsNameChangeInput> = {};
    let currentValueObj = null;

    try {
      switch (listingNameChange.pathProperty) {
        case "/commonNameEnglish":
          nameChange = {
            commonNameEnglish: {
              name: {
                text: maybeParseRichText(listingNameChange.currentValue),
              },
            },
            commonNameEnglishChangeMetaData: _changeMetaData,
          };
          break;
        case "/commonNameFrench":
          nameChange = {
            commonNameFrench: {
              name: {
                text: maybeParseRichText(listingNameChange.currentValue),
              },
            },
            commonNameFrenchChangeMetaData: _changeMetaData,
          };
          break;
        case "/nameWithComma":
          nameChange = {
            nameWithComma: {
              name: {
                text: maybeParseRichText(listingNameChange.currentValue),
              },
            },
            nameWithCommaChangeMetaData: _changeMetaData,
          };
          break;
        case "/population":
          currentValueObj = maybeParseBilingualName(
            listingNameChange.currentValue
          );
          //Population can be set to null
          nameChange = {
            population: currentValueObj,
            populationChangeMetaData: _changeMetaData,
          };
          break;
        case "/genus":
          currentValueObj = maybeParseIdNameType(
            listingNameChange.currentValue
          );
          if (currentValueObj == null) throw new Error("Invalid genus object");
          nameChange = {
            genus: currentValueObj,
            genusChangeMetaData: _changeMetaData,
          };
          break;
        case "/species":
          currentValueObj = maybeParseIdNameType(
            listingNameChange.currentValue
          );
          if (currentValueObj == null)
            throw new Error("Invalid species object");
          nameChange = {
            species: currentValueObj,
            speciesChangeMetaData: _changeMetaData,
          };
          break;
        case "/subSpecies":
          currentValueObj = maybeParseIdNameType(
            listingNameChange.currentValue
          );
          // if (currentValueObj == null)
          //   throw new Error("Invalid subSpecies object");
          nameChange = {
            subSpecies: currentValueObj,
            subSpeciesChangeMetaData: _changeMetaData,
            subSpeciesPrefix: listingNameChange.subSpeciesPrefix,
          };
          break;
        case "/variety":
          currentValueObj = maybeParseIdNameType(
            listingNameChange.currentValue
          );
          // if (currentValueObj == null)
          //   throw new Error("Invalid variety object");
          nameChange = {
            variety: currentValueObj,
            varietyChangeMetaData: _changeMetaData,
            varietyPrefix: listingNameChange.varietyPrefix,
          };
          break;
      }

      const res = await updateListingWs({
        variables: {
          listingWsNameChangeId: listingWsId,
          input: nameChange,
        },
      });

      if (res.errors) throw res.errors;

      // Refetch overviewContext to update ribbon and sideNav
      overviewContext.refetch();
    } catch (e) {
      console.error(e);
      console.error("Accept name change error!");
    }
  };

  const isPathPropertyRichText = (pathProperty: string | undefined) => {
    return (
      pathProperty === "/commonNameEnglish" ||
      pathProperty === "/commonNameFrench" ||
      pathProperty === "/nameWithComma"
    );
  };

  const isPathPropertyBilingualName = (pathProperty: string | undefined) => {
    return pathProperty === "/population";
  };

  const isPathPropertyIdNameType = (pathProperty: string | undefined) => {
    return (
      pathProperty === "/genus" ||
      pathProperty === "/species" ||
      pathProperty === "/subSpecies" ||
      pathProperty === "/variety"
    );
  };

  const nameChanges = data?.cosewicWsNameChanges?.changes;
  const showWarning = nameChanges != null && nameChanges.length > 0;

  return errorLoading ? (
    <GraphqlError errors={errorLoading} />
  ) : errorSaving ? (
    <GraphqlError errors={errorSaving} />
  ) : loadingQuery || loadingMutation ? (
    <LoadingIndicator centered className="mrgn-bttm-md" />
  ) : showWarning ? (
    <Alert
      type={AlertTypes.WARNING}
      content={t("ws_name_different_warning_title")}
    >
      <SectionCard
        collapsible
        open={false}
        header={<h3>{t("view_details")}</h3>}
        showLine={false}
        contentAreaProps={{ className: "font-size-16" }}
        className={"mrgn-bttm-0"}
        classNameSummary={"py-2"}
      >
        <RenderWhenAuthorized authorizedRoles={[Roles.ListingAdministrator]}>
          <p className="mrgn-bttm-md">
            {t("ws_name_different_warning_details")}
          </p>
        </RenderWhenAuthorized>

        {nameChanges.map((item) => {
          return (
            <div
              className="flex justify-between mrgn-bttm-md"
              key={item?.pathProperty}
            >
              <div className="flex-col">
                <div>
                  {t("cosewic_name_is", {
                    name: t(convertPathToTag(item?.pathProperty)),
                  })}{" "}
                  "
                  {isPathPropertyRichText(item?.pathProperty) ? (
                    <SafeRenderHtml
                      htmlString={htmlRemoveOuterPTag(
                        maybeParseRichText(item?.currentValue)
                      )}
                    />
                  ) : isPathPropertyBilingualName(item?.pathProperty) ? (
                    maybeParseBilingualName(
                      item?.currentValue,
                      i18n.language
                    ) ?? t("no_data")
                  ) : isPathPropertyIdNameType(item?.pathProperty) ? (
                    <em>
                      {maybeParseIdNameType(item?.currentValue, "name") ??
                        t("no_data")}
                    </em>
                  ) : (
                    item?.currentValue
                  )}
                  ".
                </div>
                <div className="text-muted font-size-14">
                  {item?.nameChangeLinkToAssessment
                    ? t("change_during_assessment_on", {
                        date: formatTimestamp(
                          item?.nameChangeLinkToAssessment?.date
                        ),
                      })
                    : t("not_part_of_an_assessment")}
                </div>
              </div>
              <RenderWhenAuthorized
                authorizedRoles={[
                  Roles.ListingAdministrator,
                  Roles.GlobalAdministrator,
                ]}
              >
                <AcceptButtonAndModal
                  onAccept={() => onAcceptNameChange(item)}
                  listingId={listingWsId}
                />
              </RenderWhenAuthorized>
            </div>
          );
        })}
      </SectionCard>
    </Alert>
  ) : null;
};

export default WSNameDiffWarning;

const AcceptButtonAndModal: React.FC<{
  onAccept: () => void;
  disabled?: boolean;
  listingId: string;
}> = (props) => {
  const { t } = useTranslation();
  const confirmAcceptModal = useConfirmationModal({});
  const [confirmAcceptState, setConfirmAcceptState] =
    React.useState<boolean>(false);

  return (
    <>
      <button
        type="button"
        className="btn btn-link pt-0 text-decoration-underline"
        onClick={confirmAcceptModal.open}
        disabled={props.disabled}
        data-testid={`accept-link-${props.listingId}`}
      >
        {t("accept")}
      </button>
      {props.disabled ? null : (
        <ConfirmationModal
          modalState={confirmAcceptModal}
          title={t("name_change")}
          confirmBtnEnabled={confirmAcceptState}
          onConfirm={props.onAccept}
          onCancel={() => {
            confirmAcceptModal.close();
            setConfirmAcceptState(false);
          }}
        >
          <Alert
            type={AlertTypes.WARNING}
            content={t("accept_name_change_warning")}
          />

          <label>
            <input
              type="checkbox"
              checked={confirmAcceptState}
              onChange={(e) => setConfirmAcceptState((x) => !x)}
              data-testid="modal-checkbox-confirm-change"
            />{" "}
            {t("accept_name_change_confirm")}
          </label>
        </ConfirmationModal>
      )}
    </>
  );
};
