import { useMutation } from "@apollo/client";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import GraphqlError from "components/GraphqlError";
import Layout from "components/layouts/TwoColumn";
import {
  DuplicateChildrenFields,
  DuplicateChildrenForm,
  DuplicateType,
} from "components/molecules/DuplicateChildrenForm";
import SideNav from "components/molecules/SideNav";
import { Ribbon } from "components/organisms/cosewic/Ribbon";
import { useGlobalAlertContext } from "features/globalAlert";
import { useOverviewContext } from "features/overview";
import { SplitCosewicWsDocument } from "generated/gql-types";
import * as React from "react";
import { SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";
import SectionCard from "../../../components/atoms/SectionCard";
import { CosewicPathHelpers } from "../CosewicRouter";

export interface SplitProps {}

const Split: React.FC<SplitProps> = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { params } = useRouteMatch();
  const { cosewicWsId } = params as any;
  const alertContext = useGlobalAlertContext();
  const overviewContext = useOverviewContext();

  React.useEffect(() => {
    overviewContext.updateOverview("cosewicws", cosewicWsId);
  }, [cosewicWsId]);

  const [runSplit, splitMutationStatus] = useMutation(SplitCosewicWsDocument, {
    errorPolicy: "all",
  });

  const onSubmit: SubmitHandler<DuplicateChildrenFields> = async (formData) => {
    try {
      const numberOfDuplicates: number = Number(formData.numberOfDuplicates);

      const res = await runSplit({
        variables: {
          id: cosewicWsId,
          input: { numberOfChildren: numberOfDuplicates },
        },
      });

      if (res.errors) throw res.errors;

      alertContext.showSuccess({
        message: t("split_success_msg", { count: numberOfDuplicates }),
      });
      history.push({ pathname: CosewicPathHelpers.ViewChildren(cosewicWsId) });
    } catch (e) {}
  };

  return (
    <>
      <Ribbon />
      <Layout.Root>
        <Layout.Content>
          {/*TODO: i18n*/}
          <h1>{t("split")}</h1>

          <GraphqlError errors={splitMutationStatus.error} />

          <SectionCard
            header={
              <h2>
                {t("split_settings")}{" "}
                {splitMutationStatus.loading ? (
                  <LoadingIndicator className="mrgn-lft-sm" />
                ) : null}
              </h2>
            }
          >
            <DuplicateChildrenForm
              duplicateType={DuplicateType.SPLIT}
              onSubmit={onSubmit}
            />
          </SectionCard>
        </Layout.Content>
        <Layout.Sidebar>
          <SideNav />
        </Layout.Sidebar>
      </Layout.Root>
    </>
  );
};

export default Split;
