import React from "react";
import {
  DismissButton,
  FocusScope,
  mergeProps,
  useButton,
  useFocus,
  useMenu,
  useMenuItem,
  useMenuTrigger,
  useOverlay,
} from "react-aria";

import { useMenuTriggerState } from "@react-stately/menu";
import { useTreeState } from "@react-stately/tree";

export function MenuButton(props: any) {
  // Create state based on the incoming props
  let state = useMenuTriggerState(props);

  // Get props for the menu trigger and menu elements
  let ref = React.useRef<HTMLButtonElement | null>(null);
  let { menuTriggerProps, menuProps } = useMenuTrigger({}, state, ref);

  // Get props for the button based on the trigger props from useMenuTrigger
  let { buttonProps } = useButton(menuTriggerProps, ref);

  if (props.disabled && state.isOpen) state.close();

  return (
    <div
      style={{
        position: "relative",
        display: "inline-block",
        lineHeight: "23px",
      }}
    >
      <button
        {...buttonProps}
        ref={ref}
        title={props.title}
        className={props.className}
        disabled={props.disabled}
      >
        {props.loading ? (
          <i className="fa fa-spinner fa-spin mrgn-rght-sm" />
        ) : (
          props.buttonIcon
        )}
        {props.label}
      </button>
      {state.isOpen && (
        <div
          style={{
            position: "relative",
            background: "whitesmoke",
            zIndex: 10,
          }}
        >
          <MenuPopup
            {...props}
            {...menuProps}
            autoFocus={state.focusStrategy}
            onClose={() => state.close()}
          />
        </div>
      )}
    </div>
  );
}

function MenuPopup(props: any) {
  // Create menu state based on the incoming props
  let state = useTreeState({ ...props, selectionMode: "none" });

  // Get props for the menu element
  let ref = React.useRef<HTMLElement | null>(null);
  let { menuProps } = useMenu(props, state, ref);

  // Handle events that should cause the menu to close,
  // e.g. blur, clicking outside, or pressing the escape key.
  let overlayRef = React.useRef<HTMLElement | null>(null);
  let { overlayProps } = useOverlay(
    {
      onClose: props.onClose,
      shouldCloseOnBlur: true,
      isOpen: true,
      isDismissable: true,
    },
    overlayRef
  );

  // Wrap in <FocusScope> so that focus is restored back to the
  // trigger when the menu is closed. In addition, add hidden
  // <DismissButton> components at the start and end of the list
  // to allow screen reader users to dismiss the popup easily.
  return (
    <FocusScope restoreFocus>
      <div {...overlayProps} ref={overlayRef as any}>
        <DismissButton onDismiss={props.onClose} />
        <ul
          {...mergeProps(menuProps, props.domProps)}
          ref={ref}
          style={{
            position: "absolute",
            width: "max-content",
            margin: "4px 0 0 0",
            padding: 0,
            listStyle: "none",
            background: "whitesmoke",
            boxShadow: "1px 1px 4px 1px rgba(0, 0, 0, 0.1)",
          }}
        >
          {[...state.collection].map((item) => (
            <MenuItem
              key={item.key}
              item={item}
              state={state}
              onAction={props.onAction}
              onClose={props.onClose}
            />
          ))}
        </ul>
        <DismissButton onDismiss={props.onClose} />
      </div>
    </FocusScope>
  );
}

export function MenuItem(props: {
  item: any;
  state: any;
  onAction: any;
  onClose: any;
}) {
  const { item, state, onAction, onClose } = props;
  // Get props for the menu item element
  let ref = React.useRef<HTMLLIElement | null>(null);
  let { menuItemProps } = useMenuItem(
    {
      key: item.key,
      isDisabled: item.isDisabled,
      onAction,
      onClose,
    },
    state,
    ref
  );

  // Handle focus events so we can apply highlighted
  // style to the focused menu item
  let [isFocused, setFocused] = React.useState(false);
  let { focusProps } = useFocus({ onFocusChange: setFocused });

  return (
    <li
      {...mergeProps(menuItemProps, focusProps)}
      ref={ref}
      style={{
        fontSize: "16px",
        fontFamily: "Lato, sans-serif",
        color: "#295376",
        background: isFocused ? "lightgray" : "transparent",
        padding: "5px 5px",
        outline: "none",
        cursor: "pointer",
        borderRadius: "5px",
      }}
    >
      {item.rendered}
    </li>
  );
}
