import { useMutation, useQuery } from "@apollo/client";
import PlaceholderImage from "assets/svg/placeholder.svg";
import GraphqlError from "components/GraphqlError";
import Alert, { AlertTypes } from "components/atoms/Alert";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import NoResults from "components/atoms/NoResults";
import Layout from "components/layouts/TwoColumn";
import PageSizeSelect from "components/molecules/PageSizeSelect";
import Pagination from "components/molecules/Pagination";
import SideNav from "components/molecules/SideNav";
import RelatedSpeciesListItem from "components/molecules/listing/relatedSpecies/RelatedSpeciesListItem";
import RelatedSpeciesWarning from "components/molecules/listing/relatedSpecies/relatedSpeciesWarning";
import ConfirmationModal, {
  useConfirmationModal,
} from "components/organisms/ConfirmationModal";
import Ribbon from "components/organisms/cosewic/Ribbon";
import { ROLE_ACTIONS, RenderWhenAuthorized } from "features/auth/components";
import { useOverviewContext } from "features/overview";
import {
  DeleteListingWsParentsDocument,
  ListingWs,
  ListingWsProcess,
  ListingWsViewParentsDocument,
} from "generated/gql-types";
import { usePagination } from "hooks/util/usePagination";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link, useRouteMatch } from "react-router-dom";
import { getSortByOrder } from "util/relatedSpecies/listSortBy";
import SectionCard from "../../../../components/atoms/SectionCard";
import { ListingPathHelpers } from "../../ListingRouter";

export interface ViewParentsProps {}

const ViewParents: React.FC<ViewParentsProps> = (props) => {
  const { t, i18n } = useTranslation();
  const { params } = useRouteMatch();
  const { listingWsId } = params as any;
  const pagination = usePagination();
  const overviewContext = useOverviewContext();

  React.useEffect(() => {
    overviewContext.updateOverview("listingws", listingWsId);
  }, [listingWsId]);

  const [sortOrder, setSortOrder] = React.useState<"asc" | "desc">("desc");
  const [sortByInput, setSortByInput] = React.useState("commonName");
  const sortBy = getSortByOrder(sortByInput, sortOrder, i18n.language);

  const { data, loading, error } = useQuery(ListingWsViewParentsDocument, {
    variables: {
      params: {
        id: listingWsId,
        pageNumber: pagination.currentPage,
        pageSize: pagination.pageSize,
        sortBy,
      },
    },
  });

  const [removeParent, removeParentStatus] = useMutation(
    DeleteListingWsParentsDocument,
    {
      refetchQueries: ["ListingWsViewParents", "RelatedSpeciesWarning"],
      errorPolicy: "all",
    }
  );

  const onRemoveParent = async (parentId: string) => {
    try {
      const res = await removeParent({
        variables: {
          listingWsId,
          parentId,
        },
      });

      if (res.errors) throw res.errors;
    } catch (e) {
      console.warn("Error removing parent");
      console.error(e);
    }
  };

  if (error) {
    console.error(error);
    return <p>{t("loading_error")}</p>;
  }

  const onChangeSortBy = async (e: any) => {
    setSortByInput(e.currentTarget.value);
    pagination.goToPage(1);
  };

  const onChangeSortOrder = (e: any) => {
    setSortOrder(e.currentTarget.value);
  };

  const showRemoveButton = true;
  const viewParentsListItems = data?.listingWsParents?.items;

  return (
    <>
      <Ribbon />
      <Layout.Root>
        <Layout.Content>
          {/*TODO: i18n*/}
          <h1>{t("view_parents")}</h1>
          <GraphqlError errors={error} />
          <GraphqlError errors={removeParentStatus.error} />
          <RelatedSpeciesWarning listingWsId={listingWsId} />
          <SectionCard
            header={
              <div className="flex mrgn-tp-sm mrgn-bttm-md">
                <h2 aria-hidden hidden>
                  {t("add_parents")}
                </h2>
                <RenderWhenAuthorized
                  authorizedRoles={ROLE_ACTIONS.listing.create}
                >
                  <Link
                    to={ListingPathHelpers.AddParents(listingWsId)}
                    className="btn btn-default btn-sm mrgn-rght-md"
                    data-testid="link-add-parents"
                  >
                    {t("add_parents")}
                  </Link>
                </RenderWhenAuthorized>
              </div>
            }
          >
            <div className="flex justify-between align-center flex-wrap mrgn-bttm-md">
              <div className="flex">
                <div className="flex-col">
                  <label htmlFor="sel_sort_by">{t("sort_by")}</label>
                  <select
                    id="sel_sort_by"
                    className="form-control mrgn-rght-md"
                    value={sortByInput}
                    onChange={onChangeSortBy}
                  >
                    {/*TODO:enum members*/}
                    <option value="commonName">{t("common_name")}</option>
                    <option value="scientificName">
                      {t("scientific_name")}
                    </option>
                    <option value="population">{t("population")}</option>
                    <option value="listingId">{t("listing_id")}</option>
                  </select>
                </div>

                <div className="flex-col">
                  <label htmlFor="sel_sort_order">{t("sort_order")}</label>
                  <select
                    id="sel_sort_order"
                    className="form-control"
                    value={sortOrder}
                    onChange={onChangeSortOrder}
                  >
                    <option value="asc">{t("ascending")}</option>
                    <option value="desc">{t("descending")}</option>
                  </select>
                </div>
              </div>

              <div className="flex-col align-end justify-between flex-auto">
                {/* <p>Export to Excel</p> */}
                <PageSizeSelect
                  pageSize={pagination.pageSize}
                  onChangePageSize={pagination.setPageSize}
                />
                <div className="font-size-16">
                  {pagination.makeShowingString(
                    data?.listingWsParents?.pagination?.totalCount
                  )}
                </div>
              </div>
            </div>

            {loading ? (
              <LoadingIndicator centered className="mrgn-bttm-md" />
            ) : !viewParentsListItems || viewParentsListItems?.length === 0 ? (
              <NoResults centered />
            ) : (
              viewParentsListItems?.map((item) => {
                const listingWs = item?.listingWs as ListingWs; // Only for type conversion
                const latestListingProcess =
                  item?.latestListingWsProcess as ListingWsProcess;
                return (
                  <div className="list-item separator-line" key={listingWs?.id}>
                    <RelatedSpeciesListItem
                      listingWs={listingWs}
                      listingProcessLatest={latestListingProcess}
                      titleLink={ListingPathHelpers.ListingProfile(
                        listingWs?.id ?? "error"
                      )}
                      imgUrl={PlaceholderImage}
                      showImage={false}
                    >
                      <RenderWhenAuthorized
                        authorizedRoles={ROLE_ACTIONS.listing.delete}
                      >
                        {showRemoveButton ? (
                          <RemoveButtonAndModal
                            onRemove={() => onRemoveParent(listingWs?.id)}
                            disabled={!showRemoveButton}
                            listingId={listingWs?.id}
                          />
                        ) : null}
                      </RenderWhenAuthorized>
                    </RelatedSpeciesListItem>
                  </div>
                );
              })
            )}
          </SectionCard>

          <Pagination
            {...pagination.paginationComponentProps}
            totalPages={data?.listingWsParents?.pagination?.totalPages ?? 0}
          />
        </Layout.Content>
        <Layout.Sidebar>
          <SideNav />
        </Layout.Sidebar>
      </Layout.Root>
    </>
  );
};

export default ViewParents;

const RemoveButtonAndModal: React.FC<{
  onRemove: () => void;
  disabled?: boolean;
  listingId: string;
}> = (props) => {
  const { t } = useTranslation();
  const confirmRemoveModal = useConfirmationModal({});
  const [confirmRemoveState, setConfirmRemoveState] =
    React.useState<boolean>(false);

  return (
    <>
      <RenderWhenAuthorized authorizedRoles={ROLE_ACTIONS.listing.update}>
        <button
          type="button"
          className="btn btn-warning btn-xs"
          onClick={confirmRemoveModal.open}
          disabled={props.disabled}
          data-testid={`remove-btn-${props.listingId}`}
        >
          {t("remove")}
        </button>
      </RenderWhenAuthorized>
      {props.disabled ? null : (
        <ConfirmationModal
          modalState={confirmRemoveModal}
          title={t("remove_parent")}
          confirmBtnEnabled={confirmRemoveState}
          onConfirm={props.onRemove}
          onCancel={() => {
            confirmRemoveModal.close();
            setConfirmRemoveState(false);
          }}
        >
          <Alert
            type={AlertTypes.WARNING}
            content={t("remove_parent_warning")}
          />

          <label>
            <input
              type="checkbox"
              checked={confirmRemoveState}
              onChange={(e) => setConfirmRemoveState((x) => !x)}
              data-testid="modal-checkbox-confirm-remove"
            />{" "}
            {t("remove_confirm")}
          </label>
        </ConfirmationModal>
      )}
    </>
  );
};
