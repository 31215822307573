import NoResults from "components/atoms/NoResults";
import SafeRenderHtml from "components/atoms/SafeRenderHtml";
import SectionCard from "components/atoms/SectionCard";
import { RenderWhenAuthorized, ROLE_ACTIONS } from "features/auth/components";
import { BptProject, BptProjectPhase, Maybe } from "generated/gql-types";
import useDetailsState from "hooks/util/useDetailsState";
import { BptPathHelpers } from "pages/bpt/bptRouter";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import htmlRemoveOuterPTag from "util/htmlRemoveOuterPTag";
import StatusTag from "../../Utility/StatusTag";
import ProjectPhaseInformation from "./ProjectPhaseInformation";
import ProjectPhasesCardHeader from "./ProjectPhasesCardHeader";

interface ProjectPhasesCardProps {
  bptProject?: Maybe<BptProject>;
  projectPhases?: Maybe<BptProjectPhase>[] | null;
}

export const ProjectPhasesCard: React.FC<ProjectPhasesCardProps> = (props) => {
  const { t } = useTranslation();
  const { bptProject, projectPhases } = props;

  const phaseDetailsState = useDetailsState(projectPhases); // This is for expandAll/collapseAll buttons

  return (
    <>
      <SectionCard
        header={
          <ProjectPhasesCardHeader
            projectPhases={projectPhases}
            onExpandAll={phaseDetailsState.expandAll}
            onCollapseAll={phaseDetailsState.collapseAll}
          />
        }
        contentAreaProps={{ className: "mrgn-tp-sm" }}
        showLine={false}
      >
        {/* ------------ Phases section message ------------ */}
        <p>{t("phases_section_message")}</p>

        {projectPhases == null || projectPhases?.length === 0 ? (
          <NoResults centered />
        ) : (
          projectPhases?.map((item, index) => {
            return (
              <details
                key={index}
                className="detail-list detail-list-phase bg-grey expand-collapse-list"
                open={phaseDetailsState.getOpenState(item?.id)}
                onClick={(e) => {
                  e.preventDefault();
                  phaseDetailsState.toggleOpenState(item?.id);
                }}
              >
                <summary>
                  <div className="flexbug-9-workaround">
                    <div className="flex justify-between align-center">
                      <h3>
                        {index! + 1}
                        {". "}
                        <SafeRenderHtml
                          htmlString={htmlRemoveOuterPTag(item?.name?.text!)}
                        />
                      </h3>
                      <div className="flex justify-between gap-sm align-center">
                        <RenderWhenAuthorized
                          authorizedRoles={ROLE_ACTIONS.bptProject.view}
                        >
                          <StatusTag
                            stage={item?.stage}
                            isDelay={item?.delayed!}
                          />
                          <Link
                            to={{
                              pathname: BptPathHelpers.ProjectPhaseIndex(
                                bptProject?.id ?? "error",
                                item?.id ?? "error"
                              ),
                            }}
                            title={t("view_details")}
                            className="card-header-button-link hover-underline font-size-14 hover-grey mrgn-lft-10"
                            data-testid={"step-link-view-details" + index}
                          >
                            <i className="fas fa-eye mrgn-rght-sm"></i>
                            {t("view_details")}
                          </Link>
                        </RenderWhenAuthorized>
                      </div>
                    </div>
                  </div>
                </summary>

                <ProjectPhaseInformation projectPhaseInfo={item} />
              </details>
            );
          })
        )}
      </SectionCard>
    </>
  );
};
