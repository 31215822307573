import Alert, { AlertTypes } from "components/atoms/Alert";
import ConfirmationModal, {
  useConfirmationModal,
} from "components/organisms/ConfirmationModal";
import useCheckbox from "hooks/util/useCheckbox";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export const CreateNewVersionButtonAndModal: React.FC<{
  canCreateNewVersion: boolean;
  onCreateNewVersion: () => Promise<void>;
  title: string;
  warningMessage: string;
  buttonText: string;
  ConfirmationMessage: string;
}> = (props) => {
  const { t } = useTranslation();
  const confirmCreateNewVersionModal = useConfirmationModal({});
  const checkboxState = useCheckbox(false);

  const [showButtonSpinner, setShowButtonSpinner] = useState(false);
  const onCreateNewVersionWithSpinner = () => {
    setShowButtonSpinner(true);
    props.onCreateNewVersion().finally(() => {
      setShowButtonSpinner(false);
    });
  };

  return (
    <>
      <button
        type="button"
        title={props.title}
        className="btn btn-default btn-sm mrgn-rght-sm"
        disabled={!props.canCreateNewVersion}
        onClick={confirmCreateNewVersionModal.open}
        data-testid="button-create-new-version"
      >
        {showButtonSpinner ? (
          <>
            <i className="fa fa-spinner fa-spin mrgn-rght-sm" />
            <span className="wb-inv">&nbsp;{t("loading")}...</span>
          </>
        ) : (
          <i className="far fa-edit mrgn-rght-sm"></i>
        )}
        {t(props.buttonText)}
      </button>

      <ConfirmationModal
        modalState={confirmCreateNewVersionModal}
        title={props.title}
        confirmBtnEnabled={checkboxState.checked}
        onConfirm={onCreateNewVersionWithSpinner}
        onCancel={() => {
          confirmCreateNewVersionModal.close();
          checkboxState.reset();
        }}
      >
        <Alert type={AlertTypes.INFO} content={props.warningMessage} />

        <div className="checkbox">
          <label>
            <input
              type="checkbox"
              checked={checkboxState.checked}
              onChange={checkboxState.toggle}
              data-testid="modal-checkbox-confirm-create-new-version"
            />
            &nbsp;
            {props.ConfirmationMessage}
          </label>
        </div>
      </ConfirmationModal>
    </>
  );
};

export default CreateNewVersionButtonAndModal;
