import * as React from "react";
import { useTranslation } from "react-i18next";

export interface NoAnswerProps {
  message?: string;
}

export const NoAnswer: React.FC<NoAnswerProps> = (props) => {
  const { message } = props;
  const { t } = useTranslation();

  return (
    <span className="opct-70 font-style-italic">{message ?? t("n_a")}</span>
  );
};

export default NoAnswer;
