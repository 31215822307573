import global from "./global";
import errorMessages from "./errorMessages";
import topNav from "./TopNav";

import home from "./home";
import genus from "./admin/genus";
import species from "./admin/species";
import subspecies from "./admin/subspecies";
import variety from "./admin/variety";
import population from "./admin/population";
import taxonomicgroup from "./admin/taxonomicgroup";
import range from "./admin/range";
import lands from "./lands";
import responseStatement from "./admin/responseStatement";
import overview from "./overview";
import publicProfile from "./publicProfile";
import cosewic from "./cosewic";
import cosewicEnum from "./cosewic/enum";
import assessment from "./cosewic/assessment";
import cosewicChangeLog from "./cosewic/changeLog";
import listing from "./listing";
import listingEnum from "./listing/enum";
import listingChangeLog from "./listing/changeLog";
import changeLog from "./changeLog";
import role from "./role";
import wet from "./wet";
import search from "./search";
import searchFieldSelector from "./search/fieldSelector";
import searchOperatorSelector from "./search/operatorSelector";
import permit from "./permit";
import listingResponseStatement from "./responseStatement";
import organization from "./admin/organization";
import contact from "./admin/contacts";
import admin from "./admin";
import documents from "./documents";
import communications from "./communications";
import user from "./admin/users";
import bptTemplates from "./admin/bptTemplates";
import bptProjects from "./bptProjects";

export const resources = {
  en: {
    translation: {
      ...global.en,
      ...errorMessages.en,
      ...topNav.en,
      ...home.en,
      ...admin.en,
      ...genus.en,
      ...species.en,
      ...subspecies.en,
      ...variety.en,
      ...population.en,
      ...taxonomicgroup.en,
      ...range.en,
      ...lands.en,
      ...responseStatement.en,
      ...overview.en,
      ...publicProfile.en,
      ...cosewic.en,
      ...cosewicEnum.en,
      ...assessment.en,
      ...cosewicChangeLog.en,
      ...listing.en,
      ...listingEnum.en,
      ...listingChangeLog.en,
      ...changeLog.en,
      ...role.en,
      ...wet.en,
      ...search.en,
      ...searchFieldSelector.en,
      ...searchOperatorSelector.en,
      ...permit.en,
      ...listingResponseStatement.en,
      ...organization.en,
      ...contact.en,
      ...documents.en,
      ...communications.en,
      ...user.en,
      ...bptTemplates.en,
      ...bptProjects.en,
    },
  },
  fr: {
    translation: {
      ...global.fr,
      ...errorMessages.fr,
      ...topNav.fr,
      ...home.fr,
      ...admin.fr,
      ...genus.fr,
      ...species.fr,
      ...subspecies.fr,
      ...variety.fr,
      ...population.fr,
      ...taxonomicgroup.fr,
      ...range.fr,
      ...lands.fr,
      ...responseStatement.fr,
      ...overview.fr,
      ...publicProfile.fr,
      ...cosewic.fr,
      ...cosewicEnum.fr,
      ...assessment.fr,
      ...cosewicChangeLog.fr,
      ...listing.fr,
      ...listingEnum.fr,
      ...listingChangeLog.fr,
      ...changeLog.fr,
      ...role.fr,
      ...wet.fr,
      ...search.fr,
      ...searchFieldSelector.fr,
      ...searchOperatorSelector.fr,
      ...permit.fr,
      ...listingResponseStatement.fr,
      ...organization.fr,
      ...contact.fr,
      ...documents.fr,
      ...communications.fr,
      ...user.fr,
      ...bptTemplates.fr,
      ...bptProjects.fr,
    },
  },
};
