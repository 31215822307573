import { ApolloError, useMutation } from "@apollo/client";
import { OverlayContainer } from "@react-aria/overlays";
import type { OverlayTriggerState } from "@react-stately/overlays";
import { useOverlayTriggerState } from "@react-stately/overlays";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import GraphqlError from "components/GraphqlError";
import ModalDialog from "components/molecules/Modal";
import { useGlobalAlertContext } from "features/globalAlert";
import {
  BptProjectStep,
  Maybe,
  UpdateBptProjectStepDocument,
} from "generated/gql-types";
import * as React from "react";
import { SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  domainModelIntoProjectStepForm,
  projectStepFormIntoCreateInput,
} from "../../Utility/bptProjectFormUtil";
import ProjectStepForm, { ProjectStepFormFields } from "../ProjectStepForm";

export interface EditProjectStepModalProps {
  modalState: OverlayTriggerState;
  projectId?: Maybe<string>;
  projectStepInfo?: Maybe<BptProjectStep>;
  hasActivities?: boolean;
  isStepCompleted?: boolean;
  onCompleted: (data: any) => void;
  onError: (error: ApolloError | undefined) => void;
}

const EditProjectStepModal: React.FC<EditProjectStepModalProps> = (props) => {
  const { t } = useTranslation();
  const alertContext = useGlobalAlertContext();
  const {
    modalState,
    projectId,
    projectStepInfo,
    hasActivities,
    isStepCompleted,
  } = props;

  const [updateBptProjectStep, { loading: saving, error, reset }] = useMutation(
    UpdateBptProjectStepDocument,
    {
      refetchQueries: ["BptProjectStepList"],
      onCompleted: props.onCompleted,
      onError: props.onError,
      errorPolicy: "all",
    }
  );

  const onSubmit: SubmitHandler<Partial<ProjectStepFormFields>> = async (
    formData
  ) => {
    const input = projectStepFormIntoCreateInput(formData);
    try {
      const res = await updateBptProjectStep({
        variables: {
          projectId: projectId ?? "error",
          stepId: projectStepInfo?.id ?? "error",
          input: input,
        },
      });

      if (res.errors == null) {
        modalState.close();
        alertContext.showSuccess({
          message: t("successfully_updated_project_step"),
          timeOut: 5000,
        });
      }
    } catch (e) {
      console.warn("Error saving new project step");
      console.error(e);
    }
  };

  const onClose = () => {
    modalState.close();
    reset();
  };

  if (!modalState.isOpen) return null;

  const formDefaultValues = domainModelIntoProjectStepForm(projectStepInfo);

  return (
    <>
      <OverlayContainer>
        <ModalDialog
          title={projectStepInfo?.name?.plainText ?? ""}
          className={"modal-width-40"}
          isOpen
          onClose={onClose}
          showModalFooter={false}
        >
          <GraphqlError title={t("error_update_project_step")} errors={error} />
          {saving && <LoadingIndicator centered />}
          <ProjectStepForm
            defaultValues={formDefaultValues}
            hasProjectActivities={hasActivities}
            onSubmit={onSubmit}
            onClose={onClose}
            isSaving={saving}
            isStepCompleted={isStepCompleted}
          />
        </ModalDialog>
      </OverlayContainer>
    </>
  );
};

export default EditProjectStepModal;

export const useProjectStepModal = useOverlayTriggerState;
