import { ApolloError } from "@apollo/client";
import { OverlayContainer } from "@react-aria/overlays";
import type { OverlayTriggerState } from "@react-stately/overlays";
import { useOverlayTriggerState } from "@react-stately/overlays";
import ModalDialog from "components/molecules/Modal";
import * as React from "react";
import { useTranslation } from "react-i18next";
import AddCommunicationsForm, {
  AttachmentsFields,
} from "../CommunicationsForm/AddCommunicationsForm";

export interface AddFilesPopupProps {
  modalState: OverlayTriggerState;
  projectId: string;
  defaultValues?: AttachmentsFields;
  refetchQuery: () => void;
  onCompleted: (data: any) => void;
  onError: (error: ApolloError | undefined) => void;
}

const AddFilesPopup: React.FC<AddFilesPopupProps> = (props) => {
  const { t } = useTranslation();
  const { modalState, projectId, defaultValues, refetchQuery } = props;

  const onClose = () => {
    modalState.close();
    refetchQuery();
    // reset();
  };

  if (!modalState.isOpen) return null;
  return (
    <OverlayContainer>
      <ModalDialog
        title={t("add_files")}
        className={"modal-width-70"}
        isOpen
        onClose={onClose}
        showModalFooter={false}
        // isDismissable
      >
        {/* <GraphqlError title="" errors={error} /> */}
        {/* {loading && <LoadingIndicator centered />} */}
        <AddCommunicationsForm
          projectId={projectId}
          defaultValues={defaultValues}
          onClose={onClose}
        />
      </ModalDialog>
    </OverlayContainer>
  );
};

export default AddFilesPopup;

export const useAddFilesPopup = useOverlayTriggerState;
export type { AttachmentsFields };
