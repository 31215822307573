import { type ReactElement } from "react";
import * as React from "react";
import {
  AutocompleteForBptProjectDocument,
  BptTemplateReference,
  BptTemplateStage,
} from "generated/gql-types";
import { useTranslation } from "react-i18next";
import QueryAutocomplete from "../../../organisms/QueryAutocomplete/QueryAutocomplete";

const additionalQueryVariables = {
  params: {
    searchType: "contains",
    isCaseSensitive: false,
    sortBy: "name.plainText:asc",
    stage: BptTemplateStage.Active.toString(),
  },
};

const BptTemplateAutocomplete: React.FC<BptTemplateAutocompleteProps> = (
  props
) => {
  const { t } = useTranslation();
  return (
    <QueryAutocomplete
      id={"bptTemplateAutocomplete"}
      label={props.label}
      queryDocument={AutocompleteForBptProjectDocument}
      listDisplayFormatter={(item) =>
        item.name.plainText + "(v." + item.templateVersion + ")"
      }
      onSelectionChange={props.onSelectionChange}
      placeholder={props?.initialValue?.name?.plainText ?? t("bpt_Template")}
      isRequired={props.isRequired}
      onClear={() => {
        props.onSelectionChange(null);
      }}
      additionalQueryVariables={additionalQueryVariables}
      disabled={props.disabled}
    />
  );
};

interface BptTemplateAutocompleteProps {
  isRequired?: boolean;
  onSelectionChange: (selectedItem?: BptTemplateReference | null) => void;
  label: string | ReactElement;
  disabled: boolean | undefined;
  initialValue?: BptTemplateReference | null;
}

export default BptTemplateAutocomplete;
