import { useMutation, useQuery } from "@apollo/client";
import GraphqlError from "components/GraphqlError";
import { AlertTypes } from "components/atoms/Alert";
import DeleteButtonModal from "components/atoms/DeleteButtonModal";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import SafeRenderHtml from "components/atoms/SafeRenderHtml";
import Layout from "components/layouts/OneColumn";
import PhaseInformationCard from "components/molecules/admin/bptTemplates/Phase/PhaseInformationCard/PhaseInformationCard";
import PhaseSelectionDropdownForm, {
  PhaseSelectionFields,
} from "components/molecules/admin/bptTemplates/Step/PhaseSelectionDropdownForm/PhaseSelectionDropdownForm";
import StepsCard from "components/molecules/admin/bptTemplates/Step/StepsCard/StepsCard";
import ReturnToButton from "components/molecules/admin/bptTemplates/utility/ReturnToButton";
import { RenderWhenAuthorized, ROLE_ACTIONS } from "features/auth/components";
import { GlobalAlert, useGlobalAlertContext } from "features/globalAlert";
import {
  BptTemplateDocument,
  BptTemplatePhaseDocument,
  BptTemplatePhaseListDocument,
  BptTemplateStage,
  BptTemplateStepListDocument,
  DeleteBptTemplatePhaseDocument,
  DeleteBptTemplateStepDocument,
  Scalars,
} from "generated/gql-types";
import * as React from "react";
import { flushSync } from "react-dom";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";
import htmlRemoveOuterPTag from "util/htmlRemoveOuterPTag";
import { AdminPathHelpers } from "../../../../AdminRouter";

const PhaseIndexPage: React.FC = (props) => {
  const { t } = useTranslation();
  const { params } = useRouteMatch();
  const { templateId, phaseId } = params as any;
  const [isOpen, setIsOpen] = React.useState(false);
  const alertContext = useGlobalAlertContext();
  const history = useHistory();

  // Query Template information
  const { loading, error, data } = useQuery(BptTemplateDocument, {
    variables: {
      templateId: templateId,
    },
    skip: templateId == null,
    fetchPolicy: "network-only",
    errorPolicy: "all",
  });

  // Query phase information
  const {
    loading: loadingPhase,
    error: errorPhase,
    data: dataPhase,
  } = useQuery(BptTemplatePhaseDocument, {
    variables: {
      templateId: templateId,
      phaseId: phaseId,
    },
    skip: templateId == null || phaseId == null,
    fetchPolicy: "network-only",
    errorPolicy: "all",
  });

  // Query phase list information of the template above
  const {
    loading: loadingPhaseList,
    error: errorPhaseList,
    data: dataPhaseList,
  } = useQuery(BptTemplatePhaseListDocument, {
    variables: {
      params: {
        templateId: templateId,
        pageNumber: 1,
        pageSize: 200,
      },
    },
    skip: templateId == null,
    fetchPolicy: "network-only",
    errorPolicy: "all",
  });

  // Query step list information of the template/phase above
  const {
    loading: loadingStepList,
    error: errorStepList,
    data: dataStepList,
  } = useQuery(BptTemplateStepListDocument, {
    variables: {
      params: {
        templateId: templateId,
        phaseId: phaseId,
        pageNumber: 1,
        pageSize: 50,
      },
    },
    skip: templateId == null,
    fetchPolicy: "network-only",
    errorPolicy: "all",
  });

  const [deleteStep, { loading: loadingDeleteStep, error: errorDeleteStep }] =
    useMutation(DeleteBptTemplateStepDocument, {
      refetchQueries: ["BptTemplateStepList", "BptTemplatePhase"],
      errorPolicy: "all",
    });

  const [
    deletePhase,
    { loading: loadingDeletePhase, error: errorDeletePhase },
  ] = useMutation(DeleteBptTemplatePhaseDocument, {
    refetchQueries: ["BptTemplatePhaseList"],
    errorPolicy: "all",
  });

  const onDeletePhase = async (
    templateId: Scalars["ID"],
    phaseId: Scalars["ID"]
  ) => {
    try {
      const res = await deletePhase({
        variables: {
          templateId: templateId,
          phaseId: phaseId,
        },
      });

      if (res.errors) throw res.errors;

      alertContext.showSuccess({
        message: t("successfully_delete_phase"),
        timeOut: 5000,
      });

      history.push({
        pathname: AdminPathHelpers.BptTemplatesIndex(templateId),
      });
    } catch (e) {
      console.error(e);
    }
  };

  const onDeleteStep = async (
    templateId: Scalars["ID"],
    stepId: Scalars["ID"]
  ) => {
    try {
      const res = await deleteStep({
        variables: {
          templateId: templateId,
          stepId: stepId,
        },
      });

      if (res.errors) throw res.errors;

      alertContext.showSuccess({
        message: t("successfully_delete_step"),
        timeOut: 5000,
      });
    } catch (e) {
      console.error(e);
    }
  };

  const onCollapseAll = async () => {
    $("details").removeAttr("open");
    setIsOpen(false);
  };

  const onPhaseSelectionSubmit = (phaseSelection: PhaseSelectionFields) => {
    flushSync(() => {
      history.push({
        pathname: AdminPathHelpers.BptTemplatesPhaseIndex(
          templateId,
          phaseSelection.phaseId
        ),
      });
    });
  };

  return (
    <>
      <Layout.Root>
        <Layout.Content>
          {/* ----- Phase name title ----- */}
          <h1>
            <SafeRenderHtml
              htmlString={htmlRemoveOuterPTag(
                dataPhase?.bptTemplatePhase?.name?.text
              )}
            />
          </h1>
          {/* ----- Alert/Errors section ----- */}
          <GlobalAlert />
          <GraphqlError
            title={t("error_loading_bpt_Template")}
            errors={error}
          />
          <GraphqlError
            title={t("error_loading_bpt_template_phase")}
            errors={errorPhase}
          />
          <GraphqlError
            title={t("error_loading_bpt_template_phase_list")}
            errors={errorPhaseList}
          />
          <GraphqlError
            title={t("error_loading_bpt_template_step_list")}
            errors={errorStepList}
          />

          {loading || loadingPhase || loadingPhaseList || loadingStepList ? (
            <LoadingIndicator />
          ) : !error && !errorPhase && !errorPhaseList && !loadingStepList ? (
            <>
              <div className="flex justify-between align-center mrgn-bttm-md">
                {/* ----- Switch to other phase/Phase selection section ----- */}
                <PhaseSelectionDropdownForm
                  onSubmit={onPhaseSelectionSubmit}
                  phaseList={dataPhaseList?.bptTemplatePhaseList?.phases}
                  currentPhaseId={phaseId}
                />

                {/* ----- Delete phase button ----- */}
                {data?.bptTemplate?.stage === BptTemplateStage.Draft ? (
                  <div>
                    <RenderWhenAuthorized
                      authorizedRoles={
                        ROLE_ACTIONS.administration.bptTemplate.update
                      }
                    >
                      <DeleteButtonModal
                        buttonText={t("delete_phase")}
                        buttonTitle={t("delete_phase")}
                        modalTitle={t("delete_phase")}
                        alertContent={t("html_delete_phase_warning")}
                        alertConfirm={t("i_confirm")}
                        alertType={AlertTypes.DANGER}
                        onDelete={() =>
                          onDeletePhase(
                            templateId ?? "error",
                            phaseId ?? "error"
                          )
                        }
                        isButtonStyle={true}
                        loading={loadingDeletePhase}
                      />
                    </RenderWhenAuthorized>
                  </div>
                ) : null}
              </div>

              {/* ----- Phase information section ----- */}
              <PhaseInformationCard
                bptTemplate={data?.bptTemplate}
                phaseInfo={dataPhase?.bptTemplatePhase}
              />

              {/* ----- Steps Section ----- */}
              <StepsCard
                bptTemplate={data?.bptTemplate}
                phaseInfo={dataPhase?.bptTemplatePhase}
                stepBundles={dataStepList?.bptTemplateStepList?.stepBundles}
                isOpen={isOpen}
                onCollapseAll={onCollapseAll}
                onDeleteStep={onDeleteStep}
              />

              {/* ------------- Return to template page (button) ------------- */}
              <ReturnToButton
                targetText={data?.bptTemplate?.name?.text!}
                link={AdminPathHelpers.BptTemplatesIndex(templateId)}
              />
            </>
          ) : null}
        </Layout.Content>
      </Layout.Root>
    </>
  );
};

export default PhaseIndexPage;
