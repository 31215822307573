import * as React from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import {
  Maybe,
  NaicsResource,
  NaicsSubLevelListDocument,
} from "../../../generated/gql-types";
import { NAICS_VERSION } from "../../../config/constants";
import LoadingIndicator from "../../atoms/LoadingIndicator";
import { Controller, ControllerProps } from "react-hook-form";
import { Control, FieldValues } from "react-hook-form/dist/types";

export interface NAICSSublevelDropdownProps {
  searchCode: string;
  label: string;
  value?: NaicsResource;
  onChange: (resource?: Maybe<NaicsResource>) => void;
}

export const NAICSSublevelDropdown = React.forwardRef<
  HTMLSelectElement,
  NAICSSublevelDropdownProps
>(({ searchCode, onChange, value, ...props }, ref) => {
  const { t, i18n } = useTranslation();
  const { loading, error, data } = useQuery(NaicsSubLevelListDocument, {
    variables: {
      params: {
        code: searchCode,
        pageNumber: 1,
        pageSize: 50,
        version: NAICS_VERSION,
      },
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
    errorPolicy: "all",
  });

  const findResourceOnChange = (e: any) => {
    const code = e.currentTarget.value;
    const resource = data?.naicsSublevelList?.naics.find(
      (x) => x?.code === code
    );
    onChange(resource);
  };

  if (loading)
    return (
      <label>
        {props.label}
        <div>
          <LoadingIndicator />
        </div>
      </label>
    );

  if (!loading && !error && data?.naicsSublevelList?.naics.length === 0) {
    return (
      <label>
        {props.label}
        <p>
          <i>N/A</i>
        </p>
      </label>
    );
  }

  return (
    <label className="width-100">
      {props.label}
      <select
        className="form-control width-100"
        ref={ref}
        {...props}
        defaultValue={value?.code ?? undefined}
        onChange={findResourceOnChange}
        required
      >
        <option disabled selected hidden>
          {t("select_a_value")}
        </option>

        {data?.naicsSublevelList?.naics.map((x) => {
          return (
            <option key={x?.code} value={x?.code ?? "error - missing code"}>
              {x?.code}
              {" - "}
              {i18n.language === "fr"
                ? x?.className?.french ?? ""
                : x?.className?.english ?? ""}
            </option>
          );
        })}
      </select>
    </label>
  );
});

export interface NAICSSublevelDropdownWithControllerProps<
  TFieldValues extends FieldValues
> extends Omit<NAICSSublevelDropdownProps, "onChange" | "value">,
    Omit<ControllerProps<TFieldValues>, "render"> {
  render?: never;
  control: Control<TFieldValues>;
}

export const NAICSSublevelDropdownWithController = <
  TFieldValues extends FieldValues
>({
  label,
  searchCode,
  ...props
}: NAICSSublevelDropdownWithControllerProps<TFieldValues>) => {
  return (
    <Controller
      {...props}
      render={({ field: { value, onChange } }) => (
        <NAICSSublevelDropdown
          value={value as any}
          onChange={onChange}
          label={label}
          searchCode={searchCode}
        />
      )}
    />
  );
};
