const bptProjectsTranslations = {
  en: {
    projects: "Projects",
    business_process_tracking_projects:
      "Business Process Tracking (BPT) projects",
    project_name: "Project name",
    projected_end_date: "Projected end date",
    project_information: "Project information",
    template_name_and_version: "Template name (and version)",
    project_lead: "Project lead",
    estimated_project_timeline: "Estimated project timeline",
    projected_project_timeline: "Projected project timeline",
    actual_project_timeline: "Actual project timeline",
    any_program: "Any program",
    no_program: "No program",
    enter_project_or_template_name: "Enter project or template name",
    project_or_template_name: "Project or template name",
    phases_section_message:
      "Complete all phases to enable marking the project as completed.",
    phase_details: "Phase details",
    estimated_phase_timeline: "Estimated phase timeline",
    projected_phase_timeline: "Projected phase timeline",
    actual_phase_timeline: "Actual phase timeline",
    projected_timeline: "Projected timeline",
    actual_timeline: "Actual timeline",
    links_for_version_tracking: "Link(s) for version tracking",
    upload_link_for_tracking: "Upload link (for tracking)",
    not_started: "Not started",
    in_progress: "In progress",
    completed: "Completed",
    skipped: "Skipped",
    skip: "Skip",
    skip_step: "Skip step",
    skip_activity: "Skip activity",
    steps_section_message:
      "Complete all steps to enable marking the phase as completed.",
    information: "Information",
    sar_registry: "SAR Registry",
    project_step: "Project step",
    project_phase: "Project phase",
    project_activity: "Project activity",
    enter_comments: "Enter comments",
    last_updated_by: "Last updated by: ",
    more_actions: "More actions",
    info_msg_for_links: "Place each different link on a separate line",
    successfully_updated_project_activity:
      "Successfully updated project activity",
    error_update_project_activity: "Error update project activity",
    edit_project_phase: "Edit project phase",
    successfully_updated_project_phase: "Successfully updated project phase",
    error_update_project_phase: "Error update project phase",
    successfully_updated_project_step: "Successfully updated project step",
    error_update_project_step: "Error update project step",
    edit_project_step: "Edit project step",
    delayed: "Delayed",
    error_fetching_projects: "Error fetching Projects",
    project_visibility: "Project visibility",
    added_new_bpt_project: "Added BPT Project",
    create_new_bpt_project: "Create new BPT project",
    create_project: "Create project",
    undo_skip: "Undo skip",
    html_skip_step_warning:
      "<p><strong>Are you sure you want to skip this step?</strong></p><p>Skipping a step would delete all the information of the step and all the timelines will be re-calculated.</p>",
    html_skip_activity_warning:
      "<p><strong>Are you sure you want to skip this activity?</strong></p><p>Skipping an activity would delete all the information of the activity and all the timelines will be re-calculated.</p>",
    error_update_project_step_link: "Error update project step link",
    error_add_project_step_link: "Error add project step link",
    successfully_deleted_project_step_link:
      "Successfully deleted project step link",
    successfully_added_step_link: "Successfully added step link",
    successfully_updated_step_link: "Successfully updated step link",
    project_step_link: "Project step link",
    error_loading_bpt_project: "Error loading bpt project",
    error_loading_bpt_project_phase: "Error loading bpt project phase",
    error_loading_bpt_project_phase_list:
      "Error loading bpt project phase list",
    error_loading_bpt_project_step_list: "Error loading bpt project step list",
    error_loading_bpt_project_activity_list:
      "Error loading bpt project activity list",
    updated_bpt_project: "Updated BPT Project",
    edit_bpt_project: "Edit BPT Project",
    mark_project_as_completed: "Mark project as completed",
    mark_phase_as_completed: "Mark phase as completed",
    html_mark_project_as_completed_warning:
      "<p><strong>Are you sure you want to mark this project as completed?</strong></p><p>You will not be able to make changes to your project. The project will be read only from now on.</p>",
    html_mark_phase_as_completed_warning:
      "<p><strong>Are you sure you want to mark this phase as completed?</strong></p><p>You will not be able to make changes to the actual timelines for the nested steps and activities; however, you will be able to view and edit comments.</p>",
    successfully_update_bpt_project_stage:
      "Successfully update bpt project stage",
    successfully_update_bpt_project_phase_stage:
      "Successfully update bpt project phase stage",
    successfully_update_bpt_project_step_stage:
      "Successfully update bpt project step stage",
    successfully_update_bpt_project_activity_stage:
      "Successfully update bpt project activity stage",
    error_update_bpt_project_stage: "Error update bpt project stage",
    error_update_bpt_project_phase_stage:
      "Error update bpt project phase stage",
    error_update_bpt_project_step_stage: "Error update bpt project step stage",
    error_update_bpt_project_activity_stage:
      "Error update bpt project activity stage",
    hoc: "HOC",
    the_timeline_calculation_cannot_be_done_tooltip:
      "The timeline calculation cannot be done because the system is missing the HOC calendar.",
    n_a: "n/a",
    attached_communications: "Attached communications",
  },

  fr: {
    projects: "Projets",
    business_process_tracking_projects:
      "Projets pour les Suivis de processus d'affaires (SPA)",
    project_name: "Nom du projet",
    projected_end_date: "Date de fin prévue",
    project_information: "[Fr]Project information",
    template_name_and_version: "[Fr]Template name (and version)",
    project_lead: "[Fr]Project lead",
    estimated_project_timeline: "[Fr]Estimated project timeline",
    projected_project_timeline: "[Fr]Projected project timeline",
    actual_project_timeline: "[Fr]Actual project timeline",
    any_program: "N'importe quel programme",
    no_program: "Aucun programme",
    enter_project_or_template_name: "[FR]Enter project or template name",
    project_or_template_name: "[FR]Project or template name",
    phases_section_message:
      "[Fr]Complete all phases to enable marking the project as completed.",
    phase_details: "[FR]Phase details",
    estimated_phase_timeline: "[FR]Estimated phase timeline",
    projected_phase_timeline: "[FR]Projected phase timeline",
    actual_phase_timeline: "[FR]Actual phase timeline",
    projected_timeline: "[FR]Projected timeline",
    actual_timeline: "[FR]Actual timeline",
    links_for_version_tracking: "[FR]Link(s) for version tracking",
    upload_link_for_tracking: "Upload link (for tracking)",
    not_started: "[FR]Not started",
    in_progress: "[FR]In progress",
    completed: "[FR]Completed",
    skipped: "[FR]Skipped",
    skip: "[FR]Skip",
    skip_step: "[FR]Skip step",
    skip_activity: "[FR]Skip activity",
    steps_section_message:
      "[FR]Complete all steps to enable marking the phase as completed.",
    information: "[FR]Information",
    sar_registry: "Registre EEP",
    project_step: "[FR]Project step",
    project_phase: "[FR]Project phase",
    project_activity: "[FR]Project activity",
    enter_comments: "[FR]Enter comments",
    last_updated_by: "[FR]Last updated by: ",
    more_actions: "[FR]More actions",
    info_msg_for_links: "[FR]Place each different link on a separate line",
    successfully_updated_project_activity:
      "[FR]Successfully updated project activity",
    error_update_project_activity: "[FR]Error update project activity",
    edit_project_phase: "[FR]Edit project phase",
    successfully_updated_project_phase:
      "[FR]Successfully updated project phase",
    error_update_project_phase: "[FR]Error update project phase",
    successfully_updated_project_step: "[FR]Successfully updated project step",
    error_update_project_step: "[FR]Error update project step",
    edit_project_step: "[FR]Edit project step",
    delayed: "[FR]Delayed",
    error_fetching_projects: "Error fetching Projects[FR]",
    project_visibility: "Project visibility[FR]",
    create_new_bpt_project: "Create new BPT project[FR]",
    added_new_bpt_project: "Added BPT Project[FR]",
    create_project: "[FR]Create project",
    undo_skip: "[FR]Undo skip",
    html_skip_step_warning:
      "<p><strong>[FR]Are you sure you want to skip this step?</strong></p><p>Skipping a step would delete all the information of the step and all the timelines will be re-calculated.</p>",
    html_skip_activity_warning:
      "<p><strong>[FR]Are you sure you want to skip this activity?</strong></p><p>Skipping an activity would delete all the information of the activity and all the timelines will be re-calculated.</p>",
    error_update_project_step_link: "[FR]Error update project step link",
    error_add_project_step_link: "[FR]Error add project step link",
    successfully_deleted_project_step_link:
      "[FR]Successfully deleted project step link",
    successfully_added_step_link: "[FR]Successfully added step link",
    successfully_updated_step_link: "[FR]Successfully updated step link",
    project_step_link: "[FR]Project step link",
    error_loading_bpt_project: "[FR]Error loading bpt project",
    error_loading_bpt_project_phase: "[FR]Error loading bpt project phase",
    error_loading_bpt_project_phase_list:
      "[FR]Error loading bpt project phase list",
    error_loading_bpt_project_step_list:
      "[FR]Error loading bpt project step list",
    error_loading_bpt_project_activity_list:
      "[FR]Error loading bpt project activity list",
    updated_bpt_project: "Updated BPT Project[FR]",
    edit_bpt_project: "Edit BPT Project[FR]",
    mark_project_as_completed: "[FR]Mark project as completed",
    mark_phase_as_completed: "[FR]Mark phase as completed",
    html_mark_project_as_completed_warning:
      "<p><strong>[FR]Are you sure you want to mark this project as completed?</strong></p><p>You will not be able to make changes to your project. The project will be read only from now on.</p>",
    html_mark_phase_as_completed_warning:
      "<p><strong>[FR]Are you sure you want to mark this phase as completed?</strong></p><p>You will not be able to make changes to the actual timelines for the nested steps and activities; however, you will be able to view and edit comments.</p>",
    successfully_update_bpt_project_stage:
      "[FR]Successfully update bpt project stage",
    successfully_update_bpt_project_phase_stage:
      "[FR]Successfully update bpt project phase stage",
    successfully_update_bpt_project_step_stage:
      "[FR]Successfully update bpt project step stage",
    successfully_update_bpt_project_activity_stage:
      "[FR]Successfully update bpt project activity stage",
    error_update_bpt_project_stage: "[FR]Error update bpt project stage",
    error_update_bpt_project_phase_stage:
      "[FR]Error update bpt project phase stage",
    error_update_bpt_project_step_stage:
      "[FR]Error update bpt project step stage",
    error_update_bpt_project_activity_stage:
      "[FR]Error update bpt project activity stage",
    hoc: "[FR]HOC",
    the_timeline_calculation_cannot_be_done_tooltip:
      "[FR]The timeline calculation cannot be done because the system is missing the HOC calendar.",
    n_a: "[FR]n/a",
    attached_communications: "[Fr]Attached communications",
  },
};

export default bptProjectsTranslations;
