import SafeRenderHtml from "components/atoms/SafeRenderHtml";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import htmlRemoveOuterPTag from "util/htmlRemoveOuterPTag";

export interface ReturnToButtonProps {
  targetText: string;
  link: string;
}

export const ReturnToButton: React.FC<ReturnToButtonProps> = (props) => {
  const { targetText, link } = props;
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <button
      type="button"
      className="btn btn-default"
      onClick={() =>
        history.push({
          pathname: link,
        })
      }
      data-testid="button-return-to"
    >
      {"« "}
      {t("return_to")}{" "}
      {
        <SafeRenderHtml
          htmlString={"<i>" + htmlRemoveOuterPTag(targetText) + "</i>"}
        />
      }
    </button>
  );
};

export default ReturnToButton;
