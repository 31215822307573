import { ApolloError, useMutation } from "@apollo/client";
import { OverlayContainer } from "@react-aria/overlays";
import type { OverlayTriggerState } from "@react-stately/overlays";
import { useOverlayTriggerState } from "@react-stately/overlays";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import ModalDialog from "components/molecules/Modal";
import { Maybe, Scalars, UpdateBptProjectDocument } from "generated/gql-types";
import * as React from "react";
import { SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import GraphqlError from "components/GraphqlError";
import { useGlobalAlertContext } from "features/globalAlert";
import BptProjectForm, {
  BptProjectFormFields,
} from "../Project/BptProjectForm";
import { bptProjectFormIntoUpdateInput } from "../Utility/bptProjectFormUtil";

export interface EditBptProjectPopupProps {
  modalState: OverlayTriggerState;
  refetchQueries?: string[];
  onCompleted?: (data: any) => void;
  onError?: (error: ApolloError | undefined) => void;
  projectId: Maybe<Scalars["ID"]>;
  initialValues?: BptProjectFormFields;
}

const EditBptProjectPopup: React.FC<EditBptProjectPopupProps> = (props) => {
  const { t } = useTranslation();
  const { modalState } = props;
  const alertContext = useGlobalAlertContext();

  const [editBptProject, { loading, error, reset }] = useMutation(
    UpdateBptProjectDocument,
    {
      refetchQueries: props.refetchQueries ?? [],
      onCompleted: props.onCompleted,
      onError: props.onError,
      errorPolicy: "all",
    }
  );

  const onSubmit: SubmitHandler<Partial<BptProjectFormFields>> = async (
    formData
  ) => {
    try {
      const input = bptProjectFormIntoUpdateInput(formData);
      const res = await editBptProject({
        variables: {
          projectId: props?.projectId ?? "",
          input: input,
        },
      });
      alertContext.showSuccess({
        title: t("updated_bpt_project"),
        timeOut: 5000,
      });
      if (res.errors == null) {
        modalState.close();
      }
    } catch (e) {
      console.warn("Error updating BptProject");
      console.error(e);
    }
  };

  const onClose = () => {
    modalState.close();
    reset();
  };

  if (!modalState.isOpen) return null;

  return (
    <OverlayContainer>
      <ModalDialog
        title={t("edit_bpt_project")}
        isOpen
        onClose={onClose}
        showModalFooter={false}
        className="modal-dropdown-visible"
      >
        <GraphqlError errors={error} />
        {loading && <LoadingIndicator centered />}
        <BptProjectForm
          initialValues={props.initialValues}
          isEdit={true}
          onSubmit={onSubmit}
          onClose={onClose}
        />
      </ModalDialog>
    </OverlayContainer>
  );
};

export default EditBptProjectPopup;

export const useEditBptProjectModal = useOverlayTriggerState;
export type { BptProjectFormFields as EditBptProjectFields };
