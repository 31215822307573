/**
 * This file has some helpers to resolve some details based on the environment we're
 * running in on Azure or locally.
 */

export enum RunEnvironment {
  UNRECOGNIZED = "ENV:UNRECOGNIZED",
  LOCAL = "ENV:LOCAL",
  AZURE_DEV = "ENV:AZURE_DEV",
  AZURE_ALTDEV = "ENV:AZURE_ALTDEV",
  AZURE_TEST = "ENV:AZURE_TEST",
  AZURE_MIGRATION = "ENV:AZURE_MIGRATION",
  AZURE_CLIENT = "ENV:AZURE_CLIENT",
  AZURE_PREPROD = "ENV:AZURE_PREPROD",
  AZURE_PROD = "ENV:AZURE_PROD",
}

export const resolveAzureEnvironment = (): RunEnvironment => {
  if (document.location.host.includes("sgsdep.ec.gc.ca")) {
    return RunEnvironment.AZURE_PROD;
  }

  if (document.location.host.includes("altprod.ec.gc.ca")) {
    return RunEnvironment.AZURE_PREPROD;
  }

  if (
    document.location.host.includes(".client.ec.gc") ||
    document.location.host.includes("client4.np.az.ec.gc.ca")
  ) {
    return RunEnvironment.AZURE_CLIENT;
  }

  if (
    document.location.host.includes(".migration.ec.gc") ||
    document.location.host.includes(".migration.np.az.ec.gc.ca")
  ) {
    return RunEnvironment.AZURE_MIGRATION;
  }

  if (document.location.host.includes(".test.ec.gc")) {
    return RunEnvironment.AZURE_TEST;
  }

  if (
    document.location.host.includes(".dev.ec.gc") ||
    document.location.host.includes("dev4.np.az.ec.gc.ca")
  ) {
    return RunEnvironment.AZURE_DEV;
  }

  if (
    document.location.host.includes("dev2.np.az.ec.gc.ca") ||
    document.location.host.includes("dev3.np.az.ec.gc.ca")
  ) {
    return RunEnvironment.AZURE_ALTDEV;
  }

  if (process.env.NODE_ENV === "development") {
    return RunEnvironment.LOCAL;
  }

  console.log("ENV:UNRECOGNIZED (FALLBACK CASE)");
  return RunEnvironment.UNRECOGNIZED;
};

export const getGqlEndpointForEnvironment = () => {
  const env = resolveAzureEnvironment();

  switch (env) {
    case RunEnvironment.LOCAL:
      return "http://localhost:8080/api/graphql/";
    default:
      return `${document.location.origin}/gql/api/graphql`;
  }
};

// localhostPort is optional, it's for resolving the correct service on localhost
export const getApiEndpointForEnvironment = (
  localhostPort: string = "8080"
) => {
  const env = resolveAzureEnvironment();

  switch (env) {
    case RunEnvironment.LOCAL:
      return `http://localhost:${localhostPort}/api/`;
    default:
      return `${document.location.origin}/api/`;
  }
};

export const getApiDomainName = (localhostPort: string = "7090") => {
  // return "https://sardmts-sgsdep-dev4.np.az.ec.gc.ca/";

  const env = resolveAzureEnvironment();

  switch (env) {
    case RunEnvironment.LOCAL:
      return `http://localhost:${localhostPort}/`;
    default:
      return `${document.location.origin}/`;
  }
};

export const getApiSearchServiceUrl = (localhostPort: string = "7090") => {
  const env = resolveAzureEnvironment();

  switch (env) {
    case RunEnvironment.LOCAL:
      // return `http://localhost:${localhostPort}/api/v1/`;
      return "https://sardmts-sgsdep-dev4.np.az.ec.gc.ca/api/swss/v1/";
    default:
      return `${document.location.origin}/api/swss/v1/`;
  }
};

export const getApiListingServiceUrl = (localhostPort: string = "7080") => {
  const env = resolveAzureEnvironment();

  switch (env) {
    case RunEnvironment.LOCAL:
      // return `http://localhost:${localhostPort}/api/v1/`;
      return "https://sardmts-sgsdep-dev4.np.az.ec.gc.ca/api/lwss/v1/";
    default:
      return `${document.location.origin}/api/lwss/v1/`;
  }
};

export const getApiDocumentManagementServiceUrl = (
  localhostPort: string = "7094"
) => {
  const env = resolveAzureEnvironment();

  switch (env) {
    case RunEnvironment.LOCAL:
      // return `http://localhost:${localhostPort}/api/v1/`;
      return "https://sardmts-sgsdep-dev4.np.az.ec.gc.ca/api/dms/v1/";
    default:
      return `${document.location.origin}/api/dms/v1/`;
  }
};

export const getApiContactServiceUrl = (localhostPort: string = "7093") => {
  const env = resolveAzureEnvironment();

  switch (env) {
    case RunEnvironment.LOCAL:
      // return `http://localhost:${localhostPort}/api/v1/`;
      return "https://sardmts-sgsdep-dev4.np.az.ec.gc.ca/api/cms/v1/";
    default:
      return `${document.location.origin}/api/cms/v1/`;
  }
};

export const getApiImageManagementServiceUrl = (
  localhostPort: string = "7096"
) => {
  const env = resolveAzureEnvironment();

  switch (env) {
    case RunEnvironment.LOCAL:
      // return `http://localhost:${localhostPort}/api/v1/`;
      return "https://sardmts-sgsdep-dev4.np.az.ec.gc.ca/api/ims/v1/";
    default:
      return `${document.location.origin}/api/ims/v1/`;
  }
};

export const getApiBptServiceUrl = (localhostPort: string = "7094") => {
  const env = resolveAzureEnvironment();

  switch (env) {
    case RunEnvironment.LOCAL:
      // return `http://localhost:${localhostPort}/api/v1/`;
      return "https://sardmts-sgsdep-dev4.np.az.ec.gc.ca/api/bpts/v1/";
    default:
      return `${document.location.origin}/api/bpts/v1/`;
  }
};
