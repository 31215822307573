import { ApolloError, useMutation } from "@apollo/client";
import { OverlayContainer } from "@react-aria/overlays";
import type { OverlayTriggerState } from "@react-stately/overlays";
import { useOverlayTriggerState } from "@react-stately/overlays";
import Alert, { AlertTypes } from "components/atoms/Alert";
import ModalDialog from "components/molecules/Modal";
import type { SubspeciesFields } from "components/molecules/admin/SubspeciesForm";
import SubspeciesForm from "components/molecules/admin/SubspeciesForm";
import { CreateSubspeciesDocument } from "generated/gql-types";
import i18n from "i18n";
import * as React from "react";
import { SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import LoadingIndicator from "../../../atoms/LoadingIndicator";

export interface CreateSubspeciesModalProps {
  modalState: OverlayTriggerState;
  refetchQueries?: string[];
  onCompleted?: (data: any) => void;
  onError?: (error: ApolloError | undefined) => void;
}

const CreateSubspeciesModal: React.FC<CreateSubspeciesModalProps> = (props) => {
  const { t } = useTranslation();
  const { modalState } = props;

  const [createSubspecies, { loading, error, reset }] = useMutation(
    CreateSubspeciesDocument,
    {
      refetchQueries: props.refetchQueries ?? [],
      onCompleted: props.onCompleted,
      onError: props.onError,
      errorPolicy: "all",
    }
  );

  const onSubmit: SubmitHandler<SubspeciesFields> = async (formData) => {
    try {
      const res = await createSubspecies({
        variables: {
          name: formData.name,
        },
      });

      if (res.errors == null) {
        modalState.close();
      }
    } catch (e) {
      console.warn("Error saving subspecies");
      console.error(e);
    }
  };

  const onClose = () => {
    modalState.close();
    reset();
  };

  if (!modalState.isOpen) return null;

  return (
    <OverlayContainer>
      <ModalDialog
        title={t("create_a_subspecies")}
        isOpen
        onClose={onClose}
        showModalFooter={false}
        // isDismissable
      >
        {error && (
          <Alert
            type={AlertTypes.DANGER}
            content={
              (i18n.language === "fr"
                ? error.graphQLErrors[0]?.extensions?.response?.body
                    ?.frenchMessage
                : error.graphQLErrors[0]?.extensions?.response?.body
                    ?.englishMessage) ?? "[Network Error]"
            }
          />
        )}
        {loading && <LoadingIndicator centered />}
        <SubspeciesForm
          defaultValues={{
            name: "",
          }}
          onSubmit={onSubmit}
          onClose={onClose}
        />
      </ModalDialog>
    </OverlayContainer>
  );
};

export default CreateSubspeciesModal;

export const useSubspeciesModal = useOverlayTriggerState;
export type { SubspeciesFields };
