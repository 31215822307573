import { LinkFields } from "./LinkForm";
import { Maybe } from "graphql/jsutils/Maybe";
import { ResourceLink } from "../../../../generated/gql-types";
import * as FormMappers from "util/formMappers";
import {
  FieldNamesMarkedBoolean,
  set,
  UnpackNestedValue,
} from "react-hook-form";
import { filterForDirtyFields } from "util/forms";
import isNullOrEmpty from "util/isNullOrEmpty";

export const processLinkFormValues = (
  formData: UnpackNestedValue<Partial<LinkFields>>,
  dirtyFields: FieldNamesMarkedBoolean<any>,
  initialValues: Partial<LinkFields>
): Partial<LinkFields> => {
  const values = filterForDirtyFields(formData, dirtyFields);
  setNullForLinkEmptyFields(values, dirtyFields, initialValues);
  return values;
};

const setNullForLinkEmptyFields = (
  formData: UnpackNestedValue<Partial<LinkFields>>,
  dirtyFields: FieldNamesMarkedBoolean<any>,
  initialValues: Partial<LinkFields>
) => {
  // We use lodash set() in order to make sure each level of a nested path exists when we set a value.
  // LinkFields
  if (
    dirtyFields?.name != null &&
    isNullOrEmpty(formData?.name?.text) &&
    !isNullOrEmpty(initialValues?.name?.text)
  ) {
    set(formData, "name.text", null);
  }

  if (
    dirtyFields?.urlToDocument &&
    isNullOrEmpty(formData?.urlToDocument) &&
    !isNullOrEmpty(initialValues?.urlToDocument)
  ) {
    set(formData, "urlToDocument", null);
  }

  if (
    dirtyFields?.language &&
    isNullOrEmpty(formData?.language) &&
    !isNullOrEmpty(initialValues?.language)
  ) {
    set(formData, "language", null);
  }
};

export function mapDomainModelToLinkForm(model?: Partial<Maybe<ResourceLink>>) {
  const out: Partial<LinkFields> = {};

  FormMappers.mapRichText(out, "name", model?.reference?.name);
  FormMappers.mapPrimitiveType(out, "urlToDocument", model?.reference?.uri);
  FormMappers.mapPrimitiveType(out, "language", model?.language);
  return out;
}

export const linkFormIntoCreateInput = (
  formData: Partial<LinkFields>
): ResourceLink => {
  const out: ResourceLink = {};

  // name
  FormMappers.mapRichTextInput(out, "reference.name", formData?.name);
  FormMappers.mapPrimitiveTypeInput(
    out,
    "reference.uri",
    formData?.urlToDocument
  );
  FormMappers.mapPrimitiveTypeInput(out, "language", formData?.language);

  return out;
};
