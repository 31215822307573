import { useMutation, useQuery } from "@apollo/client";
import PlaceholderImage from "assets/svg/placeholder.svg";
import GraphqlError from "components/GraphqlError";
import Alert, { AlertTypes } from "components/atoms/Alert";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import Layout from "components/layouts/TwoColumn";
import DuplicateChildrenForm, {
  DuplicateChildrenFields,
  DuplicateType,
} from "components/molecules/DuplicateChildrenForm";
import SideNav from "components/molecules/SideNav";
import RelatedSpeciesListItem from "components/molecules/cosewic/RelatedSpeciesListItem";
import CosewicWsAutocomplete from "components/organisms/cosewic/CosewicWsAutocomplete";
import useCosewicWsAutocomplete from "components/organisms/cosewic/CosewicWsAutocomplete/useCosewicWsAutocomplete";
import { Ribbon } from "components/organisms/cosewic/Ribbon";
import { MAX_NUMBER_OF_PARENTS } from "config/constants";
import { useGlobalAlertContext } from "features/globalAlert";
import { useOverviewContext } from "features/overview";
import {
  CosewicProfileDocument,
  CosewicWs,
  SplitCosewicWsDocument,
} from "generated/gql-types";
import { union } from "lodash";
import * as React from "react";
import { SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";
import { CosewicPathHelpers } from "../CosewicRouter";

export interface MergeProps {}

const Merge: React.FC<MergeProps> = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { params } = useRouteMatch();
  const { cosewicWsId } = params as any;
  const alertContext = useGlobalAlertContext();
  const cosewicWsAutocompleteInstance = useCosewicWsAutocomplete();
  const overviewContext = useOverviewContext();

  React.useEffect(() => {
    overviewContext.updateOverview("cosewicws", cosewicWsId);
  }, [cosewicWsId]);

  const [cosewicWsList, setCosewicWsList] = React.useState<
    Array<Partial<CosewicWs> | undefined>
  >([]);

  const [currentSelectedSpecies, setCurrentSelectedSpecies] = React.useState<
    CosewicWs | undefined
  >(undefined);

  const {
    data: initialCosewicWsData,
    loading: initialCosewicWsLoading,
    error: initialCosewicWsError,
  } = useQuery(CosewicProfileDocument, {
    variables: {
      id: cosewicWsId,
    },
    fetchPolicy: "network-only",
    errorPolicy: "all",
  });

  const [runMerge, mergeMutationStatus] = useMutation(SplitCosewicWsDocument, {
    errorPolicy: "all",
  });

  const onSelectCosewicWs = () => {
    if (currentSelectedSpecies)
      setCosewicWsList((x) => union([currentSelectedSpecies], x));
    cosewicWsAutocompleteInstance.clear();
  };

  const onRemoveFromList = (id?: string) => {
    if (!id) return;
    setCosewicWsList((list) => list.filter((x) => x?.id !== id));
  };

  // const foundInList = (id?: string) => {
  //   if (!id) return false;
  //   if (
  //     id === initialCosewicWsData?.cosewicWs?.id ||
  //     cosewicWsList.find((x) => id === x?.id)
  //   ) {
  //     return true;
  //   }
  //   return false;
  // };

  const onSubmit: SubmitHandler<DuplicateChildrenFields> = async (formData) => {
    try {
      const numberOfDuplicates: number = Number(formData.numberOfDuplicates);
      const idsToMerge = cosewicWsList.map((x) => x?.id);

      const res = await runMerge({
        variables: {
          id: cosewicWsId,
          input: {
            numberOfChildren: numberOfDuplicates,
            additionalParentIds: idsToMerge as any,
          },
        },
      });
      if (res.errors) throw res.errors;

      alertContext.showSuccess({
        message: t("merge_success_msg", { count: numberOfDuplicates }),
      });
      history.push({ pathname: CosewicPathHelpers.ViewChildren(cosewicWsId) });
    } catch (e) {}
  };

  return (
    <>
      <Ribbon />
      <Layout.Root>
        <Layout.Content>
          <h1>{t("merge")}</h1>

          <GraphqlError
            errors={initialCosewicWsError}
            title="Error loading the base COSEWIC WS for merging"
          />
          <GraphqlError
            errors={mergeMutationStatus.error}
            title="Error merging COSEWIC WS"
          />

          {cosewicWsList.length >= MAX_NUMBER_OF_PARENTS - 1 && (
            <Alert
              type={AlertTypes.WARNING}
              content={t("maximum_parents_warning", {
                number: MAX_NUMBER_OF_PARENTS,
              })}
            ></Alert>
          )}

          <section className="Card mrgn-bttm-md">
            <h2 className="mrgn-tp-sm">{t("cosewic_ws_to_merge")}</h2>
            <div className="separator-line-top" />

            <Alert
              type={AlertTypes.INFO}
              content={t("merge_info_message")}
            ></Alert>

            <CosewicWsAutocomplete
              onSelectionChange={setCurrentSelectedSpecies}
              label={t("search_wildlife_species")}
              placeholder={t("enter_a_cosewic_id_or_name")}
              useCosewicWsAutocompleteInstance={cosewicWsAutocompleteInstance}
              disabled={cosewicWsList.length >= MAX_NUMBER_OF_PARENTS - 1}
            />

            <button
              type="button"
              className="btn btn-primary mrgn-tp-md mrgn-bttm-md"
              disabled={
                !currentSelectedSpecies ||
                cosewicWsList.length >= MAX_NUMBER_OF_PARENTS - 1
              }
              onClick={onSelectCosewicWs}
              data-testid="button-add-to-list"
            >
              {t("add_to_list")}
            </button>

            {initialCosewicWsLoading && !initialCosewicWsError ? (
              <LoadingIndicator centered />
            ) : (
              <div
                className="list-item separator-line-top"
                style={{ background: "#E6E5E5" }}
              >
                <RelatedSpeciesListItem
                  cosewicWs={initialCosewicWsData?.cosewicWs!}
                  titleLink={CosewicPathHelpers.CosewicProfile(
                    initialCosewicWsData?.cosewicWs?.id ?? "error"
                  )}
                  imgUrl={PlaceholderImage}
                  showImage={false}
                />
              </div>
            )}

            {cosewicWsList?.map((item) => {
              return (
                <div className="list-item separator-line-top" key={item?.id}>
                  <RelatedSpeciesListItem
                    cosewicWs={item as CosewicWs}
                    titleLink={CosewicPathHelpers.CosewicProfile(
                      item?.id ?? "error"
                    )}
                    imgUrl={PlaceholderImage}
                    showImage={false}
                  >
                    <div className="button">
                      <button
                        onClick={() => onRemoveFromList(item?.id)}
                        tabIndex={0}
                        type="submit"
                        data-testid={`remove-btn-${item?.id}`}
                        className="btn btn-warning btn-xs"
                      >
                        {t("remove")}
                      </button>
                    </div>
                  </RelatedSpeciesListItem>
                </div>
              );
            })}
          </section>

          <section className="Card">
            <h2 className="mrgn-tp-sm">
              {t("merge_settings")}{" "}
              {mergeMutationStatus.loading ? (
                <LoadingIndicator className="mrgn-lft-sm" />
              ) : null}
            </h2>
            <div className="separator-line-top">
              <DuplicateChildrenForm
                disabled={cosewicWsList.length < 1}
                duplicateType={DuplicateType.MERGE}
                onSubmit={onSubmit}
              />
            </div>
          </section>
        </Layout.Content>
        <Layout.Sidebar>
          <SideNav />
        </Layout.Sidebar>
      </Layout.Root>
    </>
  );
};

export default Merge;
