import {
  BptTemplate,
  BptTemplateActivityBundle,
  Maybe,
} from "generated/gql-types";
import * as React from "react";
import { ActivityDetails } from "./ActivityDetails";

export interface ActivityBundlesProps {
  bptTemplate?: Maybe<BptTemplate>;
  activityBundles?: Maybe<Maybe<BptTemplateActivityBundle>[]>;
  isLoading?: boolean;
  onDeleteActivity: (a: any, b: any) => void;
}

export const ActivityBundles: React.FC<ActivityBundlesProps> = (props) => {
  const { activityBundles, bptTemplate, isLoading, onDeleteActivity } = props;

  return (
    <>
      {activityBundles?.map((activityBundle, n) => {
        const isBundle =
          activityBundle?.activities != null &&
          activityBundle?.activities?.length >= 2;
        return (
          <div
            key={n}
            className={
              isBundle ? "template-list-bundle" : "template-list-single"
            }
          >
            {activityBundle?.activities?.map((activity, index) => {
              return (
                <ActivityDetails
                  key={index}
                  bptTemplate={bptTemplate}
                  activityInfo={activity}
                  isLoading={isLoading}
                  onDeleteActivity={onDeleteActivity}
                />
              );
            })}
          </div>
        );
      })}
    </>
  );
};
