const adminTaxonomicGroupTranslation = {
  en: {
    taxonomicgroup: "Taxonomic group",
    list_of_taxonomic_groups: "List of taxonomic groups",
    create_a_taxonomicgroup: "Create a taxonomic group",
    enter_taxonomicgroup_name: "Enter a taxonomic group name",
    edit_taxonomicgroup: "Edit a taxonomic group",
    reactivate_a_taxonomicgroup: "Reactivate a taxonomic group",
    deactivate_a_taxonomicgroup: "Deactivate a taxonomic group",
    archive_taxonomicgroup_warning:
      "You will no longer be able to add this taxonomic group to a wildlife species.",
    unarchive_taxonomicgroup_warning:
      "You will be able to add this taxonomic group to a wildlife species.",
    no_taxonomicgroup_selected: "No taxonomic group was selected",
    archive_a_taxonomicgroup: "Archive a taxonomic group",
    unarchive_a_taxonomicgroup: "Unarchive a taxonomic group",
  },
  fr: {
    taxonomicgroup: "Groupe taxinomique",
    list_of_taxonomic_groups: "Liste des groupes taxinomiques",
    create_a_taxonomicgroup: "Créer un groupe taxinomique",
    enter_taxonomicgroup_name: "Entrez le nom d'un groupe taxinomique",
    edit_taxonomicgroup: "Modifier un groupe taxinomique",
    reactivate_a_taxonomicgroup: "Réactiver un groupe taxinomique",
    deactivate_a_taxonomicgroup: "Désactiver un groupe taxinomique",
    archive_taxonomicgroup_warning:
      "Vous ne pourrez plus ajouter ce groupe taxinomique à une espèce sauvage.",
    unarchive_taxonomicgroup_warning:
      "Vous pouvez ajouter ce groupe taxinomique à une espèce sauvage.",
    no_taxonomicgroup_selected: "Aucun groupe taxinomique n'a été sélectionné",
    archive_a_taxonomicgroup: "Archiver un groupe taxinomique",
    unarchive_a_taxonomicgroup: "Désarchiver un groupe taxinomique",
  },
};

export default adminTaxonomicGroupTranslation;
