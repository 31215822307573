import { useApolloClient, useMutation } from "@apollo/client";
import { useAsyncList } from "@react-stately/data";
import GraphqlError from "components/GraphqlError";
import Alert from "components/atoms/Alert";
import SectionCard from "components/atoms/SectionCard";
import Layout from "components/layouts/OneColumn";
import PageSizeSelect from "components/molecules/PageSizeSelect";
import Pagination from "components/molecules/Pagination";
import AdminPageFilterForm, {
  QueryFields,
  defaultQueryFields,
} from "components/molecules/admin/AdminPageFilterForm";
import AdminArchiveModal from "components/organisms/admin/AdminArchiveModal";
import AdminUnarchiveModal from "components/organisms/admin/AdminUnarchiveModal";
import AddOrganizationBtn from "components/organisms/admin/CreateOrganizationModal/AddOrganizationBtn";
import EditOrganizationModalBtn from "components/organisms/admin/EditOrganizationModal/EditOrganizationBtn";
import { RenderWhenAuthorized } from "features/auth/components";
import { ROLE_ACTIONS } from "features/auth/roles";
import {
  ArchiveStatus,
  DeactivateOrganizationDocument,
  Organization,
  OrganizationsForAdminOrganizationDocument,
  ReactivateOrganizationDocument,
} from "generated/gql-types";
import useAdminAlertMsg from "hooks/admin/useAdminAlertMsg";
import { usePagination } from "hooks/util/usePagination";
import { bilingualTextNameForLanguage } from "mappers";
import * as React from "react";
import { flushSync } from "react-dom";
import { getI18n, useTranslation } from "react-i18next";
import { formatTimestamp } from "util/formatTimestamp";
import isNullOrEmpty from "util/isNullOrEmpty";
import LoadingIndicator from "../../components/atoms/LoadingIndicator";
import NoResults from "../../components/atoms/NoResults";
import ResultsTable, {
  ColumnHeader,
} from "../../components/organisms/search/ResultsTable/ResultsTable";

const AdminOrganizations: React.FC = (props) => {
  const { t, i18n } = useTranslation();
  const [selectedRowId, setSelectedRowId] = React.useState<string>("");
  const [filters, setFilters] = React.useState<QueryFields>(defaultQueryFields);
  const adminAlertMsg = useAdminAlertMsg(t("organization"));

  const client = useApolloClient();
  const [totalCount, setTotalCount] = React.useState<number>(0);
  const [totalPages, setTotalPages] = React.useState<number>(0);
  const pagination = usePagination();

  const getSortByOrder = (column: string, direction: string) => {
    const sortBy = () => {
      switch (column) {
        case "name":
          if (i18n.language === "fr") return "name.french";
          return "name.english";
        case "emailAddress":
          return "contactDetails.emailAddress";
        default:
          return column;
      }
    };
    const sortOrder = () => {
      return direction === "ascending" ? "asc" : "desc";
    };
    return `${sortBy()}:${sortOrder()}`;
  };

  const buildOrganizationNameHtmlString = (x: Organization | null) => {
    const name = i18n.language === "fr" ? x?.name?.french : x?.name?.english;

    // Fetch the organization level information (level3/level2/level1)
    let orgLevelsString: string | undefined;
    if (x?.departments != null && x.departments.length > 0) {
      // NOTE: we reverse the array to show organization level 4 first, as per the prototype.
      const organizationLevels = x.departments
        .filter(
          (dept: any) =>
            !isNullOrEmpty(dept?.english) && !isNullOrEmpty(dept?.french)
        )
        .map((dept: any) =>
          bilingualTextNameForLanguage(getI18n().language, dept)
        )
        .reverse();
      organizationLevels[0] = "<b>" + organizationLevels[0] + "</b><small>";
      orgLevelsString = organizationLevels.join("<br />");
    }

    return (
      // "<b>" +
      // "</b>" +
      (orgLevelsString ? "" + orgLevelsString + "<br />" : "<b />") + name
    );
  };

  const rows = useAsyncList<any>({
    initialSortDescriptor: {
      column: "name",
      direction: "ascending",
    },
    async load({ cursor, filterText, sortDescriptor }: any) {
      const results = await client.query({
        query: OrganizationsForAdminOrganizationDocument,
        variables: {
          params: {
            pageNumber: pagination.currentPage,
            pageSize: pagination.pageSize,
            status: filterText.showInactive
              ? ArchiveStatus.Inactive
              : ArchiveStatus.Active,
            sortBy: getSortByOrder(
              sortDescriptor.column,
              sortDescriptor.direction
            ),
            isCaseSensitive: false,
            search:
              filterText.search.length > 0 ? filterText.search : undefined,
            searchType: "contains",
          },
        },
        fetchPolicy: "network-only",
        errorPolicy: "all",
      });
      const items =
        results.data.organizationList?.organization?.map((organization) => ({
          ...organization,
          createdAt: formatTimestamp(organization?.createdAt),
          modifiedAt: formatTimestamp(organization?.modifiedAt),
          name: buildOrganizationNameHtmlString(organization),
          nameObj: organization?.name,
          contactDetails: organization?.contactDetails,
          tag: organization?.tag,
          note: organization?.note,
          emailAddress: organization?.contactDetails?.emailAddress,
        })) ?? [];
      setTotalCount(results.data.organizationList?.pagination?.totalCount ?? 0);
      setTotalPages(results.data.organizationList?.pagination?.totalPages ?? 0);

      return {
        items,
        sortDescriptor,
      };
    },
  });

  const [runArchive] = useMutation(DeactivateOrganizationDocument, {
    onError: (_err) => {
      adminAlertMsg.onArchiveError();
    },
    onCompleted: (data) => {
      adminAlertMsg.onArchiveSuccess(
        data?.deactivateOrganization?.name?.english,
        data?.deactivateOrganization?.name?.french
      );
      rows.reload(); // refetchQueries
      setSelectedRowId("");
    },
    errorPolicy: "all",
  });

  const [runUnarchive] = useMutation(ReactivateOrganizationDocument, {
    onError: (_err) => {
      adminAlertMsg.onUnarchiveError();
    },
    onCompleted: (data) => {
      adminAlertMsg.onUnarchiveSuccess(
        data?.reactivateOrganization?.name?.english,
        data?.reactivateOrganization?.name?.french
      );
      rows.reload(); // refetchQueries
      setSelectedRowId("");
    },
    errorPolicy: "all",
  });

  const onSelectionChange = (keys: "all" | Set<string>) => {
    if (keys === "all") return;
    const selectedId = Array.from(keys)[0] ?? "";
    setSelectedRowId(selectedId);
  };

  const onArchive = async () => {
    if (selectedRowId == null || selectedRowId === "") return;
    await runArchive({
      variables: { deactivateOrganizationId: selectedRowId },
    });
  };

  const onUnarchive = async () => {
    if (selectedRowId == null || selectedRowId === "") return;
    await runUnarchive({
      variables: { reactivateOrganizationId: selectedRowId },
    });
  };

  React.useEffect(() => {
    setSelectedRowId("");
  }, [filters, pagination.pageSize]);

  React.useEffect(() => {
    rows.reload(); // refetchQueries
    setSelectedRowId("");
  }, [pagination.currentPage, pagination.pageSize]);

  const columns: ColumnHeader[] = React.useMemo(
    () => [
      {
        name: t("name"),
        key: "name",
        sortable: true,
        isHTML: true,
      },
      {
        name: t("email"),
        key: "emailAddress",
        sortable: true,
        maxWidth: 150,
      },
      {
        name: t("created_by"),
        key: "createdBy",
        sortable: true,
      },
      {
        name: t("created_on"),
        key: "createdAt",
        sortable: true,
      },
      {
        name: t("updated_by"),
        key: "modifiedBy",
        sortable: true,
        maxWidth: 150,
      },
      {
        name: t("updated_date"),
        key: "modifiedAt",
        sortable: true,
      },
    ],
    [t]
  );

  const selectedRow = rows.items.find((x) => x.id === selectedRowId);

  const onFilterSubmit = (arr: QueryFields) => {
    flushSync(() => {
      setFilters(arr);
      rows.setFilterText(arr as any);
      pagination.goToPage(1);
    });
  };

  return (
    <>
      <Layout.Root>
        <Layout.Content>
          <h1>{t("organizations")}</h1>
          <GraphqlError
            title="Error loading Organizations"
            errors={rows?.error}
          />

          {adminAlertMsg.alertIsVisible ? (
            <Alert
              type={adminAlertMsg.pageAlertType!}
              content={adminAlertMsg.pageAlertMsg!}
              onClose={adminAlertMsg.close}
              timeOut={5000}
            />
          ) : null}

          <SectionCard
            header={
              <div className="flex justify-between align-start">
                <h2>{t("list_of_organizations")}</h2>
                <div className="flex gap-md">
                  <RenderWhenAuthorized
                    authorizedRoles={ROLE_ACTIONS.administration.contact.create}
                  >
                    <AddOrganizationBtn
                      onCompleted={(data: any) => {
                        data?.createOrganization &&
                          adminAlertMsg.onCreateSuccess(
                            data?.createOrganization?.name?.english,
                            data?.createOrganization?.name?.french
                          );
                        rows.reload(); // refetchQueries
                      }}
                      onError={adminAlertMsg.onCreateError}
                    />
                  </RenderWhenAuthorized>
                  <RenderWhenAuthorized
                    authorizedRoles={ROLE_ACTIONS.administration.contact.update}
                  >
                    <EditOrganizationModalBtn
                      OrganizationId={selectedRowId}
                      defaultValues={{
                        name: selectedRow?.nameObj ?? {},
                        methodOfCommunication:
                          selectedRow?.methodOfCommunication ?? {},
                        tag: selectedRow?.tag ?? {},
                        departments: selectedRow?.departments ?? [],
                        contactDetails: selectedRow?.contactDetails ?? {},
                        note: selectedRow?.note ?? {},
                      }}
                      onCompleted={(data) => {
                        data?.updateOrganization &&
                          adminAlertMsg.onEditSuccess(
                            data?.updateOrganization?.name?.english,
                            data?.updateOrganization?.name?.french
                          );
                        rows.reload(); // refetchQueries
                      }}
                      onError={adminAlertMsg.onCreateError}
                    />
                  </RenderWhenAuthorized>
                  <RenderWhenAuthorized
                    authorizedRoles={
                      ROLE_ACTIONS.administration.contact.deactivateReactivate
                    }
                  >
                    {!filters.showInactive ? (
                      <AdminArchiveModal
                        onArchive={onArchive}
                        modalTitle={t("archive_an_organization")}
                        warningText={t("archive_organization_warning")}
                        selectedItemId={selectedRowId}
                      />
                    ) : null}
                  </RenderWhenAuthorized>
                  <RenderWhenAuthorized
                    authorizedRoles={
                      ROLE_ACTIONS.administration.contact.deactivateReactivate
                    }
                  >
                    {filters.showInactive &&
                    (!selectedRow ||
                      selectedRow?.status === ArchiveStatus.Inactive) ? (
                      <AdminUnarchiveModal
                        onUnarchive={onUnarchive}
                        modalTitle={t("unarchive_an_organization")}
                        warningText={t("unarchive_organization_warning")}
                        selectedItemId={selectedRowId}
                      />
                    ) : null}
                  </RenderWhenAuthorized>
                </div>
              </div>
            }
          >
            {/*FILTERS:*/}
            <div className="flex justify-between flex-wrap mrgn-bttm-sm">
              <AdminPageFilterForm onSubmit={onFilterSubmit} />
              <div className="flex-auto flex-col gap-sm align-end">
                <div>
                  <PageSizeSelect
                    pageSize={pagination.pageSize}
                    onChangePageSize={pagination.setPageSize}
                  />
                </div>
                <div className="font-size-16 justify-end pb-3">
                  {pagination.makeShowingString(totalCount)}
                </div>
              </div>
            </div>

            {/*RESULTS*/}
            {rows.isLoading ? (
              <LoadingIndicator centered className="mrgn-bttm-md" />
            ) : rows && rows?.items?.length === 0 ? (
              <NoResults centered />
            ) : (
              <>
                {!rows.isLoading && rows?.error ? (
                  <pre>{JSON.stringify(rows?.error)}</pre>
                ) : null}
                {rows == null || rows?.items?.length === 0 ? null : (
                  <>
                    <ResultsTable
                      rows={rows.items}
                      columns={columns}
                      onSelectionChange={onSelectionChange as any}
                      selectedKeys={[selectedRowId]}
                      sortable
                      selectionMode="single"
                      showSelectionCheckboxes
                      sortDescriptor={rows.sortDescriptor}
                      onSortChange={rows.sort}
                    />

                    <Pagination
                      {...pagination.paginationComponentProps}
                      totalPages={totalPages ?? 0}
                    />
                  </>
                )}
              </>
            )}
          </SectionCard>
        </Layout.Content>
      </Layout.Root>
    </>
  );
};

export default AdminOrganizations;
