import { useMsal } from "@azure/msal-react";
import AdminRouter from "pages/admin/AdminRouter";
import CommunicationsRouter from "pages/communications/CommunicationsRouter";
import CosewicRouter from "pages/cosewic/CosewicRouter";
import DocumentsRouter from "pages/documents/DocumentsRouter";
import GalleryRouter from "pages/gallery/galleryRouter";
import ListingRouter from "pages/listing/ListingRouter";
import OverviewRouter from "pages/overview/OverviewRouter";
import PermitRouter from "pages/permit/PermitRouter";
import PublicProfileRouter from "pages/publicProfile/PublicProfileRouter";
import ResponseStatementRouter from "pages/responseStatement/responseStatementRouter";
import SearchRouter from "pages/search/SearchRouter";
import { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { ROLE_ACTIONS, RenderWhenAuthorized } from "./features/auth/components";
import RenderWhenFeatureIsEnabled from "./features/featureFlags/RenderWhenFeatureIsEnabled";
import { FeatureFlagKeys } from "./features/featureFlags/config";
import Home from "./pages/Home";
import SignIn from "./pages/SignIn";
import SignOut from "./pages/SignOut";
import UnauthorizedPage from "./pages/UnauthorizedPage";
import BptRouter from "pages/bpt/bptRouter";

export default function Router() {
  const { instance } = useMsal();

  // TODO: should this setActiveAccount be done elsewhere?
  useEffect(() => {
    (async () => {
      const accounts = instance.getAllAccounts();
      if (accounts.length > 0) {
        instance.setActiveAccount(accounts[0]);
      } else {
        throw new Error(
          "Couldn't find any accounts. This component should be unreachable without auth, did something go wrong?"
        );
      }
    })();
  }, [instance]);

  return (
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>

      <Route exact path="/forbidden">
        <UnauthorizedPage />
      </Route>

      <Route path={"/signin"}>
        <SignIn />
      </Route>

      <Route path={"/signout"}>
        <SignOut />
      </Route>

      <Route path="/admin">
        <RenderWhenAuthorized
          authorizedRoles={ROLE_ACTIONS.administration.viewAllAdminPages}
          fallbackComponent={<Redirect to={"/forbidden"} />}
        >
          <AdminRouter />
        </RenderWhenAuthorized>
      </Route>

      <Route path="/overview">
        <OverviewRouter />
      </Route>

      <Route path="/publicProfile">
        <PublicProfileRouter />
      </Route>

      <Route path="/gallery">
        <RenderWhenFeatureIsEnabled flagKey={FeatureFlagKeys.Photos}>
          <GalleryRouter />
        </RenderWhenFeatureIsEnabled>
      </Route>

      <Route path="/cosewic">
        <RenderWhenAuthorized
          authorizedRoles={ROLE_ACTIONS.cosewic.view}
          fallbackComponent={<Redirect to={"/forbidden"} />}
        >
          <CosewicRouter />
        </RenderWhenAuthorized>
      </Route>

      <Route path="/listing">
        <RenderWhenAuthorized
          authorizedRoles={ROLE_ACTIONS.listing.view}
          fallbackComponent={<Redirect to={"/forbidden"} />}
        >
          <ListingRouter />
        </RenderWhenAuthorized>
      </Route>

      <Route path="/search">
        <SearchRouter />
      </Route>

      <Route path="/permit">
        <PermitRouter />
      </Route>

      {/* TODO: Add RenderWhenAuthorized */}
      <Route path="/responseStatement">
        {/* <RenderWhenAuthorized
            authorizedRoles={ROLE_ACTIONS.responseStatement.view}
            fallbackComponent={<Redirect to={"/forbidden"} />}
          > */}
        <ResponseStatementRouter />
        {/* </RenderWhenAuthorized> */}
      </Route>

      <Route path="/documents">
        <RenderWhenFeatureIsEnabled flagKey={FeatureFlagKeys.DocumentManager}>
          <DocumentsRouter />
        </RenderWhenFeatureIsEnabled>
      </Route>

      <Route path="/communications">
        {/* <RenderWhenFeatureIsEnabled flagKey={FeatureFlagKeys.DocumentManager}> */}
        <CommunicationsRouter />
        {/* </RenderWhenFeatureIsEnabled> */}
      </Route>

      <Route path="/bpt">
        {/* <RenderWhenFeatureIsEnabled flagKey={FeatureFlagKeys.DocumentManager}> */}
        <BptRouter />
        {/* </RenderWhenFeatureIsEnabled> */}
      </Route>
    </Switch>
  );
}
