import Layout from "components/layouts/TwoColumn";
import DocumentsSideNav from "components/molecules/documents/DocumentsSideNav";
import RegistryPageForm, {
  RegistryPageFormFields,
} from "components/organisms/documents/Registry/RegistryPageForm/RegistryPageForm";
import {
  mapDomainModelToForm,
  mapFormToDomainModel,
} from "components/organisms/documents/Registry/RegistryPageForm/mappers";
import { useGlobalAlertContext } from "features/globalAlert";
import * as React from "react";
import { SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { CreateRegistryPageDocument } from "../../../generated/gql-types";
import { DocumentsPathHelpers } from "../../documents/DocumentsRouter";
import GraphqlError from "../../../components/GraphqlError";

export interface RegistryCreatePageProps {}

export const RegistryCreatePage: React.FC<RegistryCreatePageProps> = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const alertContext = useGlobalAlertContext();

  const [runSubmit, { loading: saving, error: savingError }] = useMutation(
    CreateRegistryPageDocument,
    { errorPolicy: "all" }
  );

  const onSubmit: SubmitHandler<Partial<RegistryPageFormFields>> = async (
    filteredFormData
  ) => {
    const mappedDomainModel = mapFormToDomainModel(filteredFormData);

    try {
      const res = await runSubmit({
        variables: {
          input: mappedDomainModel,
        },
      });

      if (res.errors) throw res.errors;

      alertContext.showSuccess({
        message: t("successfully_created_registry_page"),
        timeOut: 5000,
      });

      const registryPageId = res?.data?.createRegistryPage?.id;

      if (!registryPageId) {
        throw new Error("Did not receive a valid registry page ID!");
      }

      history.push({
        pathname: DocumentsPathHelpers.RegistryProfile(registryPageId),
      });
    } catch (e) {
      console.error(e);
    }
  };

  const onCancel = () => {
    history.push({
      pathname: DocumentsPathHelpers.DocumentsSearch, // TODO: update pathname
    });
  };

  const initialFormFields = mapDomainModelToForm(null);

  return (
    <>
      <Layout.Root className="mrgn-tp-md">
        <Layout.Content>
          <h1>{t("create_registry_page")}</h1>
          <GraphqlError
            title="Error creating new registry"
            errors={savingError}
          />
          <RegistryPageForm
            initialValues={initialFormFields}
            onSubmit={onSubmit}
            onCancel={onCancel}
          />
        </Layout.Content>
        <Layout.Sidebar>
          <DocumentsSideNav />
        </Layout.Sidebar>
      </Layout.Root>
    </>
  );
};

export default RegistryCreatePage;
