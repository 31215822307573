import AddPhaseIcon from "assets/svg/add-phase-icon.svg";
import NoResults from "components/atoms/NoResults";
import SectionCard from "components/atoms/SectionCard";
import { RenderWhenAuthorized, ROLE_ACTIONS } from "features/auth/components";
import { useGlobalAlertContext } from "features/globalAlert";
import {
  BptTemplate,
  BptTemplatePhase,
  BptTemplateStage,
  BptTemplateStepBundle,
  Maybe,
} from "generated/gql-types";
import useAdminAlertMsg from "hooks/admin/useAdminAlertMsg";
import * as React from "react";
import { useTranslation } from "react-i18next";
import TotalCalculatedDuration from "../../utility/TotalCalculatedDuration";
import AddStepBtn from "../AddStepModal/AddStepBtn";
import { StepBundles } from "./StepBundles";
import StepsCardHeader from "./StepsCardHeader";

export interface StepsCardProps {
  bptTemplate?: Maybe<BptTemplate>;
  phaseInfo?: Maybe<BptTemplatePhase>;
  stepBundles?: Maybe<Array<Maybe<BptTemplateStepBundle>>>;
  isOpen?: boolean;
  onCollapseAll: () => void;
  onDeleteStep: (a: any, b: any) => void;
}

export const StepsCard: React.FC<StepsCardProps> = (props) => {
  const { t } = useTranslation();
  const {
    bptTemplate,
    phaseInfo,
    stepBundles,
    isOpen,
    onCollapseAll,
    onDeleteStep,
  } = props;
  const alertContext = useGlobalAlertContext();
  const adminAlertMsg = useAdminAlertMsg(t("step"));

  return (
    <>
      <SectionCard
        header={
          <StepsCardHeader
            template={bptTemplate}
            stepBundles={stepBundles}
            onCollapseAll={onCollapseAll}
          />
        }
        contentAreaProps={{ className: "mrgn-tp-sm" }}
        className="pb-0"
        showLine={true}
      >
        {stepBundles == null || stepBundles?.length === 0 ? (
          <RenderWhenAuthorized
            authorizedRoles={ROLE_ACTIONS.administration.bptTemplate.create}
            fallbackComponent={<NoResults centered />}
          >
            <div className="text-center mrgn-tp-md">
              <div className="lead mrgn-tp-md mrgn-bttm-sm">
                {/* ------------ Add step button ------------ */}
                {bptTemplate?.stage === BptTemplateStage.Draft ? (
                  <AddStepBtn
                    templateId={bptTemplate?.id ?? ""}
                    phaseId={phaseInfo?.id ?? ""}
                    buttonLabel={t("no_steps_please_add")}
                    showImage
                    onCompleted={(data: any) => {
                      data?.name &&
                        adminAlertMsg.onCreateSuccess(data?.name?.text);
                    }}
                    onError={adminAlertMsg.onCreateError}
                  />
                ) : (
                  <>
                    <img src={AddPhaseIcon} alt="" />
                    <div className={"no-results-content font-size-18"}>
                      {t("no_steps")}
                    </div>
                  </>
                )}
              </div>
            </div>
          </RenderWhenAuthorized>
        ) : (
          <StepBundles
            isOpen={isOpen}
            stepBundles={stepBundles}
            bptTemplate={bptTemplate}
            onDeleteStep={onDeleteStep}
          />
        )}

        <div className="pt-0 separator-line-top mrgn-tp-20"></div>
        <div className="flex justify-between align-center py-3">
          <div>
            {bptTemplate?.stage === BptTemplateStage.Draft ? (
              <RenderWhenAuthorized
                authorizedRoles={ROLE_ACTIONS.administration.bptTemplate.create}
              >
                {/* ------------ Add step button ------------ */}
                <AddStepBtn
                  templateId={bptTemplate?.id ?? ""}
                  phaseId={phaseInfo?.id ?? ""}
                  buttonLabel={t("add_step")}
                  onCompleted={(data: any) => {
                    data?.name &&
                      adminAlertMsg.onCreateSuccess(data?.name?.text);
                  }}
                  onError={adminAlertMsg.onCreateError}
                />
              </RenderWhenAuthorized>
            ) : null}
          </div>

          {/* ------------ Total calculated duration ------------ */}
          <TotalCalculatedDuration
            calculatedDuration={phaseInfo?.calculatedDuration}
          />
        </div>
      </SectionCard>
    </>
  );
};

export default StepsCard;
