import { Item } from "@react-stately/collections";
import { useAsyncList } from "@react-stately/data";
import { formatPageNumber } from "../../../../util/formatPageNumber";
import Autocomplete from "../../../molecules/Autocomplete";
import {
  BasicSearchFacet,
  fetchBasicSearchAutocompleteFacets,
  FetchBasicSearchAutocompleteFacetsParams,
} from "./fetchBasicSearchAutocompleteFacets";

export interface BasicSearchAutocompleteFilterProps {
  facetName: string;

  id: string;
  onSelectionChange: (newSelection: string) => void;
  label: string;
  placeholder?: string;
  onClear?: () => void;
}

const BasicSearchFacetAutocomplete = (
  props: BasicSearchAutocompleteFilterProps
) => {
  // useAsyncList docs: https://react-spectrum.adobe.com/react-stately/useAsyncList.html
  const list = useAsyncList<BasicSearchFacet>({
    async load({ cursor, filterText }: any) {
      // check for -1 to halt fetching the next page
      if (cursor === "-1") return { items: [], cursor: "-1" };

      const params: FetchBasicSearchAutocompleteFacetsParams = {
        facetName: props.facetName,
        search: filterText,
        indexName:
          props.facetName ===
          "Listing/LatestProcess/RegulatoryProcess/RegulatoryBundle"
            ? "wildlife-species-index-v1"
            : "land-index-v1",
        pageNumber: formatPageNumber(cursor),
        pageSize: formatPageNumber(15),
      };

      const results = await fetchBasicSearchAutocompleteFacets(params);
      const items = results.values ?? [];

      const hasNextPage =
        results?.pagination?.totalPages > results?.pagination?.currentPage;

      if (hasNextPage === false) {
        return {
          items, // return the final set of items
          cursor: "-1", // Use -1 to halt fetching
        };
      }

      const newPageNumber =
        formatPageNumber(results?.pagination?.currentPage) + 1;

      return {
        items,
        cursor: newPageNumber.toString(),
      };
    },
  });

  const onSelectionChange = (key: any) => {
    props.onSelectionChange(key);
  };

  const onClear = () => {
    list.setSelectedKeys(new Set([]));
    list.setFilterText("");
    if (props.onClear != null) props.onClear();
  };

  return (
    <Autocomplete
      hideNoResultsMessage
      id={props.id}
      label={props.label}
      items={list.items}
      inputValue={list.filterText}
      onInputChange={list.setFilterText}
      loadingState={list.loadingState}
      placeholder={props.placeholder}
      onLoadMore={list.loadMore}
      onClear={onClear}
      onSelectionChange={onSelectionChange}
      defaultInputValue={undefined}
      isForMultiSelection={true}
    >
      {(item: any) => <Item key={item.value}>{item.value}</Item>}
    </Autocomplete>
  );
};

export default BasicSearchFacetAutocomplete;
