import { ApolloError } from "@apollo/client";
import { RenderWhenAuthorized, ROLE_ACTIONS } from "features/auth/components";
import {
  BptTemplate,
  BptTemplateStage,
  BptTemplateStepBundle,
  Maybe,
} from "generated/gql-types";
import * as React from "react";
import { useTranslation } from "react-i18next";
import ReorderStepBundlesBtn from "../ReorderStepBundlesModal/ReorderStepBundlesBtn";

export interface StepsCardHeaderProps {
  template?: Maybe<BptTemplate>;
  stepBundles?: Maybe<Maybe<BptTemplateStepBundle>[]>;
  onCollapseAll: () => void;
}

export const StepsCardHeader: React.FC<StepsCardHeaderProps> = (props) => {
  const { t } = useTranslation();
  const { template, stepBundles, onCollapseAll } = props;

  return (
    <div className="flex justify-between align-start">
      <h2>{t("steps")}</h2>

      <div className="flex gap-md">
        {template?.stage === BptTemplateStage.Draft ? (
          <RenderWhenAuthorized
            authorizedRoles={ROLE_ACTIONS.administration.bptTemplate.update}
          >
            <ReorderStepBundlesBtn
              templateId={template?.id}
              stepBundles={stepBundles}
              onCompleted={function (data: any): void {
                throw new Error("Function not implemented.");
              }}
              onError={function (error: ApolloError | undefined): void {
                throw new Error("Function not implemented.");
              }}
            />
          </RenderWhenAuthorized>
        ) : null}

        <div className="btn-group">
          <button
            type="button"
            className="btn btn-default card-header-button-link"
            onClick={onCollapseAll}
            data-testid="button-collapse-all"
            name="collapseAll"
            disabled={stepBundles == null || stepBundles?.length === 0}
          >
            {t("collapse_all")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default StepsCardHeader;
