import * as React from "react";
import { useTranslation } from "react-i18next";
import ReorderStepBundlesPopup, {
  ReorderStepBundlesPopupProps,
  useReorderStepBundlesPopup,
} from "./ReorderStepBundlesPopup";

interface ReorderStepBundlesBtnProps
  extends Omit<ReorderStepBundlesPopupProps, "modalState"> {
  buttonLabel?: string;
}

const ReorderStepBundlesBtn: React.FC<ReorderStepBundlesBtnProps> = ({
  buttonLabel,
  ...props
}) => {
  const { t } = useTranslation();
  const modalState = useReorderStepBundlesPopup({});
  const label = buttonLabel ?? t("reorder_and_bundle_steps");
  const validId = props.stepBundles != null && props.stepBundles.length > 1;

  return (
    <>
      <button
        type="button"
        className="btn btn-link card-header-button-link"
        onClick={modalState.open}
        title={label}
        disabled={!validId}
        data-testid="button-reorder-step"
      >
        <span
          className="glyphicon glyphicon-sort mrgn-rght-sm"
          aria-hidden="true"
        ></span>
        {label}
      </button>
      {validId ? (
        <ReorderStepBundlesPopup modalState={modalState} {...props} />
      ) : null}
    </>
  );
};

export default ReorderStepBundlesBtn;
