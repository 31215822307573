import * as React from "react";
import { useTranslation } from "react-i18next";
import AddStepLinkModal, { useAddStepLinkModal } from "./AddStepLinkModal";

const AddStepLinkBtn: React.FC<any> = ({ ...props }) => {
  const { t } = useTranslation();
  const modalState = useAddStepLinkModal({});
  const validProjectId = props.projectId != null && props.projectId.length > 0;

  return (
    <>
      <button
        type="button"
        className="btn btn-link card-header-button-link font-size-14"
        onClick={modalState.open}
        disabled={!validProjectId || props.disabled}
        data-testid="button-add-step-link"
      >
        <i className="fas fa-link mrgn-rght-sm"></i>
        {t("add_link")}
      </button>
      {validProjectId ? (
        <AddStepLinkModal modalState={modalState} {...props} />
      ) : null}
    </>
  );
};

export default AddStepLinkBtn;
