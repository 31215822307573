import { BptTemplateActivityBundlesUpdateInput } from "generated/gql-types";
import { random, set } from "lodash";
import { ActivityBundlesFormFields } from "./ActivityBundlesReorderForm";

export const formIntoUpdateInput = (
  formData: Partial<ActivityBundlesFormFields>
): BptTemplateActivityBundlesUpdateInput => {
  let out: BptTemplateActivityBundlesUpdateInput = {};

  const activityBundles = formData.activityBundles?.map((b) => ({
    bundleId: b.id,
    activityIds: b.activities?.map((s) => s?.id),
  }));

  set(out, "activityBundles", activityBundles);

  return out;
};

export const getTemporaryNewBundleId = () => {
  const NEW_BUNDLE_ID_PREFIX = "NEW-BUNDLE-";

  // Note: There is a length limitation of 19 characters for the id restricted from the Backend.
  return NEW_BUNDLE_ID_PREFIX + random(100000, 999999);
};

// export const replaceNewBundleIdsWithNull = (
//   formData: Partial<ActivityBundlesFormFields>
// ) => {
//   // Replace all NEW BUNDLE IDs with null
//   formData.activityBundles?.forEach((sb) => {
//     if (sb.id?.startsWith(NEW_BUNDLE_ID_PREFIX)) {
//       sb.id = null;
//     }
//   });
//   return formData;
// };
