import { useQuery } from "@apollo/client";
import GraphqlError from "components/GraphqlError";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import SectionCard from "components/atoms/SectionCard";
import Layout from "components/layouts/OneColumn";
import PageSizeSelect from "components/molecules/PageSizeSelect";
import Pagination from "components/molecules/Pagination";
import CreateDistributionListButtonAndModal from "components/organisms/DistributionLists/CreateDistributionListButtonAndModal/CreateDistributionListButtonAndModal";
import { ColumnHeader } from "components/organisms/admin/AdminTable";
import ResultsTable from "components/organisms/search/ResultsTable/ResultsTable";
import { ROLE_ACTIONS, RenderWhenAuthorized } from "features/auth/components";
import { GlobalAlert } from "features/globalAlert";
import { SortDescriptor } from "features/search/utils/makeOrderByString";
import {
  AuthorizationCurrentUserDocument,
  DistributionListsDocument,
} from "generated/gql-types";
import { usePagination } from "hooks/util/usePagination";
import * as React from "react";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { formatTimestamp } from "util/formatTimestamp";
import { CommunicationsPathHelpers } from "../CommunicationsRouter";
import { distributionListDomainModelIntoForm } from "components/organisms/DistributionLists/distributionlistFormUtil";

export const DistributionListsPage: React.FC = () => {
  const { t } = useTranslation();
  const pagination = usePagination();
  const [sortDescriptor, setSortDescriptor] = useState<SortDescriptor | any>({
    column: "name",
    direction: "ascending",
  });

  const getSortByOrder = (column: string, direction: string) => {
    const sortBy = () => {
      switch (column) {
        case "name":
          return "name";
        case "createdBy":
          return "createdBy";
        case "createdAt":
          return "createdAt";
        case "modifiedAt":
          return "modifiedAt";
        case "membersCount":
          return "membersCount";
        default:
          return column;
      }
    };
    const sortOrder = () => {
      return direction === "ascending" ? "asc" : "desc";
    };
    return `${sortBy()}:${sortOrder()}`;
  };

  const { data, loading, error } = useQuery(DistributionListsDocument, {
    errorPolicy: "all",
    variables: {
      params: {
        pageNumber: pagination.currentPage,
        pageSize: pagination.pageSize,
        sortBy: getSortByOrder(sortDescriptor.column, sortDescriptor.direction),
      },
    },
    fetchPolicy: "network-only",
  });
  const {
    loading: loadingCurrentUser,
    error: errorCurrentUser,
    data: dataCurrentUser,
  } = useQuery(AuthorizationCurrentUserDocument, {
    errorPolicy: "all",
    fetchPolicy: "network-only",
  });

  const onChangePageSize = async (newPageSize: number) => {
    pagination.setPageSize(newPageSize);
    pagination.goToPage(1);
  };

  const onSortChange = (sortDescriptor: SortDescriptor) => {
    setSortDescriptor(sortDescriptor);
    pagination.goToPage(1);
  };
  const columns: ColumnHeader[] = React.useMemo(
    () => [
      {
        name: t("name"),
        key: "name",
        sortable: true,
        hasLink: true,
      },

      {
        name: t("created_by"),
        key: "createdBy",
        sortable: true,
      },
      {
        name: t("created_date"),
        key: "createdAt",
        sortable: true,
      },
      {
        name: t("updated_date"),
        key: "modifiedAt",
        sortable: true,
      },
      {
        name: t("contacts_organizations"),
        key: "membersCount",
        sortable: true,
      },
    ],
    [t]
  );

  const rows: any = useMemo(
    () =>
      data?.distributionListList?.distributionList?.map((value) => {
        return {
          _href: CommunicationsPathHelpers.DistributionList(
            value?.id ?? "error"
          ),
          id: value?.id,
          name: value?.name ?? "",
          createdBy: value?.createdBy ?? "",
          createdAt: formatTimestamp(value?.createdAt ?? ""),
          modifiedAt: formatTimestamp(value?.modifiedAt ?? ""),
          membersCount: value?.membersCount ?? 0,
        };
      }),
    [data?.distributionListList?.distributionList]
  );

  const initialValues = distributionListDomainModelIntoForm(
    null,
    dataCurrentUser?.authorizationCurrentUser?.program,
    true
  );

  return (
    <>
      <Layout.Root>
        <Layout.Content>
          <GlobalAlert />
          <h1>{t("distribution_lists")}</h1>
          {
            <GraphqlError
              title="Error fetching Distribution lists"
              errors={error}
            />
          }
          {
            <GraphqlError
              title="Error fetching Current User"
              errors={errorCurrentUser}
            />
          }
          <SectionCard
            header={
              <div className="flex justify-between align-start">
                <h2>{t("all_lists")}</h2>
                <div className="flex gap-md">
                  <RenderWhenAuthorized
                    authorizedRoles={ROLE_ACTIONS.distributionList.create}
                  >
                    <CreateDistributionListButtonAndModal
                      defaultValues={initialValues}
                      disabled={
                        loading ||
                        error != null ||
                        loadingCurrentUser ||
                        errorCurrentUser != null
                      }
                    />
                  </RenderWhenAuthorized>
                </div>
              </div>
            }
          >
            {loading || loadingCurrentUser ? (
              <LoadingIndicator centered className="mrgn-bttm-md" />
            ) : rows == null || rows?.length <= 0 ? (
              <div className="text-center mrgn-tp-md">
                <div className="lead mrgn-tp-md mrgn-bttm-sm">
                  <RenderWhenAuthorized
                    authorizedRoles={ROLE_ACTIONS.distributionList.create}
                  >
                    <CreateDistributionListButtonAndModal
                      defaultValues={initialValues}
                      showImage={true}
                      disabled={
                        loading ||
                        error != null ||
                        loadingCurrentUser ||
                        errorCurrentUser != null
                      }
                    />
                  </RenderWhenAuthorized>
                </div>
              </div>
            ) : (
              <>
                <div className="flex mrgn-bttm-md align-center justify-between flex-wrap">
                  <div className="font-size-18">
                    {pagination.makeShowingString(
                      data
                        ? data?.distributionListList?.pagination?.totalCount
                        : 0
                    )}
                  </div>
                  <div>
                    <PageSizeSelect
                      pageSize={pagination.pageSize}
                      onChangePageSize={onChangePageSize}
                    />
                  </div>
                </div>
                <ResultsTable
                  rows={rows}
                  columns={columns}
                  sortable
                  sortDescriptor={sortDescriptor}
                  onSortChange={onSortChange as any}
                />
                <Pagination
                  {...pagination.paginationComponentProps}
                  totalPages={
                    data?.distributionListList?.pagination?.totalPages ?? 0
                  }
                />
              </>
            )}
          </SectionCard>
        </Layout.Content>
      </Layout.Root>
    </>
  );
};
