import FieldValidationError from "components/atoms/forms/FieldValidationError";
import FormButtons from "components/molecules/FormButtons/FormButtons";
import { HTMLItalicsInputWithController } from "components/molecules/HTMLItalicsInput";
import { FullHTMLEditorWithController } from "components/organisms/FullHTMLEditor";
import { RichText, Scalars } from "generated/gql-types";
import { Maybe } from "graphql/jsutils/Maybe";
import * as React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { processActivityFormValues } from "../bptFormUtil";

export interface ActivityFormFields {
  id?: Maybe<Scalars["ID"]>;
  name?: Maybe<RichText>;
  description?: Maybe<RichText>;
  owner?: Maybe<RichText>;
  estimatedDuration?: Maybe<number>;
  calculatedDuration?: Maybe<number>;
  includeCommonHoliday?: boolean;
}

export interface ActivityFormProps {
  defaultValues?: Partial<ActivityFormFields>;
  onSubmit: SubmitHandler<Partial<ActivityFormFields>>;
  onClose: () => void;
  isSaving?: boolean;
}

export const ActivityForm: React.FC<ActivityFormProps> = (props) => {
  const defaultValues = props.defaultValues;
  const { t } = useTranslation();

  const form = useForm<ActivityFormFields>({
    defaultValues: defaultValues,
    mode: "onChange",
  });
  const { handleSubmit, formState, register, control } = form;
  const { dirtyFields, isDirty, isSubmitting, errors } = formState;

  const onSubmit: SubmitHandler<Partial<ActivityFormFields>> = async (
    formData
  ) => {
    const cleanedValues = processActivityFormValues(
      formData,
      dirtyFields,
      defaultValues ?? {}
    );

    return new Promise<void>((resolve) => {
      setTimeout(() => {
        props.onSubmit(cleanedValues);
        resolve();
      }, 500);
    });
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <fieldset className="pt-0" disabled={props.isSaving}>
          {/* ---------- Activity name ---------- */}
          <div className="form-group ">
            <label htmlFor="name" className="required">
              {t("activity_name")}
              <strong className="required"> ({"required"})</strong>
            </label>
            <HTMLItalicsInputWithController
              control={control}
              rules={{
                required: true,
              }}
              name="name.text"
              id="nameInput"
              defaultValue={(defaultValues?.name as any)?.text ?? ""}
              placeholder={`${t("activity_name")}`}
            />
            {errors.name && (errors.name as any)?.text?.type === "required" && (
              <FieldValidationError>
                {t("field_is_required")}
              </FieldValidationError>
            )}
          </div>

          {/* ---------- Activity Description ---------- */}
          <div className="form-group">
            <label htmlFor="description">{t("activity_description")}</label>
            <FullHTMLEditorWithController
              control={control}
              defaultValue={(defaultValues?.description as any)?.text ?? ""}
              id="descriptionField"
              name="description.text"
              placeholder={`${t("enter_your_text")}`}
            />
          </div>

          {/* ---------- Activity Owner ---------- */}
          <div className="form-group">
            <label htmlFor="owner">{t("activity_owner")}</label>
            <HTMLItalicsInputWithController
              control={control}
              rules={{
                required: false,
              }}
              name="owner.text"
              id="ownerField"
              defaultValue={(defaultValues?.owner as any)?.text ?? ""}
              placeholder={`${t("enter_group_role_or_person_responsible")}`}
            />
          </div>

          <div className="form-group">
            {/* ---------- Estimate duration ---------- */}
            <label htmlFor="estimateDuration">
              {t("estimated_duration_business_days")}
            </label>
            <input
              type="number"
              id="estimateDurationField"
              className="form-control width-50"
              min={0}
              max={999999}
              placeholder={t("number_business_days")}
              {...register("estimatedDuration")}
            />

            {/* ---------- Include House of Common Holidays ---------- */}
            <div className="checkbox">
              <label htmlFor="IncludeHouseOfCommonHolidaysCheckbox">
                <input
                  type="checkbox"
                  id="IncludeHouseOfCommonHolidaysCheckbox"
                  {...register("includeCommonHoliday")}
                  name="includeCommonHoliday"
                />
                &nbsp;
                {t("include_house_of_common_holidays")}
              </label>
            </div>
          </div>
        </fieldset>

        <hr className="row mrgn-tp-0 mrgn-bttm-md" />
        <FormButtons
          isDirty={isDirty}
          isSubmitting={isSubmitting || props.isSaving}
          onCancel={props.onClose}
          errors={errors}
        />
      </form>
    </>
  );
};

export default ActivityForm;
