import {
  ApolloError,
  useMutation as useMutationApollo,
  useQuery,
} from "@apollo/client";
import { useMutation } from "@tanstack/react-query";
import { getApiBptServiceUrl } from "azure/environment";
import DeleteButtonModal from "components/atoms/DeleteButtonModal";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import NoResults from "components/atoms/NoResults";
import SectionCard from "components/atoms/SectionCard";
import { getUserAccessToken } from "features/auth/CustomMsalProvider";
import { ROLE_ACTIONS, RenderWhenAuthorized } from "features/auth/components";
import { useGlobalAlertContext } from "features/globalAlert";
import {
  BptProject,
  BptProjectCommunicationsDocument,
  BptProjectStage,
  DeleteBptProjectCommunicationDocument,
  Maybe,
} from "generated/gql-types";
import * as React from "react";
import { useTranslation } from "react-i18next";
import AddCommunicationsBtn from "../AddCommunicationsBtnModal/AddCommunicationsBtn";

interface AttachedCommunicationsCardProps {
  bptProject?: Maybe<BptProject>;
  refetchBptProject: () => void;
}

export const AttachedCommunicationsCard: React.FC<
  AttachedCommunicationsCardProps
> = (props) => {
  const { bptProject, refetchBptProject } = props;
  const { t } = useTranslation();
  const alertContext = useGlobalAlertContext();
  const [downloadCommunicationId, setDownloadCommunicationId] =
    React.useState("");
  const [removeCommunicationId, setRemoveCommunicationId] = React.useState("");

  const projectId = bptProject?.id;
  const isProjectCompleted = bptProject?.stage === BptProjectStage.Completed;

  const {
    loading: loadingBptProjectCommunications,
    error: errorBptProjectCommunications,
    data: dataBptProjectCommunications,
    refetch: refetchBptProjectCommunications,
  } = useQuery(BptProjectCommunicationsDocument, {
    variables: {
      projectId: projectId ?? "error",
    },
    skip: projectId == null,
    fetchPolicy: "network-only",
    errorPolicy: "all",
  });

  const communications = dataBptProjectCommunications?.bptProjectCommunications;

  const fetchDownload = async (params: {
    projectId: string;
    communicationId: string;
    attachmentName: string;
  }) => {
    const token = await getUserAccessToken();
    const baseUrl =
      getApiBptServiceUrl("7080") +
      `project/${params.projectId}/communication/${params.communicationId}`;

    return fetch(baseUrl, {
      method: "GET",
      body: null, // Not required.
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        // 204 = No content
        if (res.status === 204 || res.status < 200 || res.status >= 300) {
          throw new Error(res.statusText);
        }
        return res;
      })
      .then((res) => res.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", params.attachmentName);
        document.body.appendChild(link);
        link.click();
        link?.parentNode?.removeChild(link);
      });
  };

  const downloadMutation = useMutation(
    ["document", "file", "download"],
    fetchDownload,
    {
      onError: () => {
        alertContext.showError({
          title: t("download_failure"),
          message: (downloadMutation.error as any)?.message ?? undefined,
          timeOut: 5000,
        });
        downloadMutation.reset();
        window.scrollTo(0, 260); // Scroll to page header to show error message.
      },
    }
  );

  const runDownload = async (
    projectId: Maybe<string> | undefined,
    communicationId: Maybe<string> | undefined,
    attachmentName: Maybe<string> | undefined
  ) => {
    if (projectId == null || communicationId == null || attachmentName == null)
      return;
    return downloadMutation.mutate({
      projectId,
      communicationId,
      attachmentName,
    });
  };

  const [
    removeBptProjectCommunication,
    { loading: loadingRemoveBptProjectCommunication },
  ] = useMutationApollo(DeleteBptProjectCommunicationDocument, {
    refetchQueries: ["BptProjectCommunications"],
    errorPolicy: "all",
  });

  const onRemoveBptProjectCommunication = async (
    projectId: string,
    communicationId: string
  ) => {
    try {
      const res = await removeBptProjectCommunication({
        variables: {
          projectId,
          communicationId,
        },
      });

      if (res.errors) throw res.errors;

      alertContext.showSuccess({
        message: t("successfully_removed_file"),
        timeOut: 5000,
      });
    } catch (e) {
      console.error("Remove project communication file failed!");
      alertContext.showError({
        message: t("remove_fail"),
        timeOut: 5000,
      });
    }
  };

  return (
    <SectionCard
      header={
        <div className="flexbug-9-workaround">
          <div className="flex justify-between align-start">
            <h2 className="mrgn-tp-0 mrgn-bttm-0">
              {t("attached_communications")}
              {communications && communications?.length > 0 ? (
                <span className="badge mrgn-lft-10 mrgn-bttm-sm">
                  {communications.length}
                  <span className="wb-inv"> files attached</span>
                </span>
              ) : null}
            </h2>
            {!isProjectCompleted && (
              <RenderWhenAuthorized
                authorizedRoles={ROLE_ACTIONS.bptProject.update}
              >
                <AddCommunicationsBtn
                  defaultValues={undefined}
                  projectId={projectId ?? ""}
                  buttonLabel={t("add_files")}
                  refetchQuery={() => {
                    refetchBptProject();
                    refetchBptProjectCommunications();
                  }}
                  onCompleted={function (data: any): void {
                    throw new Error("Function not implemented.");
                  }}
                  onError={function (error: ApolloError | undefined): void {
                    throw new Error("Function not implemented.");
                  }}
                />
              </RenderWhenAuthorized>
            )}
          </div>
        </div>
      }
      classNameSummary={"mrgn-bttm-0 py-2_5"}
      collapsible
      open={false}
      showLine={communications == null || communications.length <= 0}
    >
      {loadingBptProjectCommunications ? (
        <LoadingIndicator centered className="mrgn-tp-md" />
      ) : errorBptProjectCommunications ? (
        <span className="label label-danger">{t("fetch_fail")}</span>
      ) : communications == null || communications.length <= 0 ? (
        <>
          {!isProjectCompleted ? (
            <RenderWhenAuthorized
              authorizedRoles={ROLE_ACTIONS.bptProject.update}
              fallbackComponent={<NoResults centered />}
            >
              <div className="text-center mrgn-tp-md">
                <div className="lead mrgn-tp-md mrgn-bttm-0">
                  <AddCommunicationsBtn
                    defaultValues={undefined}
                    projectId={projectId ?? ""}
                    buttonLabel={t("add_files")}
                    showImage
                    refetchQuery={() => {
                      refetchBptProject();
                      refetchBptProjectCommunications();
                    }}
                    onCompleted={function (data: any): void {
                      throw new Error("Function not implemented.");
                    }}
                    onError={function (error: ApolloError | undefined): void {
                      throw new Error("Function not implemented.");
                    }}
                  />
                </div>
              </div>
            </RenderWhenAuthorized>
          ) : (
            <NoResults centered />
          )}
        </>
      ) : (
        <>
          {communications?.map((communication, index) => (
            <div
              className="row list-item separator-line species-field px-0 py-2_5"
              key={"file - " + index}
            >
              <div className="col-sm-12 species-data">
                <dl>
                  <dt className="text-muted wb-inv">
                    {t("file")} - {index + 1}
                  </dt>
                  <dd className="mrgn-bttm-0">
                    <div className="flex justify-between align-center">
                      {/* ---------- Communication file name ---------- */}
                      <div className="font-size-16 mrgn-bttm-3">
                        {communication?.reference?.name}
                      </div>

                      {!isProjectCompleted && (
                        <RenderWhenAuthorized
                          authorizedRoles={ROLE_ACTIONS.bptProject.update}
                        >
                          <div className="flex justify-right gap-sm flex-wrap">
                            {/* ---------- Download communication file (button) ---------- */}
                            <button
                              type="button"
                              className="btn btn-link p-0 min-height-fit-content font-size-14 hover-grey"
                              onClick={() => {
                                setDownloadCommunicationId(
                                  communication?.attachmentId ?? ""
                                );
                                runDownload(
                                  projectId,
                                  communication?.attachmentId,
                                  communication?.reference?.name
                                );
                              }}
                              title={t("download_file")}
                              disabled={
                                projectId == null ||
                                communication?.attachmentId == null ||
                                downloadMutation.isLoading
                              }
                              data-testid={`button-download-file-${index}`}
                            >
                              {downloadMutation.isLoading &&
                              downloadCommunicationId ===
                                communication?.attachmentId ? (
                                <LoadingIndicator />
                              ) : (
                                <i className="fas fa-download"></i>
                              )}
                            </button>

                            {/* ---------- Delete communication file (button) ---------- */}
                            <DeleteButtonModal
                              showButtonText={false}
                              buttonTitle={t("remove_file")}
                              modalTitle={t("remove_file")}
                              alertContent={t("remove_file_warning")}
                              alertConfirm={t("i_confirm")}
                              onDelete={() => {
                                setRemoveCommunicationId(
                                  communication?.attachmentId ?? ""
                                );
                                onRemoveBptProjectCommunication(
                                  projectId ?? "error",
                                  communication?.attachmentId ?? "error"
                                );
                              }}
                              loading={
                                loadingRemoveBptProjectCommunication &&
                                removeCommunicationId ===
                                  communication?.attachmentId
                              }
                              disabled={
                                projectId == null ||
                                communication?.attachmentId == null ||
                                loadingRemoveBptProjectCommunication
                              }
                              className="font-size-14 hover-grey"
                              dataTestid={`button-remove-file-${index}`}
                            />
                          </div>
                        </RenderWhenAuthorized>
                      )}
                    </div>
                  </dd>
                </dl>
              </div>
            </div>
          ))}
        </>
      )}
    </SectionCard>
  );
};

export default AttachedCommunicationsCard;
