import { ApolloError, useMutation as useMutationApollo } from "@apollo/client";
import { useMutation } from "@tanstack/react-query";
import { getApiDocumentManagementServiceUrl } from "azure/environment";
import DeleteButtonModal from "components/atoms/DeleteButtonModal";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import MissingData from "components/atoms/MissingData";
import NoResults from "components/atoms/NoResults";
import SectionCard from "components/atoms/SectionCard";
import { getUserAccessToken } from "features/auth/CustomMsalProvider";
import { ROLE_ACTIONS, RenderWhenAuthorized } from "features/auth/components";
import { useGlobalAlertContext } from "features/globalAlert";
import {
  ArchiveStatus,
  DeletePhotoLicenseAttachmentDocument,
  Maybe,
  PhotoLicense,
} from "generated/gql-types";
import * as React from "react";
import { useTranslation } from "react-i18next";
import AddPhotoLicenseBtn from "../../AddLicenseFileModal/AddLicenseFileBtn";

interface LicenseFileCardProps {
  photoLicenseData?: Maybe<PhotoLicense>;
  refetchQuery: () => void;
}

export const LicenseFileCard: React.FC<LicenseFileCardProps> = (props) => {
  const { photoLicenseData, refetchQuery } = props;
  const { t, i18n } = useTranslation();
  const alertContext = useGlobalAlertContext();

  const fetchDownload = async (params: {
    photoLicenseId: string;
    licenseFileId: string;
    licenseFileName: string;
  }) => {
    const token = await getUserAccessToken();
    const baseUrl =
      getApiDocumentManagementServiceUrl("7080") +
      `photolicense/${params.photoLicenseId}/license/${params.licenseFileId}`;

    return fetch(baseUrl, {
      method: "GET",
      body: null, // Not required.
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        // 204 = No content
        if (res.status === 204 || res.status < 200 || res.status >= 300) {
          throw new Error(res.statusText);
        }
        return res;
      })
      .then((res) => res.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", params.licenseFileName);
        document.body.appendChild(link);
        link.click();
        link?.parentNode?.removeChild(link);
      });
  };

  const downloadMutation = useMutation(
    ["document", "licenseFile", "download"],
    fetchDownload,
    {
      onError: () => {
        alertContext.showError({
          title: t("download_failure"),
          message: (downloadMutation.error as any)?.message ?? undefined,
          timeOut: 5000,
        });
        downloadMutation.reset();
        window.scrollTo(0, 260); // Scroll to page header to show error message.
      },
    }
  );

  const runDownload = async (
    photoLicenseId: Maybe<string> | undefined,
    licenseFileId: Maybe<string> | undefined,
    licenseFileName: Maybe<string> | undefined
  ) => {
    if (
      photoLicenseId == null ||
      licenseFileId == null ||
      licenseFileName == null
    )
      return;
    return downloadMutation.mutate({
      photoLicenseId,
      licenseFileId,
      licenseFileName,
    });
  };

  const [deleteLicenseFile, { loading: loadingDeleteLicenseFile }] =
    useMutationApollo(DeletePhotoLicenseAttachmentDocument, {
      refetchQueries: ["PhotoLicense"],
      errorPolicy: "all",
    });

  const onRemoveLicenseFile = async (
    photoLicenseId: string,
    licenseFileId: string
  ) => {
    try {
      const res = await deleteLicenseFile({
        variables: {
          photoLicenseId,
          licenseFileId,
        },
      });
      if (res.errors) throw res.errors;

      alertContext.showSuccess({
        message: t("licence_file_deleted_successfully"),
        timeOut: 5000,
      });
    } catch (e) {
      console.error("Remove License file failed!");
      alertContext.showError({
        title: t("remove_fail"),
        message:
          i18n.language === "fr"
            ? (e as any)[0].extensions.response.body.messages[0].frenchMessage
            : (e as any)[0].extensions.response.body.messages[0].englishMessage,
        timeOut: 5000,
      });
    }
  };

  const licenseFile = photoLicenseData?.licenseFile;

  return (
    <SectionCard
      header={
        <div className="flex justify-between align-start">
          <h2>{t("licence_file")}</h2>
          <div className="flex gap-md">
            {photoLicenseData?.stage === ArchiveStatus.Active &&
              licenseFile == null && (
                <RenderWhenAuthorized
                  authorizedRoles={ROLE_ACTIONS.photos.update}
                >
                  <AddPhotoLicenseBtn
                    defaultValues={undefined}
                    photoLicenseId={photoLicenseData?.id ?? ""}
                    buttonLabel={t("add_licence")}
                    refetchQuery={refetchQuery}
                    onCompleted={function (data: any): void {
                      throw new Error("Function not implemented.");
                    }}
                    onError={function (error: ApolloError | undefined): void {
                      throw new Error("Function not implemented.");
                    }}
                  />
                </RenderWhenAuthorized>
              )}
          </div>
        </div>
      }
      showLine={licenseFile == null}
    >
      {licenseFile == null ? (
        <NoResults message={t("no_files")} centered />
      ) : (
        <div className="list-item separator-line species-field py-2_5">
          <div className="flex justify-between align-start">
            <dl>
              {/* ---------- License file name ---------- */}
              <div className="species-data">
                <dt className="text-muted wb-inv">{t("file_name")}</dt>
                <dd className="mrgn-bttm-0">
                  <button
                    type="button"
                    className="btn btn-link p-0 min-height-fit-content mrgn-rght-md text-left"
                    onClick={() =>
                      runDownload(
                        photoLicenseData?.id,
                        photoLicenseData?.licenseFile?.id,
                        photoLicenseData?.licenseFile?.name
                      )
                    }
                    title={t("download_licence")}
                  >
                    {licenseFile?.name ? licenseFile.name : <MissingData />}
                  </button>
                  {downloadMutation.isLoading && <LoadingIndicator />}
                </dd>
              </div>
            </dl>
            {photoLicenseData?.stage === ArchiveStatus.Active && (
              <RenderWhenAuthorized
                authorizedRoles={ROLE_ACTIONS.photos.update}
              >
                {/* ---------- Delete license file button ---------- */}
                <DeleteButtonModal
                  showButtonText={false}
                  buttonTitle={t("delete_licence")}
                  modalTitle={t("delete_licence")}
                  alertContent={t("delete_licence_file_modal_message")}
                  alertConfirm={t("i_confirm")}
                  onDelete={() => {
                    onRemoveLicenseFile(
                      photoLicenseData?.id ?? "error",
                      licenseFile.id ?? "error"
                    );
                  }}
                  loading={loadingDeleteLicenseFile}
                  disabled={
                    photoLicenseData?.id == null ||
                    licenseFile.id == null ||
                    loadingDeleteLicenseFile
                  }
                  className="font-size-14 hover-grey"
                  dataTestid="button-delete-license-file"
                />
              </RenderWhenAuthorized>
            )}
          </div>
        </div>
      )}
    </SectionCard>
  );
};

export default LicenseFileCard;
