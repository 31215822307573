import { useApolloClient, useMutation } from "@apollo/client";
import { useAsyncList } from "@react-stately/data";
import GraphqlError from "components/GraphqlError";
import Alert from "components/atoms/Alert";
import SectionCard from "components/atoms/SectionCard";
import Layout from "components/layouts/OneColumn";
import PageSizeSelect from "components/molecules/PageSizeSelect";
import Pagination from "components/molecules/Pagination";
import AdminArchiveModal from "components/organisms/admin/AdminArchiveModal";
import AdminUnarchiveModal from "components/organisms/admin/AdminUnarchiveModal";
import EditResponseStatementModalBtn from "components/organisms/admin/EditResponseStatementModal/EditResponseStatementBtn";
import ResultsTable from "components/organisms/search/ResultsTable/ResultsTable";
import { ROLE_ACTIONS, RenderWhenAuthorized } from "features/auth/components";
import {
  AdminResponseStatementType,
  ArchiveStatus,
  DeactivateAdminResponseStatementDocument,
  ReactivateAdminResponseStatementDocument,
  ResponseStatementsForAdminResponseStatementDocument,
} from "generated/gql-types";
import useAdminAlertMsg from "hooks/admin/useAdminAlertMsg";
import { usePagination } from "hooks/util/usePagination";
import * as React from "react";
import { useTranslation } from "react-i18next";
import LoadingIndicator from "../../components/atoms/LoadingIndicator";
import NoResults from "../../components/atoms/NoResults";
import AddResponseStatementBtn from "../../components/organisms/admin/CreateResponseStatementModal/AddResponseStatementBtn";

interface ResponseStatementColumns {
  id: String;
  selected: boolean;
  statementNameEnglish?: string;
  statementNameFrench?: string;
  type?: string;
  messageEnglish?: string;
  messageFrench?: string;
}

const ResponseStatement: React.FC = (props) => {
  const { t, i18n } = useTranslation();
  const [selectedRowId, setSelectedRowId] = React.useState<string>("");
  const adminAlertMsg = useAdminAlertMsg(t("responseStatement"));
  const [showArchivedCheckboxState, setShowArchivedCheckboxState] =
    React.useState<boolean>(false);

  const client = useApolloClient();
  const [totalCount, setTotalCount] = React.useState<number>(0);
  const [totalPages, setTotalPages] = React.useState<number>(0);
  const pagination = usePagination();

  const getSortByOrder = (column: string, direction: string) => {
    const sortBy = column;
    const sortOrder = () => {
      return direction === "ascending" ? "asc" : "desc";
    };
    return `${sortBy}:${sortOrder()}`;
  };

  const rows = useAsyncList<any>({
    initialSortDescriptor: { column: "type", direction: "ascending" },
    async load({ cursor, filterText, sortDescriptor }: any) {
      const results = await client.query({
        query: ResponseStatementsForAdminResponseStatementDocument,
        variables: {
          params: {
            pageNumber: pagination.currentPage,
            pageSize: pagination.pageSize,
            status: showArchivedCheckboxState
              ? ArchiveStatus.Inactive
              : ArchiveStatus.Active,
            sortBy: getSortByOrder(
              sortDescriptor.column,
              sortDescriptor.direction
            ),
          },
        },
        fetchPolicy: "network-only",
        errorPolicy: "all",
      });

      const items =
        results?.data?.adminResponseStatementList?.adminResponseStatement.map(
          (responseStatement) => ({
            id: responseStatement?.id,
            statementNameEnglish: responseStatement?.name?.english,
            statementNameFrench: responseStatement?.name?.french,
            type:
              responseStatement?.type ===
              AdminResponseStatementType.ResponseStatementScenarios
                ? t("response_statement_scenarios")
                : t("applicable_federal_legislation"),
            typeCode: responseStatement?.type,
            includeConsultationPeriod:
              responseStatement?.includeConsultationPeriod,
            messageEnglish: responseStatement?.message?.english?.text,
            messageFrench: responseStatement?.message?.french?.text,
            status: responseStatement?.status,
          })
        ) ?? [];

      setTotalCount(
        results.data.adminResponseStatementList?.pagination?.totalCount ?? 0
      );
      setTotalPages(
        results.data.adminResponseStatementList?.pagination?.totalPages ?? 0
      );

      return {
        items,
        sortDescriptor,
      };
    },
  });

  const [runArchive] = useMutation(DeactivateAdminResponseStatementDocument, {
    onError: (_err) => {
      adminAlertMsg.onArchiveError();
    },
    onCompleted: (data) => {
      adminAlertMsg.onArchiveSuccess(
        data?.deactivateAdminResponseStatement?.name?.english,
        data?.deactivateAdminResponseStatement?.name?.french
      );
      rows.reload(); // refetchQueries
      setSelectedRowId("");
    },
    errorPolicy: "all",
  });

  const [runUnarchive] = useMutation(ReactivateAdminResponseStatementDocument, {
    onError: (_err) => {
      adminAlertMsg.onUnarchiveError();
    },
    onCompleted: (data) => {
      adminAlertMsg.onUnarchiveSuccess(
        data?.reactivateAdminResponseStatement?.name?.english,
        data?.reactivateAdminResponseStatement?.name?.french
      );
      rows.reload(); // refetchQueries
      setSelectedRowId("");
    },
    errorPolicy: "all",
  });

  const onSelectionChange = (keys: "all" | Set<string>) => {
    if (keys === "all") return;
    const selectedId = Array.from(keys)[0] ?? "";
    setSelectedRowId(selectedId);
  };

  const onArchive = async () => {
    if (selectedRowId == null || selectedRowId === "") return;
    await runArchive({
      variables: { deactivateAdminResponseStatementId: selectedRowId },
    });
  };

  const onUnarchive = async () => {
    if (selectedRowId == null || selectedRowId === "") return;
    await runUnarchive({
      variables: { reactivateAdminResponseStatementId: selectedRowId },
    });
  };

  React.useEffect(() => {
    rows.reload(); // refetchQueries
    setSelectedRowId("");
  }, [showArchivedCheckboxState, pagination.currentPage, pagination.pageSize]);

  React.useEffect(() => {
    pagination.goToPage(1);
  }, [showArchivedCheckboxState, pagination.pageSize]);

  const columns: Array<{
    name: string;
    key: keyof ResponseStatementColumns;
    sortable: boolean;
    isHTML: boolean;
  }> = React.useMemo(
    () => [
      {
        name: t("statement_name_english"),
        key: "statementNameEnglish",
        sortable: false,
        isHTML: false,
      },
      {
        name: t("statement_name_french"),
        key: "statementNameFrench",
        sortable: false,
        isHTML: false,
      },
      {
        name: t("statement_type"),
        key: "type",
        sortable: true,
        isHTML: false,
      },
      {
        name: t("english_text"),
        key: "messageEnglish",
        sortable: false,
        isHTML: true,
      },
      {
        name: t("french_text"),
        key: "messageFrench",
        sortable: false,
        isHTML: true,
      },
    ],
    [i18n.language]
  );

  const selectedRow = rows.items.find((x) => x.id === selectedRowId);

  return (
    <>
      <Layout.Root>
        <Layout.Content>
          <h1>{t("response_statement_fields")}</h1>
          <GraphqlError
            title="Error loading Response Statement"
            errors={rows?.error}
          />

          {adminAlertMsg.alertIsVisible ? (
            <Alert
              type={adminAlertMsg.pageAlertType!}
              content={adminAlertMsg.pageAlertMsg!}
              onClose={adminAlertMsg.close}
              timeOut={5000}
            />
          ) : null}

          <SectionCard
            header={
              <div className="flex justify-between align-start">
                <h2>{t("list_of_response_statement_fields")}</h2>
                <div className="flex gap-md">
                  <RenderWhenAuthorized
                    authorizedRoles={
                      ROLE_ACTIONS.administration.responseStatementField.create
                    }
                  >
                    <AddResponseStatementBtn
                      onCompleted={() => {
                        rows.reload(); // refetchQueries
                      }}
                    />
                  </RenderWhenAuthorized>
                  <RenderWhenAuthorized
                    authorizedRoles={
                      ROLE_ACTIONS.administration.responseStatementField.update
                    }
                  >
                    <EditResponseStatementModalBtn
                      responseStatementId={selectedRowId}
                      defaultValues={{
                        nameEnglish: selectedRow?.statementNameEnglish ?? "",
                        nameFrench: selectedRow?.statementNameFrench ?? "",
                        statementType:
                          selectedRow?.typeCode ??
                          AdminResponseStatementType.ResponseStatementScenarios,
                        includeConsultationPeriod:
                          selectedRow?.includeConsultationPeriod ?? false,
                        englishMessage: selectedRow?.messageEnglish ?? "",
                        frenchMessage: selectedRow?.messageFrench ?? "",
                      }}
                      onCompleted={(data) => {
                        data?.updateAdminResponseStatement &&
                          adminAlertMsg.onEditSuccess(
                            data?.updateAdminResponseStatement?.name?.english,
                            data?.updateAdminResponseStatement?.name?.french
                          );
                        rows.reload(); // refetchQueries
                      }}
                      onError={adminAlertMsg.onCreateError}
                    />
                  </RenderWhenAuthorized>
                  <RenderWhenAuthorized
                    authorizedRoles={
                      ROLE_ACTIONS.administration.responseStatementField
                        .deactivateReactivate
                    }
                  >
                    {!showArchivedCheckboxState ? (
                      <AdminArchiveModal
                        onArchive={onArchive}
                        modalTitle={t("archive_a_response_statement")}
                        warningText={t("archive_response_statement_warning")}
                        selectedItemId={selectedRowId}
                      />
                    ) : null}
                  </RenderWhenAuthorized>
                  <RenderWhenAuthorized
                    authorizedRoles={
                      ROLE_ACTIONS.administration.responseStatementField
                        .deactivateReactivate
                    }
                  >
                    {showArchivedCheckboxState &&
                    (!selectedRow ||
                      selectedRow?.status === ArchiveStatus.Inactive) ? (
                      <AdminUnarchiveModal
                        onUnarchive={onUnarchive}
                        modalTitle={t("unarchive_a_response_statement")}
                        warningText={t("unarchive_response_statement_warning")}
                        selectedItemId={selectedRowId}
                      />
                    ) : null}
                  </RenderWhenAuthorized>
                </div>
              </div>
            }
          >
            {/*FILTERS:*/}
            <div className="flex justify-between flex-wrap mrgn-bttm-sm">
              <div className="checkbox">
                <label htmlFor="showInactiveCheckbox">
                  <input
                    id="showInactiveCheckbox"
                    type="checkbox"
                    checked={showArchivedCheckboxState}
                    onChange={(e) => setShowArchivedCheckboxState((x) => !x)}
                  />
                  &nbsp;
                  {t("show_archived_only")}
                </label>
              </div>

              <div className="flex-auto flex-col gap-sm align-end">
                <div>
                  <PageSizeSelect
                    pageSize={pagination.pageSize}
                    onChangePageSize={pagination.setPageSize}
                  />
                </div>
                <div className="font-size-16 justify-end pb-3">
                  {pagination.makeShowingString(totalCount)}
                </div>
              </div>
            </div>

            {/*RESULTS*/}
            {rows.isLoading ? (
              <LoadingIndicator centered className="mrgn-bttm-md" />
            ) : rows && rows?.items?.length === 0 ? (
              <NoResults centered />
            ) : (
              <>
                <ResultsTable
                  rows={rows.items}
                  columns={columns}
                  onSelectionChange={onSelectionChange as any}
                  selectedKeys={[selectedRowId]}
                  sortable
                  selectionMode="single"
                  showSelectionCheckboxes
                  sortDescriptor={rows.sortDescriptor}
                  onSortChange={rows.sort}
                />

                <Pagination
                  {...pagination.paginationComponentProps}
                  totalPages={totalPages ?? 0}
                />
              </>
            )}
          </SectionCard>
        </Layout.Content>
      </Layout.Root>
    </>
  );
};

export default ResponseStatement;
