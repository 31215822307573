import { ApolloError, useMutation } from "@apollo/client";
import DeleteButtonModal from "components/atoms/DeleteButtonModal";
import MissingData from "components/atoms/MissingData";
import NoResults from "components/atoms/NoResults";
import { PhotoImage } from "components/atoms/PhotoImage/PhotoImage";
import SafeRenderHtml from "components/atoms/SafeRenderHtml";
import SectionCard from "components/atoms/SectionCard";
import { ROLE_ACTIONS, RenderWhenAuthorized } from "features/auth/components";
import { useGlobalAlertContext } from "features/globalAlert";
import {
  ArchiveStatus,
  DeletePhotoDocument,
  Maybe,
  PhotoLicense,
  PhotoType,
  PublishState,
} from "generated/gql-types";
import { GalleryPathHelpers } from "pages/gallery/galleryRouter";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { formatTimestamp } from "util/formatTimestamp";
import htmlIsNullOrEmpty from "util/htmlIsNullOrEmpty";
import htmlRemoveOuterPTag from "util/htmlRemoveOuterPTag";
import AddPhotosBtn from "../../AddPhotosModal/AddPhotosBtn";
import EditPhotoBtn from "../EditPhotoModal/EditPhotoBtn";
import { mapDomainModelToForm } from "../PhotoForm/mappers";

interface PhotosCardProps {
  photoLicenseData?: Maybe<PhotoLicense>;
  refetchQuery: () => void;
}

export const PhotosCard: React.FC<PhotosCardProps> = (props) => {
  const { photoLicenseData, refetchQuery } = props;
  const { t, i18n } = useTranslation();
  const alertContext = useGlobalAlertContext();
  const [removeAttachmentId, setRemoveAttachmentId] = React.useState("");

  const [deletePhoto, { loading: loadingDeletePhoto }] = useMutation(
    DeletePhotoDocument,
    {
      refetchQueries: ["PhotoLicense"],
      errorPolicy: "all",
    }
  );

  const onRemoveAttachment = async (
    photoLicenseId: string,
    photoId: string
  ) => {
    try {
      const res = await deletePhoto({
        variables: {
          photoLicenseId,
          photoId,
        },
      });
      if (res.errors) throw res.errors;

      alertContext.showSuccess({
        message: t("photo_deleted_successfully"),
        timeOut: 5000,
      });
    } catch (e) {
      console.error("Remove Photo attachment file failed!");
      alertContext.showError({
        title: t("remove_fail"),
        message:
          i18n.language === "fr"
            ? (e as any)[0].extensions.response.body.messages[0].frenchMessage
            : (e as any)[0].extensions.response.body.messages[0].englishMessage,
        timeOut: 5000,
      });
    }
  };

  const photos = photoLicenseData?.photos;

  return (
    <SectionCard
      header={
        <div className="flex justify-between align-start">
          <h2>{t("photos")}</h2>
          <div className="flex gap-md">
            {photoLicenseData?.stage === ArchiveStatus.Active && (
              <RenderWhenAuthorized
                authorizedRoles={ROLE_ACTIONS.photos.update}
              >
                <AddPhotosBtn
                  defaultValues={undefined}
                  photoLicenseId={photoLicenseData?.id ?? ""}
                  buttonLabel={t("add_photos")}
                  refetchQuery={refetchQuery}
                  onCompleted={function (data: any): void {
                    throw new Error("Function not implemented.");
                  }}
                  onError={function (error: ApolloError | undefined): void {
                    throw new Error("Function not implemented.");
                  }}
                />
              </RenderWhenAuthorized>
            )}
          </div>
        </div>
      }
      showLine={photos == null || photos.length <= 0}
    >
      {photos == null || photos.length <= 0 ? (
        <>
          {photoLicenseData?.stage === ArchiveStatus.Active ? (
            <RenderWhenAuthorized
              authorizedRoles={ROLE_ACTIONS.photos.update}
              fallbackComponent={<NoResults centered />}
            >
              <div className="text-center mrgn-tp-md">
                <div className="lead mrgn-tp-md mrgn-bttm-sm">
                  <AddPhotosBtn
                    defaultValues={undefined}
                    photoLicenseId={photoLicenseData?.id ?? ""}
                    buttonLabel={t("add_files_to_your_document")}
                    showImage
                    refetchQuery={refetchQuery}
                    onCompleted={function (data: any): void {
                      throw new Error("Function not implemented.");
                    }}
                    onError={function (error: ApolloError | undefined): void {
                      throw new Error("Function not implemented.");
                    }}
                  />
                </div>
              </div>
            </RenderWhenAuthorized>
          ) : (
            <NoResults centered />
          )}
        </>
      ) : (
        <>
          {photos?.map((photo, index) => {
            const mappedPhoto = mapDomainModelToForm(photo);
            return (
              <div
                className="row list-item separator-line species-field py-2_5"
                key={"photo - " + index}
              >
                {photoLicenseData?.stage === ArchiveStatus.Active && (
                  <RenderWhenAuthorized
                    authorizedRoles={ROLE_ACTIONS.photos.update}
                  >
                    <div className="pull-right flex justify-right gap-sm">
                      {/* ---------- Edit photo button ---------- */}
                      <EditPhotoBtn
                        photoLicenseId={photoLicenseData?.id ?? "error"}
                        photoId={photo?.id ?? "error"}
                        defaultValues={mappedPhoto}
                        refetchQuery={refetchQuery}
                        onCompleted={function (data: any): void {
                          return;
                        }}
                        onError={function (
                          error: ApolloError | undefined
                        ): void {
                          throw new Error("Function not implemented.");
                        }}
                      />
                      {/* ---------- Delete photo button ---------- */}
                      <DeleteButtonModal
                        showButtonText={false}
                        buttonTitle={t("delete_photo")}
                        modalTitle={t("delete_photo")}
                        alertContent={t("delete_photo_modal_message")}
                        alertConfirm={t("i_confirm")}
                        onDelete={() => {
                          setRemoveAttachmentId(photo?.id ?? "");
                          onRemoveAttachment(
                            photoLicenseData?.id ?? "error",
                            photo?.id ?? "error"
                          );
                        }}
                        loading={
                          loadingDeletePhoto && removeAttachmentId === photo?.id
                        }
                        disabled={
                          photoLicenseData?.id == null ||
                          photo?.id == null ||
                          loadingDeletePhoto
                        }
                        className="font-size-14 hover-grey"
                        dataTestid={`button-remove-file-${index}`}
                      />
                    </div>
                  </RenderWhenAuthorized>
                )}

                <div className="pull-left flex gap-sm flex-wrap-sm">
                  <div className="min-width-fit-content">
                    {/* ---------- Thumbnail image ---------- */}
                    <PhotoImage
                      name={photo?.name ?? ""}
                      size={PhotoType.Thumbnail}
                      className="species-thumbnail img-thumbnail mrgn-rght-sm mrgn-bttm-sm"
                      alt=""
                    />
                  </div>
                  <div>
                    <dl>
                      {/* ---------- Photo title ---------- */}
                      <div className="species-data">
                        <dt className="text-muted wb-inv">
                          {t("photo")} - {index + 1}
                        </dt>
                        <dd className="mrgn-bttm-10">
                          {htmlIsNullOrEmpty(
                            i18n.language === "fr"
                              ? photo?.title?.french?.text
                              : photo?.title?.english?.text
                          ) ? (
                            <MissingData />
                          ) : (
                            <SafeRenderHtml
                              htmlString={
                                i18n.language === "fr"
                                  ? photo?.title?.french?.text
                                  : photo?.title?.english?.text
                              }
                            />
                          )}
                        </dd>
                      </div>
                    </dl>
                    <details className="species-details mrgn-lft-10">
                      <summary className="px-2 py-0 white-space-nowrap">
                        {t("photo_information")}
                      </summary>
                      <dl className="row">
                        {/* ---------- Date taken ---------- */}
                        <div className="col-sm-12 species-data">
                          <dt className="text-muted">{t("date_taken")}</dt>
                          <dd>
                            {formatTimestamp(photo?.dateTaken) ?? (
                              <MissingData />
                            )}
                          </dd>
                        </div>
                      </dl>
                      {/* ---------- Photo description ---------- */}
                      <dl className="row">
                        <div className="col-sm-12 species-data">
                          <dt className="text-muted white-space-nowrap">
                            {t("photo_description")}
                          </dt>
                          <dd>
                            {htmlIsNullOrEmpty(
                              i18n.language === "fr"
                                ? photo?.description?.french?.text
                                : photo?.description?.english?.text
                            ) ? (
                              <MissingData />
                            ) : (
                              <SafeRenderHtml
                                htmlString={
                                  i18n.language === "fr"
                                    ? photo?.description?.french?.text
                                    : photo?.description?.english?.text
                                }
                              />
                            )}
                          </dd>
                        </div>
                      </dl>
                      {/* ---------- Associated wildlife species ---------- */}
                      <dl className="row">
                        <div className="col-sm-12 species-data">
                          <dt className="text-muted">
                            {t("associated_wildlife_species")}
                          </dt>
                          <dd>
                            {photo?.photoSpecies == null ||
                            photo.photoSpecies.length <= 0 ? (
                              <MissingData />
                            ) : (
                              photo.photoSpecies.map((x, index) => (
                                <div
                                  key={`associated-species-${index}`}
                                  className="flex justify-between gap-lg mrgn-bttm-sm"
                                >
                                  <div>
                                    {x?.associatedSpecies?.listingRefId ==
                                    null ? (
                                      <>
                                        <Link
                                          to={GalleryPathHelpers.GalleryByReference(
                                            "cosewicws",
                                            x?.associatedSpecies?.cosewicRefId
                                              ?.id ?? "error"
                                          )}
                                          data-testid={`associated-species-${index}`}
                                        >
                                          <SafeRenderHtml
                                            htmlString={
                                              i18n.language === "fr"
                                                ? htmlRemoveOuterPTag(
                                                    x?.associatedSpecies
                                                      ?.commonNameFrench?.name
                                                      ?.text
                                                  ) +
                                                  (x?.associatedSpecies
                                                    ?.population?.nameFr
                                                    ? ", " +
                                                      x.associatedSpecies
                                                        ?.population?.nameFr!
                                                    : "")
                                                : htmlRemoveOuterPTag(
                                                    x?.associatedSpecies
                                                      ?.commonNameEnglish?.name
                                                      ?.text
                                                  ) +
                                                  (x?.associatedSpecies
                                                    ?.population?.nameEn
                                                    ? ", " +
                                                      x?.associatedSpecies
                                                        ?.population?.nameEn!
                                                    : "")
                                            }
                                          />
                                        </Link>
                                        <br />
                                      </>
                                    ) : (
                                      <>
                                        <Link
                                          to={GalleryPathHelpers.GalleryByReference(
                                            "listingws",
                                            x?.associatedSpecies?.listingRefId
                                              ?.id ?? "error"
                                          )}
                                          data-testid={`associated-species-${index}`}
                                        >
                                          <SafeRenderHtml
                                            htmlString={
                                              i18n.language === "fr"
                                                ? htmlRemoveOuterPTag(
                                                    x?.associatedSpecies
                                                      ?.commonNameFrench?.name
                                                      ?.text
                                                  ) +
                                                  (x?.associatedSpecies
                                                    ?.population?.nameFr
                                                    ? ", " +
                                                      x?.associatedSpecies
                                                        ?.population?.nameFr!
                                                    : "")
                                                : htmlRemoveOuterPTag(
                                                    x?.associatedSpecies
                                                      ?.commonNameEnglish?.name
                                                      ?.text
                                                  ) +
                                                  (x?.associatedSpecies
                                                    ?.population?.nameEn
                                                    ? ", " +
                                                      x?.associatedSpecies
                                                        ?.population?.nameEn!
                                                    : "")
                                            }
                                          />
                                        </Link>
                                        <br />
                                      </>
                                    )}
                                  </div>
                                  <div className="bg-info px-2">
                                    {x?.state == null ? (
                                      <MissingData />
                                    ) : (
                                      t("photo_is_" + x.state.toLowerCase())
                                    )}
                                  </div>
                                </div>
                              ))
                            )}
                          </dd>
                        </div>
                      </dl>
                      {/* ---------- Photo usage history ---------- */}
                      <dl className="row">
                        <div className="col-sm-12 species-data">
                          <dt className="text-muted white-space-nowrap">
                            {t("photo_usage_history")}
                          </dt>
                          <dd>
                            {htmlIsNullOrEmpty(photo?.usageHistory?.text) ? (
                              <MissingData />
                            ) : (
                              <SafeRenderHtml
                                htmlString={photo?.usageHistory?.text}
                              />
                            )}
                          </dd>
                        </div>
                      </dl>
                    </details>
                  </div>
                </div>
                <div className="clearfix" />
              </div>
            );
          })}
        </>
      )}
    </SectionCard>
  );
};

export default PhotosCard;
