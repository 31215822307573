import { ApolloError, useMutation } from "@apollo/client";
import { OverlayContainer } from "@react-aria/overlays";
import type { OverlayTriggerState } from "@react-stately/overlays";
import { useOverlayTriggerState } from "@react-stately/overlays";
import GraphqlError from "components/GraphqlError";
import ModalDialog from "components/molecules/Modal";
import { useGlobalAlertContext } from "features/globalAlert";
import {
  BptTemplateActivityBundle,
  Maybe,
  UpdateBptTemplateBundleActivityDocument,
} from "generated/gql-types";
import * as React from "react";
import { SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  ActivityBundlesForm,
  ActivityBundlesFormFields,
} from "./ActivityBundlesReorderForm";
import { formIntoUpdateInput } from "./ReorderActivityBundlesUtil";

export interface ReorderActivityBundlesPopupProps {
  templateId?: Maybe<string>;
  activityBundles?: Maybe<Maybe<BptTemplateActivityBundle>[]>; // ActivityList
  modalState: OverlayTriggerState;
  onCompleted: (data: any) => void;
  onError: (error: ApolloError | undefined) => void;
}

const ReorderActivityBundlesPopup: React.FC<
  ReorderActivityBundlesPopupProps
> = (props) => {
  const { t } = useTranslation();
  const { modalState } = props;
  const alertContext = useGlobalAlertContext();

  const [runSubmit, { loading: saving, error: savingError, reset }] =
    useMutation(UpdateBptTemplateBundleActivityDocument, {
      refetchQueries: [
        "BptTemplateActivityList",
        "BptTemplateStepList",
        "BptTemplatePhase",
      ],
      errorPolicy: "all",
    });

  const onSubmit: SubmitHandler<Partial<ActivityBundlesFormFields>> = async (
    formData
  ) => {
    const input = formIntoUpdateInput(formData);

    try {
      const res = await runSubmit({
        variables: {
          templateId: props.templateId ?? "error",
          input: input,
        },
      });
      if (res.errors == null) {
        modalState.close();
      }
      alertContext.showSuccess({
        message: t("successfully_update_activity_order"),
        timeOut: 5000,
      });
    } catch (e) {
      console.error(e);
    }
  };

  const onClose = () => {
    modalState.close();
    reset();
  };

  if (!modalState.isOpen) return null;

  //const defaultValues = {}; //domainModelIntoForm(props.type!, originalList);

  return (
    <>
      <OverlayContainer>
        <ModalDialog
          title={t("reorder_and_bundle_activities")}
          className={"modal-width-50"}
          isOpen
          onClose={onClose}
          showModalFooter={false}
        >
          <GraphqlError
            title={t("error_activities_reorder")}
            errors={savingError}
          />
          <div>{t("reorder_activities_message")}</div>
          <ActivityBundlesForm
            defaultValues={{
              activityBundles:
                props.activityBundles as BptTemplateActivityBundle[],
            }}
            onSubmit={onSubmit}
            onClose={onClose}
            isSaving={saving}
          />
        </ModalDialog>
      </OverlayContainer>
    </>
  );
};

export default ReorderActivityBundlesPopup;

export const useReorderActivityBundlesPopup = useOverlayTriggerState;
