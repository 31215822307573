import FormButtons from "components/molecules/FormButtons/FormButtons";
import { BptTemplatePhase } from "generated/gql-types";
import * as React from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { PhasesReorder } from "./PhasesReorder";

export interface PhasesReorderFormFields {
  phases: Array<BptTemplatePhase>;
}

export interface PhasesReorderFormProps {
  defaultValues?: Partial<PhasesReorderFormFields>;
  onSubmit: SubmitHandler<Partial<PhasesReorderFormFields>>;
  onClose: () => void;
  isSaving?: boolean;
}

export const PhasesReorderForm: React.FC<PhasesReorderFormProps> = (props) => {
  const form = useForm<PhasesReorderFormFields>({
    defaultValues: props.defaultValues,
    mode: "onChange",
  });

  const { control, handleSubmit, formState } = form;
  const { isDirty, isSubmitting, errors } = formState;

  const onSubmit: SubmitHandler<Partial<PhasesReorderFormFields>> = async (
    formData
  ) => {
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        props.onSubmit(formData);
        resolve();
      }, 500);
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <fieldset disabled={props.isSaving}>
        <Controller
          control={control}
          name="phases"
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <PhasesReorder
              value={value as Array<BptTemplatePhase>}
              onChange={onChange}
            />
          )}
        />
      </fieldset>

      <hr className="row mrgn-tp-0 mrgn-bttm-md" />
      <FormButtons
        isDirty={isDirty}
        isSubmitting={isSubmitting || props.isSaving}
        onCancel={props.onClose}
        errors={errors}
      />
    </form>
  );
};
