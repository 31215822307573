import MissingData from "components/atoms/MissingData";
import SafeRenderHtml from "components/atoms/SafeRenderHtml";
import SectionCard from "components/atoms/SectionCard";
import { TooltipAlert } from "components/atoms/Tooltip/TooltipAlert";
import { RenderWhenAuthorized, ROLE_ACTIONS } from "features/auth/components";
import { useGlobalAlertContext } from "features/globalAlert";
import {
  BptTemplate,
  BptTemplatePhase,
  BptTemplateStage,
  Maybe,
} from "generated/gql-types";
import useAdminAlertMsg from "hooks/admin/useAdminAlertMsg";
import * as React from "react";
import { useTranslation } from "react-i18next";
import EditPhaseBtn from "../EditPhaseModal/EditPhaseBtn";

export interface PhaseInformationCardProps {
  bptTemplate?: Maybe<BptTemplate>;
  phaseInfo?: Maybe<BptTemplatePhase>;
}

export const PhaseInformationCard: React.FC<PhaseInformationCardProps> = (
  props
) => {
  const { t } = useTranslation();
  const { bptTemplate, phaseInfo } = props;
  const alertContext = useGlobalAlertContext();
  const adminAlertMsg = useAdminAlertMsg(t("phase"));

  const templateId = bptTemplate?.id;
  const isTemplateDraft = bptTemplate?.stage === BptTemplateStage.Draft;

  return (
    <>
      <SectionCard
        header={
          <div className="flex justify-between align-start">
            <h2>{t("phase_information")}</h2>
            {isTemplateDraft ? (
              <RenderWhenAuthorized
                authorizedRoles={ROLE_ACTIONS.administration.bptTemplate.update}
              >
                <EditPhaseBtn
                  refetchQueries={["BptTemplatePhase"]}
                  templateId={templateId}
                  phaseInfo={phaseInfo}
                  onCompleted={(data: any) => {
                    alertContext.showSuccess({
                      message: data?.UpdateBptTemplatePhase?.name?.text,
                      timeOut: 5000,
                    });
                  }}
                  onError={adminAlertMsg.onCreateError}
                />
              </RenderWhenAuthorized>
            ) : null}
          </div>
        }
      >
        <div className="species-field font-size-18">
          {/* ------------ Description ------------ */}
          <div className="row">
            <dl>
              <div className="species-data col-sm-12">
                <dt className="text-muted">{t("description")}</dt>
                <dd>
                  {phaseInfo?.description == null ? (
                    <MissingData />
                  ) : (
                    <SafeRenderHtml
                      htmlString={phaseInfo?.description.text ?? ""}
                    />
                  )}
                </dd>
              </div>
            </dl>
          </div>

          <div className="row">
            <dl>
              {/* ------------ Calculated duration ------------ */}
              <div className="species-data col-sm-12">
                <dt className="text-muted">
                  {t("calculated_duration")}
                  <TooltipAlert
                    isVisible={phaseInfo?.calculatedDuration == null}
                  >
                    {t("can_not_be_calculated_tooltip")}
                  </TooltipAlert>
                </dt>
                <dd>
                  {phaseInfo?.calculatedDuration == null ? (
                    <MissingData />
                  ) : (
                    <SafeRenderHtml
                      htmlString={
                        phaseInfo?.calculatedDuration + " " + t("business_days")
                      }
                    />
                  )}
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </SectionCard>
    </>
  );
};

export default PhaseInformationCard;
