import { OverlayContainer } from "@react-aria/overlays";
import { useOverlayTriggerState } from "@react-stately/overlays";
import cc from "classcat";
import { Maybe } from "generated/gql-types";
import * as React from "react";
import { useTranslation } from "react-i18next";
import ModalDialog from "../../molecules/Modal";

export const InfoButtonAndModal: React.FC<{
  title?: Maybe<string>;
  text?: string | any;
  buttonTitle?: string;
  buttonIcon?: React.ReactElement;
  className?: string;
  classNameModal?: string;
}> = ({
  title,
  text,
  buttonTitle,
  buttonIcon,
  className,
  classNameModal,
  children,
}) => {
  const { t } = useTranslation();
  const modalState = useOverlayTriggerState({});

  return (
    <>
      <button
        type="button"
        className={cc(["btn btn-link info-button-modal", className])}
        data-testid="button-info"
        onClick={modalState.open}
        title={buttonTitle || title || t("info")}
      >
        {buttonIcon != null
          ? text || buttonIcon
          : text || <span className="far fa-question-circle" />}
      </button>
      {modalState.isOpen && (
        <OverlayContainer>
          <ModalDialog
            id="info_modal"
            title={title || t("info")}
            onClose={modalState.close}
            buttonText="close"
            className={classNameModal}
          >
            {children}
          </ModalDialog>
        </OverlayContainer>
      )}
    </>
  );
};

export default InfoButtonAndModal;
