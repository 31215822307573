import { useState, type ReactElement } from "react";
import * as React from "react";
import {
  AutocompleteForProgramDocument,
  BilingualAbbreviationAcronymTagsLookupText,
} from "generated/gql-types";
import QueryAutocomplete from "../../QueryAutocomplete/QueryAutocomplete";
import i18n from "i18n";
import { useTranslation } from "react-i18next";
import { bilingualLookupTextNameForLanguage } from "mappers";

const additionalQueryVariables = {
  params: {
    searchType: "contains",
    isCaseSensitive: false,
    category: "programs",
    acronym: "COSEWIC,LISTING,RECOVERY,SRA,PROTECTION,PERMITS",
    sortBy: i18n.language === "fr" ? "name.french:asc" : "name.english:asc",
  },
};

const ProgramAutocomplete: React.FC<ProgramAutocompleteProps> = (props) => {
  const { t, i18n } = useTranslation();
  const [cleared, setCleared] = useState(false);
  const placeHolder = React.useMemo(() => {
    if (cleared || props.initialValue == null) {
      return t("program");
    } else {
      return bilingualLookupTextNameForLanguage(
        i18n.language,
        props.initialValue
      );
    }
  }, [cleared, props.initialValue, i18n, t]);
  return (
    <QueryAutocomplete
      id={"programAutocomplete"}
      label={props.label}
      queryDocument={AutocompleteForProgramDocument}
      listDisplayFormatter={(item) =>
        i18n.language === "fr" ? item.name.french : item.name.english
      }
      onSelectionChange={props.onSelectionChange}
      placeholder={placeHolder}
      isRequired={props.isRequired}
      onClear={() => {
        props.onSelectionChange(undefined);
        setCleared(true);
      }}
      additionalQueryVariables={additionalQueryVariables}
      disabled={props.disabled}
    />
  );
};

interface ProgramAutocompleteProps {
  isRequired?: boolean;
  onSelectionChange: (
    selectedItem?: BilingualAbbreviationAcronymTagsLookupText
  ) => void;
  label: string | ReactElement;
  disabled: boolean | undefined;
  initialValue?: any;
  currentUserProgram?: string;
}

export default ProgramAutocomplete;
