import { BptTemplateReference, Maybe, RichText } from "generated/gql-types";
import * as React from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import FieldValidationError from "components/atoms/forms/FieldValidationError";
import FormButtons from "components/molecules/FormButtons/FormButtons";
import { HTMLItalicsInputWithController } from "components/molecules/HTMLItalicsInput";
import BptTemplateAutocomplete from "components/molecules/bptProjects/BptTemplateAutoComplete";
import { FullHTMLEditorWithController } from "components/organisms/FullHTMLEditor";
import isNullOrEmpty from "util/isNullOrEmpty";
import { processBptProjectFormValues } from "../Utility/bptProjectFormUtil";

export interface BptProjectFormFields {
  name?: Maybe<RichText>;
  templateReference?: Maybe<BptTemplateReference> | null;
  projectLead?: Maybe<RichText>;
}

interface BptProjectFormProps {
  isEdit?: boolean;
  initialValues?: Partial<BptProjectFormFields>;
  onSubmit: SubmitHandler<Partial<BptProjectFormFields>>;
  onClose: () => void;
}

const BptProjectForm: React.FC<BptProjectFormProps> = (props) => {
  const defaultValues = props.initialValues;
  const form = useForm<BptProjectFormFields>({
    defaultValues: defaultValues,
  });
  const { control, handleSubmit, formState } = form;
  const { isDirty, isSubmitting, errors, dirtyFields } = formState;
  const { t } = useTranslation();

  const onSubmit: SubmitHandler<BptProjectFormFields> = (formData) => {
    const cleanedValues = processBptProjectFormValues(
      formData,
      dirtyFields,
      defaultValues ?? {}
    );
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        props.onSubmit(cleanedValues);
        resolve();
      }, 500);
    });
  };

  return (
    <form
      onSubmit={(e) => {
        e.stopPropagation();
        handleSubmit(onSubmit)(e);
      }}
    >
      {/* =============== Project name =============== */}
      <div className="form-group">
        <label htmlFor="nameInput" className="required">
          {t("project_name")}{" "}
          <strong className="required">({t("required")})</strong>
        </label>
        <HTMLItalicsInputWithController
          control={control}
          rules={{
            required: true,
          }}
          defaultValue={defaultValues?.name?.text ?? ""}
          id="nameInput"
          name="name.text"
          placeholder={`${t("project_name")}`}
        />
        {errors.name && (errors.name as any)?.text?.type === "required" && (
          <FieldValidationError>{t("field_is_required")}</FieldValidationError>
        )}
      </div>
      {/*---------------BptTemplate---------------------*/}
      <div className="form-group">
        <Controller
          control={control}
          name="templateReference"
          rules={{
            required: true,
          }}
          render={({ field: { onChange, value } }) => (
            <BptTemplateAutocomplete
              label={t("select_template")}
              isRequired={true}
              onSelectionChange={onChange}
              disabled={
                !isNullOrEmpty(defaultValues?.templateReference?.id) ||
                props.isEdit
              }
              initialValue={value}
            />
          )}
        />
      </div>
      {/* =============== ProjectLead =============== */}
      <div className="form-group">
        <label htmlFor="projectLead">
          <span className="field-name">{t("project_lead")}</span>{" "}
        </label>
        <FullHTMLEditorWithController
          control={control}
          id="projectLead"
          name="projectLead.text"
          defaultValue={defaultValues?.projectLead?.text ?? ""}
        />
        {errors.projectLead && (
          <FieldValidationError>{errors.projectLead}</FieldValidationError>
        )}
      </div>

      <hr className="row mrgn-tp-0 mrgn-bttm-md" />
      <FormButtons
        isDirty={isDirty}
        isSubmitting={isSubmitting}
        onCancel={props.onClose}
      />
    </form>
  );
};

export default BptProjectForm;
