import { useMutation, useQuery } from "@apollo/client";
import GraphqlError from "components/GraphqlError";
import MissingData from "components/atoms/MissingData";
import SafeRenderHtml from "components/atoms/SafeRenderHtml";
import SectionCard from "components/atoms/SectionCard";
import Layout from "components/layouts/OneColumn";
import EditDistributionListButtonAndModal from "components/organisms/DistributionLists/EditDistributionListButtonAndModal/EditDistributionListButtonAndModal";
import ManageContactsOrganizationsCard from "components/organisms/DistributionLists/ManageContactsOrganizationsCard/ManageContactsOrganizationsCard";
import { distributionListDomainModelIntoForm } from "components/organisms/DistributionLists/distributionlistFormUtil";
import { ContactType } from "components/organisms/contacts/types";
import DeleteButtonAndModal from "components/organisms/documents/DeleteButtonAndModal/DeleteButtonAndModal";
import { ROLE_ACTIONS, RenderWhenAuthorized } from "features/auth/components";
import { GlobalAlert, useGlobalAlertContext } from "features/globalAlert";
import {
  Contact,
  CreateDistributionListMemberDocument,
  DeleteDistributionListDocument,
  DeleteDistributionListMemberDocument,
  DistributionListDocument,
  DistributionListMemberVisibility,
  Organization,
} from "generated/gql-types";
import { CommunicationsPathHelpers } from "pages/communications/CommunicationsRouter";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";
import { formatTimestamp } from "util/formatTimestamp";
import htmlIsNullOrEmpty from "util/htmlIsNullOrEmpty";
import isNullOrEmpty from "util/isNullOrEmpty";

export const DistributionListPage: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { params } = useRouteMatch();
  const alertContext = useGlobalAlertContext();
  const { distributionListId } = params as any;
  const history = useHistory();

  //#region Distribution list metadata.
  const { loading, error, data } = useQuery(DistributionListDocument, {
    errorPolicy: "all",
    variables: {
      distributionListId: distributionListId,
    },
    fetchPolicy: "network-only",
  });
  const name = data?.distributionList?.name;
  const program =
    i18n.language === "fr"
      ? data?.distributionList?.program?.name?.french
      : data?.distributionList?.program?.name?.english;
  const createdBy = data?.distributionList?.createdBy;
  const createdOn = data?.distributionList?.createdAt;
  const updatedBy = data?.distributionList?.modifiedBy;
  const updatedDate = data?.distributionList?.modifiedAt;
  const note = data?.distributionList?.note?.text?.text;
  const noteUpdatedBy = data?.distributionList?.note?.modifiedBy;
  const noteUpdatedDate = data?.distributionList?.note?.modifiedAt;
  //#endregion

  //#region Add contact/organization button.
  const [
    createDistributionListMember,
    { loading: loadingCreateListMember, error: errorCreateListMember },
  ] = useMutation(CreateDistributionListMemberDocument, {
    errorPolicy: "all",
    onCompleted: (data) => {
      if (data.createDistributionListMember != null) {
        alertContext.clear();
        alertContext.showSuccess({
          message: t("contact_organization_successfully_added"),
          timeOut: 5000,
        });
      }
    },
  });

  const onAddContactOrOrg = (
    newValue: Contact | Organization | undefined | null,
    contactType: ContactType
  ) => {
    if (contactType === "contact") {
      const value = newValue as Contact;
      createDistributionListMember({
        variables: {
          createDistributionListMemberId:
            distributionListId ?? "ERR_MISSING_ID",
          input: {
            visibilityState: DistributionListMemberVisibility.Include,
            contactRefId: value.id,
            organizationRefId: null,
          },
        },
      });
    } else if (contactType === "organization") {
      const value = newValue as Organization;
      createDistributionListMember({
        variables: {
          createDistributionListMemberId:
            distributionListId ?? "ERR_MISSING_ID",
          input: {
            visibilityState: DistributionListMemberVisibility.Include,
            contactRefId: null,
            organizationRefId: value.id,
          },
        },
      });
    } else {
      throw new Error(`unsupported contact type: ${contactType}`);
    }
  };
  //#endregion

  //#region Remove contact/organization button.
  const [
    deleteDistributionListMember,
    { loading: loadingDeleteListMember, error: errorDeleteListMember },
  ] = useMutation(DeleteDistributionListMemberDocument, {
    errorPolicy: "all",
  });

  const onRemoveContactOrOrg = async (memberId: string) => {
    if (memberId == null || memberId === "") return;
    try {
      const res = await deleteDistributionListMember({
        variables: {
          distributionListId: distributionListId ?? "ERR_MISSING_ID",
          memberId: memberId,
        },
      });
      if (res.errors) throw res.errors;
      alertContext.clear();
      alertContext.showSuccess({
        message: t("successfully_removed_contact_member"),
        timeOut: 5000,
      });
    } catch (e) {
      console.error(e);
    }
  };
  //#endregion

  //#region Delete Distribution List button
  const onDeleteDistributionList = async () => {
    if (distributionListId == null || distributionListId === "") return;
    await runDeleteDistributionList({
      variables: { deleteDistributionListId: distributionListId },
    });
  };

  const [runDeleteDistributionList] = useMutation(
    DeleteDistributionListDocument,
    {
      onCompleted: (data) => {
        alertContext.showSuccess({
          message: name + " " + t("delete_success"),
          timeOut: 5000,
        });

        history.push({
          pathname: CommunicationsPathHelpers.DistributionLists,
        });
      },
      onError: (_err) => {
        alertContext.showError({
          title: t("delete_fail"),
          message: _err.message,
          timeOut: 5000,
        });
        window.scrollTo(0, 260);
      },
      errorPolicy: "all",
    }
  );

  const canDelete = React.useMemo(() => {
    if (loading) return false;
    if (data?.distributionList?.id != null) {
      return true;
    }
    return false;
  }, [data?.distributionList?.id, loading]);
  const initialValues = distributionListDomainModelIntoForm(
    data?.distributionList
  );

  //#endregion

  return (
    <>
      <Layout.Root>
        <Layout.Content>
          <GlobalAlert />
          <h1>{!loading && (name ?? "")}</h1>
          <GraphqlError
            title="Error fetching Distribution list"
            errors={error}
          />
          <GraphqlError
            title={t("add_fail")}
            errors={errorCreateListMember}
            showFrontendErrorMessages
          />
          <GraphqlError
            title={t("remove_fail")}
            errors={errorDeleteListMember}
          />
          <div className="flex justify-between align-center flex-wrap mrgn-bttm-md gap-sm">
            <div className="flex font-size-16 text-muted">
              <div>
                {t("program")}
                {": "}
                {program ?? <MissingData />}
              </div>
              <span className="px-2_5">{"|"}</span>
              <div style={{ maxWidth: 450 }}>
                {t("created_by")}
                {": "}
                {createdBy ?? <MissingData />}
              </div>
              <span className="px-2_5">{"|"}</span>
              <div>
                {t("created_date")}
                {": "}
                {formatTimestamp(createdOn) ?? <MissingData />}
              </div>
            </div>

            <div className="flex justify-right flex-auto gap-md">
              <RenderWhenAuthorized
                authorizedRoles={ROLE_ACTIONS.distributionList.update}
              >
                <EditDistributionListButtonAndModal
                  distributionListId={data?.distributionList?.id ?? ""}
                  defaultValues={initialValues}
                />
              </RenderWhenAuthorized>

              <RenderWhenAuthorized
                authorizedRoles={ROLE_ACTIONS.distributionList.delete}
              >
                <DeleteButtonAndModal
                  canAction={canDelete}
                  onAction={onDeleteDistributionList}
                  warningTitle={t(
                    "delete_distribution_list_modal_warning_title"
                  )}
                  warningMessage={t(
                    "delete_distribution_list_modal_warning_message"
                  )}
                  buttonText={t("delete_distribution_list")}
                  buttonTitle={t("delete_distribution_list")}
                  modalTitle={t("delete_distribution_list")}
                />
              </RenderWhenAuthorized>
            </div>
          </div>
          <ManageContactsOrganizationsCard
            distributionListId={distributionListId}
            distributionListName={data?.distributionList?.name ?? ""}
            updatedBy={updatedBy}
            updatedDate={updatedDate}
            onAddContactOrOrg={onAddContactOrOrg}
            onRemoveContactOrOrg={onRemoveContactOrOrg}
          />
          <SectionCard header={<h2>{t("note")}</h2>}>
            <div className="row">
              <dl>
                <div className="species-data col-sm-12">
                  <dt className="text-muted wb-inv">{t("note")}</dt>
                  <dd>
                    {htmlIsNullOrEmpty(note) ? (
                      <MissingData />
                    ) : (
                      <SafeRenderHtml htmlString={note} />
                    )}
                  </dd>
                </div>
              </dl>
            </div>
            <div className="flex separator-line-top text-muted font-size-14">
              <div>
                {t("updated_by")}
                {": "}
                {!isNullOrEmpty(noteUpdatedBy) ? (
                  noteUpdatedBy
                ) : (
                  <MissingData />
                )}
              </div>
              <span className="px-2_5">{"|"}</span>
              <div>
                {t("updated_date")}
                {": "}
                {formatTimestamp(noteUpdatedDate) ?? <MissingData />}
              </div>
            </div>
          </SectionCard>
        </Layout.Content>
      </Layout.Root>
    </>
  );
};
