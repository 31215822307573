import { RenderWhenAuthorized } from "features/auth/components";
import { ROLE_ACTIONS } from "features/auth/roles";
import RenderWhenFeatureIsEnabled from "features/featureFlags/RenderWhenFeatureIsEnabled";
import { FeatureFlagKeys } from "features/featureFlags/config";
import * as React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { makeRelativeNestedRoute } from "util/router";
import AdminIndexPage from ".";
import AdminBptTemplates from "./AdminBptTemplates";
import AdminContacts from "./AdminContacts";
import AdminGenus from "./AdminGenus";
import AdminOrganizations from "./AdminOrganizations";
import AdminPopulation from "./AdminPopulation";
import AdminRange from "./AdminRange";
import AdminResponseStatement from "./AdminResponseStatement";
import AdminSpecies from "./AdminSpecies";
import AdminSubspecies from "./AdminSubspecies";
import AdminTaxonomicGroup from "./AdminTaxonomicGroup";
import AdminUsers from "./AdminUsers";
import AdminVariety from "./AdminVariety";
import BptTemplatesIndex from "./bptTemplates/[id]";
import BptTemplatesPhasesIndex from "./bptTemplates/[id]/phases/[id]";
import ContactEdit from "./contacts/[id]/edit";
import ContactCreate from "./contacts/create";
import LandsIndexPage from "./lands";
import { LandsEditPage } from "./lands/[id]/edit";
import { LandsCreatePage } from "./lands/create";

export interface AdminRouterProps {}

export const AdminPathHelpers = {
  Home: () => `/admin`,
  Genus: () => `/admin/genus`,
  Species: () => `/admin/species`,
  Subspecies: () => `/admin/subspecies`,
  Population: () => `/admin/population`,
  Variety: () => `/admin/variety`,
  TaxonomicGroup: () => `/admin/taxonomicGroup`,
  Range: () => `/admin/range`,
  Lands: () => `/admin/lands`,
  LandsCreate: () => `/admin/lands/create`,
  LandsEdit: (landId: string) => `/admin/lands/${landId}/edit`,

  ResponseStatement: () => `/admin/responseStatement`,

  Organizations: () => `/admin/organizations`,
  Contacts: () => `/admin/contacts`,
  ContactsCreate: () => `/admin/contacts/create`,
  ContactsEdit: (id: string) => `/admin/contacts/${id}/edit`,

  Users: () => `/admin/users`,

  BptTemplates: () => `/admin/bptTemplates`,
  BptTemplatesIndex: (id: string) => `/admin/bptTemplates/${id}`,
  BptTemplatePhaseEdit: (id: string, phaseId: string) =>
    `/admin/bptTemplates/${id}/phases/${phaseId}/edit`,
  BptTemplatesPhaseIndex: (id: string, phaseId: string) =>
    `/admin/bptTemplates/${id}/phases/${phaseId}`,
  BptTemplateStepIndex: (id: string, phaseId: string, stepId: string) =>
    `/admin/bptTemplates/${id}/phases/${phaseId}/steps/${stepId}`,
};

export const AdminRouter: React.FC<AdminRouterProps> = (props) => {
  const match = useRouteMatch();
  const relative = React.useMemo(
    () => makeRelativeNestedRoute(match.url),
    [match.url]
  );

  return (
    <Switch>
      <Route exact path={relative("/")}>
        <AdminIndexPage />
      </Route>
      {/* -------------- SAR-DMTS properties -------------- */}
      <Route path={relative("/genus")}>
        <AdminGenus />
      </Route>
      <Route path={relative("/species")}>
        <AdminSpecies />
      </Route>
      <Route path={relative("/subspecies")}>
        <AdminSubspecies />
      </Route>
      <Route path={relative("/population")}>
        <AdminPopulation />
      </Route>
      <Route path={relative("/variety")}>
        <AdminVariety />
      </Route>
      <Route path={relative("/taxonomicGroup")}>
        <AdminTaxonomicGroup />
      </Route>
      <Route path={relative("/range")}>
        <AdminRange />
      </Route>
      <Route path="/admin/lands/create">
        <RenderWhenAuthorized
          authorizedRoles={ROLE_ACTIONS.administration.land.create}
          fallbackComponent={<Redirect to={"/forbidden"} />}
        >
          <LandsCreatePage />
        </RenderWhenAuthorized>
      </Route>
      <Route path="/admin/lands/:landId/edit">
        <RenderWhenAuthorized
          authorizedRoles={ROLE_ACTIONS.administration.land.update}
          fallbackComponent={<Redirect to={"/forbidden"} />}
        >
          <LandsEditPage />
        </RenderWhenAuthorized>
      </Route>
      <Route path="/admin/lands" exact>
        {/* <RenderWhenAuthorized
          authorizedRoles={ROLE_ACTIONS.administration.land.view}
          fallbackComponent={<Redirect to={"/forbidden"} />}
        > */}
        <LandsIndexPage />
        {/* </RenderWhenAuthorized> */}
      </Route>

      {/* -------------- Document management -------------- */}
      <Route path={relative("/responseStatement")}>
        <AdminResponseStatement />
      </Route>

      {/* -------------- Contact management -------------- */}
      <Route path={relative("/organizations")}>
        <AdminOrganizations />
      </Route>
      <Route path={relative("/contacts/:contactId/edit")}>
        <RenderWhenAuthorized
          authorizedRoles={ROLE_ACTIONS.administration.contact.update}
          fallbackComponent={<Redirect to={"/forbidden"} />}
        >
          <ContactEdit />
        </RenderWhenAuthorized>
      </Route>
      <Route path={relative("/contacts/create")}>
        <RenderWhenAuthorized
          authorizedRoles={ROLE_ACTIONS.administration.contact.create}
          fallbackComponent={<Redirect to={"/forbidden"} />}
        >
          <ContactCreate />
        </RenderWhenAuthorized>
      </Route>
      <Route path={relative("/contacts")}>
        <AdminContacts />
      </Route>

      {/* -------------- Authorization management -------------- */}
      <Route path={relative("/users")}>
        <RenderWhenFeatureIsEnabled flagKey={FeatureFlagKeys.Authorization}>
          <RenderWhenAuthorized
            authorizedRoles={ROLE_ACTIONS.administration.user.view}
            fallbackComponent={<Redirect to={"/forbidden"} />}
          >
            <AdminUsers />
          </RenderWhenAuthorized>
        </RenderWhenFeatureIsEnabled>
      </Route>

      {/* -------------- Business process tracking management -------------- */}
      <Route path={relative("/bptTemplates/:templateId/phases/:phaseId")}>
        <RenderWhenFeatureIsEnabled
          flagKey={FeatureFlagKeys.BusinessProcessTracking}
        >
          <RenderWhenAuthorized
            authorizedRoles={ROLE_ACTIONS.administration.bptTemplate.view}
            fallbackComponent={<Redirect to={"/forbidden"} />}
          >
            <BptTemplatesPhasesIndex />
          </RenderWhenAuthorized>
        </RenderWhenFeatureIsEnabled>
      </Route>
      <Route path={relative("/bptTemplates/:templateId")}>
        <RenderWhenFeatureIsEnabled
          flagKey={FeatureFlagKeys.BusinessProcessTracking}
        >
          <RenderWhenAuthorized
            authorizedRoles={ROLE_ACTIONS.administration.bptTemplate.view}
            fallbackComponent={<Redirect to={"/forbidden"} />}
          >
            <BptTemplatesIndex />
          </RenderWhenAuthorized>
        </RenderWhenFeatureIsEnabled>
      </Route>
      <Route path={relative("/bptTemplates")}>
        <RenderWhenFeatureIsEnabled
          flagKey={FeatureFlagKeys.BusinessProcessTracking}
        >
          <RenderWhenAuthorized
            authorizedRoles={ROLE_ACTIONS.administration.bptTemplate.view}
            fallbackComponent={<Redirect to={"/forbidden"} />}
          >
            <AdminBptTemplates />
          </RenderWhenAuthorized>
        </RenderWhenFeatureIsEnabled>
      </Route>
    </Switch>
  );
};

export default AdminRouter;
