import { useQuery } from "@apollo/client";
import Alert, { AlertTypes } from "components/atoms/Alert";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import GraphqlError from "components/GraphqlError";
import Layout from "components/layouts/OneColumn";
import { useOverviewContext } from "features/overview";
import { OverviewWsSpeciesProfileByReferenceDocument } from "generated/gql-types";
import useQueryParams from "hooks/util/useQueryParams";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { PublicProfilePathHelpers } from "./PublicProfileRouter";

const PublicProfilePageByReference: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const overviewContext = useOverviewContext();
  const query = useQueryParams() as any;

  const overviewProgram = query.get("program");
  const overviewId = query.get("id");

  React.useEffect(() => {
    overviewContext.updateOverview(overviewProgram, overviewId);
  }, [overviewProgram, overviewId]);

  const { loading, error, data } = useQuery(
    OverviewWsSpeciesProfileByReferenceDocument,
    {
      variables: {
        referenceId: overviewId as any,
        referenceName: overviewProgram as any,
      },
      skip: overviewId == null || overviewProgram == null,
      fetchPolicy: "network-only",
      errorPolicy: "all",
    }
  );

  const speciesProfileId =
    data?.overviewWsSpeciesProfileByReference?.speciesProfileId;

  if (speciesProfileId != null) {
    history.replace(PublicProfilePathHelpers.PublicProfile(speciesProfileId));
  }

  return (
    <>
      <Layout.Root>
        <Layout.Content>
          <br />
          <GraphqlError
            title="Error fetching OverviewWsSpeciesProfileByReference"
            errors={error}
          />
          {loading ? (
            <LoadingIndicator centered className="mrgn-bttm-md" />
          ) : (
            speciesProfileId == null && (
              <Alert
                type={AlertTypes.DANGER}
                title="Error fetching OverviewWsSpeciesProfileByReference"
                content="Missing species profile details."
              />
            )
          )}
        </Layout.Content>
      </Layout.Root>
    </>
  );
};

export default PublicProfilePageByReference;
