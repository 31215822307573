const contactTranslations = {
  en: {
    contact: "Contact",
    contacts: "Contacts",
    list_of_contacts: "List of contacts",
    organization: "Organization",
    created_on: "Created on",
    updated_by: "Update by",
    archive_contact_warning:
      "Are you sure you would like to archive this contact?",
    unarchive_contact_warning:
      "Are you sure you would like to unarchive this contact?",
    archive_a_contact: "Archive a contact",
    unarchive_a_contact: "Unarchive a contact",
    all_tags: "All tags",
    create_a_contact: "Create a contact",
    contact_information: "Contact information",
    first_name: "First name",
    last_name: "Last name",
    job_title_english: "Job title (English)",
    job_title_french: "Job title (French)",
    method_of_communication: "Method of communication",
    address_line_1: "Address Line 1",
    address_line_2: "Address Line 2",
    city: "City",
    country: "Country",
    postal_code: "Postal code/ZIP code/etc",
    postal_code_Canada: "Postal code (Canada)",
    zip_code_US: "Zip code (U.S.)",
    u_s: "U.S.",
    province_territory_state_region_etc: "Province/territory/state/region",
    email: "Email",
    phone: "Phone",
    fax: "Fax",
    CONSULTEE_INDIGENOUS: "Consultee - Indigenous",
    CONSULTEE_EXPERT: "Consultee - Expert",
    CONSULTEE_CITIZEN: "Consultee - Citizen",
    EDUCATION: "Education",
    PHOTOGRAPHER: "Photographer",
    SCIENTIST: "Scientist",
    use_organization_address: "Use organization address",
    contact_tags: "Contact tags",
    mailing_address: "Mailing address",
    successfully_added_contact: "Successfully added contact",
    successfully_updated_contact: "Successfully updated contact",
    successfully_added_contact_details: "Successfully added contact details",
    successfully_removed_contact_details:
      "Successfully removed contact details",

    add_contact: "Add contact",
    add_a_contact: "Add a contact",
    edit_a_contact: "Edit a contact",
    remove_contact: "Remove contact",

    select_a_tag: "Select a tag...",
    any_tags: "Any tags",
    no_tags: "No tags",

    // Confirm save popup
    confirm_save: "Confirm save",
    changes_publish_on_registry_warning_title:
      "Changes will be published on the Registry.",
    changes_publish_on_registry_warning_message:
      "This contact/organization information is posted on the Registry. Saved changes will be reflected on all Registry pages. Mailing address fields are required to comply with Registry contact information standards.",
    changes_publish_on_registry_confirm_warning_message:
      "Contact/organization already published on the Registry. Any changes that you make will be reflected on all published pages. Please confirm that you wish to save your changes, or return to review your changes.",

    invalid_post_or_zip_code_format: "Invalid postal or zip code format.",
    valid_phone_number: "Enter a valid phone number",
    valid_fax_number: "Enter a valid fax number",
    valid_street_address: "Enter a valid street address",

    select_organization: "Select organization",
    add_new_organization: "Add new organization",
    remove_organization: "Remove organization",
  },

  fr: {
    contact: "Contact",
    contacts: "Contacts",
    list_of_contacts: "Liste des contacts",
    organization: "Organisation",
    created_on: "Créé le",
    updated_by: "Mis à jour par",
    archive_contact_warning: "Êtes-vous sûr de vouloir archiver ce contact ?",
    unarchive_contact_warning:
      "Êtes-vous sûr de vouloir désarchiver ce contact ?",
    archive_a_contact: "Archiver un contact",
    unarchive_a_contact: "Désarchiver un contact",
    all_tags: "Toutes les étiquettes",
    create_a_contact: "Créer un contact",
    contact_information: "Informations sur le contact",
    first_name: "Prénom",
    last_name: "Nom",
    job_title_english: "Titre du poste (anglais)",
    job_title_french: "Titre du poste (français)",
    method_of_communication: "Méthode de communication",
    address_line_1: "Ligne d'adresse 1",
    address_line_2: "Ligne d'adresse 2",
    city: "Ville",
    country: "Pays",
    postal_code: "Code postal/Code ZIP/etc",
    postal_code_Canada: "Code postal (Canada)",
    zip_code_US: "Code postal (États-Unis)",
    u_s: "États-Unis",
    province_territory_state_region_etc: "Province/territoire/état/région ",
    email: "Addresse Courriel",
    phone: "Téléphone",
    fax: "Télécopieur",
    CONSULTEE_INDIGENOUS: "Individu consulté - Autochtones",
    CONSULTEE_EXPERT: "Individu consulté - Expert",
    CONSULTEE_CITIZEN: "Individu consulté - Citoyen",
    EDUCATION: "Éducation",
    PHOTOGRAPHER: "Photographe",
    SCIENTIST: "Scientifique",
    use_organization_address: "Utilisez l'adresse de l'organisation",
    contact_tags: "Étiquettes du contact",
    mailing_address: "Adresse postale",
    successfully_added_contact: "Ajout d'un contact réussi",
    successfully_updated_contact: "Mise à jour du contact réussie",
    successfully_added_contact_details:
      "Les informations de contact ont été sauvegardées avec succès",
    successfully_removed_contact_details:
      "Les informations du contact ont été supprimées avec succès",

    add_contact: "Ajouter un contact",
    add_a_contact: "Ajouter un contact",
    edit_a_contact: "Modifier un contact",
    remove_contact: "Retirer le contact",

    select_a_tag: "Sélectionner une étiquette",
    any_tags: "N'importe quelle étiquette",
    no_tags: "Aucune étiquettes",

    // Confirm save popup
    confirm_save: "Confirmer l'enregistrement",
    changes_publish_on_registry_warning_title:
      "Les modifications seront publiées sur le Registre.",
    changes_publish_on_registry_warning_message:
      "L'informations sur le contact/l'organisations sont publiées sur le Registre. Les modifications enregistrées seront reflétées sur toutes les pages du Registre. Les champs relatifs à l'adresse postale sont obligatoires pour se conformer aux normes des contacts sur le Registry.",
    changes_publish_on_registry_confirm_warning_message:
      "Contact/Organisation déjà publié sur le Registre. Toutes les modifications que vous apportez seront reflétés sur toutes les pages publiées. Veuillez confirmer que vous souhaitez enregistrer vos modifications ou retourner pour revoir les modifications apportés.",
    invalid_post_or_zip_code_format: "Code postal et code zip invalides",
    valid_phone_number: "Ajouter un numéro de téléphone valide",
    valid_fax_number: "Ajouter un numéro de fax valide",
    valid_street_address: "Ajouter une adresse valide",

    select_organization: "Sélectionner une organisation",
    add_new_organization: "Ajouter une nouvelle organisation",
    remove_organization: "Retirer l'organisation",
  },
};

export default contactTranslations;
