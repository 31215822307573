const operatorSelectorTranslation = {
  en: {
    EQ: "=",
    NE: "!=",
    GT: ">",
    LT: "<",
    GE: ">=",
    LE: "<=",
    STARTS_WITH: "Starts with",
    ENDS_WITH: "Ends with",
    CONTAINS: "Contains",
    NOT_CONTAINS: "Does not contains",
  },

  fr: {
    EQ: "=",
    NE: "!=",
    GT: ">",
    LT: "<",
    GE: ">=",
    LE: "<=",
    STARTS_WITH: "Commence par",
    ENDS_WITH: "Se termine par",
    CONTAINS: "Contient",
    NOT_CONTAINS: "Ne contient pas",
  },
};

export default operatorSelectorTranslation;
