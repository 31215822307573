import { useMutation, useQuery } from "@apollo/client";
import GraphqlError from "components/GraphqlError";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import MissingData from "components/atoms/MissingData";
import SafeRenderHtml from "components/atoms/SafeRenderHtml";
import Layout from "components/layouts/TwoColumn";
import DocumentsSideNav from "components/molecules/documents/DocumentsSideNav";
import ArchiveUnArchiveButtonAndModal from "components/organisms/ArchiveUnArchiveButtonAndModal/ArchiveUnArchiveButtonAndModal";
import LicenseFileCard from "components/organisms/documents/PhotoLicense/LicenseFileCard/LicenseFileCard";
import PhotosCard from "components/organisms/documents/PhotoLicense/PhotosCard/PhotosCard";
import PhotoLicenseInfoCard from "components/organisms/photoLicense/PhotoLicenseInfoCard/PhotoLicenseInfoCard";
import { ROLE_ACTIONS, RenderWhenAuthorized } from "features/auth/components";
import { GlobalAlert, useGlobalAlertContext } from "features/globalAlert";
import {
  ArchiveStatus,
  PhotoLicenseDocument,
  UpdatePhotoLicenseStageDocument,
} from "generated/gql-types";
import { DocumentsPathHelpers } from "pages/documents/DocumentsRouter";
import * as React from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";
import htmlRemoveOuterPTag from "util/htmlRemoveOuterPTag";
import htmlIsNullOrEmpty from "util/htmlIsNullOrEmpty";

export interface PhotoLicenseProfilePageProps {}

export const PhotoLicenseProfilePage: React.FC<
  PhotoLicenseProfilePageProps
> = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const alertContext = useGlobalAlertContext();
  const { params } = useRouteMatch();
  const { photoLicenseId } = params as any;

  const { loading, error, data, refetch } = useQuery(PhotoLicenseDocument, {
    errorPolicy: "all",
    variables: {
      photoLicenseId: photoLicenseId,
    },
    fetchPolicy: "network-only",
  });

  const [updatePhotoLicenseStage, updatePhotoLicenseStageMutationStatus] =
    useMutation(UpdatePhotoLicenseStageDocument, {
      refetchQueries: ["PhotoLicense"],
      errorPolicy: "all",
    });

  const title = useMemo(() => {
    if (i18n.language === "fr") {
      if (!htmlIsNullOrEmpty(data?.photoLicense?.title?.french?.text)) {
        return data?.photoLicense?.title?.french?.text;
      } else {
        return data?.photoLicense?.title?.english?.text ?? "MISSING TITLE";
      }
    }

    if (!htmlIsNullOrEmpty(data?.photoLicense?.title?.english?.text)) {
      return data?.photoLicense?.title?.english?.text;
    } else {
      return data?.photoLicense?.title?.french?.text ?? "MISSING TITLE";
    }
  }, [i18n.language, data?.photoLicense?.title]);

  const onArchive = async () => {
    try {
      const res = await updatePhotoLicenseStage({
        variables: {
          updatePhotoLicenseStageId: photoLicenseId,
          stage: ArchiveStatus.Inactive,
        },
      });
      if (res.errors) throw res.errors;
      alertContext.showSuccess({
        message: t("photo_licence_archived_successfully"),
        timeOut: 5000,
      });

      history.push({
        pathname: DocumentsPathHelpers.PhotoLicenseProfile(
          photoLicenseId ?? "error"
        ),
      });
    } catch (e) {
      console.error(e);
    }
  };

  const onUnArchive = async () => {
    try {
      const res = await updatePhotoLicenseStage({
        variables: {
          updatePhotoLicenseStageId: photoLicenseId,
          stage: ArchiveStatus.Active,
        },
      });
      if (res.errors) throw res.errors;
      alertContext.showSuccess({
        message: t("photo_licence_unarchived_successfully"),
        timeOut: 5000,
      });

      history.push({
        pathname: DocumentsPathHelpers.PhotoLicenseProfile(
          photoLicenseId ?? "error"
        ),
      });
    } catch (e) {
      console.error(e);
    }
  };

  const canArchive = React.useMemo(() => {
    if (loading) return false;

    if (data?.photoLicense?.stage === ArchiveStatus.Active) {
      return true;
    }
    return false;
  }, [data?.photoLicense?.stage, loading]);

  const canUnArchive = React.useMemo(() => {
    if (loading) return false;

    if (data?.photoLicense?.stage === ArchiveStatus.Inactive) {
      return true;
    }
    return false;
  }, [data?.photoLicense?.stage, loading]);

  const showArchive = React.useMemo(() => {
    if (loading) return false;

    if (data?.photoLicense?.stage === ArchiveStatus.Active) {
      return true;
    }
    return false;
  }, [data?.photoLicense?.stage, loading]);

  const showUnArchive = React.useMemo(() => {
    if (loading) return false;

    if (data?.photoLicense?.stage === ArchiveStatus.Inactive) {
      return true;
    }
    return false;
  }, [data?.photoLicense?.stage, loading]);

  return (
    <>
      <Layout.Root className="mrgn-tp-md">
        <Layout.Content>
          <GlobalAlert />

          <h1>
            {!loading && (
              <SafeRenderHtml htmlString={htmlRemoveOuterPTag(title)} />
            )}
          </h1>
          <GraphqlError
            title="Error loading photo license document information"
            errors={error}
          />
          <GraphqlError
            title="Error updating stage of photo license document"
            errors={updatePhotoLicenseStageMutationStatus.error}
          />

          {loading ? (
            <LoadingIndicator />
          ) : (
            <>
              <div className="flex justify-between align-center mrgn-bttm-md">
                <div className="flex font-size-18">
                  <div>
                    {t("stage")}{" "}
                    <div className="label label-info">
                      {data?.photoLicense?.stage ? (
                        t(data?.photoLicense?.stage)
                      ) : (
                        <MissingData />
                      )}
                    </div>
                  </div>
                </div>
                {showArchive && (
                  <RenderWhenAuthorized
                    authorizedRoles={ROLE_ACTIONS.photos.update}
                  >
                    <ArchiveUnArchiveButtonAndModal
                      canAction={canArchive}
                      onAction={onArchive}
                      warningMessage={t("archive_photo_licence_modal_message")}
                      buttonText="archive"
                      modalTitle={t("archive_photo_licence")}
                    />
                  </RenderWhenAuthorized>
                )}

                {showUnArchive && (
                  <RenderWhenAuthorized
                    authorizedRoles={ROLE_ACTIONS.photos.update}
                  >
                    <ArchiveUnArchiveButtonAndModal
                      canAction={canUnArchive}
                      onAction={onUnArchive}
                      warningMessage={t(
                        "unarchive_photo_licence_modal_message"
                      )}
                      buttonText="unarchive"
                      modalTitle={t("unarchive_photo_licence")}
                    />
                  </RenderWhenAuthorized>
                )}
              </div>
              <PhotoLicenseInfoCard photosLicenseData={data?.photoLicense} />
              <LicenseFileCard
                photoLicenseData={data?.photoLicense}
                refetchQuery={refetch}
              />
              <PhotosCard
                photoLicenseData={data?.photoLicense}
                refetchQuery={refetch}
              />
            </>
          )}
        </Layout.Content>
        <Layout.Sidebar>
          <DocumentsSideNav />
        </Layout.Sidebar>
      </Layout.Root>
    </>
  );
};

export default PhotoLicenseProfilePage;
