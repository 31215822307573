import {
  BilingualAbbreviationAcronymTagsLookupText,
  DistributionList,
  DistributionListCreateInput,
  Maybe,
} from "generated/gql-types";
import { set } from "lodash";
import { FieldNamesMarkedBoolean, UnpackNestedValue } from "react-hook-form";
import * as FormMappers from "util/formMappers";
import { DistributionListFormFields } from "./DistributionListForm/DistributionListForm";
import { filterForDirtyFields } from "util/forms";
import isNullOrEmpty from "util/isNullOrEmpty";

export const distributionListDomainModelIntoForm = (
  inputData?: DistributionList | null,
  userProgram?: Maybe<BilingualAbbreviationAcronymTagsLookupText>,
  isCreate?: boolean
): Partial<DistributionListFormFields> => {
  const out: Partial<DistributionListFormFields> = {};

  if (inputData !== null) {
    // name
    FormMappers.mapPrimitiveType(out, "name", inputData?.name);

    // program
    FormMappers.mapBilingualAbbreviationAcronymLookupText(
      out,
      "program",
      inputData?.program
    );

    // Note
    FormMappers.mapNote(out, "note", inputData?.note);
  } else if (isCreate) {
    // program
    FormMappers.mapBilingualAbbreviationAcronymLookupTextFromOneWithTags(
      out,
      "program",
      userProgram
    );
  }

  return out;
};

export const processDistributioListFormValues = (
  formData: UnpackNestedValue<Partial<DistributionListFormFields>>,
  dirtyFields: FieldNamesMarkedBoolean<any>,
  initialValues: Partial<DistributionListFormFields>
): Partial<DistributionListFormFields> => {
  const values = filterForDirtyFields(formData, dirtyFields);
  setNullForDistributionListEmptyFields(values, dirtyFields, initialValues);
  return values;
};

const setNullForDistributionListEmptyFields = (
  formData: UnpackNestedValue<Partial<DistributionListFormFields>>,
  dirtyFields: FieldNamesMarkedBoolean<any>,
  initialValues: Partial<DistributionListFormFields>
) => {
  // We use lodash set() in order to make sure each level of a nested path exists when we set a value.

  // DistributionListFormFields

  if (
    dirtyFields?.name != null &&
    isNullOrEmpty(formData?.name) &&
    !isNullOrEmpty(initialValues?.name)
  ) {
    set(formData, "name", null);
  }

  if (
    dirtyFields?.program?.name != null &&
    isNullOrEmpty(formData?.program?.name) &&
    !isNullOrEmpty(initialValues?.program?.name)
  ) {
    set(formData, "program", null);
  }

  if (
    dirtyFields?.note?.text != null &&
    isNullOrEmpty(formData?.note?.text) &&
    !isNullOrEmpty(initialValues?.note?.text)
  ) {
    set(formData, "note", null);
  }
};

export const formIntoCreateInputDistributionList = (
  formData: Partial<DistributionListFormFields>,
  initialValues?: Partial<DistributionListFormFields>,
  isCreate?: boolean
): DistributionListCreateInput => {
  const out: DistributionListCreateInput = {};

  // name
  FormMappers.mapPrimitiveTypeInput(out, "name", formData?.name);

  // program
  if (formData?.program != null) {
    FormMappers.mapBilingualAbbreviationAcronymLookupTextInput(
      out,
      "program",
      formData?.program
    );
  } else if (isCreate) {
    FormMappers.mapBilingualAbbreviationAcronymLookupTextInput(
      out,
      "program",
      initialValues?.program
    );
  }

  //Note
  FormMappers.mapNoteInput(out, "note", formData?.note);

  return out;
};
