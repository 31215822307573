import { useQuery } from "@apollo/client";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import NoResults from "components/atoms/NoResults";
import PageSizeSelect from "components/molecules/PageSizeSelect";
import Pagination from "components/molecules/Pagination";
import ResultsTable, {
  ColumnHeader,
} from "components/organisms/search/ResultsTable/ResultsTable";
import { useOverviewContext } from "features/overview";
import {
  SortDescriptor,
  makeSortByString,
} from "features/search/utils/makeOrderByString";
import { PermitDescriptionListDocument } from "generated/gql-types";
import { usePagination } from "hooks/util/usePagination";
import useQueryParams from "hooks/util/useQueryParams";
import i18n from "i18n";
import * as React from "react";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { formatTimestamp } from "util/formatTimestamp";

interface PermitDescriptionDocumentPageColumns {
  id?: string;
  "Title/English/Text"?: string;
  "Title/French/Text"?: string;
  State?: string;
  ModifiedAt?: string;
}

const PermitDocumentsPage: React.FC = (props) => {
  const { t } = useTranslation();
  const pagination = usePagination({ pageSize: 10 });
  const [sortDescriptor, setSortDescriptor] = useState<
    SortDescriptor | undefined
  >(undefined);
  const overviewContext = useOverviewContext();
  const query = useQueryParams() as any;
  const overviewProgram = query.get("program");
  const overviewId = query.get("id");

  const onChangePageSize = async (newPageSize: number) => {
    pagination.setPageSize(newPageSize);
    pagination.goToPage(1);
  };

  const onSortChange = (sortDescriptor: SortDescriptor) => {
    setSortDescriptor(sortDescriptor);
    pagination.goToPage(1);
  };

  const sortByString = useMemo(() => {
    if (sortDescriptor == null) {
      setSortDescriptor({
        column: i18n.language === "fr" ? "title.french" : "title.english",
        direction: "ascending",
      });
    }

    return makeSortByString(sortDescriptor);
  }, [sortDescriptor]);

  const { data, loading, error } = useQuery(PermitDescriptionListDocument, {
    errorPolicy: "all",
    variables: {
      params: {
        listingWsRefId: overviewContext.listing?.id,
        pageNumber: pagination.currentPage,
        pageSize: pagination.pageSize,
        sortBy: sortByString,
      },
    },
    fetchPolicy: "network-only",
  });

  // Update Ribbon and SideNav.
  React.useEffect(() => {
    overviewContext.updateOverview(overviewProgram, overviewId!);
  }, [overviewProgram, overviewId]);

  const columns: ColumnHeader[] = React.useMemo(
    () => [
      {
        name: t("name"),
        key: i18n.language === "fr" ? "title.french" : "title.english",
        sortable: true,
        hasLink: true,
      },

      {
        name: t("state"),
        key: "state",
        sortable: true,
      },
      {
        name: t("publication_date"),
        key: "publishedDate",
        sortable: true,
      },
    ],
    [t]
  );

  const rows: any = useMemo(
    () =>
      data?.permitDescriptionList?.permitDescription?.map((value) => {
        let permitDescriptionName = "";
        if (i18n.language === "fr") {
          permitDescriptionName = value?.title?.french?.text ?? "";
        } else {
          permitDescriptionName = value?.title?.english?.text ?? "";
        }

        return {
          _href: `/permit/${value?.id}`,
          id: value?.id,
          [i18n.language === "fr" ? "title.french" : "title.english"]:
            permitDescriptionName,
          state: t(value?.state ?? ""),
          modifiedAt: formatTimestamp(value?.modifiedAt ?? ""),
        };
      }),
    [data?.permitDescriptionList?.permitDescription]
  );

  return (
    <>
      <>
        {loading ? (
          <LoadingIndicator centered className="mrgn-bttm-md" />
        ) : rows && rows?.length === 0 ? (
          <NoResults centered />
        ) : (
          <>
            <div className="flex mrgn-bttm-md align-center justify-between flex-wrap">
              <div className="font-size-18">
                {pagination.makeShowingString(
                  data ? data?.permitDescriptionList?.pagination?.totalPages : 0
                )}
              </div>
              <div>
                <PageSizeSelect
                  pageSize={pagination.pageSize}
                  onChangePageSize={onChangePageSize}
                />
              </div>
            </div>
            {!loading && error ? <pre>{JSON.stringify(error)}</pre> : null}
            {rows == null || rows.length === 0 ? null : (
              <ResultsTable
                rows={rows}
                columns={columns}
                sortable
                sortDescriptor={sortDescriptor}
                onSortChange={onSortChange as any}
              />
            )}
            <Pagination
              {...pagination.paginationComponentProps}
              totalPages={
                data?.permitDescriptionList?.pagination?.totalPages ?? 0
              }
            />
          </>
        )}
      </>
    </>
  );
};

export default PermitDocumentsPage;
