import { Item } from "@react-stately/collections";
import { useOverlayTriggerState } from "@react-stately/overlays";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import { MenuButton } from "components/atoms/MenuButton/MenuButton";
import SafeRenderHtml from "components/atoms/SafeRenderHtml";
import InfoButtonAndModal from "components/organisms/InfoButtonAndModal/InfoButtonAndModal";
import { RenderWhenAuthorized, ROLE_ACTIONS } from "features/auth/components";
import { BptProjectStage, BptProjectStep, Maybe } from "generated/gql-types";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { PopupMenuKey } from "types/bpt/enums";
import htmlRemoveOuterPTag from "util/htmlRemoveOuterPTag";
import ProjectActivitiesSection from "../../Activity/ActivitiesSection/ProjectActivitiesSection";
import { StepInfo } from "../../Information/StepInfo";
import PopupMenuModal, {
  TypeOfQueryInputProps,
} from "../../Utility/PopupMenuModal";
import StatusTag from "../../Utility/StatusTag";
import ProjectStepInformation from "./ProjectStepInformation";

export interface ProjectStepDetailsProps
  extends Omit<TypeOfQueryInputProps, "TypeOfQueryInputProps"> {
  projectId?: string;
  isOpen?: boolean;
  projectStepInfo?: Maybe<BptProjectStep>;
  isLoading?: boolean;
}

export const ProjectStepDetails: React.FC<ProjectStepDetailsProps> = (
  props
) => {
  const { t } = useTranslation();

  // --------------- constants and callbacks --------------------------- //
  const { projectId, isOpen, projectStepInfo, isLoading } = props;
  const isPhaseCompleted = props.isPhaseCompleted;
  const stage = projectStepInfo?.stage;
  const isStepCompleted = stage === BptProjectStage.Completed;
  const isStepNotStarted = stage === BptProjectStage.NotStarted;
  const isStepSkipped = stage === BptProjectStage.Skipped;

  const [openId, setOpenId] = React.useState(false);

  const onToggle = (event: { preventDefault: () => void }) => {
    setOpenId(true);
  };

  // --------------- Popup Menu Edit/Skip ---------------- //
  const modal = useOverlayTriggerState({});
  const adminAlertMsgTitle = t("project_step");
  const [menuTypeKey, setMenuTypeKey] = React.useState("");

  const onSelectionChange = (key: any) => {
    switch (key) {
      case PopupMenuKey.EDIT: {
        modal.open();
        setMenuTypeKey(PopupMenuKey.EDIT);
        break;
      }
      case PopupMenuKey.SKIP: {
        modal.open();
        setMenuTypeKey(PopupMenuKey.SKIP);
        break;
      }
      case PopupMenuKey.UNDO_SKIP: {
        setMenuTypeKey(PopupMenuKey.UNDO_SKIP);
        props.onSkip(
          projectId!,
          projectStepInfo?.id!,
          BptProjectStage.NotStarted,
          "BptProjectStep"
        );
        break;
      }
    }
  };

  return (
    <>
      <details
        key={projectStepInfo?.id}
        className="detail-list detail-list-step bg-grey expand-collapse-list"
        open={isOpen}
        onToggle={onToggle}
      >
        <summary>
          <div className="flexbug-9-workaround">
            <div className="flex justify-between align-center">
              <h3>
                <SafeRenderHtml
                  htmlString={htmlRemoveOuterPTag(
                    projectStepInfo?.name?.text ?? "Error"
                  )}
                />
                <InfoButtonAndModal
                  title={projectStepInfo?.name?.plainText}
                  buttonTitle={t("template_information")}
                  buttonIcon={<i className="fas fa-info-circle"></i>}
                >
                  <StepInfo dataInfo={projectStepInfo} />
                </InfoButtonAndModal>
              </h3>
              <div className="flex gap-sm align-center">
                <StatusTag
                  showHOC={projectStepInfo?.includeHouseOfCommons!}
                  stage={stage}
                  isDelay={projectStepInfo?.delayed!}
                />

                {props.isProjectCompleted ? null : (
                  <RenderWhenAuthorized
                    authorizedRoles={ROLE_ACTIONS.bptProject.update}
                  >
                    <MenuButton
                      label={""}
                      onAction={onSelectionChange}
                      className="btn btn-link card-header-button-link font-size-14 hover-grey"
                      disabled={false}
                      title={t("more_actions")}
                      buttonIcon={
                        <i className="fas fa-ellipsis-v"></i> // Three dots menu icon
                      }
                      loading={isLoading}
                    >
                      <Item key={PopupMenuKey.EDIT}>
                        <span
                          className="font-size-14 px-3"
                          title={t("edit_step")}
                        >
                          {<i className="fas fa-pen mrgn-rght-sm"></i>}{" "}
                          {t("edit")}
                        </span>
                      </Item>
                      {!isPhaseCompleted &&
                        !isStepCompleted &&
                        isStepNotStarted && (
                          <Item key={PopupMenuKey.SKIP}>
                            <span
                              className="font-size-14 px-3"
                              title={t("skip_step")}
                            >
                              {
                                <i className="far fa-times-circle mrgn-rght-sm"></i>
                              }{" "}
                              {t("skip")}
                            </span>
                          </Item>
                        )}
                      {!isPhaseCompleted && !isStepCompleted && isStepSkipped && (
                        <Item key={PopupMenuKey.UNDO_SKIP}>
                          <span
                            className="font-size-14 px-3"
                            title={t("undo_skip_step")}
                          >
                            {<i className="fas fa-sync-alt mrgn-rght-sm"></i>}{" "}
                            {t("undo_skip")}
                          </span>
                        </Item>
                      )}
                    </MenuButton>
                  </RenderWhenAuthorized>
                )}
              </div>
            </div>
          </div>
        </summary>

        {isLoading ? (
          <LoadingIndicator centered className="mrgn-bttm-md" />
        ) : (
          <>
            <ProjectStepInformation
              projectId={projectId}
              projectStepInfo={projectStepInfo}
              isPhaseCompleted={isPhaseCompleted}
            />
            {openId && projectStepInfo?.hasActivities && (
              <ProjectActivitiesSection
                projectId={projectId}
                projectStepId={projectStepInfo?.id!}
                {...props}
              />
            )}
          </>
        )}
      </details>

      <>
        {/* --------------- Popup Menu Edit and Skip ---------------- */}
        <PopupMenuModal
          __typename={"BptProjectStep"}
          menuTypeKey={menuTypeKey}
          modal={modal}
          projectId={projectId}
          dataInfo={projectStepInfo}
          isCompleted={isStepCompleted || isPhaseCompleted}
          adminAlertMsgTitle={adminAlertMsgTitle}
          hasActivities={projectStepInfo?.hasActivities!}
          {...props}
        />
      </>
    </>
  );
};

export default ProjectStepDetails;
