import { useMutation } from "@apollo/client";
import GraphqlError from "components/GraphqlError";
import Layout from "components/layouts/TwoColumn";
import DocumentsSideNav from "components/molecules/documents/DocumentsSideNav";
import PhotoLicenseDocumentForm, {
  PhotoLicenseDocumentFormFields,
} from "components/organisms/documents/PhotoLicense/PhotoLicenseDocumentForm/PhotoLicenseDocumentForm";
import {
  mapDomainModelToForm,
  mapFormToDomainModel,
} from "components/organisms/documents/PhotoLicense/PhotoLicenseDocumentForm/mappers";
import { useGlobalAlertContext } from "features/globalAlert";
import { CreatePhotoLicenseDocument } from "generated/gql-types";
import * as React from "react";
import { SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { DocumentsPathHelpers } from "../DocumentsRouter";

export interface PhotoLicenseCreatePageProps {}

export const PhotoLicenseCreatePage: React.FC<
  PhotoLicenseCreatePageProps
> = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const alertContext = useGlobalAlertContext();

  const [createPhotoLicense, { loading: saving, error: savingError }] =
    useMutation(CreatePhotoLicenseDocument, { errorPolicy: "all" });

  const onSubmit: SubmitHandler<
    Partial<PhotoLicenseDocumentFormFields>
  > = async (FilteredFormData) => {
    const mappedDomainModel = mapFormToDomainModel(FilteredFormData);

    try {
      const res = await createPhotoLicense({
        variables: {
          input: mappedDomainModel,
        },
      });

      if (res.errors) throw res.errors;

      alertContext.showSuccess({
        title: t("document_successfully_added"),
        // message:
        //   bilingualRichTextObjectForLanguage(
        //     i18n.language,
        //     res.data?.createPermitDescription?.title
        //   )?.plainText ?? "",
        timeOut: 5000,
      });

      history.push({
        pathname: DocumentsPathHelpers.PhotoLicenseProfile(
          res.data?.createPhotoLicense?.id ?? "error"
        ),
      });
    } catch (e) {
      console.error(e);
    }
  };

  const onCancel = () => {
    history.push({
      pathname: DocumentsPathHelpers.DocumentsSearch,
    });
  };

  const initialFormFields = mapDomainModelToForm(null);

  return (
    <>
      <Layout.Root>
        <Layout.Content>
          <h1>{t("create_photo_licence_document")}</h1>
          <GraphqlError
            title="Error creating new photo licence document"
            errors={savingError}
          />
          <PhotoLicenseDocumentForm
            initialValues={initialFormFields}
            onSubmit={onSubmit}
            onCancel={onCancel}
          />
        </Layout.Content>
        <Layout.Sidebar>
          <DocumentsSideNav />
        </Layout.Sidebar>
      </Layout.Root>
    </>
  );
};

export default PhotoLicenseCreatePage;
