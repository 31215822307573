import EditLink from "components/atoms/EditLink";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import SectionCard from "components/atoms/SectionCard";
import { RenderWhenAuthorized } from "features/auth/components";
import { Roles } from "features/auth/roles";
import * as React from "react";
import BilingualHtmlText from "./BilingualHtmlText";

export interface BilingualHtmlCardProps {
  header: string;
  htmlStringEnglish?: string | null;
  htmlStringFrench?: string | null;
  open?: boolean;
  loading?: boolean;
  editLinkTo: string;
  role: Roles[];
}

export const BilingualHtmlCard: React.FC<BilingualHtmlCardProps> = (props) => {
  const {
    header,
    htmlStringEnglish,
    htmlStringFrench,
    open = false,
    loading = false,
    editLinkTo,
    role,
  } = props;

  return (
    <SectionCard
      collapsible
      open={open}
      header={
        <div className="flexbug-9-workaround">
          <div className="flex justify-between align-center">
            <h2 className="mrgn-tp-0 mrgn-bttm-0">{header}</h2>
            <RenderWhenAuthorized authorizedRoles={role}>
              {!loading ? (
                <EditLink linkTo={editLinkTo} state={header} />
              ) : null}
            </RenderWhenAuthorized>
          </div>
        </div>
      }
      classNameSummary={"py-2_5"}
    >
      {loading ? (
        <LoadingIndicator centered className="mrgn-bttm-md" />
      ) : (
        <BilingualHtmlText
          htmlStringEnglish={htmlStringEnglish}
          htmlStringFrench={htmlStringFrench}
          editLinkTo={editLinkTo}
        />
      )}
    </SectionCard>
  );
};

export default BilingualHtmlCard;
