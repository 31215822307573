const roleTranslation = {
  en: {
    role: "Roles",
    role_name: "Role Name",
    create_a_role: "Create a Role",
    enter_role_name: "Enter a Role name",
    edit_role: "Edit Role",
    reactivate_a_role: "Reactivate a Role",
    deactivate_a_role: "Deactivate a Role",
    deactivate_role_warning:
      "You will no longer be able to add this Role to a Wildlife Species.",
    reactivate_role_warning:
      "You will be able to add this Role to a Wildlife Species.",
    no_role_selected: "No role was selected",
    add_user_to_role: "Add user to role",
    role_delete_success: "Role ",
    delete_a_user: "Remove user from role",
    delete_user_warning: "This user will no longer have access to this role",
    delete_user_confirm:
      "I confirm that I'd like to remove this user from this role",
  },

  fr: {
    role: "Rôles",
    role_name: "Nom du rôle",
    create_a_role: "Créer un rôle",
    enter_role_name: "Entrez un nom de rôle",
    edit_role: "Editer le rôle",
    reactivate_a_role: "Réactiver un rôle",
    deactivate_a_role: "Désactiver un rôle",
    deactivate_role_warning:
      "Vous ne pourrez plus ajouter ce rôle à une espèce sauvage.",
    reactivate_role_warning:
      "Vous pourrez ajouter ce rôle à une espèce sauvage.",
    no_role_selected: "Aucun rôle n'a été sélectionné",
    add_user_to_role: "Ajouter l'utilisateur au rôle",
    delete_a_user: "Enlever l'utilisateur du rôle",
    delete_user_warning: "Cet utilisateur n'aura plus accès à ce rôle",
    delete_user_confirm:
      "Je confirme que je souhaite enlever cet utilisateur de ce rôle",
  },
};

export default roleTranslation;
