import { useMutation, useQuery } from "@apollo/client";
import GraphqlError from "components/GraphqlError";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import MissingData from "components/atoms/MissingData";
import SafeRenderHtml from "components/atoms/SafeRenderHtml";
import Layout from "components/layouts/TwoColumn";
import ResponseStatementSideNav from "components/molecules/responseStatement/ResponseStatementSideNav";
import BackToDraftButtonAndModal from "components/organisms/BackToDraftButtonAndModal/BackToDraftButtonAndModal";
import FinalizeButtonAndModal from "components/organisms/FinalizeButtonAndModal/FinalizeButtonAndModal";
import MakeApproveButtonAndModal from "components/organisms/MakeApproveButtonAndModal/MakeApproveButtonAndModal";
import MakeResponseStatementPublishButtonAndModal from "components/organisms/MakeResponseStatementPublishButtonAndModal/MakeResponseStatementPublishButtonAndModal";
import MakeResponseStatementUnPublishButtonAndModal from "components/organisms/MakeResponseStatementUnPublishButtonAndModal/MakeResponseStatementUnPublishButtonAndModal";
import { ContactType } from "components/organisms/contacts/types";
import ResponseStatementCard from "components/organisms/responseStatement/responseStatement/ResponseStatementCard";
import { ROLE_ACTIONS, RenderWhenAuthorized } from "features/auth/components";
import { GlobalAlert, useGlobalAlertContext } from "features/globalAlert";
import {
  Contact,
  DeleteResponseStatementContactOrOrganizationDocument,
  Gender,
  Organization,
  ResponseStatementDocument,
  ResponseStatementStage,
  ResponseStatementState,
  UpdateResponseStatementContactOrOrganizationDocument,
  UpdateResponseStatementStageDocument,
  UpdateResponseStatementStateDocument,
} from "generated/gql-types";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";
import htmlRemoveOuterPTag from "util/htmlRemoveOuterPTag";
import { ResponseStatementPathHelpers } from "../responseStatementRouter";

const ResponseStatementProfilePage: React.FC = (props) => {
  const { t, i18n } = useTranslation();
  const { params } = useRouteMatch();
  const { responseStatementId } = params as any;
  const history = useHistory();
  const alertContext = useGlobalAlertContext();

  const { loading, error, data } = useQuery(ResponseStatementDocument, {
    errorPolicy: "all",
    variables: {
      responseStatementId: responseStatementId,
    },
  });

  const isFinalStage = React.useMemo(() => {
    if (loading) return false;
    if (data?.responseStatement?.stage === ResponseStatementStage.Final) {
      return true;
    }
    return false;
  }, [loading, data?.responseStatement?.stage]);

  const isApprovedStage = React.useMemo(() => {
    if (loading) return false;
    if (data?.responseStatement?.stage === ResponseStatementStage.Approved) {
      return true;
    }
    return false;
  }, [loading, data?.responseStatement?.stage]);

  const title =
    i18n.language === "fr"
      ? data?.responseStatement?.title?.french?.text
      : data?.responseStatement?.title?.english?.text;

  const [
    updateResponseStatementStage,
    updateResponseStatementStageMutationStatus,
  ] = useMutation(UpdateResponseStatementStageDocument, {
    refetchQueries: ["ResponseStatement"],
    errorPolicy: "all",
  });

  const [
    updateResponseStatementState,
    updateResponseStatementStateMutationStatus,
  ] = useMutation(UpdateResponseStatementStateDocument, {
    refetchQueries: ["ResponseStatement"],
    errorPolicy: "all",
  });

  const canFinalize = React.useMemo(() => {
    if (loading) return false;

    if (
      data?.responseStatement?.stage === ResponseStatementStage.Draft &&
      data?.responseStatement?.dueDate &&
      data?.responseStatement?.scenario?.name &&
      data?.responseStatement?.referenceData?.listingRef?.gender &&
      data?.responseStatement?.referenceData?.listingRef?.gender !==
        Gender.NotInitialized &&
      data?.responseStatement?.referenceData?.assessmentRef?.designationReason
        ?.english &&
      data?.responseStatement?.referenceData?.assessmentRef?.designationReason
        ?.french &&
      data?.responseStatement?.referenceData?.listingRef?.competentMinisters &&
      ((data?.responseStatement?.scenario?.includeConsultationPeriod === true &&
        data?.responseStatement?.referenceData?.listingRef?.consultationPath !=
          null) ||
        data?.responseStatement?.scenario?.includeConsultationPeriod === false)
    ) {
      return true;
    }
    return false;
  }, [
    loading,
    data?.responseStatement?.stage,
    data?.responseStatement?.dueDate,
    data?.responseStatement?.scenario?.name,
    data?.responseStatement?.scenario?.includeConsultationPeriod,
    data?.responseStatement?.referenceData?.listingRef?.consultationPath,
    data?.responseStatement?.referenceData?.listingRef?.gender,
    data?.responseStatement?.referenceData?.listingRef?.competentMinisters,
    data?.responseStatement?.referenceData?.assessmentRef?.designationReason
      ?.english,
    data?.responseStatement?.referenceData?.assessmentRef?.designationReason
      ?.french,
  ]);

  const showFinalize = React.useMemo(() => {
    if (loading) return false;

    if (data?.responseStatement?.stage === ResponseStatementStage.Draft) {
      return true;
    }
    return false;
  }, [loading, data?.responseStatement?.stage]);

  const showPublish = React.useMemo(() => {
    if (loading) return false;

    if (
      data?.responseStatement?.stage === ResponseStatementStage.Approved &&
      data?.responseStatement?.state === ResponseStatementState.NotPublished &&
      data?.responseStatement?.contactOrOrganizationFormatted?.english !== null
    ) {
      return true;
    }
    return false;
  }, [
    loading,
    data?.responseStatement?.stage,
    data?.responseStatement?.state,
    data?.responseStatement?.contactOrOrganizationFormatted?.english,
  ]);

  const showUnPublish = React.useMemo(() => {
    if (loading) return false;

    if (
      data?.responseStatement?.stage === ResponseStatementStage.Approved &&
      data?.responseStatement?.state === ResponseStatementState.Published
    ) {
      return true;
    }
    return false;
  }, [loading, data?.responseStatement?.stage, data?.responseStatement?.state]);

  const onFinalize = async () => {
    try {
      const res = await updateResponseStatementStage({
        variables: {
          updateResponseStatementStageId: responseStatementId,
          stage: ResponseStatementStage.Final,
        },
      });
      if (res.errors) throw res.errors;
    } catch (e) {
      console.error(e);
    }
  };

  const onBackToDraft = async () => {
    try {
      const res = await updateResponseStatementStage({
        variables: {
          updateResponseStatementStageId: responseStatementId,
          stage: ResponseStatementStage.Draft,
        },
      });
      if (res.errors) throw res.errors;
      history.push({
        pathname: ResponseStatementPathHelpers.ResponseStatementProfile(
          responseStatementId ?? "error"
        ),
      });
    } catch (e) {
      console.error(e);
    }
  };

  const onApprove = async () => {
    try {
      const res = await updateResponseStatementStage({
        variables: {
          updateResponseStatementStageId: responseStatementId,
          stage: ResponseStatementStage.Approved,
        },
      });
      if (res.errors) throw res.errors;
      alertContext.showSuccess({
        title: t("response_statement_successfully_approved"),
        message: t("response_statement_can_be_published"),
        timeOut: 5000,
      });

      history.push({
        pathname: ResponseStatementPathHelpers.ResponseStatementProfile(
          responseStatementId ?? "error"
        ),
      });
    } catch (e) {
      console.error(e);
    }
  };

  const onPublish = async () => {
    try {
      const res = await updateResponseStatementState({
        variables: {
          updateResponseStatementStateId: responseStatementId,
          state: ResponseStatementState.Published,
        },
      });
      if (res.errors) throw res.errors;
      alertContext.showSuccess({
        title: t("response_statement_successfully_published"),
        message: t("response_statement_updated_on_registry"),
        timeOut: 5000,
      });

      history.push({
        pathname: ResponseStatementPathHelpers.ResponseStatementProfile(
          responseStatementId ?? "error"
        ),
      });
    } catch (e) {
      console.error(e);
    }
  };

  const onUnPublish = async () => {
    try {
      const res = await updateResponseStatementState({
        variables: {
          updateResponseStatementStateId: responseStatementId,
          state: ResponseStatementState.NotPublished,
        },
      });
      if (res.errors) throw res.errors;
      alertContext.showSuccess({
        title: t("response_statement_successfully_unpublished"),
        message: t("response_statement_unpublished_from_registry"),
        timeOut: 5000,
      });

      history.push({
        pathname: ResponseStatementPathHelpers.ResponseStatementProfile(
          responseStatementId ?? "error"
        ),
      });
    } catch (e) {
      console.error(e);
    }
  };

  const [
    updateContactOrOrg,
    { loading: updateContactLoading, error: updateContactError },
  ] = useMutation(UpdateResponseStatementContactOrOrganizationDocument, {
    refetchQueries: ["ResponseStatement"],
    onCompleted: (data) => {
      alertContext.clear();
      alertContext.showSuccess({
        message: t("successfully_added_contact_details"),
        timeOut: 5000,
      });
      updateResponseStatementStageMutationStatus.reset();
    },
    errorPolicy: "all",
  });

  const [
    deleteContactOrOrg,
    { loading: deleteContactLoading, error: deleteContactError },
  ] = useMutation(DeleteResponseStatementContactOrOrganizationDocument, {
    refetchQueries: ["ResponseStatement"],
    onCompleted: (data) => {
      alertContext.clear();
      alertContext.showSuccess({
        message: t("successfully_removed_contact_details"),
        timeOut: 5000,
      });
      updateResponseStatementStageMutationStatus.reset();
    },
    errorPolicy: "all",
  });

  const onSaveContactOrOrg = async (
    newValue: Contact | Organization | undefined | null,
    contactType: ContactType
  ) => {
    if (contactType === "contact") {
      const value = newValue as Contact;
      updateContactOrOrg({
        variables: {
          id: responseStatementId ?? "ERR_MISSING_ID",
          input: {
            organizationRef: null,
            contactRef: {
              contactDetails: {
                address: {
                  addressLines: value.contactDetails?.address?.addressLines,
                  city: value.contactDetails?.address?.city,
                  provinceOrState:
                    value.contactDetails?.address?.provinceOrState,
                  postalOrZipCode:
                    value.contactDetails?.address?.postalOrZipCode,
                  countryCode: value.contactDetails?.address?.countryCode,
                },
                emailAddress: value.contactDetails?.emailAddress,
                phoneNumber: value.contactDetails?.phoneNumber,
                faxNumber: value.contactDetails?.faxNumber,
              },
              id: value?.id,
              jobTitle: value?.jobTitle,
              name: value?.name,
              organizationDetails: value?.organizationDetails,
            },
          },
        },
      });
    } else if (contactType === "organization") {
      const value = newValue as Organization;
      await updateContactOrOrg({
        variables: {
          id: responseStatementId ?? "ERR_MISSING_ID",
          input: {
            contactRef: null,
            organizationRef: {
              organizationDetails: {
                id: value?.id,
                name: value?.name,
                departments: value?.departments,
              },
              contactDetails: {
                address: {
                  addressLines: value.contactDetails?.address?.addressLines,
                  city: value.contactDetails?.address?.city,
                  provinceOrState:
                    value.contactDetails?.address?.provinceOrState,
                  postalOrZipCode:
                    value.contactDetails?.address?.postalOrZipCode,
                  countryCode: value.contactDetails?.address?.countryCode,
                },
                emailAddress: value.contactDetails?.emailAddress,
                phoneNumber: value.contactDetails?.phoneNumber,
                faxNumber: value.contactDetails?.faxNumber,
              },
            },
          },
        },
      });
    } else {
      throw new Error(`unsupported contact type: ${contactType}`);
    }
  };

  const onRemoveContactOrOrg = async () => {
    await deleteContactOrOrg({
      variables: {
        id: responseStatementId ?? "ERR_MISSING_ID",
      },
    });
  };

  return (
    <>
      <Layout.Root>
        <Layout.Content>
          <GlobalAlert />
          <h1>
            {!(
              loading ||
              updateResponseStatementStateMutationStatus.loading ||
              updateResponseStatementStageMutationStatus.loading
            ) && <SafeRenderHtml htmlString={htmlRemoveOuterPTag(title)} />}
          </h1>

          <GraphqlError title={t("fetch_fail")} errors={error} />

          <GraphqlError
            title={t("update_fail")}
            errors={updateResponseStatementStageMutationStatus.error}
          />

          <GraphqlError
            title={t("update_fail")}
            errors={updateResponseStatementStateMutationStatus.error}
          />

          <GraphqlError title={t("update_fail")} errors={updateContactError} />

          <GraphqlError title={t("remove_fail")} errors={deleteContactError} />

          {updateContactLoading || deleteContactLoading ? (
            <LoadingIndicator />
          ) : null}

          {loading ||
          updateResponseStatementStateMutationStatus.loading ||
          updateResponseStatementStageMutationStatus.loading ? (
            <LoadingIndicator />
          ) : (
            <>
              <div className="flex justify-between align-center mrgn-bttm-md">
                <div className="flex font-size-18">
                  <div>
                    {t("stage")}{" "}
                    <div className="label label-info">
                      {data?.responseStatement?.stage ? (
                        t(data?.responseStatement?.stage)
                      ) : (
                        <MissingData />
                      )}
                    </div>
                  </div>
                  <div className="mrgn-lft-md">
                    {t("state")}{" "}
                    <div className="label label-info">
                      {data?.responseStatement?.state ? (
                        t(data?.responseStatement?.state)
                      ) : (
                        <MissingData />
                      )}
                    </div>
                  </div>
                </div>
                <div>
                  {showPublish ? (
                    <RenderWhenAuthorized
                      authorizedRoles={ROLE_ACTIONS.responseStatement.publish}
                    >
                      <MakeResponseStatementPublishButtonAndModal
                        canPublish={showPublish}
                        onPublish={onPublish}
                        warningTitle={t("publish_modal_warning_title")}
                        warningMessage={t("publish_modal_warning_message")}
                        buttonText="publish_to_registry"
                        ConfirmationMessage="i_confirm"
                      />
                    </RenderWhenAuthorized>
                  ) : null}

                  {showUnPublish ? (
                    <RenderWhenAuthorized
                      authorizedRoles={ROLE_ACTIONS.responseStatement.unPublish}
                    >
                      <MakeResponseStatementUnPublishButtonAndModal
                        canUnPublish={showUnPublish}
                        onUnPublish={onUnPublish}
                        warningTitle={t("unpublish_modal_warning_title")}
                        warningMessage={t("unpublish_modal_warning_message")}
                        buttonText="unpublish"
                        ConfirmationMessage="i_confirm"
                      />
                    </RenderWhenAuthorized>
                  ) : null}

                  {showFinalize ? (
                    <RenderWhenAuthorized
                      authorizedRoles={ROLE_ACTIONS.responseStatement.update}
                    >
                      <FinalizeButtonAndModal
                        canFinalize={canFinalize}
                        onFinalize={onFinalize}
                        warningMessage={t(
                          "finalize_response_statement_warning"
                        )}
                        buttonText="finalize"
                        ConfirmationMessage="finalize_response_statement_confirmation"
                      />
                    </RenderWhenAuthorized>
                  ) : null}

                  {isFinalStage ? (
                    <RenderWhenAuthorized
                      authorizedRoles={ROLE_ACTIONS.responseStatement.update}
                    >
                      <MakeApproveButtonAndModal
                        canApprove={isFinalStage}
                        onApprove={onApprove}
                        warningTitle={t("approve_modal_warning_title")}
                        warningMessage={t("approve_modal_warning_message")}
                        buttonText="approve"
                        ConfirmationMessage="i_confirm"
                      />
                    </RenderWhenAuthorized>
                  ) : null}

                  {(isFinalStage || isApprovedStage) && !showUnPublish ? (
                    <RenderWhenAuthorized
                      authorizedRoles={ROLE_ACTIONS.responseStatement.update}
                    >
                      <BackToDraftButtonAndModal
                        canBackToDraft={true}
                        onBackToDraft={onBackToDraft}
                        warningTitle={t("back_to_draft_modal_warning_title")}
                        warningMessage={t(
                          "back_to_draft_modal_warning_message"
                        )}
                        buttonText="back_to_draft"
                        ConfirmationMessage="i_confirm"
                      />
                    </RenderWhenAuthorized>
                  ) : null}
                </div>
              </div>
              <ResponseStatementCard
                responseStatement={data?.responseStatement}
                onSaveContactOrOrg={onSaveContactOrOrg}
                onRemoveContactOrOrg={onRemoveContactOrOrg}
              />
            </>
          )}
        </Layout.Content>
        <Layout.Sidebar>
          <ResponseStatementSideNav />
        </Layout.Sidebar>
      </Layout.Root>
    </>
  );
};

export default ResponseStatementProfilePage;
