const cosewicTranslation = {
  en: {
    cosewic: "COSEWIC",
    cosewic_information: "COSEWIC information",
    cosewic_id: "COSEWIC ID",
    cosewic_id_export: "COSEWIC ID",
    cosewic_id_status: "COSEWIC ID status",
    wildlife_species: "Wildlife species",
    wildlife_species_information: "Wildlife species information",
    wildlife_species_stage: "Wildlife species stage",
    wildlife_species_status_and_criteria:
      "Wildlife species status and criteria",
    edit_wildlife_species_status_and_criteria:
      "Edit wildlife species status and criteria",
    status_criteria: "Status criteria",
    applicability_of_criteria: "Applicability of criteria",
    edit_designation: "Edit designation",
    ws_id: "Wildlife species ID",
    add_ws: "Add COSEWIC wildlife species",
    cosewic_ws_name: "COSEWIC wildlife species name",
    common_name: "Common name",
    update_ws: "Update wildlife species",
    scientific_name: "Scientific name",
    view_ws: "View wildlife species",
    make_permanent: "Make permanent",
    name_with_comma: "Name With Comma",
    basic_information: "Basic information",
    select_a_group: "Select a group",
    endemic_status: "Endemic status",
    select_a_status: "Select a status",
    select_locations: "Select locations",
    select_a_population: "Select a population",
    ssp_msg:
      '"ssp." will precede the subspecies in the scientific name for vascular plants.',
    var_msg:
      '"var." will precede the variety in the scientific name for vascular plants.',
    cosewic_sciname_warning_title: "Scientific name is incomplete.",
    cosewic_sciname_warning_message:
      "In order for a COSEWIC wildlife species to become permanent, the scientific name must have a species property.",
    delete_a_cosewicws: "Delete COSEWIC wildlife species",
    delete_cosewic_warning:
      "You will not be able to recover this COSEWIC wildlife species",
    delete_confirm: "I confirm that I would like to delete.",
    add_new_assessment: "Add a new assessment",
    edit_assessment: "Edit assessment",
    cosewic_assessment: "COSEWIC assessment",
    permanent_cosewic_warning:
      "Are you sure you want to make this wildlife species permanent?",
    confirm_make_permanent:
      "I confirm that I would like to make the wildlife species permanent.",
    edit_cosewic_information: "Edit COSEWIC information",
    confirm_split: "I confirm that I'd like to split.",
    latest_assessment_date: "Latest assessment date",
    number_of_duplicates: "Number of duplicates (children)",
    related_species: "Related species",
    relationship_types: "Relationship types",
    select_all_siblings: "Select all siblings",
    sibling: "Sibling",
    split: "Split",
    split_cosewic_ws: "Split COSEWIC wildlife species",
    split_settings: "Split settings",
    update_siblings: "Update siblings",
    view_children: "View children",
    view_parents: "View parents",
    view_siblings: "View siblings",
    split_warning: "You will be creating {{count}} wildlife species",
    split_success_msg:
      "You've successfully created {{count}} new wildlife species.",
    sibling_update_success_msg: "Successfully updated siblings",
    merge: "Merge",
    cosewic_ws_to_merge: "COSEWIC wildlife species to merge",
    merge_settings: "Merge settings",
    merge_cosewic_ws: "Merge COSEWIC wildlife species",
    merge_warning: "You will be creating {{count}} wildlife species",
    merge_success_msg:
      "You've successfully created {{count}} new wildlife species.",
    confirm_merge: "I confirm that I'd like to merge.",
    other_recognized_names: "Other recognized names",
    other_recognized_english_common_names:
      "Other recognized common names (English)",
    other_recognized_french_common_names:
      "Other recognized common names (French)",
    other_recognized_scientific_names: "Other recognized scientific names",
    indigenous_names: "Indigenous names",
    publish_ws_to_registry_warning:
      "You will be publishing this wildlife species to the registry",
    publish_to_registry_confirm:
      "I confirm that I’d like to publish to registry.",
    cosewic_status: "COSEWIC status",
    select_an_assessment: "Select an assessment",
    not_related_to_an_assessment: "Not related to an assessment",
    maximum_parents_warning:
      "The maximum of {{number}} wildlife species have been added to the list.",
    selected_ws_already_added_to_list:
      "The selected wildlife species has already been added to the list.",
    enter_a_cosewic_id_or_name: "Enter a COSEWIC ID or name",
    merge_info_message:
      "You must have 2 or more assessed wildlife species selected to proceed with a merge. Use the search tool to add one or more wildlife species to the list.",
    other_recognized_names_placeholder:
      "Select any part of the text that you want to italicize",
    required_to_make_ws_permanent_tooltip:
      "Field required to make this wildlife species permanent.",
    species_required_to_make_ws_permanent_tooltip:
      'Field "species" is required to make this wildlife species permanent.',
    must_be_assessed_to_make_ws_permanent_tooltip:
      'The assessment must be "assessed" to make this wildlife species permanent.',
    ws_has_unpublished_changes_warning_title:
      "Wildlife species information has unpublished changes.",
    change_has_not_been_published: '"{{change}}" has not been published.',
  },
  fr: {
    cosewic: "COSEPAC",
    cosewic_information: "Information du COSEPAC",
    cosewic_id: "ID du COSEPAC",
    cosewic_id_export: "Identifiant COSEPAC",
    cosewic_id_status: "Statut de l'ID du COSEPAC",
    wildlife_species: "Espèce sauvage",
    wildlife_species_information: "Information sur l'espèce sauvage",
    wildlife_species_stage: "Stade de l'espèce sauvage",
    wildlife_species_status_and_criteria:
      "Statut de l'espèce sauvage et critères d'évaluation",
    edit_wildlife_species_status_and_criteria:
      "Modifier le statut et les critères d'évaluation de l'espèce sauvage",
    status_criteria: "Critères de désignation du statut",
    applicability_of_criteria: "Applicabilité des critères",
    edit_designation: "Modifier la désignation",
    ws_id: "ID de l'espèce sauvage",
    name_with_comma: "Nom avec virgule",
    add_ws: "Ajouter l'espèce sauvage du COSEPAC",
    cosewic_ws_name: "Nom de l'espèce sauvage du COSEPAC",
    common_name: "Nom commun",
    update_ws: "Mettre à jour l'espèce sauvage",
    scientific_name: "Nom scientifique",
    view_ws: "Voir l'espèce sauvage",
    make_permanent: "Mettre le stade à permanent",
    basic_information: "Information de base",
    select_a_group: "Sélectionnez un groupe",
    endemic_status: "Statut endémique",
    select_a_status: "Sélectionnez un statut",
    select_locations: "Sélectionnez les lieux",
    select_a_population: "Sélectionnez une population",
    ssp_msg:
      "« ssp.» précédera la sous-espèce dans le nom scientifique des plantes vasculaires.",
    var_msg:
      "« var. » précédera la variété dans le nom scientifique des plantes vasculaires.",
    cosewic_sciname_warning_title: "Le nom scientifique est incomplet.",
    cosewic_sciname_warning_message:
      "Pour qu'une espèce sauvage du COSEPAC devienne permanente, le nom scientifique doit avoir une espèce identifiée.",
    delete_a_cosewicws: "Supprimer l'espèce sauvage du COSEPAC",
    delete_cosewic_warning:
      "Vous ne pourrez pas récupérer cette espèce sauvage du COSEPAC",
    delete_confirm: "Je confirme que je souhaite supprimer.",
    add_new_assessment: "Ajouter une nouvelle évaluation",
    edit_assessment: "Modifier l'évaluation",
    cosewic_assessment: "Évaluation du COSEPAC",
    permanent_cosewic_warning:
      "Êtes-vous certain de vouloir rendre cette espèce sauvage permanente ?",
    confirm_make_permanent:
      "Je confirme que je souhaite rendre cette espèce sauvage permanente.",
    edit_cosewic_information: "Modifier l'information du COSEPAC",
    confirm_split: "Je confirme que je souhaite diviser.",
    latest_assessment_date: "Date de la dernière évaluation",
    number_of_duplicates: "Nombre de duplicatas (enfants)",
    related_species: "Espèces apparentées",
    relationship_types: "Types de relation",
    select_all_siblings: "Sélectionnez tous les frères et soeurs",
    sibling: "Frère et sœur",
    split: "Division",
    split_cosewic_ws: "Diviser l'espèce sauvage du COSEPAC",
    split_settings: "Paramètres de division",
    update_siblings: "Mise à jour des frères et soeurs",
    view_children: "Voir les enfants",
    view_parents: "Voir les parents",
    view_siblings: "Voir les frères et sœurs",
    split_warning: "Vous allez créer {{count}} espèces sauvages",
    split_success_msg:
      "Vous avez réussi à créer {{count}} nouvelles espèces sauvages.",
    sibling_update_success_msg: "Frères et sœurs mis à jour avec succès",
    merge: "Fusion",
    cosewic_ws_to_merge: "Espèces sauvages du COSEPAC à fusionner",
    merge_settings: "Paramètres de fusion",
    merge_cosewic_ws: "Fusionner les espèces sauvages du COSEPAC",
    merge_warning: "Vous allez créer {{count}} espèces sauvages",
    merge_success_msg:
      "Vous avez réussi à créer {{count}} nouvelles espèces sauvages.",
    confirm_merge: "Je confirme que je souhaite fusionner.",
    other_recognized_names: "Autres noms reconnus",
    other_recognized_english_common_names:
      "Autres noms communs reconnus (anglais)",
    other_recognized_french_common_names:
      "Autres noms communs reconnus (français)",
    other_recognized_scientific_names: "Autres noms scientifiques reconnus",
    indigenous_names: "Noms autochtones",
    publish_ws_to_registry_warning:
      "Vous allez publier cette espèce sauvage dans le Registre.",
    publish_to_registry_confirm:
      "Je confirme que je souhaite publier dans le Registre.",
    cosewic_status: "Statut du COSEPAC",
    select_an_assessment: "Sélectionnez une évaluation",
    not_related_to_an_assessment: "Non lié à une évaluation",
    maximum_parents_warning:
      "Le maximum de {{number}} espèces sauvages a été ajouté à la liste.",
    selected_ws_already_added_to_list:
      "L'espèce sauvage sélectionnée a déjà été ajoutée à la liste.",
    enter_a_cosewic_id_or_name: "Entrez un ID ou un nom du COSEPAC",
    merge_info_message:
      "Vous devez avoir sélectionné 2 espèces sauvages évaluées ou plus pour procéder à une fusion. Utilisez l'outil de recherche pour ajouter une ou plusieurs espèces sauvages à la liste.",
    other_recognized_names_placeholder:
      "Sélectionnez la partie du texte que vous voulez mettre en italique",
    required_to_make_ws_permanent_tooltip:
      "Nécessaire pour rendre cette espèce sauvage permanente.",
    species_required_to_make_ws_permanent_tooltip:
      "Le champs 'Espèce' est nécessaire pour rendre cette espèce sauvage permanente.",
    must_be_assessed_to_make_ws_permanent_tooltip:
      "L'évaluation doit être 'évalué' pour rendre cette espèce sauvage permanente.",
    ws_has_unpublished_changes_warning_title:
      "L'information sur l'espèce sauvage comporte des changements non publiés.",
    change_has_not_been_published: '"{{change}}" n\'a pas été publié.',
  },
};

export default cosewicTranslation;
