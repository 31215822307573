import { useMutation, useQuery } from "@apollo/client";
import GraphqlError from "components/GraphqlError";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import SafeRenderHtml from "components/atoms/SafeRenderHtml";
import Layout from "components/layouts/TwoColumn";
import PermitSideNav from "components/molecules/permit/PermitSideNav";
import PermitDescriptionForm, {
  PermitDescriptionFormFields,
} from "components/organisms/permit/permitDescriptionForm/PermitDescriptionForm";
import {
  mapDomainModelToForm,
  mapFormToDomainModel,
} from "components/organisms/permit/permitDescriptionForm/mappers";
import { useGlobalAlertContext } from "features/globalAlert";
import {
  PermitDescriptionDocument,
  PermitDescriptionStage,
  UpdatePermitDescriptionDocument,
} from "generated/gql-types";
import i18n from "i18n";
import { bilingualRichTextObjectForLanguage } from "mappers";
import * as React from "react";
import { SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";
import { PermitPathHelpers } from "../PermitRouter";

export interface PermitEditPageProps {}

export const PermitEditPage: React.FC<PermitEditPageProps> = () => {
  const { t } = useTranslation();
  const { params } = useRouteMatch();
  const { permitId } = params as any;
  const history = useHistory();
  const alertContext = useGlobalAlertContext();

  const { loading, error, data } = useQuery(PermitDescriptionDocument, {
    variables: { permitDescriptionId: permitId },
  });

  const [updatePermitDescription, { loading: saving, error: savingError }] =
    useMutation(UpdatePermitDescriptionDocument, { errorPolicy: "all" });

  const onSubmit: SubmitHandler<Partial<PermitDescriptionFormFields>> = async (
    FilteredFormData
  ) => {
    const mappedDomainModel = mapFormToDomainModel(FilteredFormData);

    try {
      const res = await updatePermitDescription({
        variables: {
          updatePermitDescriptionId: permitId,
          input: mappedDomainModel,
        },
      });

      if (res.errors) throw res.errors;

      alertContext.showSuccess({
        title: t("permit_description_successfully_updated"),
        message:
          bilingualRichTextObjectForLanguage(
            i18n.language,
            res.data?.updatePermitDescription?.title
          )?.plainText ?? "",
        timeOut: 5000,
      });

      history.push({
        pathname: PermitPathHelpers.PermitProfile(permitId ?? "error"),
      });
    } catch (e) {
      console.error(e);
    }
  };

  const onCancel = () => {
    history.push({
      pathname: PermitPathHelpers.PermitProfile(permitId ?? "error"),
    });
  };

  const permitDescription = data?.permitDescription;
  const initialFormFields = mapDomainModelToForm(permitDescription);
  const isFinalStage =
    permitDescription?.stage === PermitDescriptionStage.Final;

  const pageTitle =
    i18n.language === "fr"
      ? permitDescription?.title?.french?.text
      : permitDescription?.title?.english?.text;

  const requiresValidation = permitDescription?.requiresValidation;
  const isDmtsPermitDescription = permitDescription?.isDmtsPermitDescription;

  if (!requiresValidation) {
    history.push({
      pathname: PermitPathHelpers.PermitProfile(permitId ?? "error"),
    });
  }

  return (
    <>
      <Layout.Root className="mrgn-tp-md">
        <Layout.Content>
          <h1>
            {t("edit")} {!loading && <SafeRenderHtml htmlString={pageTitle} />}
          </h1>
          <GraphqlError
            title="Error loading permit description"
            errors={error}
          />
          <GraphqlError
            title="Error updating permit description"
            errors={savingError}
          />
          {loading ? (
            <LoadingIndicator />
          ) : (
            <PermitDescriptionForm
              initialValues={initialFormFields}
              isFinalStage={isFinalStage}
              isDmtsPermitDescription={isDmtsPermitDescription}
              onSubmit={onSubmit}
              onCancel={onCancel}
            />
          )}
        </Layout.Content>
        <Layout.Sidebar>
          <PermitSideNav />
        </Layout.Sidebar>
      </Layout.Root>
    </>
  );
};

export default PermitEditPage;
