import { Item } from "@react-stately/collections";
import { useOverlayTriggerState } from "@react-stately/overlays";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import { MenuButton } from "components/atoms/MenuButton/MenuButton";
import SafeRenderHtml from "components/atoms/SafeRenderHtml";
import InfoButtonAndModal from "components/organisms/InfoButtonAndModal/InfoButtonAndModal";
import { RenderWhenAuthorized, ROLE_ACTIONS } from "features/auth/components";
import {
  BptProjectActivity,
  BptProjectStage,
  Maybe,
} from "generated/gql-types";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { PopupMenuKey } from "types/bpt/enums";
import htmlRemoveOuterPTag from "util/htmlRemoveOuterPTag";
import { ActivityInfo } from "../../Information/ActivityInfo";
import PopupMenuModal, {
  TypeOfQueryInputProps,
} from "../../Utility/PopupMenuModal";
import StatusTag from "../../Utility/StatusTag";
import ProjectActivityInformation from "../ActivityInformation/ProjectActivityInformation";

export interface ProjectActivityDetailsProps
  extends Omit<TypeOfQueryInputProps, "TypeOfQueryInputProps"> {
  projectId?: string;
  projectActivityInfo?: Maybe<BptProjectActivity>;
  isLoading?: boolean;
}

export const ProjectActivityDetails: React.FC<ProjectActivityDetailsProps> = (
  props
) => {
  const { t } = useTranslation();
  const {
    projectId,
    projectActivityInfo,
    isLoading,
    isPhaseCompleted,
    isProjectCompleted,
  } = props;

  const stage = projectActivityInfo?.stage;
  const isActivityCompleted = stage === BptProjectStage.Completed;
  const isActivityNotStarted = stage === BptProjectStage.NotStarted;
  const isActivitySkipped = stage === BptProjectStage.Skipped;

  // --------------- Popup Menu Edit/Skip ---------------- //
  const modal = useOverlayTriggerState({});
  const adminAlertMsgTitle = t("project_activity");
  const [menuTypeKey, setMenuTypeKey] = React.useState("");

  const onSelectionChange = (key: any) => {
    switch (key) {
      case PopupMenuKey.EDIT: {
        modal.open();
        setMenuTypeKey(PopupMenuKey.EDIT);
        break;
      }
      case PopupMenuKey.SKIP: {
        modal.open();
        setMenuTypeKey(PopupMenuKey.SKIP);
        break;
      }
      case PopupMenuKey.UNDO_SKIP: {
        setMenuTypeKey(PopupMenuKey.UNDO_SKIP);
        props.onSkip(
          projectId!,
          projectActivityInfo?.id!,
          BptProjectStage.NotStarted,
          "BptProjectActivity"
        );
        break;
      }
    }
  };

  return (
    <>
      <details
        key={projectActivityInfo?.id}
        className="detail-list detail-list-activity expand-collapse-list"
      >
        <summary>
          <div className="flexbug-9-workaround">
            <div className="flex justify-between align-center">
              <h5>
                <SafeRenderHtml
                  htmlString={htmlRemoveOuterPTag(
                    projectActivityInfo?.name?.text ?? "Error"
                  )}
                />
                <InfoButtonAndModal
                  title={projectActivityInfo?.name?.plainText}
                  buttonTitle={t("template_information")}
                  buttonIcon={<i className="fas fa-info-circle"></i>}
                >
                  <ActivityInfo dataInfo={projectActivityInfo} />
                </InfoButtonAndModal>
              </h5>
              <div className="flex gap-sm align-center">
                <StatusTag
                  showHOC={projectActivityInfo?.includeHouseOfCommons!}
                  stage={stage}
                />
                {isProjectCompleted ? null : (
                  <RenderWhenAuthorized
                    authorizedRoles={ROLE_ACTIONS.bptProject.update}
                  >
                    <MenuButton
                      label={""}
                      onAction={onSelectionChange}
                      className="btn btn-link card-header-button-link font-size-14 hover-grey"
                      disabled={false}
                      title={t("more_actions")}
                      buttonIcon={<i className="fas fa-ellipsis-v"></i>}
                      loading={isLoading}
                    >
                      <Item key={PopupMenuKey.EDIT}>
                        <span
                          className="font-size-14 px-3"
                          title={t("edit_activity")}
                        >
                          {<i className="fas fa-pen mrgn-rght-sm"></i>}{" "}
                          {t("edit")}
                        </span>
                      </Item>
                      {!isPhaseCompleted &&
                        !isActivityCompleted &&
                        isActivityNotStarted && (
                          <Item key={PopupMenuKey.SKIP}>
                            <span
                              className="font-size-14 px-3"
                              title={t("skip_activity")}
                            >
                              {
                                <i className="far fa-times-circle mrgn-rght-sm"></i>
                              }{" "}
                              {t("skip")}
                            </span>
                          </Item>
                        )}
                      {!isPhaseCompleted &&
                        !isActivityCompleted &&
                        isActivitySkipped && (
                          <Item key={PopupMenuKey.UNDO_SKIP}>
                            <span
                              className="font-size-14 px-3"
                              title={t("undo_skip_activity")}
                            >
                              {<i className="fas fa-sync-alt mrgn-rght-sm"></i>}{" "}
                              {t("undo_skip")}
                            </span>
                          </Item>
                        )}
                    </MenuButton>
                  </RenderWhenAuthorized>
                )}
              </div>
            </div>
          </div>
        </summary>

        {isLoading ? (
          <LoadingIndicator centered className="mrgn-bttm-md" />
        ) : (
          <ProjectActivityInformation
            projectActivityInfo={projectActivityInfo}
          />
        )}
      </details>

      <>
        {/* --------------- Popup Menu Edit and Skip ---------------- */}
        <PopupMenuModal
          __typename={"BptProjectActivity"}
          menuTypeKey={menuTypeKey}
          modal={modal}
          projectId={projectId}
          dataInfo={projectActivityInfo as BptProjectActivity}
          adminAlertMsgTitle={adminAlertMsgTitle}
          isCompleted={isActivityCompleted || isPhaseCompleted}
          {...props}
        />
      </>
    </>
  );
};

export default ProjectActivityDetails;
