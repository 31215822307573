const bptTemplatesTranslations = {
  en: {
    sar_recovery_templates: "Sar Recovery Templates",
    template_description: "Template description",
    object_type: "Object type",
    template_name: "Template name",
    template_information: "Template information",
    template_program: "Program",
    template_visibility: "Visibility",
    template_object_type: "Object type",
    calculated_duration: "Calculated duration",
    total_calculated_duration: "Total calculated duration",
    html_activate_template_warning:
      "<p><strong>Please confirm you wish to activate this template.</strong></p><p>By activating the new draft template, only the new version will be made available to create new projects. New projects will use the new version. This will not impact any existing started/in-progress or completed projects.</p>",
    activate: "Activate",
    activate_fail: "Activation Failed",
    bpt_template_successfully_activated: "BPT Template successfully activated",
    html_deactivate_template_warning:
      "<p><strong>Are you sure you want to deactivate this template?</strong></p><p>This will prevent future projects from being created using the template.  This will not impact existing started/in-progress or completed projects.</p><p>The template cannot be reactivated.</p>",
    bpt_template_successfully_deleted:
      "BPT Template draft successfully deleted",
    bpt_template_successfully_created_new_version:
      "New draft version has been successfully created.<br/> The existing template version remains usable.",
    bpt_template_successfully_deactivated:
      "BPT Template successfully deactivated",
    expand_all: "Expand all",
    collapse_all: "Collapse all",
    phase: "Phase",
    phases: "Phases",
    phase_name: "Phase name",
    delete_phase: "Delete phase",
    html_delete_phase_warning:
      "<p><strong>Are you sure want to delete this phase?</strong></p><p>This will permanently delete this phase and its information, including all steps and activities associated with this phase.</p>",
    add_phase: "Add phase",
    delete_draft: "Delete draft",
    delete_draft_template: "Delete draft template",
    delete_draft_warning:
      "<p><strong>Are you sure want to delete this draft template?</strong></p><p>The draft will be deleted permanently and cannot be recovered.</p>",
    activate_template: "Activate template",
    deactivate_template: "Deactivate template",
    create_new_version: "Create new version",
    Go_To_New_Version: "Go to new version",
    create_new_draft_success:
      "<p><strong>New draft version has been successfully created.</strong></p><p>The existing template version remains usable.</p>",
    draft_Already_Exist:
      "<p><strong>Looks like a new draft version already exists.</strong></p><p>You may edit the existing draft template.</p>",
    html_create_new_version_warning:
      "<p><strong>Please confirm you wish to create a new draft version of this activated template.</strong></p><p>This new draft version will have the same name. This will not impact existing started/in-progress or completed projects.</p>",
    activate_template_confirm: "Activate template confirm",
    deactivate: "Deactivate",
    deactivate_template_confirm: "Deactivate template confirm",
    estimated_duration: "Estimated duration",
    estimated_duration_business_days: "Estimated duration (business days)",
    include_house_of_common_holidays: "Include House of Common Holidays",
    enter_an_phase_name: "Enter an phase name",
    phase_description: "Phase description",
    phase_information: "Phase information",
    successfully_added_phase: "Successfully added phase",
    successfully_delete_phase: "Successfully deleted phase",
    create_new_phase: "Create new phase",
    successfully_updated_phase: "Successfully updated phase",
    successfully_update_phase_order: "Successfully updated phase order",
    edit_phase: "Edit phase",
    business_days: "Business days",
    number_business_days: "# business days",
    manage_templates: "Manage templates",
    create_new_bpt_template: "Create new BPT template",
    visibility: "Visibility",
    reorder: "Reorder",
    reorder_phases: "Reorder phases",
    reorder_steps: "Reorder steps",
    reorder_activities: "Reorder activities",
    edit_bpt_template: "Edit BPT template",
    updated_bpt_template: "BPT Template Updated",
    added_new_bpt_template: "Added BPT Template",
    bpt_template: "BPT template",
    manage_bpt_templates: "Manage BPT templates",
    version: "Version",
    all_templates: "All templates",
    include_deactivated_and_obsolete_templates:
      "Include deactivated and obsolete templates",
    no_phases_please_add:
      "There are no phases, please add a phase to get started.",
    reorder_phases_message:
      "Reorder the phases by using the move up and move down buttons to move them into the desired order.",
    move_up: "Move up",
    move_down: "Move down",
    business_process_tracking_templates:
      "Business Process Tracking (BPT) templates",
    error_loading_bpt:
      "Error loading Business Process Tracking (BPT) templates",
    business_process_tracking: "Business Process Tracking",

    // Enums
    ALL_PROGRAMS: "All programs",
    OBSOLETE: "Obsolete",

    // Steps definitions
    step: "Step",
    steps: "Steps",
    step_name: "Step name",
    add_step: "Add step",
    reorder_and_bundle_steps: "Reorder and bundle steps",
    reorder_and_bundle_steps_message:
      "Reorder the steps by using the move up and move down buttons to move them into the desired order.",
    no_steps_please_add:
      "There are no steps, please add a step to get started.",
    delete_step: "Delete step",
    return_to: "Return to",
    switch_to_other_phase: "Switch to other phase: ",
    select_phase: "Select phase",
    bpt_template_phase: "BPT template phase",
    step_information: "Step information",
    step_owner: "Step owner",
    edit_step: "Edit step",
    can_not_be_calculated_tooltip:
      "Cannot be calculated as some durations were not entered",
    value_is_required_to_calculate_durations_tooltip:
      "Value is required to calculate durations",
    enter_step_name: "Enter step name",
    enter_your_text: "Enter your text",
    enter_group_role_or_person_responsible:
      "Enter group, role or person responsible",
    step_description: "Step description",
    successfully_added_step: "Successfully added step",
    successfully_updated_step: "Successfully updated step",
    view_details: "View details",
    html_delete_step_warning:
      "<p><strong>Are you sure want to delete this step?</strong></p><p>This will permanently delete this step and all its information, including all activities associated with this step.</p>",

    // Activity definitions
    activity: "Activity",
    activities: "Activities",
    activity_name: "Activity name",
    add_activity: "Add activity",
    reorder_activity: "Reorder activities",
    reorder_activity_message:
      "Reorder the activities by using the move up and move down buttons to move them into the desired order.",
    no_activities_please_add:
      "There are no activities, please add a activity to get started.",
    delete_activity: "Delete activity",

    activity_information: "Activity information",
    activity_owner: "Activity owner",
    edit_activity: "Edit activity",
    enter_activity_name: "Enter activity name",

    activity_description: "Activity description",
    successfully_added_activity: "Successfully added activity",
    successfully_updated_activity: "Successfully updated activity",
    successfully_deleted_activity: "Successfully deleted activity",

    html_delete_activity_warning:
      "<p><strong>Are you sure want to delete this activity?</strong></p><p>This will permanently delete this activity and all its information.</p>",
    reorder_and_bundle_activities: "Reorder and bundle activities",
    error_update_bpt_template_activity: "Error Update BPT Template Activity",
    error_create_bpt_template_activity: "Error Create BPT Template Activity",
    error_loading_bpt_template_activity: "Error Loading BPT Template Activity",
    error_update_bpt_template_step: "Error Update BPT Template Step!",
    error_create_bpt_template_step: "Error Create BPT Template Step!",
    error_update_bpt_template_phase: "Error Update BPT Template Phase!",
    error_create_bpt_template_phase: "Error Create BPT Template Phase!",
    error_loading_bpt_template_phase: "Error Loading BPT Template Phase!",
    error_loading_bpt_template_phase_list:
      "Error Loading BPT Template Phase List!",
    error_loading_bpt_template_step_list:
      "Error Loading BPT Template Step List!",
    error_loading_bpt_Template: "Error Loading BPT Template!",
    error_loading_latest_bpt_template_draft:
      "Error Loading Latest BPT Template Draft!",
    error_deleting_bpt_template: "Error Deleting BPT Template!",
    error_copying_to_latest_draft_bpt_template:
      "Error Copying To Latest BPT Template Draft!",

    no_phases: "There are no phases.",
    no_steps: "There are no steps.",
    error_phases_reorder: "Error phases reorder!",
    error_steps_reorder: "Error steps reorder!",
    create_new_bundle_add_steps:
      "Create a new bundle and add the selected steps into it",
    remove_step_from_bundle: "Remove step from bundle",
    select_for_bundling: "Select for bundling",
    add_selected_steps_into_this_bundle:
      "Add the selected step(s) into this bundle",
    reorder_steps_message:
      "Reorder the steps by using the move up and move down buttons to move them into the desired order.",
    successfully_update_step_order: "Successfully updated step order",
    enter_group_role_or_person_responsible:
      "Enter group, role or person responsible",

    create_new_bundle_add_activities:
      "Create a new bundle and add the selected activities into it",
    remove_activity_from_bundle: "Remove activity from bundle",
    add_selected_activities_into_this_bundle:
      "Add the selected activity(ies) into this bundle",
    error_activities_reorder: "Error activities reorder!",
    reorder_activities_message:
      "Reorder the activities by using the move up and move down buttons to move them into the desired order.",
    successfully_update_activity_order: "Successfully updated activity order",
    select_template: "Select a template",
  },

  fr: {
    sar_recovery_templates: "Sar Recovery Templates [FR]",
    template_description: "Template description[FR]",
    object_type: "Object type[FR]",
    template_name: "Template name[FR]",
    template_information: "Template information[FR]",
    template_program: "Program[FR]",
    template_visibility: "Visibility[FR]",
    template_object_type: "Object type[FR]",
    calculated_duration: "Calculated duration[FR]",
    total_calculated_duration: "Total calculated duration[FR]",
    activate_fail: "Activation Failed[FR]",
    bpt_template_successfully_activated:
      "BPT Template successfully activated[FR]",
    bpt_template_successfully_deleted:
      "L'ébauche de modèle a été supprimée avec succès",
    bpt_template_successfully_created_new_version:
      "L'ébauche de la nouvelle version a été créée.<br />La version actuelle du modèle reste utilisable.",
    bpt_template_successfully_deactivated:
      "BPT Template successfully deactivated[FR]",
    html_activate_template_warning:
      "<p><strong>Veuillez confirmer que vous souhaitez activer ce modèle.</strong></p><p>En activant l'ébauche de la nouvelle version du modèle, seulement la nouvelle version sera disponible pour créer de nouveaux projets. Les nouveaux projets utiliseront la nouvelle version. Cela n'aura pas d'impact sur les projets en cours ou terminés.</p>",
    activate: "Activate[FR]",
    html_deactivate_template_warning:
      "<p><strong>Êtes-vous certain de vouloir désactiver ce modèle ?</strong></p><p>Cette désactivation empêchera la création de futurs projets à l'aide du modèle.  Cela n'aura pas d'incidence sur les projets en cours ou terminés. Le modèle ne peut être réactivé.</p>",
    expand_all: "Expand all[FR]",
    collapse_all: "Collapse all[FR]",
    phase: "Phase[FR]",
    phases: "Phases[FR]",
    phase_name: "Phase name[FR]",
    delete_phase: "Delete phase[FR]",
    html_delete_phase_warning:
      "<p><strong>[FR]Are you sure want to delete this phase?</strong></p><p>This will permanently delete this phase and its information, including all steps and activities associated with this phase.</p>",
    add_phase: "Add phase[FR]",
    delete_draft: "Delete draft[FR]",
    delete_draft_template: "Delete draft template[FR]",
    delete_draft_warning: "Delete draft warning[FR]",
    activate_template: "Activate template[FR]",
    deactivate_template: "Deactivate template[FR]",
    activate_template_confirm: "Activate template confirm[FR]",
    deactivate: "Deactivate[FR]",
    deactivate_template_confirm: "Deactivate template confirm[FR]",
    create_new_version: "Créer une nouvelle version",
    create_new_draft_success:
      "<p><strong>L'ébauche de la nouvelle version a été créée.<strong></p><p>La version actuelle du modèle reste utilisable.</p>",
    draft_Already_Exist:
      "<p><strong>Il semble qu'une ébauche de la nouvelle version existe déjà.</strong></p><p>Vous pouvez modifier la version existante.</p>",
    Go_To_New_Version: "Aller à la nouvelle version",
    html_create_new_version_warning:
      "<p><strong>Veuillez confirmer que vous souhaitez créer une ébauche de la nouvelle version de ce modèle activé.</strong></p><p>Cette opération créera une nouvelle version brouillon portant le même nom. Cela n'aura pas d'impact sur les projets existants commencés/en cours ou terminés.</p>",
    estimated_duration: "Estimated duration[FR]",
    estimated_duration_business_days: "Estimated duration (business days)[FR]",
    include_house_of_common_holidays: "Include House of Common Holidays[FR]",
    enter_an_phase_name: "Enter an phase name[FR]",
    phase_description: "Phase description[FR]",
    phase_information: "Phase information[FR]",
    successfully_added_phase: "Successfully added phase[FR]",
    successfully_delete_phase: "Successfully deleted phase[FR]",
    create_new_phase: "Create new phase[FR]",
    successfully_updated_phase: "Successfully updated phase[FR]",
    successfully_update_phase_order: "Successfully updated phase order[FR]",
    edit_phase: "Edit phase[FR]",
    business_days: "Business days[FR]",
    number_business_days: "# business days[FR]",
    manage_templates: "Manage templates[FR]",
    create_new_bpt_template: "Create new BPT template[FR]",
    visibility: "Visibility[FR]",
    reorder: "Reorder[FR]",
    reorder_phases: "Reorder phases[FR]",
    reorder_steps: "Reorder steps[FR]",
    reorder_activities: "Reorder activities[FR]",
    edit_bpt_template: "Edit BPT template[FR]",
    updated_bpt_template: "BPT Template Updated[FR]",
    added_new_bpt_template: "Added BPT Template[FR]",
    bpt_template: "BPT template[FR]",
    manage_bpt_templates: "Manage BPT templates[FR]",
    version: "Version",
    all_templates: "All templates[FR]",
    include_deactivated_and_obsolete_templates:
      "Include deactivated and obsolete templates[FR]",
    no_phases_please_add:
      "There are no phases, please add a phase to get started. [FR]",
    reorder_phases_message:
      "Reorder the phases by using the move up and move down buttons to move them into the desired order. [FR]",
    move_up: "Move up [FR]",
    move_down: "Move down [FR]",
    business_process_tracking_templates:
      "Modèles des Suivis de processus d'affaires (SPA)",
    error_loading_bpt:
      "Erreur de chargement des Modèles des Suivis de processus d'affaires (SPA)",
    business_process_tracking: "Suivis de processus d'affaires",

    // Enums
    ALL_PROGRAMS: "All programs [Fr]",
    OBSOLETE: "Obsolete [FR]",

    // Steps definitions
    step: "[FR]Step",
    steps: "[FR]Steps",
    step_name: "[FR]Step name",
    add_step: "[FR]Add step",
    reorder_and_bundle_steps: "[FR]Reorder and bundle steps",
    reorder_and_bundle_steps_message:
      "[FR]Reorder the steps by using the move up and move down buttons to move them into the desired order.",
    no_steps_please_add:
      "[FR]There are no steps, please add a step to get started.",
    delete_step: "[FR]Delete step",
    return_to: "[FR]Return to",
    switch_to_other_phase: "[FR]Switch to other phase: ",
    select_phase: "[FR]Select phase",
    bpt_template_phase: "[FR]BPT template phase",
    step_information: "[FR]Step information",
    step_owner: "[FR]Step owner",
    edit_step: "[FR]Edit step",
    can_not_be_calculated_tooltip:
      "[FR]Cannot be calculated as some durations were not entered",
    value_is_required_to_calculate_durations_tooltip:
      "[FR]Value is required to calculate durations",
    enter_step_name: "[FR]Enter step name",
    step_description: "[FR]Step description",
    successfully_added_step: "[FR]Successfully added step",
    successfully_updated_step: "[FR]Successfully updated step",
    view_details: "[FR]View details",
    html_delete_step_warning:
      "<p><strong>[FR]Are you sure want to delete this step?</strong></p><p>This will permanently delete this step and all its information, including all activities associated with this step.</p>",

    // Activity definitions
    activity: "[FR]Activity",
    activities: "[FR]Activities",
    activity_name: "[FR]Activity name",
    add_activity: "[FR]Add activity",
    reorder_activity: "[FR]Reorder activities",
    reorder_activity_message:
      "[FR]Reorder the activities by using the move up and move down buttons to move them into the desired order.",
    no_activities_please_add:
      "[FR]There are no activities, please add a activity to get started.",
    delete_activity: "[FR]Delete activity",

    activity_information: "[FR]Activity information",
    activity_owner: "[FR]Activity owner",
    edit_activity: "[FR]Edit activity",
    enter_activity_name: "[FR]Enter activity name",

    activity_description: "[FR]Activity description",
    successfully_added_activity: "[FR]Successfully added activity",
    successfully_updated_activity: "[FR]Successfully updated activity",
    successfully_deleted_activity: "[FR]Successfully deleted activity",

    html_delete_activity_warning:
      "<p><strong>[FR]Are you sure want to delete this activity?</strong></p><p>This will permanently delete this activity and all its information.</p>",
    reorder_and_bundle_activities: "[FR]Reorder and bundle activities",
    error_update_bpt_template_activity:
      "[FR]Error Update BPT Template Activity",
    error_create_bpt_template_activity:
      "[FR]Error Create BPT Template Activity",
    error_loading_bpt_template_activity:
      "[FR]Error Loading BPT Template Activity",
    error_update_bpt_template_step: "[FR]Error Update BPT Template Step!",
    error_create_bpt_template_step: "[FR]Error Create BPT Template Step!",
    error_update_bpt_template_phase: "[FR]Error Update BPT Template Phase!",
    error_create_bpt_template_phase: "[FR]Error Create BPT Template Phase!",
    error_loading_bpt_template_phase: "[FR]Error Loading BPT Template Phase!",
    error_loading_bpt_template_phase_list:
      "[FR]Error Loading BPT Template Phase List!",
    error_loading_bpt_template_step_list:
      "[FR]Error Loading BPT Template Step List!",
    error_loading_bpt_Template: "[FR]Error Loading BPT Template!",
    error_loading_latest_bpt_template_draft:
      "[FR]Error Loading Latest BPT Template Draft!",
    error_deleting_bpt_template: "[FR]Error Deleting BPT Template!",
    error_copying_to_latest_draft_bpt_template:
      "[FR]Error Copying To Latest BPT Template Draft!",

    no_phases: "[FR]There are no phases.",
    no_steps: "[FR]There are no steps.",
    error_phases_reorder: "[FR]Error phases reorder!",
    error_steps_reorder: "[FR]Error steps reorder!",
    create_new_bundle_add_steps:
      "[FR]Create a new bundle and add the selected steps into it",
    remove_step_from_bundle: "[FR]Remove step from bundle",
    select_for_bundling: "[FR]Select for bundling",
    add_selected_steps_into_this_bundle:
      "[FR]Add the selected step(s) into this bundle",
    reorder_steps_message:
      "[FR]Reorder the steps by using the move up and move down buttons to move them into the desired order.",
    successfully_update_step_order: "[FR]Successfully updated step order",
    enter_group_role_or_person_responsible:
      "[FR]Enter group, role or person responsible",
    create_new_bundle_add_activities:
      "[FR]Create a new bundle and add the selected activities into it",
    remove_activity_from_bundle: "[FR]Remove activity from bundle",
    add_selected_activities_into_this_bundle:
      "[FR]Add the selected activity(ies) into this bundle",
    error_activities_reorder: "[FR]Error activities reorder!",
    reorder_activities_message:
      "[FR]Reorder the activities by using the move up and move down buttons to move them into the desired order.",
    successfully_update_activity_order:
      "[FR]Successfully updated activity order",
    select_template: "[FR]Select a template",
  },
};

export default bptTemplatesTranslations;
