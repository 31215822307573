import MissingData from "components/atoms/MissingData";
import { TooltipAlert } from "components/atoms/Tooltip/TooltipAlert";
import * as React from "react";
import { useTranslation } from "react-i18next";

export interface TotalCalculatedDurationProps
  extends React.DetailedHTMLProps<
    React.SelectHTMLAttributes<HTMLSelectElement>,
    HTMLSelectElement
  > {
  calculatedDuration?: number | null;
}

export const TotalCalculatedDuration = React.forwardRef(
  (props: TotalCalculatedDurationProps, ref) => {
    const { t } = useTranslation();
    const { calculatedDuration } = props;

    return (
      <div className="font-size-16 text-muted">
        {t("calculated_duration")}
        {": "}
        {calculatedDuration == null ? <MissingData /> : calculatedDuration}
        <TooltipAlert isVisible={calculatedDuration == null}>
          {t("can_not_be_calculated_tooltip")}
        </TooltipAlert>
      </div>
    );
  }
);

export default TotalCalculatedDuration;
