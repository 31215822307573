import {
  BptProjectStep,
  BptProjectStepBundle,
  Maybe,
} from "generated/gql-types";
import * as React from "react";
import ProjectStepDetails from "./ProjectStepDetails";
import { TypeOfQueryInputProps } from "../../Utility/PopupMenuModal";

export interface ProjectStepBundlesSectionProps
  extends Omit<TypeOfQueryInputProps, "TypeOfQueryInputProps"> {
  isOpen?: boolean;
  projectStepBundles?: Maybe<Maybe<BptProjectStepBundle>[]>;
  projectId?: string;
  isLoading?: boolean;
}

export const ProjectStepBundlesSection: React.FC<
  ProjectStepBundlesSectionProps
> = (props) => {
  const { projectStepBundles, isOpen, projectId, isLoading } = props;

  return (
    <>
      {projectStepBundles?.map((projectStepBundle, n) => {
        const isBundle =
          projectStepBundle?.steps != null &&
          projectStepBundle?.steps?.length >= 2;

        return (
          <div
            key={n}
            className={
              isBundle ? "template-list-bundle" : "template-list-single"
            }
          >
            {projectStepBundle?.steps?.map((step, index) => {
              return (
                <ProjectStepDetails
                  key={index}
                  isOpen={isOpen}
                  projectStepInfo={step as BptProjectStep}
                  projectId={projectId}
                  isLoading={isLoading}
                  {...props}
                />
              );
            })}
          </div>
        );
      })}
    </>
  );
};
