import {
  BptTemplate,
  BptTemplateActivity,
  BptTemplateActivityCreateInput,
  BptTemplateInput,
  BptTemplatePhase,
  BptTemplatePhaseInput,
  BptTemplateStep,
  BptTemplateStepCreateInput,
} from "generated/gql-types";
import { set } from "lodash";
import { FieldNamesMarkedBoolean, UnpackNestedValue } from "react-hook-form";
import * as FormMappers from "util/formMappers";
import htmlIsNullOrEmpty from "util/htmlIsNullOrEmpty";
import { filterForDirtyFields } from "../../../../util/forms";
import isNullOrEmpty from "../../../../util/isNullOrEmpty";
import { ActivityFormFields } from "./Activity/ActivityForm";
import { PhaseFormFields } from "./Phase/PhaseForm";
import { StepFormFields } from "./Step/StepForm";
import { BptTemplateFormFields } from "./Template/BptTemplateForm";

export const processPhaseFormValues = (
  formData: UnpackNestedValue<Partial<PhaseFormFields>>,
  dirtyFields: FieldNamesMarkedBoolean<any>,
  initialValues: Partial<PhaseFormFields>
): Partial<PhaseFormFields> => {
  const values = filterForDirtyFields(formData, dirtyFields);
  setNullForPhaseEmptyFields(values, dirtyFields, initialValues);
  return values;
};

const setNullForPhaseEmptyFields = (
  formData: UnpackNestedValue<Partial<PhaseFormFields>>,
  dirtyFields: FieldNamesMarkedBoolean<any>,
  initialValues: Partial<PhaseFormFields>
) => {
  // We use lodash set() in order to make sure each level of a nested path exists when we set a value.
  // PhaseFormFields
  if (
    dirtyFields?.name != null &&
    isNullOrEmpty(formData?.name?.text) &&
    !isNullOrEmpty(initialValues?.name?.text)
  ) {
    set(formData, "name.text", null);
  }

  if (
    dirtyFields?.description &&
    htmlIsNullOrEmpty(formData?.description?.text) &&
    !isNullOrEmpty(initialValues?.description?.text)
  ) {
    set(formData, "description.text", null);
  }
};

export const domainModelIntoPhaseForm = (
  phase?: BptTemplatePhase | null
): Partial<PhaseFormFields> => {
  const out: Partial<PhaseFormFields> = {};

  // name
  FormMappers.mapRichText(out, "name", phase?.name);

  // Phase description
  FormMappers.mapRichText(out, "description", phase?.description);
  return out;
};

export const phaseFormIntoCreateInput = (
  formData: Partial<PhaseFormFields>
): BptTemplatePhaseInput => {
  const out: BptTemplatePhaseInput = {};

  // name
  FormMappers.mapRichTextInput(out, "name", formData?.name);

  // description
  FormMappers.mapRichTextInput(out, "description", formData?.description);

  return out;
};

export const BptTemplateDomainModelIntoForm = (
  bptTemplate?: BptTemplate | null
): Partial<BptTemplateFormFields> => {
  const out: Partial<BptTemplateFormFields> = {};
  // name
  FormMappers.mapRichText(out, "name", bptTemplate?.name);
  // description
  FormMappers.mapRichText(out, "description", bptTemplate?.description);
  // program
  FormMappers.mapBilingualAbbreviationAcronymTagsLookupText(
    out,
    "program",
    bptTemplate?.program
  );
  // object type
  FormMappers.mapBilingualAbbreviationAcronymTagsLookupText(
    out,
    "objectType",
    bptTemplate?.objectType
  );
  // Visibility
  FormMappers.mapPrimitiveType(out, "visibility", bptTemplate?.visibility);

  return out;
};

export const processBptTemplateFormValues = (
  formData: UnpackNestedValue<Partial<BptTemplateFormFields>>,
  dirtyFields: FieldNamesMarkedBoolean<any>,
  initialValues: Partial<BptTemplateFormFields>
): Partial<BptTemplateFormFields> => {
  const values = filterForDirtyFields(formData, dirtyFields);
  setNullForBptTemplateEmptyFields(values, dirtyFields, initialValues);
  return values;
};

const setNullForBptTemplateEmptyFields = (
  formData: UnpackNestedValue<Partial<BptTemplateFormFields>>,
  dirtyFields: FieldNamesMarkedBoolean<any>,
  initialValues: Partial<BptTemplateFormFields>
) => {
  // We use lodash set() in order to make sure each level of a nested path exists when we set a value.
  // BptTemplateFormFields
  if (
    dirtyFields?.program?.name != null &&
    isNullOrEmpty(formData?.program?.name) &&
    !isNullOrEmpty(initialValues?.program?.name)
  ) {
    set(formData, "program", null);
  }

  if (
    dirtyFields?.visibility &&
    htmlIsNullOrEmpty(formData?.visibility) &&
    !isNullOrEmpty(initialValues?.visibility)
  ) {
    set(formData, "visibility", null);
  }

  if (
    dirtyFields?.name?.text != null &&
    isNullOrEmpty(formData?.name?.text) &&
    !isNullOrEmpty(initialValues?.name?.text)
  ) {
    set(formData, "name.text", null);
  }
  if (
    dirtyFields?.description?.text != null &&
    isNullOrEmpty(formData?.description?.text) &&
    !isNullOrEmpty(initialValues?.description?.text)
  ) {
    set(formData, "description.text", null);
  }

  if (
    dirtyFields?.objectType?.name != null &&
    isNullOrEmpty(formData?.objectType?.name) &&
    !isNullOrEmpty(initialValues?.objectType?.name)
  ) {
    set(formData, "objectType", null);
  }
};

export const formIntoCreateInputBptTemplate = (
  formData: Partial<BptTemplateFormFields>,
  initialValues?: Partial<BptTemplateFormFields>,
  isCreate?: boolean
): BptTemplateInput => {
  const out: BptTemplateInput = {};
  // name
  FormMappers.mapRichTextInput(out, "name", formData?.name);
  // description
  if (formData?.description != null) {
    FormMappers.mapRichTextInput(
      out,
      "description",
      isNullOrEmpty(formData?.description?.text) ? null : formData?.description
    );
  }

  // program
  if (formData?.program != null) {
    FormMappers.mapBilingualAbbreviationAcronymTagsLookupTextInput(
      out,
      "program",
      formData?.program
    );
  } else if (isCreate) {
    FormMappers.mapBilingualAbbreviationAcronymTagsLookupTextInput(
      out,
      "program",
      initialValues?.program
    );
  }

  // object type
  FormMappers.mapBilingualAbbreviationAcronymTagsLookupTextInput(
    out,
    "objectType",
    formData?.objectType
  );

  // Visibility
  if (formData?.visibility != null) {
    FormMappers.mapPrimitiveTypeInput(out, "visibility", formData?.visibility);
  } else if (isCreate) {
    FormMappers.mapPrimitiveTypeInput(
      out,
      "visibility",
      initialValues?.visibility
    );
  }

  return out;
};

export const processStepFormValues = (
  formData: UnpackNestedValue<Partial<StepFormFields>>,
  dirtyFields: FieldNamesMarkedBoolean<any>,
  initialValues: Partial<StepFormFields>
): Partial<StepFormFields> => {
  const values = filterForDirtyFields(formData, dirtyFields);
  setNullForStepEmptyFields(values, dirtyFields, initialValues);
  return values;
};

const setNullForStepEmptyFields = (
  formData: UnpackNestedValue<Partial<StepFormFields>>,
  dirtyFields: FieldNamesMarkedBoolean<any>,
  initialValues: Partial<StepFormFields>
) => {
  // We use lodash set() in order to make sure each level of a nested path exists when we set a value.
  // StepFormFields
  if (
    dirtyFields?.name != null &&
    isNullOrEmpty(formData?.name?.text) &&
    !isNullOrEmpty(initialValues?.name?.text)
  ) {
    set(formData, "name.text", null);
  }

  if (
    dirtyFields?.owner != null &&
    isNullOrEmpty(formData?.owner?.text) &&
    !isNullOrEmpty(initialValues?.owner?.text)
  ) {
    set(formData, "owner.text", null);
  }

  if (
    dirtyFields?.estimatedDuration != null &&
    isNullOrEmpty(formData?.estimatedDuration) &&
    !isNullOrEmpty(initialValues?.estimatedDuration)
  ) {
    set(formData, "estimatedDuration", null);
  }

  if (
    dirtyFields?.description &&
    htmlIsNullOrEmpty(formData?.description?.text) &&
    !isNullOrEmpty(initialValues?.description?.text)
  ) {
    set(formData, "description.text", null);
  }

  if (
    dirtyFields?.includeCommonHoliday &&
    formData?.includeCommonHoliday === initialValues?.includeCommonHoliday
  ) {
    set(formData, "includeCommonHoliday", null);
  }
};

export const domainModelIntoStepForm = (
  step?: BptTemplateStep | null
): Partial<StepFormFields> => {
  const out: Partial<StepFormFields> = {};

  // name
  FormMappers.mapRichText(out, "name", step?.name);

  // Owner
  FormMappers.mapRichText(out, "owner", step?.owner);

  // Estimate duration
  FormMappers.mapPrimitiveType(
    out,
    "estimatedDuration",
    step?.estimatedDuration
  );

  // Step description
  FormMappers.mapRichText(out, "description", step?.description);

  // Step includeCommonHoliday
  FormMappers.mapPrimitiveType(
    out,
    "includeCommonHoliday",
    step?.includeHouseOfCommons,
    false
  );

  // id
  FormMappers.mapPrimitiveType(out, "id", step?.id);

  return out;
};

export const stepFormIntoCreateInput = (
  formData: Partial<StepFormFields>
): BptTemplateStepCreateInput => {
  const out: BptTemplateStepCreateInput = {};

  // name
  FormMappers.mapRichTextInput(out, "name", formData?.name);

  // Owner
  FormMappers.mapRichTextInput(out, "owner", formData?.owner);

  // estimatedDuration
  FormMappers.mapPrimitiveTypeInput(
    out,
    "estimatedDuration",
    formData?.estimatedDuration != null
      ? parseInt(String(formData?.estimatedDuration))
      : formData?.estimatedDuration
  );

  // description
  FormMappers.mapRichTextInput(out, "description", formData?.description);

  // includeHouseOfCommons
  FormMappers.mapPrimitiveTypeInput(
    out,
    "includeHouseOfCommons",
    formData?.includeCommonHoliday
  );

  return out;
};

export const processActivityFormValues = (
  formData: UnpackNestedValue<Partial<ActivityFormFields>>,
  dirtyFields: FieldNamesMarkedBoolean<any>,
  initialValues: Partial<ActivityFormFields>
): Partial<ActivityFormFields> => {
  const values = filterForDirtyFields(formData, dirtyFields);
  setNullForActivityEmptyFields(values, dirtyFields, initialValues);
  return values;
};

const setNullForActivityEmptyFields = (
  formData: UnpackNestedValue<Partial<ActivityFormFields>>,
  dirtyFields: FieldNamesMarkedBoolean<any>,
  initialValues: Partial<ActivityFormFields>
) => {
  // We use lodash set() in order to make sure each level of a nested path exists when we set a value.
  // ActivityFormFields
  if (
    dirtyFields?.name != null &&
    isNullOrEmpty(formData?.name?.text) &&
    !isNullOrEmpty(initialValues?.name?.text)
  ) {
    set(formData, "name.text", null);
  }

  if (
    dirtyFields?.owner != null &&
    isNullOrEmpty(formData?.owner?.text) &&
    !isNullOrEmpty(initialValues?.owner?.text)
  ) {
    set(formData, "owner.text", null);
  }

  if (
    dirtyFields?.estimatedDuration != null &&
    isNullOrEmpty(formData?.estimatedDuration) &&
    !isNullOrEmpty(initialValues?.estimatedDuration)
  ) {
    set(formData, "estimatedDuration", null);
  }

  if (
    dirtyFields?.description &&
    htmlIsNullOrEmpty(formData?.description?.text) &&
    !isNullOrEmpty(initialValues?.description?.text)
  ) {
    set(formData, "description.text", null);
  }

  if (
    dirtyFields?.includeCommonHoliday &&
    formData?.includeCommonHoliday === initialValues?.includeCommonHoliday
  ) {
    set(formData, "includeCommonHoliday", null);
  }
};

export const domainModelIntoActivityForm = (
  activity?: BptTemplateActivity | null
): Partial<ActivityFormFields> => {
  const out: Partial<ActivityFormFields> = {};

  // name
  FormMappers.mapRichText(out, "name", activity?.name);

  // Owner
  FormMappers.mapRichText(out, "owner", activity?.owner);

  // Estimate duration
  FormMappers.mapPrimitiveType(
    out,
    "estimatedDuration",
    activity?.estimatedDuration
  );

  // Step description
  FormMappers.mapRichText(out, "description", activity?.description);

  // Step includeCommonHoliday
  FormMappers.mapPrimitiveType(
    out,
    "includeCommonHoliday",
    activity?.includeHouseOfCommons,
    false
  );

  // id
  FormMappers.mapPrimitiveType(out, "id", activity?.id);

  return out;
};

export const activityFormIntoCreateInput = (
  formData: Partial<ActivityFormFields>
): BptTemplateActivityCreateInput => {
  const out: BptTemplateActivityCreateInput = {};

  // name
  FormMappers.mapRichTextInput(out, "name", formData?.name);

  // Owner
  FormMappers.mapRichTextInput(out, "owner", formData?.owner);

  // estimatedDuration
  FormMappers.mapPrimitiveTypeInput(
    out,
    "estimatedDuration",
    formData?.estimatedDuration != null
      ? parseInt(String(formData?.estimatedDuration))
      : formData?.estimatedDuration
  );

  // description
  FormMappers.mapRichTextInput(out, "description", formData?.description);

  // includeHouseOfCommons
  FormMappers.mapPrimitiveTypeInput(
    out,
    "includeHouseOfCommons",
    formData?.includeCommonHoliday
  );

  return out;
};
