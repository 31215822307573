import { useMutation, useQuery } from "@apollo/client";
import GraphqlError from "components/GraphqlError";
import LoadingIndicator from "components/atoms/LoadingIndicator";
import MissingData from "components/atoms/MissingData";
import SafeRenderHtml from "components/atoms/SafeRenderHtml";
import Layout from "components/layouts/TwoColumn";
import DocumentsSideNav from "components/molecules/documents/DocumentsSideNav";
import RegistryPageNeedsPublishChangesWarning from "components/molecules/documents/RegistryPageNeedsPublishChangesWarning";
import { ArchiveUnArchiveButtonAndModal } from "components/organisms/ArchiveUnArchiveButtonAndModal/ArchiveUnArchiveButtonAndModal";
import DocumentInfoCard from "components/organisms/documents/DocumentInfoCard/DocumentInfoCard";
import FilesCard from "components/organisms/documents/FilesCard/FilesCard";
import { ROLE_ACTIONS, RenderWhenAuthorized } from "features/auth/components";
import { GlobalAlert, useGlobalAlertContext } from "features/globalAlert";
import {
  ArchiveStatus,
  DocumentDocument,
  DocumentStage,
  DocumentState,
  UpdateDocumentStageDocument,
} from "generated/gql-types";
import * as React from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";
import htmlIsNullOrEmpty from "util/htmlIsNullOrEmpty";
import htmlRemoveOuterPTag from "util/htmlRemoveOuterPTag";
import { DocumentsPathHelpers } from "../DocumentsRouter";

export interface DocumentsProfilePageProps {}

export const DocumentsProfilePage: React.FC<DocumentsProfilePageProps> = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const alertContext = useGlobalAlertContext();
  const { params } = useRouteMatch();
  const { documentId } = params as any;

  const { loading, error, data, refetch } = useQuery(DocumentDocument, {
    errorPolicy: "all",
    variables: {
      documentId: documentId,
    },
    fetchPolicy: "network-only",
  });

  const [updateDocumentStage, updateDocumentStageMutationStatus] = useMutation(
    UpdateDocumentStageDocument,
    {
      refetchQueries: ["Document"],
      errorPolicy: "all",
    }
  );

  const title = useMemo(() => {
    if (i18n.language === "fr") {
      if (!htmlIsNullOrEmpty(data?.document?.title?.french?.text)) {
        return data?.document?.title?.french?.text;
      } else {
        return data?.document?.title?.english?.text ?? "MISSING TITLE";
      }
    }

    if (!htmlIsNullOrEmpty(data?.document?.title?.english?.text)) {
      return data?.document?.title?.english?.text;
    } else {
      return data?.document?.title?.french?.text ?? "MISSING TITLE";
    }
  }, [i18n.language, data?.document?.title]);

  const onArchive = async () => {
    try {
      const res = await updateDocumentStage({
        variables: {
          updateDocumentStageId: documentId,
          archiveStatus: ArchiveStatus.Inactive,
        },
      });
      if (res.errors) throw res.errors;
      alertContext.showSuccess({
        message: t("document_archived_successfully"),
        timeOut: 5000,
      });

      history.push({
        pathname: DocumentsPathHelpers.DocumentsProfile(documentId ?? "error"),
      });
    } catch (e) {
      console.error(e);
    }
  };

  const canArchive = React.useMemo(() => {
    if (loading) return false;

    if (data?.document?.stage === DocumentStage.Active) {
      return true;
    }
    return false;
  }, [data?.document?.stage, loading]);

  const onUnArchive = async () => {
    try {
      const res = await updateDocumentStage({
        variables: {
          updateDocumentStageId: documentId,
          archiveStatus: ArchiveStatus.Active,
        },
      });
      if (res.errors) throw res.errors;
      alertContext.showSuccess({
        message: t("document_unarchived_successfully"),
        timeOut: 5000,
      });

      history.push({
        pathname: DocumentsPathHelpers.DocumentsProfile(documentId ?? "error"),
      });
    } catch (e) {
      console.error(e);
    }
  };

  const canUnArchive = React.useMemo(() => {
    if (loading) return false;

    if (data?.document?.stage === DocumentStage.Inactive) {
      return true;
    }
    return false;
  }, [data?.document?.stage, loading]);

  const showArchive = React.useMemo(() => {
    if (loading) return false;

    if (
      data?.document?.stage === DocumentStage.Active &&
      data?.document?.state === DocumentState.NotPublished
    ) {
      return true;
    }
    return false;
  }, [data?.document?.stage, data?.document?.state, loading]);

  const showUnArchive = React.useMemo(() => {
    if (loading) return false;

    if (
      data?.document?.stage === DocumentStage.Inactive &&
      data?.document?.state === DocumentState.NotPublished
    ) {
      return true;
    }
    return false;
  }, [data?.document?.stage, data?.document?.state, loading]);

  return (
    <>
      <Layout.Root className="mrgn-tp-md">
        <Layout.Content>
          <GlobalAlert />
          <RegistryPageNeedsPublishChangesWarning documentId={documentId} />
          <h1>
            {!loading && (
              <SafeRenderHtml htmlString={htmlRemoveOuterPTag(title)} />
            )}
          </h1>
          <GraphqlError
            title="Error loading document information"
            errors={error}
          />
          <GraphqlError
            title="Error updating stage of Document"
            errors={updateDocumentStageMutationStatus.error}
          />
          {loading ? (
            <LoadingIndicator />
          ) : (
            <>
              <div className="flex justify-between align-center mrgn-bttm-md">
                <div className="flex font-size-18">
                  <div>
                    {t("stage")}{" "}
                    <div className="label label-info">
                      {data?.document?.stage ? (
                        t(data?.document?.stage)
                      ) : (
                        <MissingData />
                      )}
                    </div>
                  </div>
                  <div className="mrgn-lft-md">
                    {t("state")}{" "}
                    <div className="label label-info">
                      {data?.document?.state ? (
                        t(data?.document?.state)
                      ) : (
                        <MissingData />
                      )}
                    </div>
                  </div>
                </div>
                {showArchive && (
                  <RenderWhenAuthorized
                    authorizedRoles={
                      ROLE_ACTIONS.documents.deactivateReactivate
                    }
                  >
                    <ArchiveUnArchiveButtonAndModal
                      canAction={canArchive}
                      onAction={onArchive}
                      warningMessage={t("archive_document_modal_message")}
                      buttonText="archive"
                      modalTitle={t("archive_document")}
                    />
                  </RenderWhenAuthorized>
                )}

                {showUnArchive && (
                  <RenderWhenAuthorized
                    authorizedRoles={
                      ROLE_ACTIONS.documents.deactivateReactivate
                    }
                  >
                    <ArchiveUnArchiveButtonAndModal
                      canAction={canUnArchive}
                      onAction={onUnArchive}
                      warningMessage={t("unarchive_document_modal_message")}
                      buttonText="unarchive"
                      modalTitle={t("unarchive_document")}
                    />
                  </RenderWhenAuthorized>
                )}
              </div>
              <DocumentInfoCard documentData={data?.document} />
              <FilesCard documentData={data?.document} refetchQuery={refetch} />
            </>
          )}
        </Layout.Content>
        <Layout.Sidebar>
          <DocumentsSideNav />
        </Layout.Sidebar>
      </Layout.Root>
    </>
  );
};

export default DocumentsProfilePage;
